import {

  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
  Button,

  Zoom,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
// import IncidentTable from './IncidentTable';
// import ListOfVictims from './ListOfVictims';
import axios from "axios";
import MissingDocument from "../MissingDocument";
import VictimsTable from "./tables/VictimsTable";
// import AdverseVehicleTable from "./tables/AdverseVehicleTable";
// import PropertyTable from "./tables/PropertyTable";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { v4 as uuidv4 } from "uuid";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import BusinessRules from "./checklist/BusinessRules";
import { NotificationContext } from "../../contexts/NotificationContext";
import {
  capitalizeAndReplaceUnderscore,
  isValidDate,
  isValidEmail,
  isValidTime,
} from "../../utils/stringUtils";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";
// import { AnyAaaaRecord } from "dns";

// const label = { inputProps: { 'aria-label': 'Switch demo' } };

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function MotorVehicleForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  policyNo,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });

  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [victimsTable, setVictimsTable] = React.useState({
    headers: [],
    rows: [],
  });
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { validateBusinessRules } = useBusinessRules();
  const { notification, setNotification } =
    React.useContext(NotificationContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/motor-vehicle-form?transId=${transID}&id=${docId}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {

            const data = res?.data?.data;
            const tblVictimItemsJSON = res.data.data.tableVictims
              ? JSON.parse(res.data.data.tableVictims)
              : null;


            if (tblVictimItemsJSON) {
              const tblShopItemRows = tblVictimItemsJSON.rows.map(
                (row: any) => {
                  // Generate a unique ID for each row
                  const id = uuidv4();

                  // Handle the case where row is empty
                  if (row.length === 0) {
                    return tblVictimItemsJSON.header.reduce(
                      (acc: any, key: any) => {
                        acc[key] = "";
                        return acc;
                      },
                      { id }
                    ); // Include unique id in the object
                  }

                  return row.reduce(
                    (acc: any, value: any, index: any) => {
                      acc[tblVictimItemsJSON.header[index]] = value;
                      return acc;
                    },
                    { id }
                  ); // Include unique id in the object
                }
              );

              setVictimsTable({
                headers: tblVictimItemsJSON.header,
                rows: tblShopItemRows,
              });
            }

            const statusOptions: any = {
              0: null,
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };

            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData(
              globalData!.accessToken!,
              {
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View motor vehicle form with document id #${docId}`,
              user: globalData.username,
              fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
              roleLevel: globalData.role
            });
          })
          .catch((err: any) => {
            console.error(err);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to check if the date is not in the future
  const isNotFutureDate = (dateStr: string): boolean => {
    const enteredDate = new Date(dateStr);
    const today = new Date();

    // Normalize the dates to remove time components
    enteredDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return enteredDate <= today;
  };

  // Determine if there's an error
  const hasError =
    mainState.data.adDate === '' ||
    mainState.formError.adDate ||
    !isValidDate(mainState.data.adDate) ||
    !isNotFutureDate(mainState.data.adDate);

  // Determine the helper text based on the error
  const getHelperText = () => {
    if (mainState.data.adDate === '') {
      return 'Date is required';
    }
    if (mainState.formError.adDate) {
      return 'There is an error with the date';
    }
    if (!isValidDate(mainState.data.adDate)) {
      return 'Invalid Date';
    }
    if (!isNotFutureDate(mainState.data.adDate)) {
      return 'Date cannot be in the future';
    }
    return '';
  };

  const updateData = async () => {
    // validate date and time
    const isAdDateValid = isValidDate(mainState?.data?.adDate);
    if (!isAdDateValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          adDate: "Invalid Date Format",
        },
      }));

      alert("Incident Date: Invalid Date Format!");
    }

    const isAdDateNotFuture = isNotFutureDate(mainState?.data?.adDate);
    if (!isAdDateNotFuture) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          adDate: "Date cannot be in the future",
        },
      }));

      alert("Incident Date: Date cannot be in the future!");
    }



    if (!mainState?.data?.nameInsured || mainState?.data?.nameInsured === "") {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          nameInsured: "Empty required field. Please input data",
        },
      }));
      alert("Name of Insured: Empty required field. Please input data");
    }

    const isAdTimeValid = isValidTime(mainState?.data?.adTime);
    if (!isAdTimeValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          adTime: "Invalid Time Format",
        },
      }));

      alert("Incident Time: Invalid Time Format!");
    }

    const isEmailValid = isValidEmail(mainState?.data?.emailAddress);
    if (!isEmailValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          emailAddress: "Invalid Email Format",
        },
      }));
      alert("Email Address: Invalid Email Format!");
    }

    const isPolicyNoValid =
      mainState?.data?.policyNo && mainState?.data?.policyNo !== "";
    if (!isPolicyNoValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          policyNo: "Empty required field. Please input data",
        },
      }));
      alert("Policy No: Empty required field. Please input data");
    }

    const isResidenceAddressValid =
      mainState?.data?.residenceAddress &&
      mainState?.data?.residenceAddress !== "";
    if (!isResidenceAddressValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          residenceAddress: "Empty required field. Please input data",
        },
      }));
      alert("Residence Address: Empty required field. Please input data");
    }

    const isMailingAddressValid =
      mainState?.data?.mailingAddress && mainState?.data?.mailingAddress !== "";
    if (!isMailingAddressValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          mailingAddress: "Empty required field. Please input data",
        },
      }));
      alert("Mailing Address: Empty required field. Please input data");
    }

    const isHomePhoneNoValid =
      mainState?.data?.homePhoneno && mainState?.data?.homePhoneno !== "";
    if (!isHomePhoneNoValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          homePhoneno: "Empty required field. Please input data",
        },
      }));
      alert("Home Phone No: Empty required field. Please input data");
    }

    const isOfficePhoneNoValid =
      mainState?.data?.officePhoneno && mainState?.data?.officePhoneno !== "";
    if (!isOfficePhoneNoValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          officePhoneno: "Empty required field. Please input data",
        },
      }));
      alert("Office Phone No: Empty required field. Please input data");
    }

    const isMobileNoValid =
      mainState?.data?.mobileNo && mainState?.data?.mobileNo !== "";
    if (!isMobileNoValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: {
          ...prev.formError,
          mobileNo: "Empty required field. Please input data",
        },
      }));
      alert("Mobile No: Empty required field. Please input data");
    }

    if (
      isAdDateValid &&
      isAdTimeValid &&
      isValidEmail(mainState?.data?.emailAddress) &&
      mainState?.data?.nameInsured &&
      mainState?.data?.nameInsured !== "" &&
      mainState?.data?.policyNo &&
      mainState?.data?.policyNo !== "" &&
      mainState?.data?.residenceAddress &&
      mainState?.data?.residenceAddress !== "" &&
      mainState?.data?.mailingAddress &&
      mainState?.data?.mailingAddress !== "" &&
      mainState?.data?.homePhoneno &&
      mainState?.data?.homePhoneno !== "" &&
      mainState?.data?.officePhoneno &&
      mainState?.data?.officePhoneno !== "" &&
      mainState?.data?.emailAddress &&
      mainState?.data?.emailAddress !== "" &&
      mainState?.data?.mobileNo &&
      mainState?.data?.mobileNo !== ""
    ) {
      // prepare table of victims
      let tblItemsReq = mainState?.data?.tableItems;
      if (victimsTable?.headers.length > 0) {
        tblItemsReq = JSON.stringify({
          header: victimsTable?.headers,
          rows: victimsTable?.rows.map((obj) => {
            return victimsTable?.headers.map((key) => obj[key]);
          }),
        });
      }
      const dataReq = {
        ...mainState?.data,
        tableVictims: tblItemsReq,
      };


      try {
        setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/motor-vehicle-form?transId=${transID}&id=${docId}`,
          dataReq,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        const data = res.data.data;
        console.log(data)
        const message = res.data.message;

        // const statusOptions: any = {
        //   0: null,
        //   1: { value: 1, label: "Open" },
        //   2: { value: 2, label: "Approve" },
        //   3: { value: 3, label: "Reject" },
        // };
        // setMainState((prevState: any) => ({
        //   ...prevState,
        //   data: data,
        //   selections: { status: statusOptions[data.status] },
        //   formError: data?.error,
        //   isLoading: false,
        // }));

        validateBusinessRules( globalData!.accessToken!,
          `/api/acap/document-validation/motor-vehicle-form/business-rules?transId=${transID}&id=${docId}&taskType=${"document_validation"}`,
          mainState?.data,
          transID,
          "document_validation"
        );

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: transID,
          taskType: "document_validation",
          event: "update_form",
          description: `Update motor vehicle form with document id #${docId}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        });

        setMainState((prev: any) => ({ ...prev, formError: {} }));

        setNotification({
          level: "success",
          showAlert: true,
          message: message,
        });
        // close();
      } catch (error: any) {
        setNotification({
          level: "error",
          showAlert: true,
          message: error.response.data.message,
        });
        alert(error.response.data.message)
        console.error("Error updating data:", error.response.data.message);
        setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
      }
    }
  };

  // const isAnyFieldEmpty = () => {

  //   for (const key in mainState?.data) {
  //     const fieldValue = mainState?.data[key];
  //     if (
  //       key !== 'remarks' && key !== 'id' && key !== 'status' &&
  //       key !== 'transId' && key !== 'sourceId' && key !== 'homePhone' &&
  //       key !== 'officePhone' && key !== 'regOwnerOfVehicleAdverse' && key !== 'makeAndModelAdverse' &&
  //       key !== 'plateNoAdverse' && key !== 'nameOfDriverAdverse' && key !== 'addressAdverse' &&
  //       key !== 'telNoAdverse' && key !== 'relationToOwnerOfVehicleAdverse' && key !== 'licenseNoAdverse' &&
  //       key !== 'issueDateAdverse' && key !== 'insurerOfVehicleAdverse' && key !== 'policyNoAdverse' &&
  //       key !== 'briefDescriptionOfImpactAdverse' && key !== 'incidentDate' && key !== 'incidentTime' &&
  //       key !== 'incidentPlace' && key !== 'incidentFacts' && key !== 'sketchOfIncidentGdriveId' &&
  //       key !== 'relationToOwnerOfVehicleInsured' && key !== 'policyNoInsured' && key !== 'briefDescriptionOfImpactInsured' &&
  //       (typeof fieldValue !== 'string' || !fieldValue.trim())) {

  //       return true;
  //     } else if (key === 'status' && (typeof fieldValue !== 'number' || fieldValue === 0)) {

  //       return true;
  //     }
  //   }
  //   return false;
  // }

  React.useEffect(() => {
    // apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Tooltip
            title="Ex. December 12, 2012"
            placement="top-start"
            TransitionComponent={Zoom}
          >
            <FormControl variant="outlined" fullWidth className="tp-mb-20px">
              <Typography variant="body1" className="tp-text-bold">
                Date Accomplished
              </Typography>
              <OutlinedInput
                id="dateAccomplished"
                size="small"
                type="text"
                placeholder={`Enter Date Accomplished Here...`}
                value={mainState?.data?.dateAccomplished || ""}
                onChange={handleInputChange}
                inputProps={{
                  "aria-label": "dateAccomplished",
                }}
                disabled={disabled}
              />
            </FormControl>
          </Tooltip>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Name of Insured
                  <span className="tp-text-subtitle"></span>
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="nameInsured"
                  size="small"
                  type="text"
                  placeholder={`Enter Name of Insured Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.nameOfInsuredConf + "%"} size="small" className={datadata.nameOfInsuredConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.nameInsured || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('nameOfInsured')}
                  inputProps={{
                    "aria-label": "nameInsured",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.nameInsured === ""}
                />
                {(mainState?.data?.nameInsured ?? "") === "" && (
                  <FormHelperText error id="nameInsured">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Policy No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="policyNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Policy No. Here...`}
                  value={mainState?.data?.policyNo || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "policyNo",
                  }}
                  disabled={false}
                  error={mainState?.data?.policyNo === ""}
                />
                {/* <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleModalOpen}
                      style={{ backgroundColor: 'red' }}
                    >
                      Check Policy No.
                    </Button> */}
                {(mainState?.data?.policyNo ?? "") === "" && (
                  <FormHelperText error id="policyNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Residence Address
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="residenceAddress"
                  size="small"
                  type="text"
                  placeholder={`Enter Residence Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.residenceAddressConf + "%"} size="small" className={datadata.residenceAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.residenceAddress || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('residenceAddress')}
                  inputProps={{
                    "aria-label": "residenceAddress",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.residenceAddress === ""}
                />
                {(mainState?.data?.residenceAddress ?? "") === "" && (
                  <FormHelperText error id="residenceAddress">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Home Phone No.
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="homePhoneno"
                  size="small"
                  type="text"
                  placeholder={`Enter Home Phone Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.homePhoneConf + "%"} size="small" className={datadata.homePhoneConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.homePhoneno || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "homePhoneno",
                  }}
                  disabled={false}
                  error={mainState?.data?.homePhoneno === ""}
                />
                {(mainState?.data?.homePhoneno ?? "") === "" && (
                  <FormHelperText error id="homePhoneno">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Mailing Address
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="mailingAddress"
                  size="small"
                  type="text"
                  placeholder={`Enter Mailing Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mailingAddressConf + "%"} size="small" className={datadata.mailingAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.mailingAddress || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mailingAddress')}
                  inputProps={{
                    "aria-label": "mailingAddress",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.mailingAddress === ""}
                />
                {(mainState?.data?.mailingAddress ?? "") === "" && (
                  <FormHelperText error id="mailingAddress">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Office Phone
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="officePhoneno"
                  size="small"
                  type="text"
                  placeholder={`Enter Office Phone Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.officePhoneConf + "%"} size="small" className={datadata.officePhoneConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.officePhoneno || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "officePhoneno",
                  }}
                  disabled={false}
                  error={mainState?.data?.officePhoneno === ""}
                />
                {(mainState?.data?.officePhoneno ?? "") === "" && (
                  <FormHelperText error id="officePhoneno">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <Tooltip
                title="Ex. juan.delacruz@email.com"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Email Address <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="emailAddress"
                    size="small"
                    type="text"
                    placeholder={`Enter Email Address Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={datadata.emailAddressConf + "%"} size="small" className={datadata.emailAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.emailAddress || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('emailAddress')}
                    inputProps={{
                      "aria-label": "emailAddress",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.emailAddress === "" ||
                      mainState?.formError?.emailAddress ||
                      !isValidEmail(mainState?.data?.emailAddress)
                    }
                  />
                  {(mainState?.data?.emailAddress === "" ||
                    mainState?.formError?.emailAddress ||
                    !isValidEmail(mainState?.data?.emailAddress)) && (
                      <FormHelperText error id="emailAddress">
                        {!isValidEmail(mainState?.data?.emailAddress) &&
                          "Invalid Email Address"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Mobile No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="mobileNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Mobile No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.mobileNoConf + "%"} size="small" className={datadata.mobileNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.mobileNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('mobileNo')}
                  inputProps={{
                    "aria-label": "mobileNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.mobileNo === ""}
                />
                {mainState?.data?.mobileNo === "" && (
                  <FormHelperText error id="mobileNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* <IncidentTable transID={transID} policeReport={false} mainState={mainState} setMainState={setMainState} disabled={disabled} /> */}

          {/* <InsuredVehicleTable transID={transID} /> */}
          <br />
          <br />
          <Typography variant="h6" className="tp-text-header-primary">
            Details of Vehicle
          </Typography>
          <hr />
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Register Owner of Vehicle
                </Typography>
                <OutlinedInput
                  id="registerOwnerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Owner Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.registerOwnerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "registerOwnerVehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
                {mainState?.data?.registerOwnerVehicle === "" && (
                  <FormHelperText error id="registerOwnerVehicle">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Register Owner of Vehicle
                </Typography>
                <OutlinedInput
                  id="aregisterOwnerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Owner Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aregisterOwnerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aregisterOwnerVehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Make & Model
                </Typography>
                <OutlinedInput
                  id="makeModel"
                  size="small"
                  type="text"
                  placeholder={`Enter Make Model Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.makeModel || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "date",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Make & Model
                </Typography>
                <OutlinedInput
                  id="amakeModel"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Make Model Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.amakeModel || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "amakeModel",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Plate No.
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Plate No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "plateNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Plate No.
                </Typography>
                <OutlinedInput
                  id="aplateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Plate No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aplateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aplateNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Name of Driver
                </Typography>
                <OutlinedInput
                  id="nameDriver"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Name Driver Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.nameDriver || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "nameDriver",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Name of Driver
                </Typography>
                <OutlinedInput
                  id="anameDriver"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Name Driver Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.anameDriver || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "anameDriver",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Address
                </Typography>
                <OutlinedInput
                  id="address"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.address || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "address",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse address
                </Typography>
                <OutlinedInput
                  id="aaddress"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aaddress || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aaddress",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Tel No.
                </Typography>
                <OutlinedInput
                  id="telNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Tel No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.telNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "telNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Tel No.
                </Typography>
                <OutlinedInput
                  id="stelNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Tel No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.stelNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "stelNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Relation to Owner of Vehicle
                </Typography>
                <OutlinedInput
                  id="relationToOwnerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Relation to Owner Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.relationToOwnerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "relationToOwnerVehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Relationship to Owner of Vehicle
                </Typography>
                <OutlinedInput
                  id="arelationToOwnerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Relationship to Owner of Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.arelationToOwnerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "arelationToOwnerVehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured License No.
                </Typography>
                <OutlinedInput
                  id="licenseNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured License No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.licenseNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "licenseNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse License No.
                </Typography>
                <OutlinedInput
                  id="alicenseNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Owner Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.alicenseNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "alicenseNo",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured License Date/Place
                </Typography>
                <OutlinedInput
                  id="issuedDatePlace"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured License Date/Place Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.issuedDatePlace || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "issuedDatePlace",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse License Date/Place
                </Typography>
                <OutlinedInput
                  id="aissuedDatePlace"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse License Date/Place Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aissuedDatePlace || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aissuedDatePlace",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Insurer of Vehicle
                </Typography>
                <OutlinedInput
                  id="insurerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter  Insured Insurer of Vehicle  Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.insurerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "insurerVehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Insured Insurer of Vehicle
                </Typography>
                <OutlinedInput
                  id="aInsurervehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Insured Insurer of Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aInsurervehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aInsurervehicle",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Description of Impact
                </Typography>
                <OutlinedInput
                  id="iimpactDesc"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Description of Impact Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.iimpactDesc || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "iimpactDesc",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Description of Impact
                </Typography>
                <OutlinedInput
                  id="aimpactDesc"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Description of Impact Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aimpactDesc || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('incidentDate')}
                  inputProps={{
                    "aria-label": "aimpactDesc",
                  }}
                  disabled={disabled}
                // error={!!dataformError?.incidentDate}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* <AdverseVehicleTable transID={transID} disabled={disabled} /> */}

          <Typography variant="h6" className="tp-text-header-primary">
            Details of the Incident
          </Typography>
          <hr />
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <Tooltip
                title="Ex. December 12, 2012"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Incident Date
                  </Typography>
                  <OutlinedInput
                    id="adDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Incident Date Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={datadata.incidentDateConf + "%"} size="small" className={datadata.incidentDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.adDate || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('incidentDate')}
                    inputProps={{
                      "aria-label": "adDate",
                    }}
                    disabled={disabled}
                    error={hasError}
                  />
                  {hasError && (
                    <FormHelperText error id="adDate-helper-text">
                      {getHelperText()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <Tooltip
                title="Ex. 08:00 pm"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Incident Time
                  </Typography>
                  <OutlinedInput
                    id="adTime"
                    size="small"
                    type="text"
                    placeholder={`Enter Incident Time Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={datadata.incidentTimeConf + "%"} size="small" className={datadata.incidentTimeConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.adTime || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('incidentTime')}
                    inputProps={{
                      "aria-label": "adTime",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.adTime === "" ||
                      mainState?.formError?.adTime ||
                      !isValidTime(mainState?.data?.adTime)
                    }
                  />
                  {(mainState?.data?.adTime === "" ||
                    mainState?.formError?.adTime ||
                    !isValidTime(mainState?.data?.adTime)) && (
                      <FormHelperText error id="adTime">
                        {!isValidTime(mainState?.data?.adTime) && "Invalid Time"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>

          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Incident Place
              {/* <span className="tp-text-required">*</span> */}
            </Typography>
            <OutlinedInput
              id="adPlace"
              size="small"
              type="text"
              placeholder={`Enter Incident Place Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.incidentPlaceConf + "%"} size="small" className={datadata.incidentPlaceConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.adPlace || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('incidentPlace')}
              inputProps={{
                "aria-label": "adPlace",
              }}
              disabled={disabled}
            // error={!!dataformError?.incidentPlace}
            />
            {/* {!!dataformError?.incidentPlace && (
            <FormHelperText error id="incidentPlace">
              {dataformError?.incidentPlace}
            </FormHelperText>
          )} */}
          </FormControl>

          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Incident Facts
            </Typography>
            <OutlinedInput
              id="adFacts"
              size="small"
              type="text"
              placeholder={`Enter Incident Facts Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.incidentFactsConf + "%"} size="small" className={datadata.incidentFactsConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.adFacts || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('incidentFacts')}
              inputProps={{
                "aria-label": "adFacts",
              }}
              disabled={disabled}
            // error={!!dataformError?.incidentFacts}
            />
            {/* {!!dataformError?.incidentFacts && (
            <FormHelperText error id="incidentFacts">
              {dataformError?.incidentFacts}
            </FormHelperText>
          )} */}
          </FormControl>
          <br />
          {/* <Typography variant="h6" className="tp-text-header-primary">
                Sketch of the Incident
              </Typography>
              <br />
              <hr />
              <Card>
                <CardContent>
                  {mainState?.data?.sketchOfIncidentGDriveId ?
                    <img src={"https://drive.google.com/uc?export=view&id=" + mainState?.data?.sketchOfIncidentGDriveId} width={"30%"} height={"40%"} alt='img' />
                    :
                    <Typography variant="body1" className="tp-text-muted">
                      No Available Sketch of the Incident
                    </Typography>}
                </CardContent>
              </Card> */}
          <br />
          <br />
          {/* <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    With Signature of Driver <span className="tp-text-subtitle">(Insured Vehicle)</span>
                  </Typography>
                  {mainState?.data.signatureInsuredGDriveId ?
                    <img src={"https://drive.google.com/uc?export=view&id=" + mainState?.data?.signatureInsuredGDriveId} width={"30%"} height={"40%"} alt='img' />
                    :
                    <Typography variant="body1" className="tp-text-muted">
                      No Available Signature
                    </Typography>}
                </Grid>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    With Signature of Driver <span className="tp-text-subtitle">(Adverse Vehicle)</span>
                  </Typography>
                  {mainState?.data?.signatureAdverseGdriveId ?
                    <img src={"https://drive.google.com/uc?export=view&id=" + mainState?.data?.signatureAdverseGdriveId} width={"30%"} height={"40%"} alt='img' />
                    :
                    <Typography variant="body1" className="tp-text-muted">
                      No Available Signature
                    </Typography>}
                </Grid>
              </Grid> */}
          <br />
          <Typography variant="h6" className="tp-text-bold tp-text-primary">
            Property/ies Involved other than the Insured vehicles
          </Typography>
          <hr />
          <FormControl
            variant="outlined"
            fullWidth
            className="tp-mb-20px tp-mt-15px"
          >
            <Typography variant="body1" className="tp-text-bold">
              Owner of the properties
            </Typography>
            <OutlinedInput
              id="ownerProperty"
              size="small"
              type="text"
              placeholder={`Enter Owner of the properties Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.propertyOwnerConf + "%"} size="small" className={datadata.propertyOwnerConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.ownerProperty || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('propertyOwner')}
              inputProps={{
                "aria-label": "ownerProperty",
              }}
              disabled={disabled}
            // error={!!dataformError?.propertyOwner}
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Address{" "}
              <span className="tp-text-subtitle">
                (No, Street, City, Municipality, Province, Zip Code)
              </span>
            </Typography>
            <OutlinedInput
              id="propertyAddress"
              size="small"
              type="text"
              placeholder={`Enter Address Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.propertyAddressConf + "%"} size="small" className={datadata.propertyAddressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.propertyAddress || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('propertyAddress')}
              inputProps={{
                "aria-label": "propertyAddress",
              }}
              disabled={disabled}
            // error={!!dataformError?.propertyAddress}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Brief Description of the damage/s sustained
            </Typography>
            <OutlinedInput
              id="propertyDamageDesc"
              size="small"
              type="text"
              placeholder={`Enter Description of the Damage Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.propertyDamageDescriptionConf + "%"} size="small" className={datadata.propertyDamageDescriptionConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.propertyDamageDesc || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('propertyDamageDescription')}
              inputProps={{
                "aria-label": "propertyDamageDesc",
              }}
              disabled={disabled}
            // error={!!dataformError?.propertyDamageDescription}
            />
          </FormControl>

          {/* <PropertyTable transID={transID} disabled={disabled} /> */}

          <br />
          <br />
          {/* <ListOfVictims transID={transID} /> */}
          {/* 
            // #region VictimsTable
          */}
          <VictimsTable
            transID={transID}
            mainState={mainState}
            victimsTable={victimsTable}
            setVictimsTable={setVictimsTable}
            disabled={disabled}
          />

          <br />
          <br />
          {/* 
            // #region BusinessRules
          */}
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"document_validation"} documentType={"motor_vehicle_form"} />
          <br />
          {/* 
            // #region ForProcessor
          */}
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === "" && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            // error={!!dataformError?.remarks}
            />
            {/* {!!dataformError?.remarks && (
            <FormHelperText error id="remarks">
              {dataformError?.remarks}
            </FormHelperText>
          )} */}
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* {option !== 0 && <Button onClick={() => handleBack()} variant="outlined" size='medium' >Back</Button>}&emsp; */}
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={
                      "/api/acap/document-validation/motor-vehicle-form"
                    }
                    documentType={"Motor Vehicle Form"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                    color="error"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
