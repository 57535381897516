import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import * as React from "react";
import invoiceItems from "../../data/isip_invoice_items.json"


{/* <Box
  sx={{
    height: 500, // Increase the height value as desired
    width: "100%",
    "& .actions": {
      color: "text.secondary",
    },
    "& .textPrimary": {
      color: "text.primary",
    },
  }}
></Box> */}
export default function InvoiceTable({ transID }: any) {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const columns = [
    {
        field: "qty",
        type: "qty",
        headerName: "Qty",
        width: 10,
    },
    {
        field: "unit",
        type: "unit",
        headerName: "Unit",
        width: 10,
    },
    {
        field: "description",
        type: "description",
        headerName: "Job Description",
        width: 230,
    },
    {
        field: "labor",
        type: "LABOR",
        headerName: "LABOR",
        width: 150,
    },
    {
      field: "parts",
      type: "PARTS",
      headerName: "PARTS",
      width: 150,
    },
    {
        field: "amount",
        type: "amount",
        headerName: "Amount",
        width: 150,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, isEditMode }: any) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;


        if (isEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon fontSize="large" style={{ color: "#2683e0" }} />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <CancelIcon fontSize="large" style={{ color: "#d30000" }} />
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon fontSize="large" style={{ color: "#2683e0" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rows)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon fontSize="large" style={{ color: "#d30000" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const [rows, setRows] = React.useState<GridRowsProp>([]);
//   const [columns, setColumns] = React.useState<GridColDef[]>(column);

  const handleEditClick = (id: GridRowId, rowData: any) => () => {
    let newRows = rowData;

    const index = newRows.findIndex((data: any) => data.id === id);

    if (index > -1) {
      newRows[index].isEditMode = true;
      setRows(newRows);
    } else {

    }
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));

  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }

  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel
    ) => void;
  }

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleCreateClick = () => {
      const id = randomId();
      setRows((oldRows) => [
        ...oldRows,
        { id, name: "", age: "", isNew: true },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };
    return (
      <GridToolbarContainer>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <Typography
              variant="h6"
              style={{ color: "#2683e0", fontWeight: "bold", padding: "15px" }}
            >
              List of Items
            </Typography>
          </Grid>
          <Grid item md={3}>
            <div
              style={{
                marginTop: "15px",
                paddingRight: "10px",
                float: "right",
              }}
            >
              <Button
                color="primary"
                size="medium"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreateClick}
              >
                Add Item
              </Button>
            </div>
          </Grid>
        </Grid>
        {/* <GridToolbarExport /> */}
      </GridToolbarContainer>
    );
  }

  //#region Private Methods

  // Define a function to fetch data from the API

  // Showing of Data in Production
//   const fetchData = async () => {
//     try {
//       const res = await apiFetch(
//         `api/isip/info/?transId=${transID}&documentType=SalesInvoiceItems`,
//         "GET"
//       );
//       const data = await res.json();
//       const resData = await data["response"]?.replaceAll("\n", "");

//       const parsedData = await JSON.parse(resData);

//       // Columns

//       setColumns((prevState) => [...parsedData["columns"], ...prevState]);

//       setRows(parsedData["rows"]);

//       // // Rows


//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

  // Updating of Data in Production
  // const updateData = async () => {

  //   await apiFetch(`api/bpims/info/?transId=${transID}&documentType=EstimationItems`, 'PUT', JSON.stringify(InvoiceState?.data)) // Replace with your API endpoint
  //     .then(async response => {
  //       const data = await response.json();
  //       const parsedData = JSON.parse(data['res']);

  //       if (!parsedData?.success) {
  //         setInvoiceState(preveState => ({
  //           ...preveState,
  //           formError: { ...preveState.formError, ...parsedData.errors }
  //         }))
  //       } else {
  //         setInvoiceState(preveState => ({
  //           ...preveState,
  //           formError: {}
  //         }))
  //       }
  //     })
  //     .catch(error => {

  //     })
  // }

  // // Use useEffect to fetch data when the component mounts
  React.useEffect(() => {
    setRows(invoiceItems);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  //#endregion Private Methods

  //#region Web Controls

  //#endregion Web Controls

  //#region Control methods

  // const handleInputChange = (e: any) => {
  //   const { value, id } = e.target;
  //   setInvoiceState(preveState => ({
  //     ...preveState,
  //     data: { ...preveState.data, [id]: value },
  //     changed: { ...preveState.changed, [id]: true } // if the value of the params change then set the id of changed variable in to true for saving in the database using onBlur.
  //   }))
  // };

  // const handleBlur = (id) => {
  //   if (InvoiceState.changed[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData()
  //     // do something here.. ex. udpate your state.

  //   }

  // }

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Combined JSON Table (This should be a return/request data from update)


  //#endregion Control Methods
  return (
    <Box
      sx={{
        height: 450,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        disableRowSelectionOnClick
        disableColumnMenu
      />
    </Box>
  );
}
