import React, { useState } from 'react';
import { Button, CircularProgress, Tooltip, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import AWS from 'aws-sdk';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { GlobalDataContext } from "../contexts/GlobalDataContext";

// Configure AWS S3
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;
const REGION = process.env.REACT_APP_REGION!;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY!;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY!;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

interface UploadButtonProps {
  transId: any;
  onVideoUpload: () => void;
}

const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150MB in bytes

const UploadVideos: React.FC<UploadButtonProps> = ({ transId, onVideoUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const { globalData } = React.useContext(GlobalDataContext);
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) return;

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      setMessage({ type: 'error', text: 'File size exceeds 150MB limit.' });
      return;
    }

    const timestamp = Date.now();
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts.pop();
    const fileNameWithoutExt = fileNameParts.join('.');
    const fileNameWithTimestamp = `${fileNameWithoutExt}_${timestamp}.${fileExtension}`;
    const folderName = `ACAP-${transId}/Videos`;

    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${folderName}/${fileNameWithTimestamp}`,
      ContentType: file.type,
    };

    setUploading(true);
    setMessage(null);

    try {
      // Upload file to S3
      await new Promise<void>((resolve, reject) => {
        myBucket.putObject(params).send((err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });

      const fileUrl = `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${folderName}/${fileNameWithTimestamp}`;

      // POST request with file info
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/videos`, {
        transId: transId,
        filename: fileNameWithTimestamp,
        url: fileUrl,
        channel: 1,
        status: 1,
      },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      onVideoUpload();
      setMessage({ type: 'success', text: 'File uploaded successfully!' });
    } catch (error) {
      console.error(error);
      setMessage({ type: 'error', text: 'Error uploading file.' });
    } finally {
      setUploading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setMessage(null);
  };

  return (
    <div>
      <input
        accept="video/mp4,video/quicktime"
        id="upload-button"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
      <Tooltip title="Maximum file size: 150MB" arrow>
        <label htmlFor="upload-button">
          <Button
            variant="contained"
            component="span"
            color="error"
            disabled={uploading}
            startIcon={<VideoFileIcon />}
          >
            Upload Video
          </Button>
        </label>
      </Tooltip>
      {uploading && <CircularProgress style={{ marginLeft: 10 }} />}
      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={message?.type || 'info'} sx={{ width: '100%' }}>
          {message?.text || ''}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadVideos;
