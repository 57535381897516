import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { v4 as uuidv4 } from 'uuid';
import {GlobalDataContext } from "../contexts/GlobalDataContext";

interface FileUploadProps {
    transId: any; // This is the folder name you want to pass
    onFileUpload: () => void; // Callback function to notify file upload completion
}

const ImsiFileUpload: React.FC<FileUploadProps> = ({ transId, onFileUpload }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadMessage, setUploadMessage] = useState(''); // State for success/error message
    const [uploadUrl, setUploadUrl] = useState<string | null>(null); // Store the URL
    const [open, setOpen] = useState(false); // State to handle modal open/close
    const [isUploading, setIsUploading] = useState(false); // State to show loader
    const { globalData } = React.useContext(GlobalDataContext);
    // Function to handle modal open
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Function to handle modal close
    const handleClose = () => {
        setOpen(false);
        setSelectedFiles([]); // Clear selected files when modal closes
        setUploadMessage(''); // Clear message on close
        setUploadUrl(null); // Clear the URL on close
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files as FileList);
        if (files.length > 10) {
            alert('You can only upload a maximum of 10 files.');
        } else {
            setSelectedFiles(files as File[]);
        }
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            alert('Please select files to upload.');
            return;
        }

        setIsUploading(true); // Show loader during upload

        const formData = new FormData();
        selectedFiles.forEach(file => formData.append('files', file));

        // Add the folder name (transId) to the form data
        formData.append('folderName', transId);

        try {
            // Upload files and get the URL from the backend
            const uploadResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${globalData.accessToken}`,
                },
            });

            const s3Url = uploadResponse.data; // Assuming the backend returns the URL
            const randomFileName = `${uuidv4()}.pdf`

            // After getting the S3 URL, send the second POST request
            const otherDocumentData = {
                transId: transId,
                filename: randomFileName, // Hardcoded filename
                url: s3Url, // URL received from the first request
                channel: 1, // Default value
                status: 1, // Default value
                imsi: true
            };

            const documentResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/other-document`, otherDocumentData);

            setUploadMessage('Files uploaded successfully and document information saved.');
            setUploadUrl(documentResponse.data.url); // Store the URL for clickable link

            // Notify the parent component after upload
            onFileUpload();
        } catch (error) {
            console.error('Error uploading files or saving document information', error);
            setUploadMessage('Error uploading files or saving document information.');
        } finally {
            setIsUploading(false); // Hide loader after upload
        }
    };

    return (
        <div>
            {/* Button to open the modal */}
            <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={<FilePresentIcon/>}>
                Upload Files
            </Button>

            {/* File upload modal */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload Files (Max of 10 Files)</DialogTitle>
                <DialogContent>
                    <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .pdf, .tiff"
                        multiple
                        onChange={handleFileChange}
                    />
                    {selectedFiles.length > 0 && (
                        <Typography variant="body2" color="textSecondary">
                            {selectedFiles.length} file(s) selected
                        </Typography>
                    )}
                    {uploadMessage && (
                        <Typography variant="body2" color="textPrimary" style={{ marginTop: '16px' }}>
                            {uploadMessage}{' '}
                            {uploadUrl && (
                                <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
                                    Click here to view the document
                                </a>
                            )}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        {uploadMessage ? 'Close' : 'Cancel'}
                    </Button>
                    {!uploadMessage && (
                        <Button
                            onClick={handleUpload}
                            color="primary"
                            variant="contained"
                            disabled={selectedFiles.length === 0 || isUploading} // Disable if no files or during upload
                        >
                            {isUploading ? <CircularProgress size={24} /> : 'Upload'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ImsiFileUpload;
