import React, { useState } from "react";
import {
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Select from "react-select";
// import { GlobalDataContext } from "../../contexts/GlobalDataContext";

export default function ForProcessor({
  mainState,
  isDisable,
  handleSelectChange,
  transId,
  isTotalLoss,
  isResend,
  onRaEmailValueChange,
}: any) {
  // Initialize raEmail with a default empty string if undefined
  const [raEmail, setRaEmail] = useState<string | undefined>(
    mainState?.selections?.raEmail || ''
  );

  const handleChangeRaEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setRaEmail(newValue); // Update the state with the new value
    onRaEmailValueChange?.(newValue); // Pass the new value directly
  };
  
  // console.log('isResend', isResend);

  return (
    <div>
      <Typography variant="h6" className="tp-text-header-primary">
        For Processor
      </Typography>
      <hr className="tp-border-hr_primary" />

      {isResend ? (
        <FormControl variant="outlined" fullWidth className="tp-mb-20px">
          <Typography variant="body1" className="tp-text-bold">
            CA Email <span className="tp-text-required">*</span>
          </Typography>
          <OutlinedInput
            id="raEmail"
            value={raEmail}
            onChange={handleChangeRaEmail}
            disabled={isDisable}
            placeholder="Enter CA Email"
          />
        </FormControl>
      ) : (
        <FormControl variant="outlined" fullWidth className="tp-mb-20px">
          <Typography variant="body1" className="tp-text-bold">
            Status <span className="tp-text-required">*</span>
          </Typography>
          <Select
            id="status"
            placeholder="Please select status here..."
            value={
              mainState?.selections?.status ||
              (mainState?.statusOptions ? mainState.statusOptions[0] : null)
            }
            isDisabled={isDisable}
            onChange={handleSelectChange}
            options={mainState?.statusOptions || []}
          />
        </FormControl>
      )}
    </div>
  );
}
