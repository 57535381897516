import {
  Box,
  Card,
  CardContent,
  Grid,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import POTable from "./POTable";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import NoImageFound from "../../assets/noFoundImage.json";
import { formatDate } from "../../utils/FormatDate";
import * as React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import {useGlobalData } from '../../contexts/GlobalDataContext';

export default function POValidation({ transID }: any) {
  const [loaState, setLoaState] = React.useState<any>({
    data: {},
    tableData: [],
  });
  const { globalData } = useGlobalData(); // Assuming this is how you access global data
  const isManager = globalData.role === "Isip Manager";

  const { transId } = useParams();

  //#region Private Methods

 

  const fetchData = async () => {
    try {
        (async () => {
            setLoaState({ ...loaState, isLoading: true })
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/loa?transId=${transId}`,
                // headers: {
                //     'Authorization': `Bearer ${globalData?.sessionToken}`
                //   }
            }).then((res: any) => {
                const data = res.data.data[0];
                //const attachmentsData = data['attachments']


                const statusOptions: any = {
                    0: null,
                    1: { value: 1, label: 'Approved' },
                    2: { value: 2, label: 'Reject' }
                }
                setLoaState({
                    ...loaState,
                    data: data,
                    selections: { status: statusOptions[data?.status] }
                })
            }).catch((err: any) => {
                console.error(err?.response?.data)
                let errorMessage = ""
                if (err?.response?.status === 401) errorMessage = err?.response?.data
                if (err?.response?.status === 400) errorMessage = err?.response?.data
                setLoaState({ error: errorMessage, isLoading: false })
            })
        })();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


  // // Use useEffect to fetch data when the component mounts
  React.useEffect(() => {
    fetchData();
   
  }, []); // Empty dependency array means this effect runs only once after the initial render

  const columns = [
    // { field: "id", headerName: "ID", flex: 1 },
    {
      field: "item",
      headerClassName: "super-app-theme--header",
      headerName: "Item",
      sortable: true,
      width: 100,
      editable: false,
    },
    {
      field: "description",
      headerClassName: "super-app-theme--header",
      headerName: "Material/Description",
      sortable: true,
      flex: 1,
      editable: false,
    },
    {
      field: "quantity",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      sortable: true,
      flex: 1,
      editable: false,
    },
    {
      field: "um",
      headerClassName: "super-app-theme--header",
      headerName: "UM",
      sortable: true,
      width: 250,
      editable: false,
    },
    {
      field: "costCenter",
      headerClassName: "super-app-theme--header",
      headerName: "Cost Center",
      sortable: true,
      flex: 1,
      editable: false,
    },
    {
      field: "unitPrice",
      headerClassName: "super-app-theme--header",
      headerName: "Unit Price",
      sortable: true,
      flex: 1,
      editable: false,
    },
    {
      field: "netAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Net Amount",
      sortable: true,
      flex: 1,
      editable: false,
    },
  ];


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    setLoaState({
      ...loaState,
      data: { ...loaState.data, [id]: value }, // if the value of the params change then set the id of changed variable in to true for saving in the database using onBlur.
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={8} md={8}>

        <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Insured Name <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="insured"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Name Here...`}
                 
                  value={loaState.data.insured || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatableSales")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  // error={!!invoiceState.formError?.vatableSales}
                />
             
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  LOA Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="loaDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Po No. Here...`}
             
                  value={loaState.data.loaDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  // error={!!invoiceState.formError?.vatAmount}
                />
            
              </FormControl>
            </Grid>
          </Grid>


          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Vendor Name <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="vendorName"
              size="small"
              type="text"
        
              placeholder={`Enter Vendor Name Here...`}
           
              value={loaState.data?.vendorName || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("customerName")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              // error={!!invoiceState.formError?.customerName}
            />
         
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Unit <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="unit"
              size="small"
              type="text"
              
              placeholder={`Enter Address Here...`}
          
              value={loaState.data?.unit || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("customerName")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              // error={!!invoiceState.formError?.customerName}
            />
        
          </FormControl>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Plate No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Plate No. Here...`}
                 
                  value={loaState.data.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatableSales")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  // error={!!invoiceState.formError?.vatableSales}
                />
             
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Our Ref No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="refNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Po No. Here...`}
              
                  value={loaState.data.refNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  // error={!!invoiceState.formError?.vatAmount}
                />
           
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Policy No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="policyNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Po No. Here...`}
                
                  value={loaState.data.policyNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  // error={!!invoiceState.formError?.vatAmount}
                />
           
              </FormControl>
            </Grid>
          </Grid>

          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              In-house Estimate <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="inhouseEstimate"
              size="small"
              type="text"
             
              placeholder={`Enter In-house Estimate Here...`}
           
              value={loaState.data?.inhouseEstimate || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("customerName")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              // error={!!invoiceState.formError?.customerName}
            />
        
          </FormControl>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Deductible's <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="deductible"
                  size="small"
                  type="text"
                  placeholder={`Enter Plate No. Here...`}
               
                  value={loaState.data.deductible || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatableSales")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  // error={!!invoiceState.formError?.vatableSales}
                />
              
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Depreciation <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="depreciation"
                  size="small"
                  type="text"
                  placeholder={`Enter Po No. Here...`}
              
                  value={loaState.data.depreciation || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  // error={!!invoiceState.formError?.vatAmount}
                />
             
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Total <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="total"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Here...`}
                
                  value={loaState.data.total || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  // error={!!invoiceState.formError?.vatAmount}
                />
            
              </FormControl>
            </Grid>
          </Grid>

          {/* <POTable transID={transID} /> */}

          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Net Amount <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="netAmount"
              size="small"
              type="text"
         
              placeholder={`Enter Net Amount Here...`}
          
              value={loaState.data?.netAmount || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("customerName")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              // error={!!invoiceState.formError?.customerName}
            />
         
          </FormControl>


        </Grid>

        <Grid item xs={4} sm={4} md={4}>
          <Typography variant="h6" className="tp-text-header-primary">
            Document Summary
          </Typography>
          {loaState.data?.gdriveFileId ? (
            <>
              <Typography variant="body1" className="tp-mt-15px">
                Filename: &ensp;
                <span className="tp-text-primary">
                  {loaState.data?.filename
                    ? loaState.data?.filename
                    : "No Attachment"}
                </span>
              </Typography>
              <Typography variant="body1" className="tp-mb-15px">
                Date Received: &ensp;
                <span className="tp-text-primary">
                  {loaState.data?.createdAt
                    ? formatDate(loaState.data?.createdAt)
                    : "No Attachment"}
                </span>
              </Typography>
              <hr />
              {loaState.data?.filename?.includes(".pdf") ? (
                <iframe
                  width={"100%"}
                  height={"750"}
                  src={`https://drive.google.com/file/d/1qpC6nLPc5fAawgubNS-4x6M58V4ry49D/preview`}
                  //https://drive.google.com/file/d/1qpC6nLPc5fAawgubNS-4x6M58V4ry49D/view?usp=sharing
                  title="pdf"
                ></iframe>
              ) : (
                <iframe
                  width={"100%"}
                  height={"750"}
                  src={NoImageFound.image}
                  title="pdf"
                ></iframe>
              )}
            </>
          ) : (
            <>
              <img
                src={NoImageFound.image}
                width="100%"
                height="60%"
                alt="img"
              />
              <br />
              <Typography variant="h6" textAlign="center">
                No Available Document
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
    </div>
  );
}
