import MergeIcon from '@mui/icons-material/Merge';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../api/FormatDate";
import FileUpload from "./FileUpload";
import UploadVideos from "./UploadVideos";
import { GlobalData, GlobalDataContext } from "../contexts/GlobalDataContext";

const DocumentTable = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [fileErrorOpen, setFileErrorOpen] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState<any[]>([]);
  const [isMerging, setIsMerging] = React.useState(false); // Track merging state

  const [fileUploadTrigger, setFileUploadTrigger] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [formData, setFormData] = React.useState<any>({ id: 0, fileName: '', tag: '' });
  const [isEditing, setIsEditing] = React.useState<any>(false);
  const [selectedDocId, setSelectedDocId] = React.useState(0);
  const { globalData } = React.useContext(GlobalDataContext);

  const handleFileUploadComplete = () => {
    setFileUploadTrigger(prev => !prev); // Toggle to trigger useEffect in DocumentTable
  };

  const [fileVideoTrigger, setVideoUploadTrigger] = React.useState(false);

  const handleVideoUploadComplete = () => {
    setVideoUploadTrigger(prev => !prev); // Toggle to trigger useEffect in DocumentTable
  };

  const handleFileErrorClose = () => {
    setFileErrorOpen(false);
  };

  const { transId } = useParams();

  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
  });

  const apiHandler = async (endpoint: string) => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
      headers: {
        'Authorization': `Bearer ${globalData?.accessToken}`
      }
    })
      .then((res: any) => {
        const data = res.data;
        const attachmentsData = data["attachments"];
        setMainState({
          ...mainState,
          data: data,
          attachments: attachmentsData,
        });
      })
      .catch((err: any) => {
        let errorMessage = "";
        if (err?.response?.status === 401 || err?.response?.status === 400) {
          errorMessage = err?.response?.data;
        }
        // Optionally, set the error state here to display the error
        console.error("API Error:", errorMessage);
        setMainState((prevState: typeof mainState) => ({
          ...prevState,
          error: errorMessage,
        }));
      });
  };

  const fetchData = (endpoint: string) => {
    setMainState({
      data: {},
      attachments: [],
      docStatus: {},
      response: "",
      isLoading: false,
      error: "",
    });
    apiHandler(endpoint);
  };

  React.useEffect(() => {
    fetchData(
      `/api/acap/document-validation/document-tracker?transId=${transId}`,

    );
  }, [fileUploadTrigger, fileVideoTrigger]);

  // Handle checkbox selection
  const handleCheckboxChange = (doc: any) => {
    setSelectedDocs((prevSelected) => {
      if (prevSelected.includes(doc)) {
        return prevSelected.filter((d) => d !== doc);
      } else {
        return [...prevSelected, doc];
      }
    });
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFileUploadClick = () => {
    handleMenuClose();
    handleFileUploadComplete();
  };

  const handleVideoUploadClick = () => {
    handleMenuClose();
    handleVideoUploadComplete();
  };

  // Utility function to get mergeable documents (exclude videos)
  const getMergeableDocs = (docs: any[]) => {
    return docs.filter(doc => doc.documentType !== "Videos");
  };

  // Function to merge and view selected documents
  const handleMergeAndView = async () => {
    setIsMerging(true);
    if (selectedDocs.length < 2) {
      alert("Please select at least two documents to merge.");
      setIsMerging(false);
      return;
    }

    try {
      // Send selected document URLs to the backend to merge
      const mergeResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/merge-documents`, {
        documentUrls: selectedDocs.map(doc => doc.fileUrl), // Send the URLs of selected documents

      },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        });


      // Open the merged document in a new tab
      window.open(mergeResponse.data, "_blank");
    } catch (error: any) {
      console.error("Error merging documents:", error);
      // Provide more user-friendly error messages based on the error response
      const errorMsg = error?.response?.data?.message || "An error occurred while merging documents. Please try again.";
      alert(errorMsg);
    } finally {
      setIsMerging(false); // Hide the loader when merging is complete
    }
  };

  // Function to merge all and view documents (excluding videos)
  const handleMergeAllAndView = async () => {
    setIsMerging(true);
    const allMergeableDocs = getMergeableDocs(mainState.attachments);
    if (allMergeableDocs.length < 2) {
      alert("There are less than two documents available to merge.");
      setIsMerging(false);
      return;
    }

    try {
      // Send all mergeable document URLs to the backend to merge
      const mergeAllResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/merge-documents`, {
        documentUrls: allMergeableDocs.map(doc => doc.fileUrl), // Send the URLs of all mergeable documents
      },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        });


      // Open the merged document in a new tab
      window.open(mergeAllResponse.data, "_blank");
    } catch (error: any) {
      console.error("Error merging all documents:", error);
      const errorMsg = error?.response?.data?.message || "An error occurred while merging all documents. Please try again.";
      alert(errorMsg);
    } finally {
      setIsMerging(false); // Hide the loader when merging is complete
    }
  };

  const saveDetails = () => {
    setMainState(mainState.attachment.map((attachment: any) => (attachment.id === formData.id ? formData : attachment)));
    setFormData({ id: null, tag: '', fileName: '' });
    setIsEditing(false);
  };

  const patchTagFileName = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/patch?id=${formData.id}`,
        {
          "tag": formData.tag,
          "fileName": formData.fileName
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        fetchData(
          `/api/acap/document-validation/document-tracker?transId=${transId}`
        );
        setIsEditing(false)
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleFileNameChange = (e: any) => {
    const { value } = e.target;
    setFormData({ ...formData, fileName: value });
  };

  const handleTagsChange = (event: SelectChangeEvent<string>) => {
    setFormData((prev: any) => ({
      ...prev,
      tag: event.target.value
    }));
  };

  // Edit Details
  const editDetails = (e: any) => {
    setFormData(e);
    setIsEditing(true);
  };

  const columns = [

    {
      field: "docId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      editable: false,
      renderCell: (params: any) => (
        <Stack textAlign={"center"}>
          {params.row.docId === null ? (
            <Typography variant="inherit" style={{ color: "#ed6c02" }}>
              Not Available
            </Typography>
          ) : (
            params.row.docId
          )}
        </Stack>
      ),
    },
    {
      field: "fileName",
      headerClassName: "super-app-theme--header",
      headerName: "File Name",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (isEditing && formData.id === params.row.id ?
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing && saveDetails();
            }}
          >
            <OutlinedInput size="small" placeholder="Please enter filename" value={formData.fileName} onChange={(e) => handleFileNameChange(e)} />
          </form>
          :
          <Stack textAlign={"center"}>
            {params.row.fileName === null ||
              params.row.fileName === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.fileName
            )}
          </Stack>
        );
      },
    },
    {
      field: "tag",
      headerClassName: "super-app-theme--header",
      headerName: "Tag",
      flex: 1,
      editable: false,
      width: 100,
      maxWidth: 200,
      renderCell: (params: any) => {
        return (isEditing && formData.id === params.row.id ?
          <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing && saveDetails();
            }}
          >
            {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              isEditing ? saveDetails() : "";
            }}
          > */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={formData.tag}
                onChange={handleTagsChange}
                size="small"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ minWidth: 120 }}
              >
                <MenuItem value="insured">insured</MenuItem>
                <MenuItem value="tp1">tp1</MenuItem>
                <MenuItem value="tp2">tp2</MenuItem>
                <MenuItem value="tp3">tp3</MenuItem>
                <MenuItem value="tp4">tp4</MenuItem>
                <MenuItem value="tp5">tp5</MenuItem>
              </Select>
            </FormControl>
          </form>
          :
          <Stack textAlign={"center"}>
            {params.row.tag === null ||
              params.row.tag === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.tag
            )}
          </Stack>
        );
      },
    },
    {
      field: "documentType",
      headerClassName: "super-app-theme--header",
      headerName: "Document Type",
      flex: 1,
      editable: false,
      renderCell: (params: any) => (
        <Stack textAlign={"center"}>
          {params.row.documentType === null ? (
            <Typography variant="inherit" style={{ color: "#ed6c02" }}>
              Not Available
            </Typography>
          ) : (
            params.row.documentType
          )}
        </Stack>
      ),
    },
    {
      field: "select",
      headerClassName: "super-app-theme--header",
      headerName: "Merge",
      sortable: false,
      width: 100,
      maxWidth: 100,
      renderCell: (params: any) => (
        params.row.documentType === "Videos" ? null : (
          <Checkbox
            checked={selectedDocs.includes(params.row)}
            onChange={() => handleCheckboxChange(params.row)}
          />
        )
      ),
    },

    {
      field: "dateReceived",
      headerClassName: "super-app-theme--header",
      headerName: "Date Received",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => (
        <Stack textAlign={"center"}>
          {params.row.dateReceived === null ? (
            <Typography variant="inherit" style={{ color: "#ed6c02" }}>
              Not Available
            </Typography>
          ) : (
            formatDate(params.row.dateReceived)
          )}
        </Stack>
      ),
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      sortable: true,
      width: 100,
      maxWidth: 100,
      renderCell: (params: any) => {
        let statusChip = <Chip label="Unknown" size="small" />;
        if (params.row.status === "1") {
          statusChip = (
            <Chip label="Open" size="small" variant="outlined" color="primary" />
          );
        } else if (params.row.status === "2") {
          statusChip = (
            <Chip label="Approve" size="small" variant="filled" color="success" />
          );
        } else if (params.row.status === "3") {
          statusChip = (
            <Chip label="Rejected" size="small" variant="filled" color="error" />
          );
        }
        return <Stack textAlign={"center"}>{statusChip}</Stack>;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      width: 250,
      sortable: false,
      renderCell: (params: any) => {
        const handleOpenDocument = () => {
          if (!params.row.fileUrl || params.row.fileUrl.trim() === "") {
            // If fileUrl is null or blank, show the error modal
            setFileErrorOpen(true);
          } else {
            // If fileUrl is valid, open it in a new tab
            window.open(params.row.fileUrl, '_blank');
          }
        };

        let actionButton = (
          <Button
            variant="contained"
            color='primary'
            size="small"
            onClick={handleOpenDocument}
          >
            View
          </Button>
        );

        if (
          params.row.status === "2" ||
          params.row.status === "5" ||
          params.row.status === "1" ||
          params.row.status === "11"
        ) {
          actionButton = (

            <>
              {isEditing && formData.id === params.row.id ?
                <Box sx={{ display: 'flex', gap: 2 }}>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={patchTagFileName}>
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>

                </Box>
                : ""}
              {isEditing ? "" :
                <Box sx={{ display: 'flex', gap: 2 }}>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      editDetails(params.row);
                      setSelectedDocId(params.row.docId);
                    }}>
                    Update
                  </Button>

                  <Button
                    variant="contained"
                    color='primary'
                    size="small"
                    onClick={handleOpenDocument}
                  >
                    View Details
                  </Button>
                </Box>}
            </>
          );
        } else if (
          params.row.status === "11" ||
          params.row.status === "12" ||
          params.row.status === "10"
        ) {
          actionButton = (
            <Button
              variant="contained"
              className="tp-custom-button-primary"
              size="small"
              disabled
            >
              Open Document
            </Button>
          );
        } else if (
          params.row.status === "2" ||
          params.row.status === "3" ||
          params.row.status === "5" ||
          params.row.status === "9" ||
          params.row.status === "8"
        ) {
          actionButton = (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleOpenDocument}
            >
              View Details
            </Button>
          );
        } else {
          actionButton = <></>;
        }

        return <Stack textAlign={"center"}>{actionButton}</Stack>;
      },

    },
  ];

  // Compute mergeable documents (exclude videos)
  const mergeableDocs = getMergeableDocs(mainState.attachments);

  return (
    <Box
      sx={{
        height: 424,
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: '#a42a25',
          color: '#ffffff',
        },
      }}
    >
      {mainState?.attachments ? (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Container for the upload buttons and the merge buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between', // Space between left group and right buttons
              alignItems: 'center',
              mb: 1, // Margin bottom for space between the buttons and the grid
            }}
          >
            {/* Left side: FileUpload and UploadVideos grouped together */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FileUpload
                transId={transId}
                onFileUpload={handleFileUploadComplete}
              />
              <UploadVideos
                transId={transId}
                onVideoUpload={handleVideoUploadComplete}
              />
            </Box>

            {/* Right side: Merge buttons or loader */}
            {isMerging ? (
              <CircularProgress />
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* Merge Selected and View Button */}
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleMergeAndView}
                  disabled={selectedDocs.length < 2 || isMerging}
                  startIcon={<MergeIcon />}
                >
                  Merge and View
                </Button>

                {/* Merge All and View Button */}
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleMergeAllAndView}
                  disabled={mergeableDocs.length < 2 || isMerging}
                  startIcon={<MergeIcon />}
                >
                  Merge All and View
                </Button>
              </Box>
            )}
          </Box>

          {/* DataGrid component */}
          <Box
            sx={{
              height: 424,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#130a68",
                color: "#ffffff",
                marginTop: "15px"
              },
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                backgroundColor: "#130a68",
              },
              "&. MuiDataGrid-footerContainer": {
                display: "none !important",
              },
            }}
          >
            <DataGrid
              rows={mainState?.attachments}
              columns={columns}
              disableRowSelectionOnClick
              disableColumnMenu
              getRowId={(row) => row.docId || row.id} // Ensure each row has a unique ID
            />
          </Box>
        </Box>
      ) : null}

      {/* Modal for file error */}
      <Dialog
        open={fileErrorOpen}
        onClose={handleFileErrorClose}
        aria-labelledby="file-error-dialog-title"
      >
        <DialogTitle id="file-error-dialog-title">File Error</DialogTitle>
        <DialogContent>
          <Typography>File error. Please contact Admin.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileErrorClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Optional: Display error messages from the API */}
      {mainState.error && (
        <Dialog
          open={Boolean(mainState.error)}
          onClose={() => setMainState((prev: any) => ({ ...prev, error: "" }))}
          aria-labelledby="api-error-dialog-title"
        >
          <DialogTitle id="api-error-dialog-title">Error</DialogTitle>
          <DialogContent>
            <Typography>{mainState.error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMainState((prev: any) => ({ ...prev, error: "" }))} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default DocumentTable;
