import {
  Container,
  Card,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  MenuItem,
  Button,
  IconButton,
  Box
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from "react-router-dom"
import React from "react";
import axios from "axios";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { convertKeysToSnakeCase } from "../../utils/SnakeCaseConverter";
import { getCurrentUser } from "../../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";

export default function AddUser() {
  const [mainState, setMainState] = React.useState<any>({
      data: {
          username: "",
          password: "",
          email: "",
          firstName: "",
          lastName: "",
          roleName: "",
          roleLevel: 0,
          status: 1
      },
      isLoading: false
  })
  const [formError, setFormError] = React.useState<any>({})
  const { globalData, updateGlobalData } = React.useContext(GlobalDataContext);
  const cognitoLoginUrl = process.env.COGNITO_LOGIN_PAGE || 'https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions';
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
      const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*_,]).{8,}$/;
      return regex.test(password);
  }

  const hasSpacing =(username: string) => {
     
      return /\s/.test(username);
  }


  const validateForm = () => {
      let isValid = true;
      const errors: any = {};

      // validate password if exists
      if (mainState.data.password.trim() === "") {
          errors.password = 'Password should not be empty.';
          isValid = false;
      }
      if (mainState.data.password !== mainState.data.confirmPassword) {
          errors.confirmPassword = 'Confirm Password and Password should be the same.';
          isValid = false;
      }


      // validate userName
      if (mainState.data.username.trim() === '') {
          errors.username = 'Username should not be empty.';
          isValid = false;
      }

      // validate email
      if (mainState.data?.email.trim() === '') {
          errors.email = 'Email should not be empty.';
          isValid = false;
      } else {
          // validate email format
          let re = /\S+@\S+\.\S+/;
          let isEmail = re.test(mainState.data?.email);
          if (!isEmail) {
              errors.email = 'Invalid email format.';
              isValid = false;
          }
      }

      // validate firstName
      if (mainState.data.firstName.trim() === '') {
          errors.firstName = 'Firstname should not be empty.';
          isValid = false;
      }

      // validate lasttName
      if (mainState.data.lastName.trim() === '') {
          errors.lastName = 'Lastname should not be empty.';
          isValid = false;

      }

      // validate role
      if (mainState.data.roleName === '') {
          errors.roleName = 'Role should not be empty.';
          isValid = false;
      }

      // validate status
      if (mainState.data.status === '') {
          errors.status = 'Status should not be empty.';
          isValid = false;
      }


      setFormError(errors);
      return isValid;
  }

  const createUser = async () => {
      const loggedInUser = await getCurrentUser(globalData);
      if (loggedInUser?.status === 0) {
          updateGlobalData({ role: "", username: null, idToken: null, isUserLogOut: true, lastLogin: null, lastLogout: null });
          secureLocalStorage.removeItem('username');
          secureLocalStorage.removeItem('role');
          secureLocalStorage.removeItem('idToken');
          secureLocalStorage.removeItem('lastLogin');
          secureLocalStorage.removeItem('lastLogout');
          secureLocalStorage.removeItem('status');
          alert("Deactivated user cannot perform such action.");
          const cognitoLoginUrl = process.env.COGNITO_LOGIN_PAGE || 'https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions';
          window.location.href = cognitoLoginUrl;
          return;
      } else {
          setMainState({ ...mainState, isLoading: true });
          const snakeCasePayload = convertKeysToSnakeCase(mainState);
          const isPasswordValid = validatePassword(snakeCasePayload?.data?.password);
          const isUserNameNotValid = hasSpacing(snakeCasePayload?.data?.username)

          if (isUserNameNotValid) {
              alert("Username must not contain any spaces.");
              return;
          }
          
          try {
              (async () => {
                  const payload = {
                      username: snakeCasePayload?.data?.username,
                      password: snakeCasePayload?.data?.password,
                      email: snakeCasePayload?.data?.email,
                      givenName: snakeCasePayload?.data?.first_name,
                      familyName: snakeCasePayload?.data?.last_name,
                      roleName: snakeCasePayload?.data?.role_name,
                      roleLevel: "1",
                      status: "1",
                      lastLogin: "N/A",
                      lastLogout: "N/A",
                      passwordAttempt: 0,
                      lastPasswordUpdate: "N/A",
                      lastUpdated: "N/A",
                      passwordExpiry: 90,
                      maxPasswordAttempts: 5,
                    };
                 await axios({
                      method: 'post',
                      url: `${process.env.REACT_APP_BACKEND_URL}/api/accounts`,
                      data: payload,
                      headers: {
                          'Authorization': `Bearer ${globalData?.accessToken}`
                      }
                  }).then((res: any) => { 
                        console.log(res)
                      setMainState({ ...mainState, isLoading: false });               
                      navigate(-1)
                  }).catch((err: any) => {  
                      let errorMessage = ""
                      if (err?.response?.status === 401) {
                          errorMessage = err?.response?.data
                          updateGlobalData({ role: "", username: null, idToken: null, isUserLogOut: true, lastLogin: null, lastLogout: null });
                          secureLocalStorage.removeItem('username');
                          secureLocalStorage.removeItem('role');
                          secureLocalStorage.removeItem('idToken');
                          secureLocalStorage.removeItem('lastLogin');
                          secureLocalStorage.removeItem('lastLogout');
                          secureLocalStorage.removeItem('status');
                          alert("Login session has expired.");
                          window.location.href = cognitoLoginUrl;
                      }

                      if (err?.response?.status === 400) {
                          if (err?.response?.data === 'Register Account Error' && isPasswordValid) {
                              alert("Username already exists.");
                          }

                          if ( err?.response?.data === 'Register Account Error' && !isPasswordValid) {
                              alert("Password must be at least 8 characters long, contain at least one uppercase letter, and include at least one special character.");
                          }

                          errorMessage = err?.response?.data
                      } 
                      setMainState({ ...mainState, isLoading: false });
                  })
              })();
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      }
  }

  const handleSubmit = (e: any) => {
      e.preventDefault();
      if (validateForm()) {
          createUser();
      }
  };

  const handleInputChange = (e: any) => {
      const { value, id } = e.target;
      setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
      setMainState({
          ...mainState,
          data: { ...mainState.data, roleName: event.target.value }
      });
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
      setMainState({
          ...mainState,
          data: { ...mainState.data, status: event.target.value }
      });
  };

  return (
      <Container maxWidth="lg" sx={{
          mt: 12
      }}>
          <IconButton
              onClick={() => navigate(-1)}
              disableRipple={true}
              aria-label="open" sx={{
                  mb: 2,
                  padding: 0
              }}>
              <ArrowBackIcon sx={{ height: 28, width: 28 }} />
              <Typography sx={{ ml: 1 }}>Back</Typography>
          </IconButton>
          <Card sx={{
              minWidth: 275,
              mb: 4,
              padding: 2
          }}>
              <CardContent>
                  <Typography
                      variant="h3"
                      sx={{
                          color: "#2683e0"
                      }}
                  >
                      Register User
                  </Typography>
                  <Typography sx={{
                      color: '#607274',
                  }}>
                      Add user to the system
                  </Typography>
                  <hr />
                  <br /><br />
                  <Box component='form' onSubmit={handleSubmit}>
                      <Grid container spacing={2} className="tp-mb-20px">

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      Username <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="username"
                                      size="small"
                                      type="text"
                                      placeholder={`Enter Username Here...`}
                                      value={mainState.data.username || ''}
                                      onChange={handleInputChange}
                                      inputProps={{
                                          "aria-label": "username",
                                      }}
                                      // disabled={disabled}
                                      error={!!formError?.username}
                                  />
                                  {!!formError?.username && (
                                      <FormHelperText error id="username">
                                          {formError?.username}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      Email <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="email"
                                      size="small"
                                      type="email"
                                      placeholder={`Enter Email Here...`}
                                      value={mainState?.data?.email || ''}
                                      onChange={handleInputChange}
                                      inputProps={{
                                          "aria-label": "email",
                                      }}
                                      error={!!formError?.email}
                                  />
                                  {!!formError?.email && (
                                      <FormHelperText error id="email">
                                          {formError?.email}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      Password <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="password"
                                      size="small"
                                      type="password"
                                      placeholder={`Enter Password Here...`}
                                      value={mainState.data.password || ''}
                                      onChange={handleInputChange}
                                      inputProps={{
                                          "aria-label": "password",
                                      }}
                                      // disabled={disabled}
                                      error={!!formError?.password}
                                  />
                                  {!!formError?.password && (
                                      <FormHelperText error id="password">
                                          {formError?.password}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      Confirm Password <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="confirmPassword"
                                      size="small"
                                      type="password"
                                      placeholder={`Enter Password Here...`}
                                      value={mainState?.data?.confirmPassword || ''}
                                      onChange={handleInputChange}
                                      // onBlur={() => handleBlur('crNo')}
                                      inputProps={{
                                          "aria-label": "confirmPassword",
                                      }}
                                      // disabled={disabled}
                                      error={!!formError?.confirmPassword}
                                  />
                                  {!!formError?.confirmPassword && (
                                      <FormHelperText error id="confirmPassword">
                                          {formError?.confirmPassword}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      First Name <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="firstName"
                                      size="small"
                                      type="text"
                                      placeholder={`Enter First Name Here...`}
                                      value={mainState?.data?.firstName || ''}
                                      onChange={handleInputChange}
                                      inputProps={{
                                          "aria-label": "firstName",
                                      }}
                                      // disabled={disabled}
                                      error={!!formError?.firstName}
                                  />
                                  {!!formError?.firstName && (
                                      <FormHelperText error id="firstName">
                                          {formError?.firstName}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth>
                                  <Typography variant="body1" className="tp-text-bold">
                                      Last Name <span className="tp-text-required">*</span>
                                  </Typography>
                                  <OutlinedInput
                                      id="lastName"
                                      size="small"
                                      type="text"
                                      placeholder={`Enter Last Name Here...`}
                                      value={mainState?.data?.lastName || ''}
                                      onChange={handleInputChange}
                                      inputProps={{
                                          "aria-label": "lastName",
                                      }}
                                      // disabled={disabled}
                                      error={!!formError?.lastName}
                                  />
                                  {!!formError?.lastName && (
                                      <FormHelperText error id="lastName">
                                          {formError?.lastName}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                                  <Typography variant="body1" className="tp-text-bold">
                                      Role <span className="tp-text-required">*</span>
                                  </Typography>
                                  <Select
                                      id="roleName"
                                      placeholder="Please select role here..."
                                      onChange={handleRoleChange}
                                      size="small"
                                      value={mainState?.data?.roleName || ""}
                                  >
                                        <MenuItem value={"Processor"}>Registration Processor</MenuItem>
                                        <MenuItem value={"Admin"}>Admin</MenuItem>
                                        <MenuItem value={"Clec"}>Senior Clec</MenuItem>
                                        {/* <MenuItem value={"Isip Processor"}>Isip Processor</MenuItem>
                                        <MenuItem value={"Isip Manager"}>Isip Manager</MenuItem> */}
                                        <MenuItem value={"Jr Clec"}>Jr Clec</MenuItem>
                                        <MenuItem value={"Head Clec"}>Head Clec</MenuItem>
                                        <MenuItem value={"Imsi Encoder"}>Imsi Encoder</MenuItem>
                                        <MenuItem value={"Imsi Admin"}>Imsi Admin</MenuItem>
                                        <MenuItem value={"Canvasser"}>Canvasser</MenuItem>
                                        <MenuItem value={"Claims Assistant"}>Claims Assistant</MenuItem>
                                        <MenuItem value={"Claims Officer Approver"}>Claims Officer Approver</MenuItem>
                                        <MenuItem value={"Claims Head Approver"}>Claims Head Approver</MenuItem>
                                        <MenuItem value={"Head Motor Claims"}>Head Motor Claims</MenuItem>
                                        <MenuItem value={"Head Claims"}>Head Claims</MenuItem>
                                  </Select>
                                  {!!formError?.roleName && (
                                      <FormHelperText error id="roleName">
                                          {formError?.roleName}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>

                          <Grid item md={6}>
                              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                                  <Typography variant="body1" className="tp-text-bold">
                                      Status <span className="tp-text-required">*</span>
                                  </Typography>
                                  <Select
                                      id="status"
                                      placeholder="Please select status here..."
                                      size="small"
                                      value={mainState.data.status || 0}
                                      onChange={handleStatusChange}
                                  >
                                      <MenuItem value={1}>Active</MenuItem>
                                      <MenuItem value={2}>Not Active</MenuItem>
                                  </Select>
                                  {!!formError?.status && (
                                      <FormHelperText error id="status">
                                          {formError?.status}
                                      </FormHelperText>
                                  )}
                              </FormControl>
                          </Grid>
                      </Grid>

                      <Grid container spacing={2} className="tp-mb-20px">
                          <Grid item md={2}>
                              <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  size="small"
                              >
                                  Save
                              </Button>
                          </Grid>
                      </Grid>

                  </Box>
              </CardContent>
          </Card>
      </Container>
  )

}