import { PhoneNumberUtil } from 'google-libphonenumber';
import { format, parse } from "date-fns";
import { toZonedTime } from 'date-fns-tz';

export function removeTrailingCommas(jsonStr: string) {
  return jsonStr.replace(/,\s*([}\]])/g, "$1");
}

export function convertStringToNumber(
  input: string | null | undefined
): number {
  if (input == null || input.trim() === "") {
    return 0;
  }

  // Remove all non-numeric characters except the decimal point
  const cleanedString = input.replace(/[^\d.]/g, "");

  // Convert the cleaned string to a number
  const number = parseFloat(cleanedString);
  // If the conversion does not result in a valid number, return 0
  if (isNaN(number)) {
    return 0;
  }
  // Fix to 2 decimal points
  return parseFloat(number.toFixed(2));
}

export function formatNumber(num: string) {
  // Check if num is an empty string or NaN, and set it to 0


  if (num === "" || isNaN(Number(num))) {
    return "0.00"
  } else {
    return Number(num).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

}

export function capitalizeAndReplaceUnderscore(
  str: string | null | undefined
): string {
  if (str === null || str === undefined || str.trim() === "") return ""; // Handle null, undefined, or empty string

  return str
    .split("_") // Split the string by underscores
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter of each word
    )
    .join(" "); // Join the words with a space
}

export function isValidDate(dateString: string | null): boolean {
  // First, check if dateString is null or an empty string
  if (!dateString) {
    return false;
  }

  // Try to create a date object
  const date = new Date(dateString);

  // Check if the date is valid
  return !isNaN(date.getTime());
}

export function isValidTime(timeString: string | null) {
  if (!timeString) {
    return false;
  }
  // Regular expression for validating 24-hour format (HH:MM or HH:MM:SS)
  const time24HourRegex = /^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/;

  // Regular expression for validating 12-hour format (HH AM/PM, HH:MMAM/PM, HH:MM AM/PM)
  const time12HourRegex = /^(0?[1-9]|1[0-2])(:[0-5]\d)?\s?(AM|PM)$/i;

  // Check if the time string matches either the 24-hour or 12-hour format
  return time24HourRegex.test(timeString) || time12HourRegex.test(timeString);
}

export function filteredArray(array: [], exclude?: boolean) {
  return array.filter((item: any) => item.validated !== exclude);
};

export function validateRole(roles: string[], role: string) {
  return roles.includes(role)
}

export function isValidNumber(str: string) {
  // Check if the input is not undefined and is a string
  if (typeof str !== 'string') {
    return false;
  }

  // Remove commas from the string
  const cleanedStr = str.replace(/,/g, '');

  // Check if the cleaned string is empty or contains only white spaces
  if (cleanedStr.trim() === '') {
    return false;
  }

  // Regular expression to check if the string is a valid number
  const validNumberPattern = /^-?\d+(\.\d+)?$/;

  // Return true only if the string matches the number pattern
  if (!validNumberPattern.test(cleanedStr)) {
    return false;
  }

  // Convert the cleaned string to a number
  const num = Number(cleanedStr);

  // Check if the conversion result is a finite number
  return !isNaN(num) && isFinite(num);
}

export function isValidEmail(email: string) {
  // Check if the input is a string
  if (typeof email !== 'string') {
    return false;
  }

  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email string against the regular expression
  return emailRegex.test(email);
}

export function convertToMilitaryTime(time: string): string {
  // Use regex to match hours, minutes, optional space, and AM/PM
  const regex = /^(\d{1,2}):?(\d{2})?\s*([APap][Mm])$/;
  const match = time.trim().match(regex);

  if (!match) {
    throw new Error("Invalid time format");
  }

  let [, hours, minutes = "00", modifier] = match; // Destructure matched groups
  let hourNumber = parseInt(hours); // Convert hours to a number

  // Adjust the hour based on AM/PM modifier
  if (modifier.toUpperCase() === 'PM' && hourNumber < 12) {
    hourNumber += 12;
  } else if (modifier.toUpperCase() === 'AM' && hourNumber === 12) {
    hourNumber = 0;
  }

  // Format the hours and minutes to always be two digits
  const formattedHours = hourNumber.toString().padStart(2, '0');
  const formattedMinutes = minutes.padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export function convertTo12HourFormat(time24: string) {
  // Split the time into hours and minutes
  let [hours, minutes] = time24.split(':').map(Number);

  // Determine if it's AM or PM
  let period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format hours and minutes to ensure two digits

  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export function convertToUTC(localTime: string) {
  // Get the current date
  const now = new Date();

  // Split the local time into hours and minutes
  const [hours, minutes] = localTime.split(':').map(Number);

  // Set the local time on the Date object
  now.setHours(hours, minutes, 0, 0);

  // Convert to UTC
  const utcHours = now.getUTCHours();
  const utcMinutes = now.getUTCMinutes();

  // Format the UTC time as HH:MM
  return `${utcHours.toString().padStart(2, '0')}:${utcMinutes.toString().padStart(2, '0')}`;
}

export const formatISODate = (dateString: string) => {
  if (!dateString) return '';
  // Extract date part from ISO string (YYYY-MM-DD)
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export function removeCommas(str: string) {
  return str.replace(/,/g, '');
}

export function getContentType(file: File): string {
  switch (file.type) {
    case "application/pdf":
      return "application/pdf";
    case "image/png":
      return "image/png";
    case "image/jpg":
    case "image/jpeg":
      return "image/jpeg";
    case "image/tiff":
    case "image/tif":
      return "image/tiff";
    default:
      return "application/octet-stream";
  }
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export function tfObjectsToArray(obj: Record<string, string>): { key: string; value: string }[] {
  const arr: { key: string; value: string }[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      arr.push({ key, value: obj[key] });
    }
  }
  return arr;
}


// const postLaborData = async (data: any[], transId: number) => {
//   // Assuming the "Scope of Work" sheet has the following rows:
//   // row1: scope of work
//   // row2: repair condition
//   // row3: repair
//   // row4: d & a/replace
//   // row5: painting

//   // Extract the necessary rows
//   const scopeOfWorkData =  data.slice(1).map(row => { // Assuming data is a 2D array
//   const scopeOfWorks = row[0] || null;
//   const repairCondition = row[1] || null;
//   const repair = row[2] || null;
//   const replace = row[3] || null;
//   const painting = row[4] || null;
//   const currentDate = new Date();
//   const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
//   const localDate = new Date(currentDate.getTime() - timezoneOffset);
//   const saveDate = localDate.toISOString().slice(0, -1);



//   return {
//     transId: transId,
//     scopeOfWorks: scopeOfWorks,
//     repairCondition: repairCondition,
//     repair: repair !== '' ? repair : 0,
//     replace: replace !== '' ? replace : 0,
//     painting: painting !== '' ? replace : 0,
//     saveDate
//   };
// }).filter(item => item !== null);

//   try {
//     for (const labor of scopeOfWorkData) {
//     await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/labor`, labor, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//   }

//     triggerRefresh(); // Trigger refresh
//   } catch (error) {
//     console.error('Error posting labor data:', error);
//   }
// };

export const generateFileName = (prefix: string): string => {
  const fileName = `${prefix}_${format(new Date(), "yyyy-MM-dd_HHmmss")}`
  return fileName;
}

export function formatToCurrency(amount: number) {
  return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace('$', '');
}

export function getFilenameInUrl(url: string) {
  const preProcess = url.split("/");
  return preProcess[preProcess.length - 1];
}

export function getFormatDate(date: string) {
  const preProcess = date.split("_");
  const processed = preProcess[preProcess.length - 1];
  const ConcatDate = processed.substring(0, 8)
  
  // Extract year, month, and day from the number string
  const year = parseInt(ConcatDate.slice(0, 4));  // First 4 digits are the year
  const month = parseInt(ConcatDate.slice(4, 6)); // Next 2 digits are the month
  const day = parseInt(ConcatDate.slice(6, 8));   // Last 2 digits are the day
  
  // Create a Date object
  const expectedDate = new Date(year, month - 1, day); // Month is 0-based, so subtract 1
  
  // Format the date as "Month day, year"
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  return expectedDate.toLocaleDateString('en-US', options);
}

export function handleJSONStringArray(item: unknown): Record<string, any>[] {


  if (Array.isArray(item)) {
    return item; // Directly return the array if it's already in the correct format
  }

  if (typeof item === "string") {
    try {
      const parsed = JSON.parse(item);
      return Array.isArray(parsed) ? parsed : []; // Ensure the parsed result is an array
    } catch (error) {
      console.error("Failed to parse payment data JSON string:", error);
    }
  }

  console.warn("Unexpected payment data format:", item);
  return []; // Return an empty array for any unsupported format
};

export function convertUtcToPht(utcDate: Date) {
  const timeZone = 'Asia/Manila';
  const phTime = toZonedTime(utcDate, timeZone);
  return format(phTime, 'yyyy-MM-dd HH:mm:ss'); 
}

export function formatPht(dateString: string) {
  const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
  return format(parsedDate, 'MM/dd/yyyy | hh:mm:ss a');
}