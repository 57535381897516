import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { formatToCurrency } from '../utils/stringUtils';
import GenerateCNC from './GenerateCNC';
import GenerateNCC from './GenerateNCC';

export default function CNCValidationTask() {

  function formatDate(dateString: any) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleReset = () => {
    setMainState({ ...mainState, data: { ...mainState.data, policyNo: '' } });
    setValidated(false);
    setSelectedComponent(null);
  };

  const location = useLocation();
  const { sender } = location.state || {};
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: '',
    isLoading: false,
    error: ""
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [policyData, setPolicyData] = React.useState<PolicyData | null>(null);
  const navigate = useNavigate();
  const [validated, setValidated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState<string | null>(null);

  interface PolicyData {
    policyNumber: string;
    contractTy: string;
    contractTyp: string;
    policyStatus: string;
    clientCode: string;
    longName: string;
    inceptionDate: string;
    expiryDate: string;
    agentCode: string;
    agentName: string;
    issuingBranch: string;
    makerModelCode: string;
    interestDescription: string;
    vehicleChassis: string;
    yearManufactured: number;
    numberofSeat: number;
    deductiblePercentage: number;
    deductible: number;
    paliabilityPerson: number;
    vehicleRegistrationNumber: string;
    vehicleEngine: string;
    towingAmount: number;
    repair: number;
    motorMaker: string;
    model: string;
    itemCoverages: { itemCoverage: string; sumInsuredperItemCoverage: number; }[];
    risk: { genp: string; clausecode: string; };
    payment: { orno: string; trandate: string; effectivedate: string; amount: string; }[];
    replacementNumber: string;
  }
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const handleModalOpen = async () => {
    const policyNo = mainState.data.policyNo;
    if (!policyNo) {
      alert('Please enter a policy number');
      return;
    }
    setLoading(true);

    try {
      const signatureResponse = await fetch(`${process.env.REACT_APP_SIGNATURE_ENDPOINT}`, {
        method: 'POST',
        body: JSON.stringify({
          data: {
            policy: {
              contractOwnerSelection: policyNo,
              sfl: {
                screenSelect: "1"
              },
              sflOfS4817: {
                action: "1"
              }
            }
          }
        })
      });

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const policyEnquiryResponse = await fetch(`${process.env.REACT_APP_POLICY_ENDPOINT}`, {
        method: 'POST',
        body: JSON.stringify({
          data: {
            policy: {
              contractOwnerSelection: policyNo,
              sfl: {
                screenSelect: "1"
              },
              sflOfS4817: {
                action: "1"
              }
            }
          },
          signature: signature
        })
      });

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyData = await policyEnquiryResponse.json();
      if (policyData.data && !isEmptyObject(policyData.data)) {
        setPolicyData(policyData.data);
      } else {
        setPolicyData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error('Error fetching policy data:', error);
      setPolicyData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    setValidated(true);
    setModalOpen(false);
  };

  React.useEffect(() => { }, []);

  function toTitleCase(str: string | undefined | null): string | undefined {
    if (!str) {
      return str as undefined;
    }

    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }

  // convert json string to array
  const handleJSONStringArray = (item: unknown): Record<string, any>[] => {


    if (Array.isArray(item)) {
      return item; // Directly return the array if it's already in the correct format
    }

    if (typeof item === "string") {
      try {
        const parsed = JSON.parse(item);
        return Array.isArray(parsed) ? parsed : []; // Ensure the parsed result is an array
      } catch (error) {
        console.error("Failed to parse payment data JSON string:", error);
      }
    }

    console.warn("Unexpected payment data format:", item);
    return []; // Return an empty array for any unsupported format
  };

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Typography variant="h4" className="tp-text-header-primary">CNC/NCC Validation</Typography>
      <hr className="tp-border-hr_primary" />
      <br />
      <Card>
        <CardContent className="tp-padding-50px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} />
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ textAlign: "center" }}>
                <Typography variant="body1" >Transaction No.:</Typography>
                <Typography variant="h3" className="tp-text-header-primary">
                  ACAP - {transId}
                </Typography>
                <Typography variant="body1" >
                  Sender: <span style={{ fontWeight: "bold", color: "navy" }}>{sender}</span>
                </Typography>
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <OutlinedInput
                  fullWidth
                  id="policyNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Policy No. Here...`}
                  value={mainState?.data?.policyNo || ''}
                  onChange={handleInputChange}
                  inputProps={{ "aria-label": "policyNo" }}
                  disabled={false}
                  error={mainState?.data?.policyNo === ""}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleReset} edge="end">
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <br />
                {(mainState?.data?.policyNo ?? "") === "" && (
                  <Typography variant="body1" className="tp-text-required" textAlign="center">Please Validate the Policy No. first to genereate CNC or NCC</Typography>
                )}
                <br />
                <Button
                  fullWidth
                  variant="contained"
                  color={validated ? "success" : "error"}
                  onClick={handleModalOpen}
                  // style={{ backgroundColor: validated ? 'green' : 'red',  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : validated ? 'Validated / View Details' : 'Check Policy Details'}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} />
          </Grid>

          {validated ?
            <>
              <FormControl variant="outlined" fullWidth sx={{ mt: 2, textAlign: 'center' }}>
                <hr className="tp-border-hr_primary" /><br />
                <Typography variant="h5" className="tp-text-header-primary" textAlign="center">Generate CNC/NCC</Typography>
                <Select
                  value={selectedComponent}
                  onChange={(e) => setSelectedComponent(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>Select NCC or CNC</MenuItem>
                  <MenuItem value="GenerateNCC">NCC</MenuItem>
                  <MenuItem value="GenerateCNC">CNC</MenuItem>
                </Select>
              </FormControl>
            </>
            : ""}
          <br /><br />
          {selectedComponent &&
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
                padding: "30px"
              }}
            >
              {selectedComponent === "GenerateNCC" && (
                <>
                  <Typography variant="h6" className="tp-text-header-primary">NCC Details</Typography>
                  <hr className="tp-border-hr_primary" />
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <GenerateNCC
                      policyNo={policyData?.policyNumber}
                      longName={policyData?.longName}
                      inceptionDate={formatDate(policyData?.inceptionDate || '')}
                      expiryDate={formatDate(policyData?.expiryDate || '')}
                      model={policyData?.model}
                      motorMaker={policyData?.motorMaker}
                      interestDescription={policyData?.interestDescription}
                      motorNo={policyData?.vehicleEngine}
                      vehicleChassis={policyData?.vehicleChassis}
                      sender={sender}
                      vehicleRegistrationNumber={policyData?.vehicleRegistrationNumber}
                    />
                  </div>
                </>
              )}
              {selectedComponent === "GenerateCNC" && (
                <div>
                  <Typography variant="h6" className="tp-text-header-primary">CNC Details</Typography>
                  <hr className="tp-border-hr_primary" /><br />
                  <GenerateCNC
                    policyNo={policyData?.policyNumber}
                    longName={policyData?.longName}
                    sender={sender}
                    transId={transId}
                    inceptionDate={formatDate(policyData?.inceptionDate || '')}
                    expiryDate={formatDate(policyData?.expiryDate || '')}
                    model={policyData?.model}
                    motorMaker={policyData?.motorMaker}
                    interestDescription={policyData?.interestDescription}
                    motorNo={policyData?.vehicleEngine}
                    vehicleChassis={policyData?.vehicleChassis}
                    vehicleRegistrationNumber={policyData?.vehicleRegistrationNumber}
                  />
                </div>
              )}
            </Card>
          }
        </CardContent>
      </Card>

      <hr className="tp-border-hr_primary" /><br />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)} {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Box>
      <br /><br />
      <Modal open={modalOpen} onClose={handleModalClose} style={{ overflow: 'scroll', height: '100%' }}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "70%",
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{ position: "absolute", top: 20, right: 25 }}
          >
            <CloseIcon color="error" fontSize="large" />
          </IconButton>
          <Typography variant="h4" className="tp-text-header-primary">
            Policy Details
          </Typography>
          <hr className="tp-border-hr_primary tp-mb-20px" />

          {policyData && !isEmptyObject(policyData) ? (
            <>
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={12} lg={12} textAlign="center">
                  <Typography variant="body1">Full Name:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.longName}
                  </Typography>
                </Grid>
              </Grid>

              <hr style={{ marginTop: "20px", marginBottom: "10px" }} />

              <Grid container spacing={2} style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Policy Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.policyNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Policy Status:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.policyStatus}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Contract Type:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.contractTy}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Client Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.clientCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Inception Date:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {`${policyData.inceptionDate.slice(4, 6)}/${policyData.inceptionDate.slice(6, 8)}/${policyData.inceptionDate.slice(0, 4)}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Expiry Date:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {`${policyData.expiryDate.slice(4, 6)}/${policyData.expiryDate.slice(6, 8)}/${policyData.expiryDate.slice(0, 4)}`}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Agent Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.agentCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Agent Name:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.agentName}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Issuing Branch:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.issuingBranch}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Make Model Code:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.makerModelCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Motor Maker:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.motorMaker}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Model:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.model}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Interest Description:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.interestDescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Chassis:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleChassis}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Engine:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleEngine}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Vehicle Registration Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.vehicleRegistrationNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Year Manufactured:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.yearManufactured}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Number of Seats:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.numberofSeat}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Deductible Percentage:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.deductiblePercentage.toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Deductible:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.deductible || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Liability Person:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.paliabilityPerson || 0)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Towing Amount:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.towingAmount || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Repair:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {formatToCurrency(policyData.repair || 0)}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Typography variant="body1">Risk {policyData.risk.genp}:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.risk.clausecode}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="body1">Replacement Number:</Typography>
                  <Typography
                    variant="h5"
                    className="tp-text-header-primary"
                  >
                    {policyData.replacementNumber}
                  </Typography>
                </Grid>
              </Grid>


              <Typography variant="h5" className="tp-text-header-error" style={{ marginBottom: "15px" }}>
                Item Coverage Details
              </Typography>
              <hr />
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                {policyData.itemCoverages &&
                  handleJSONStringArray(policyData?.itemCoverages).map(
                    (coverage: any, index: any) => (
                      <Grid item xs={12} md={4} lg={4}>
                        <div>{coverage.itemCoverage}:</div>
                        {/* <Typography key={index} variant="body2">
                                                    &ensp;{formatToCurrency(coverage.sumInsuredperItemCoverage || 0)}
                                                  </Typography> */}
                        <Typography
                          variant="h5"
                          className="tp-text-header-primary"
                        >
                          {formatToCurrency(coverage.sumInsuredperItemCoverage || 0)}
                        </Typography>
                      </Grid>
                    )
                  )}
              </Grid>
              <hr /><br />
              <Typography variant="h6" className="tp-text-header-primary">Payment:</Typography>
              <br />
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                {policyData.payment && policyData.payment.map((pay, index) => (
                  <Grid item xs={12} md={4} lg={4}>
                    <Card
                      sx={{
                        boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                        borderRadius: 2,
                        padding: "15px"
                      }}
                    >
                      <Typography key={index} variant="h5" className="tp-text-header-primary">OR No: {pay.orno}</Typography>
                      <Typography key={index} variant="body1">Transaction date: <span style={{ color: "navy", fontWeight: "bold" }}>{formatDate(pay.trandate)}</span></Typography>
                      <Typography key={index} variant="body1">Effective date: <span style={{ color: "navy", fontWeight: "bold" }}>{formatDate(pay.effectivedate)}</span></Typography>
                      <Typography key={index} variant="body1">Amount: <span style={{ color: "navy", fontWeight: "bold" }}>{pay.amount}</span></Typography>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <hr /><br />
              {/* 
                              // #region Buttons
                            */}
              {!validated &&
                <Box
                  sx={{ float: "right" }}
                >
                  <Button variant="contained" onClick={handleConfirm}>
                    Confirm
                  </Button>
                  &emsp;
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                </Box>
              }
              <br />
            </>
          ) : (
            <Typography variant="body2" color="error">Policy Doesn't Exist</Typography>
          )}
        </Box>
      </Modal>
    </Container>
  );
}
