import Typography from '@mui/material/Typography';
import React from 'react';
import Container from '@mui/material/Container';
import { Box, Button } from '@mui/material';
import DETaskTable from '../components/DETaskTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { useNavigate } from 'react-router-dom';

export default function DEPage() {
    const navigate = useNavigate();
    const { globalData } = React.useContext(GlobalDataContext);

    const handleCreateTaskClick = () => {
        navigate('/create-task'); // Navigate to the create-task route
    };

    return (
        <Container maxWidth={'xl'} sx={{ mt: 12 }}>
            <Box sx={{
                my: 4,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <IconButton onClick={() => navigate('/')} disableRipple={true} aria-label="open" sx={{ padding: 0 }}>
                    <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                    <Typography sx={{ ml: 1 }}>Back</Typography>
                </IconButton>
                <Typography variant='h4' color="error" sx={{ textAlign: 'center', flexGrow: 0 }}><b>Damage Evaluation</b></Typography>
                {/* <Typography variant='h4' color="error" sx={{ textAlign: 'center', flexGrow: globalData.role === 'Clec' ? 1 : 0 }}><b>Damage Evaluation</b></Typography> */}
                {/* {globalData.role === 'Clec' && (
                    <Button variant="contained" color="error" onClick={handleCreateTaskClick}>
                        Create Task
                    </Button>
                )} */}
            </Box>
            <DETaskTable task={'Damage Evaluation'} />
        </Container>
    );
}
