import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { convertStringToNumber, formatNumber } from "../../utils/stringUtils";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { IPolicyData } from "../../types/PolicyData";
import React, { useRef } from "react";
import PaymentHistory from "../policy_details/PaymentHistory";
import { useLoaDetails } from "../../contexts/LoaDetailsContext";
import { IPayment } from "../../types/Payment";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function LoaDetails({
  fetchSearchLoaDetails,
  sfSearchResult,
  setSfSearchResult,
  searchParams,
  setSearchParams,
  sfLoaDetails,
  setSfLoaDetails,
  setPolicyAsiaData,
  transId,
  isipTaskValidation,
  setIsipTaskValidation,
}: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [policyData, setPolicyData] = React.useState<IPolicyData | null>(null);
  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setSearchParams({
      ...searchParams,
      [id]: value,
    });
  };
  const { loaDetails, fetchLoaDetails, createLoaDetails, removeLoaDetails } =
    useLoaDetails();

  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  // #region fetchGetPolicyData
  const fetchGetPolicyData = async (policyNo: string) => {
    setLoading(true);


    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: policyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();
      const signature = signatureData.data;

      const policyEnquiryResponse = await fetch(
        `${process.env.REACT_APP_POLICY_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              policy: {
                contractOwnerSelection: policyNo,
                sfl: {
                  screenSelect: "1",
                },
                sflOfS4817: {
                  action: "1",
                },
              },
            },
            signature: signature,
          }),
        }
      );

      if (!policyEnquiryResponse.ok) {
        throw new Error(`Error: ${policyEnquiryResponse.statusText}`);
      }

      const policyData = await policyEnquiryResponse.json();
      if (policyData.data && !isEmptyObject(policyData.data)) {

        setPolicyData(policyData.data);

        const listOfPayments: IPayment[] =
          typeof policyData.data.payment === "string"
            ? JSON.parse(policyData.payment)
            : policyData.data.payment;

        const ornoValues = listOfPayments.map((item: IPayment) => item.orno);
        const uniqueOrnoValues = new Set(ornoValues);
        const isDoublePayment = uniqueOrnoValues.size < ornoValues.length;
        // const isDoublePayment = false;
        const isNoPayment =
          listOfPayments.length >= 0
            ? true
            : listOfPayments[0].orno === ""
              ? true
              : false;

        setIsipTaskValidation({
          ...isipTaskValidation,
          doublePayment: isDoublePayment,
          noPremiumPayment: isNoPayment,
        });
      } else {
        setPolicyData(null);
      }
    } catch (error) {
      console.error("Error fetching policy data:", error);
      setPolicyData(null);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchLoaDetails(transId);
  }, []);

  // React.useEffect(() => {
  //   const currentPolicyNo = loaDetails.loaInformation?.policyNo;

  //   // Check if policyNo has changed
  //   if (currentPolicyNo && prevPolicyNoRef.current !== currentPolicyNo) {
  //     fetchGetPolicyData(currentPolicyNo as string);
  //     // Update the ref to the current policy number
  //     prevPolicyNoRef.current = currentPolicyNo;
  //   }
  // }, [loaDetails.loaInformation?.policyNo]);

  return (
    <div>
      {/* 
        TODO: make a condition if there is load details from db show it and hide search,
        if not show search and show details 
    */}
      <Stack direction={"row"} spacing={1}>
        <Typography
          variant="h5"
          className="tp-text-header-primary"
          textAlign="left"
        >
          LOA Details
        </Typography>
        {loaDetails.loaInformation !== null ? (
          <Chip label="Validated" size="small" color="error" />
        ) : null}
      </Stack>

      <Typography variant="body1">
        Note: Please validate LOA details before submit.
      </Typography>
      <hr />
      <br />
      {loaDetails.loaInformation === null ? (
        <React.Fragment>
          <Grid container spacing={2}>
            {/* <Grid item xs={2.4} md={2.4}>
          <TextField
            id="status"
            label="Status"
            type="text"
            fullWidth
            size="small"
            value={searchParams?.status}
            onChange={handleInputChange}
            // defaultValue="1"
          />
        </Grid> */}
            <Grid item xs={3} md={3}>
              <TextField
                id="plateNo"
                label="Plate Number"
                type="text"
                fullWidth
                size="small"
                value={searchParams?.plateNo ?? ""}
                onChange={handleInputChange}
              // defaultValue="1"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                id="policyNo"
                label="Policy Number"
                type="text"
                fullWidth
                size="small"
                value={searchParams?.policyNo ?? ""}
                onChange={handleInputChange}
              // defaultValue="1"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <TextField
                id="claimNo"
                label="Claim Number"
                type="text"
                fullWidth
                size="small"
                value={searchParams?.claimNo ?? ""}
                onChange={handleInputChange}
              // defaultValue="1"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Button
                variant="contained"
                className="tp-custom-button-primary"
                fullWidth
                onClick={() => fetchSearchLoaDetails()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : (
        !loaDetails.isLoading && (
          <React.Fragment>
            <Button
              variant="contained"
              className="tp-custom-button-primary"
              onClick={() => {
                removeLoaDetails(transId);
                setPolicyData(null);
              }}
            >
              {loaDetails.isLoading ? (
                <>
                  <CircularProgress color="inherit" size="1.5em" />
                  &emsp;
                </>
              ) : (
                <></>
              )}
              Reset LOA Details
            </Button>
            &nbsp; &nbsp;
            <Button
              variant="contained"
              disabled={loading}
              className="tp-custom-button-primary"
              onClick={() => {

                fetchGetPolicyData(
                  loaDetails.loaInformation?.policyNo as string
                );
              }}
            >
              {loading === true ? (
                <>
                  <CircularProgress color="inherit" size="1.5em" />
                  &emsp;
                </>
              ) : (
                <></>
              )}
              Show Payment History
            </Button>
          </React.Fragment>
        )
      )}
      <br />
      {!loaDetails.isLoading ? (
        loaDetails.loaInformation ? (
          <React.Fragment>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel-a-content"}
                id={"panel-a-header"}
                className={"tp-bg-lightPrimary"}
              >
                <Typography className="tp-text-bold">
                  LOA Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Grid container sx={{ my: 1 }}>
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Claim Number
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.claimNo ?? "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Policy Number
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.policyNo ?? "N/A"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Claimant Email
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.claimantEmail ?? "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Insured Name
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.insuredName ?? "N/A"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}

                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        In House Estimate / Gross Amount
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {convertStringToNumber(loaDetails.loaInformation!.inHouseEstimateAmount) ??
                          "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Repair Shop
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.vendorName ?? "N/A"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}

                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Depreciation
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {
                          parseFloat(
                            loaDetails.loaInformation!
                              .depreciationValue as string
                          )
                          ?? "0.00"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Deductible
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {
                          parseFloat(
                            loaDetails.loaInformation!.deductible as string

                          ) ?? "0.00"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Net Claim
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {
                          parseFloat(
                            loaDetails.loaInformation!.inHouseEstimateAmount as string
                          ) - parseFloat(
                            loaDetails.loaInformation!.deductible as string
                          ) - parseFloat(
                            loaDetails.loaInformation!.depreciationValue as string
                          )
                          ?? "0.00"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}

                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Make
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.make ?? "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Model
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.model ?? "N/A"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}

                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Unit
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.unit ?? "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Total
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {
                          parseFloat(loaDetails.loaInformation!.total as string)
                          ?? "0.00"}
                      </Typography>
                    </Grid>
                    {/* </Grid> */}

                    {/* <Grid container sx={{ my: 1 }}> */}
                    <Grid item xs={6} md={6}>
                      <Typography
                        fontWeight={"bold"}
                        variant="body2"
                      // className="tp-text-bold"
                      >
                        Plate Number
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {loaDetails.loaInformation!.plateNo ?? "N/A"}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#8a8888" }}
                      // className="tp-text-bold"
                    >
                      Model
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {sfLoaDetails?.model ?? "N/A"}
                    </Typography>
                  </Grid> */}
                  </Grid>
                </>
              </AccordionDetails>
            </Accordion>
            {/* 
              // #region policyData
            */}
            {policyData && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={"panel-a-content"}
                  id={"panel-a-header"}
                  className={"tp-bg-lightPrimary"}
                >
                  <Typography className="tp-text-bold">
                    Payment History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <PaymentHistory
                    policyData={policyData}
                    isipTaskValidation={isipTaskValidation}
                    setIsipTaskValidation={setIsipTaskValidation}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </React.Fragment>
        ) : (
          <></>
        )
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* 
        TODO make a table or text field for LOA details from Salesforce 
    */}
      <Modal sx={{ overflowY: "scroll" }} open={searchParams?.isShowResults}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            width: "60%",
            my: 12,
            position: "relative",
          }}
        >
          <Typography variant="h6" className="tp-text-header-primary">
            Search result for LOA details
          </Typography>
          <hr />
          <br />
          {searchParams?.isLoading !== true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Claim Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Policy Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Claimant Email
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Insured Name
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        In House Estimate Amount / Gross Amount
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Repair Shop
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Depreciation Value
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Deductible
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Make
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Model
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Body
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Plate Number
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1" className="tp-text-bold">
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sfSearchResult?.map((row: any, idx: number) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        ":hover": { backgroundColor: "#f2f2f2" },
                      }}
                      onClick={() => {

                        setSfLoaDetails({ ...row });
                        createLoaDetails({
                          ...row,
                          transId: transId,
                          status: 1,
                        });
                        setPolicyAsiaData((prev: any) => ({
                          ...prev,
                          claimNo: row?.claimNo,
                          deductible: row?.deductible,
                          total: row?.total
                        }));
                        setSearchParams({
                          searchParams: {
                            policyNo: null,
                            claimNo: null,
                            plateNo: null,
                          },
                          isShowResults: false,
                          isLoading: false,
                        });
                      }}
                    >
                      <TableCell align="center">{row.claimNo}</TableCell>
                      <TableCell align="center">{row.policyNo}</TableCell>
                      <TableCell align="center">{row.claimantEmail}</TableCell>
                      <TableCell align="center">{row.insuredName}</TableCell>
                      <TableCell align="center">
                        {row.inHouseEstimateAmount}
                      </TableCell>
                      <TableCell align="center">{row.vendorName}</TableCell>
                      <TableCell align="center">
                        {row.depreciationValue}
                      </TableCell>
                      <TableCell align="center">{row.deductible}</TableCell>
                      <TableCell align="center">{row.total}</TableCell>
                      <TableCell align="center">{row.make}</TableCell>
                      <TableCell align="center">{row.model}</TableCell>
                      <TableCell align="center">{row.unit}</TableCell>
                      <TableCell align="center">{row.plateNo}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <br />
          <br />
          <Grid item xs={12} md={12}>
            <Button
              disabled={searchParams?.isLoading}
              onClick={() => {
                setSearchParams({
                  searchParams: {
                    policyNo: null,
                    claimNo: null,
                    plateNo: null,
                  },
                  isShowResults: false,
                  isLoading: false,
                });
              }}
              variant="contained"
              size="medium"
              className="tp-custom-button-primary"
              fullWidth
            >
              Close
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
