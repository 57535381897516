import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  IconButton,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  DialogProps,
  Slide,
  Button,
  Chip,
  Stack,
  CircularProgress,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import Select from "react-select";
import { DataGrid } from "@mui/x-data-grid";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import FieldChecklist from "../processor_validation/FieldChecklist";
import UploadMissing from "../UploadMissing";
import MainModal from "../document_validation/MainModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClaimRegistration from "../ClaimRegistration";
import { formatDate } from "../../api/FormatDate";
import ClientList from "../clientList";
import PolicyChecker from "../PolicyChecker"; // Import the new component
import EmailMissingIsip from "../EmailMissingIsip"; // Import the new component
import { toTitleCase } from "../../api/utils";
import IsipMainModal from "./IsipMainModal";
import IsipPolicyAsia from "./IsipPolicyAsia";
import { convertStringToNumber, removeCommas } from "../../utils/stringUtils";
import LoaDetails from "./LoaDetails";
import { useLoaDetails } from "../../contexts/LoaDetailsContext";
import { IIsipTask } from "../../types/IsipTask";
import { IIsipTaskValidation } from "../../types/IsipTaskValidation";
import { IPolicyAsiaState } from "../../types/PolicyAsiaState";
import { NotificationContext } from "../../contexts/NotificationContext";
import BusinessRulesTask from "../document_validation/checklist/BusinessRulesTask";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function IsipDocumentValidationTask() {
  const location = useLocation();
  const { sender } = location.state || {};
  const [open, setOpen] = React.useState(false);
  const [disabled, setIsDisabled] = React.useState(false);
  const [forSubmit, setForSubmit] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [selectedForm, setSelectedForm] = React.useState(0);
  const [selectedDocId, setSelectedDocId] = React.useState(0);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    attachments: [],
    docStatus: {},
    response: "",
    isLoading: false,
    error: "",
    taskData: {},
    allStatusApproved: false,
  });
  const [mvfDoc, setMvfDoc] = React.useState<any>(null);
  const { globalData } = React.useContext(GlobalDataContext);
  const [policyNoField, setPolicyNoField] = React.useState("");
  const { transId } = useParams();
  const [clientCode, setClientCode] = React.useState<string | null>(null);
  const [policyAsiaData, setPolicyAsiaData] = React.useState<IPolicyAsiaState>({
    grossAmount: "",
    vatableSales: "",
    vatAmount: "",
    deductible: "",
    depreciation: "",
    totalDeductibleDepre: "",
    claimNo: "",
    clientCode: "",
    clientName: "",
    plateNo: "",
    netAmount: ""
  });
  const [sfSearchResult, setSfSearchResult] = React.useState<
    {
      claimNo: string;
      policyNo: string;
      inHouseEstimateAmount: string;
      repairShop: string;
      depreciationValue: string;
      deductible: string;
      make: string;
      model: string;
    }[]
  >([]);
  const [searchParams, setSearchParams] = React.useState({
    plateNo: "",
    claimNo: "",
    policyNo: "",
    isLoading: false,
    isShowResults: false,
  });
  const [sfLoaDetails, setSfLoaDetails] = React.useState<{
    claimNo: string;
    policyNo: string;
    inHouseEstimateAmount: string;
    repairShop: string;
    depreciationValue: string;
    deductible: string;
    make: string;
    model: string;
  } | null>(null);
  const [payment, setPayment] = React.useState<{
    data: {
      payment: {
        payeeid: string;
        payeename: string;
        grossamount: string;
        vatablesales: string;
        claimno: string;
        policyno: string;
        requestedby: string;
        paymentitems: {
          netamount: string; // loss reserve
          category: string; // reserve code
        }[];
      };
    };
    signature: string;
  }>();
  const navigate = useNavigate();
  const [isAmla, setIsAmla] = React.useState<boolean>(false);
  const [isCashReserved, setIsCashReserved] = React.useState<boolean>(false);
  const [isPaymentReceived, setIsPaymentReceived] =
    React.useState<boolean>(false);
  const [isipTaskState, setIsipTaskState] = React.useState<IIsipTask>({
    claimNo: "",
    id: 0,
    policyNo: "",
    saveDate: "",
    sender: "",
    status: 0,
    taskType: "",
  });
  const [isipTaskValidation, setIsipTaskValidation] =
    React.useState<IIsipTaskValidation>({
      id: 0,
      transId: Number(transId),
      amla: false,
      crf: false,
      doublePayment: true,
      noPremiumPayment: true,
      paymentReceived: false,
      salesBirNoClaim: false,
      salesBirClaim: false,
      serviceBirNoClaim: false,
      serviceBirClaim: false,
      status: 0,
      grossAmountMatch: false,
      netAmountMatch: false,
    });
  const { notification, setNotification } =
    React.useContext(NotificationContext);
  const [validateLoaBusinessRules, setValidateLoaBusinessRules] =
    React.useState<boolean>(false);
  const [isLoadingLoaBusinessRules, setIsLoadingLoaBusinessRules] =
    React.useState<boolean>(false);
    const { fetchTaskBusinessRules } = useBusinessRules();

  // #region postIsipValidation
  const postIsipValidation = async () => {

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/validation`,
        isipTaskValidation
      )
      .then((res: any) => {

        setMainState({
          ...mainState,
          isLoading: false,
        });
      })
      .catch((err: any) => {
        console.error(err);
        setMainState({
          ...mainState,
          isLoading: false,
        });
      });
  };

  // #region updateIsipTask
  const updateIsipTask = async () => {
    const isipTaskRequest = {
      sender: mainState?.taskData?.sender,
      taskType: mainState?.taskData?.taskType,
      policyNo: sfLoaDetails?.policyNo,
      claimNo: sfLoaDetails?.claimNo,
      status: mainState?.taskData?.status,
      userId: mainState?.taskData?.userDetails?.id,
    };

    let emailSubject = "Claim Invoice Notification - BPI MS Insurance";
    let emailBody = "";

    if (mainState?.data?.status === 2) {
      // pending for payment
      emailBody = `Dear Valued Client,\n\nI hope this message finds you well.\n\nWe are pleased to inform you that the invoice submitted to us for your repair has been reviewed and will proceed to the next step which is the payment process.\n\nTransaction ID: ${"ISIP-" + transId
        }\n\nThank you for your patience and cooperation throughout the claim process.\n\nShould you have any questions or need further assistance, please do not hesitate to contact us via our customer support team (88409999; 88409966) or send an email to bpimsclaims@bpims.com.\n\n\n\nRegards,\nBPI MS Team`;
    } else if (mainState?.data?.status === 3) {
      // reject
      emailBody = `Dear Valued Client,\n\nWe hope this message finds you well.\n\nAfter reviewing the invoice submitted to us for the BPI MS Insurance claim, we regret to inform you that it has been rejected due to the following reason(s):\n\nTransaction ID: ${"ISIP-" + transId
        }\n\nReason(s) for Rejection:\n\n${mainState?.data?.remarks
        }\n\nShould you have any questions or need further assistance, please do not hesitate to contact us via our customer support team (88409999; 88409966) or send an email to bpimsclaims@bpims.com.\n\nRegards,\nBPI MS Team`;
    }

    const genericEmailRequest = {
      to: mainState?.taskData?.sender,
      subject: emailSubject,
      body: emailBody,
    };

    const request = { isipTaskRequest, genericEmailRequest };


    await axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/${transId}`,
        request
      )
      .then((res: any) => {

        postIsipValidation();

        setNotification({
          level: "success",
          showAlert: true,
          message: res?.data?.message,
        });
      })
      .catch((err: any) => {
        console.error(err);
        setMainState({
          ...mainState,
          isLoading: false,
        });
      });
  };

  // #region fetchLoaDetails
  const fetchSearchLoaDetails = async () => {

    const sfClientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID ?? "";
    const sfClientSecret = process.env.REACT_APP_SALESFORCE_CLIENT_SECRET ?? "";
    const sfUsername = process.env.REACT_APP_SALESFORCE_USERNAME ?? "";
    const sfPassword = process.env.REACT_APP_SALESFORCE_PASSWORD ?? "";

    const params = new URLSearchParams();
    params.append("grant_type", "password");
    params.append("client_id", sfClientId);
    params.append("client_secret", sfClientSecret);
    params.append("username", sfUsername);
    params.append("password", sfPassword);

    setSearchParams({
      ...searchParams,
      isLoading: true,
      isShowResults: true,
    });

    try {
      const tokenResponse = await axios.post(
        `${process.env.REACT_APP_SALESFORCE_DOMAIN}/services/oauth2/token`,
        params
      );
      const token = tokenResponse.data.access_token;
      const baseUrl = `${process.env.REACT_APP_SALESFORCE_DOMAIN}/services/data/v61.0/query/`;

      let query =
        "SELECT Plate_No__c, Estimate_Summary__r.Claim_Type__r.Case__r.Account.Email__c, Estimate_Summary__r.Claim_Type__r.Case__r.Account.LastName, Estimate_Summary__r.Claim_Type__r.Case__r.Account.FirstName, Estimate_Summary__r.Claim_Type__r.Claim_Owner_Email__c, Estimate_Summary__r.Claim_Type__r.Status__c, Estimate_Summary__r.Claim_Type__r.Case__r.Claim_Number__c, Estimate_Summary__r.Claim_Type__r.Case__r.Policy_Number__c, Estimate_Summary__r.Claim_Type__r.Gross_Claim__c, Estimate_Summary__r.Claim_Type__r.Repair_Shop__r.Name, Depreciation__c, Estimate_Summary__r.Claim_Type__r.Case__r.Insurance_Policy__r.Deductible__c, Make__c, Model__c, Body__c FROM Estimate_Item__c WHERE (";

      let conditions = [];

      if (searchParams.plateNo) {
        conditions.push(`Plate_No__c = '${searchParams.plateNo}'`);
      }

      if (searchParams.claimNo) {
        conditions.push(
          `Estimate_Summary__r.Claim_Type__r.Case__r.Claim_Number__c = '${searchParams.claimNo}'`
        );
      }

      if (searchParams.policyNo) {
        conditions.push(
          `Estimate_Summary__r.Claim_Type__r.Case__r.Policy_Number__c = '${searchParams.policyNo}'`
        );
      }

      if (conditions.length > 0) {
        query += conditions.join(" AND ");
        query +=
          ") AND Estimate_Summary__r.Claim_Type__r.Status__c = 'Pending Shop\\'s Bill'";
      } else {
        query =
          "SELECT Plate_No__c, Estimate_Summary__r.Claim_Type__r.Case__r.Account.Email__c, Estimate_Summary__r.Claim_Type__r.Case__r.Account.LastName, Estimate_Summary__r.Claim_Type__r.Case__r.Account.FirstName, Estimate_Summary__r.Claim_Type__r.Claim_Owner_Email__c, Estimate_Summary__r.Claim_Type__r.Status__c, Estimate_Summary__r.Claim_Type__r.Case__r.Claim_Number__c, Estimate_Summary__r.Claim_Type__r.Case__r.Policy_Number__c, Estimate_Summary__r.Claim_Type__r.Gross_Claim__c, Estimate_Summary__r.Claim_Type__r.Repair_Shop__r.Name, Depreciation__c, Estimate_Summary__r.Claim_Type__r.Case__r.Insurance_Policy__r.Deductible__c, Make__c, Model__c, Body__c FROM Estimate_Item__c WHERE Estimate_Summary__r.Claim_Type__r.Status__c = 'Pending Shop\\'s Bill'";
      }

      const url = `${baseUrl}?q=${query}`;

      const mainQueryResponse = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = mainQueryResponse.data.records.map((item: any) => {
        return {
          claimNo:
            item?.Estimate_Summary__r.Claim_Type__r.Case__r.Claim_Number__c,
          policyNo:
            item?.Estimate_Summary__r.Claim_Type__r.Case__r.Policy_Number__c,
          inHouseEstimateAmount:
            item?.Estimate_Summary__r.Claim_Type__r.Gross_Claim__c,
          vendorName:
            item?.Estimate_Summary__r.Claim_Type__r.Repair_Shop__r?.Name ??
            null,
          depreciationValue: item?.Depreciation__c ?? 2107.35,
          deductible:
            item?.Estimate_Summary__r.Claim_Type__r.Case__r.Insurance_Policy__r
              .Deductible__c ?? 0.0,
          total: item?.Estimate_Summary__r.Depreciation_Value__c ?? 6197.35,
          make: item?.Make__c,
          model: item?.Model__c,
          unit: item?.Body__c,
          plateNo: item?.Plate_No__c,
          sfStatus: item?.Estimate_Summary__r.Claim_Type__r.Status__c,
          claimantEmail:
            item?.Estimate_Summary__r.Claim_Type__r.Case__r.Account.Email__c,
          insuredName: `${item?.Estimate_Summary__r.Claim_Type__r.Case__r.Account.FirstName} ${item?.Estimate_Summary__r.Claim_Type__r.Case__r.Account.LastName}`,
          // netClaim: item?.Net_Claim_Payment_s__c ?? 0.00
        };
      });

      // Filter unique results by claimNo
      const uniqueResults = result.filter(
        (value: any, index: any, self: any) =>
          index ===
          self.findIndex(
            (t: any) =>
              t.claimNo === value.claimNo && t.policyNo === value.policyNo
          )
      );



      setSfSearchResult(uniqueResults);

      setSearchParams({
        ...searchParams,
        isLoading: false,
        isShowResults: true,
      });

      
    } catch (error: any) {
      console.error("Error fetching data from Salesforce:", error);
      setSearchParams({
        ...searchParams,
        isLoading: false,
        isShowResults: false,
      });
    }
  };

  // fetch loa details from db
  const fetchLoaDetailsFromDb = async () => { };
  // update loa details to db
  const updateLoaDetailsToDb = async () => { };

  // #region fetchData
  const fetchData = async () => {
    setMainState({
      data: {},
      attachments: [],
      docStatus: {},
      response: "",
      isLoading: false,
      error: "",
    });

    try {
      const isiptask = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/${transId}`
      );

      const isiptaskValidation = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/validation/${transId}`
      );

      const isiptaskValidationRes = isiptaskValidation?.data?.data
        ? isiptaskValidation?.data?.data
        : {
            id: 0,
            transId: Number(transId),
            amla: false,
            crf: false,
            doublePayment: false,
            noPremiumPayment: false,
            paymentReceived: false,
            salesBirNoClaim: false,
            salesBirClaim: false,
            serviceBirNoClaim: false,
            serviceBirClaim: false,
            grossAmountMatch: false,
            netAmountMatch: false,
            status: 0,
          };
      setIsipTaskValidation(isiptaskValidationRes);

      // document tracker
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/document-tracker?transId=${transId}`,
      })
        .then((res: any) => {
          const data = res.data;

          const attachmentsData = data["attachments"];

          const statusOptions: any = {
            // 0: null,
            1: { value: 1, label: "Open" },
            2: { value: 2, label: "Pending for Payment" },
            3: { value: 3, label: "Reject" },
          };

          setMainState({
            ...mainState,
            data: data,
            attachments: attachmentsData,
            taskData: isiptask?.data?.data,
            allStatusApproved: attachmentsData.every(
              (item: any) => item.status === "2"
            ),
            selections: {
              ...mainState.selections,
              status: statusOptions[isiptask?.data?.data?.status],
            },
          });

          // const mvfData = attachmentsData.find(
          //   (doc: any) => doc.documentType === "Motor Vehicle"
          // );
          // fetchPolicyNo(mvfData?.docId);

          const forSubmission = attachmentsData.every(
            (item: any) => item?.status === "Approved"
          );
          setForSubmit(forSubmission);

          const forDisable = attachmentsData.every(
            (item: any) => item?.status === "For Policy Checking"
          );
          setIsDisabled(forDisable);
        })
        .catch((err: any) => {
          let errorMessage = "";
          if (err?.response?.status === 401 || 400) {
            errorMessage = err?.response?.data;
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  // #region summary details
  const fetchIsipPolicyData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/all-invoice?transId=${transId}`
      )
      .then((res: any) => {
        const salesInvoices = res?.data?.data?.salesInvoices;
        const serviceInvoices = res?.data?.data?.serviceInvoices;

        // sum all gross amount
        const salesTotalGrossAmount =
          salesInvoices.length > 0
            ? salesInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator + convertStringToNumber(currentValue.grossAmount),
              0
            )
            : 0;
        const serviceTotalGrossAmount =
          serviceInvoices.length > 0
            ? serviceInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator + convertStringToNumber(currentValue.grossAmount),
              0
            )
            : 0;

        // sum all vat amount
        const salesTotalVatAmount =
          salesInvoices.length > 0
            ? salesInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator + convertStringToNumber(currentValue.vatAmount),
              0
            )
            : 0;
        const serviceTotalVatAmount =
          serviceInvoices.length > 0
            ? serviceInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator + convertStringToNumber(currentValue.vatAmount),
              0
            )
            : 0;

        // sum all vatable sales
        const salesTotalVatableSales =
          salesInvoices.length > 0
            ? salesInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator +
                convertStringToNumber(currentValue.vatableSales),
              0
            )
            : 0;
        const serviceTotalVatableSales =
          serviceInvoices.length > 0
            ? serviceInvoices.reduce(
              (accumulator: any, currentValue: any) =>
                accumulator +
                convertStringToNumber(currentValue.vatableSales),
              0
            )
            : 0;

        // TODO: serviceInvoices.deductible || salesInvoices.deductible
        // priority: salesInvoice
        const salesInvoiceDeductible =
          salesInvoices?.length > 0 ? salesInvoices[0].deductible : 0;
        const serviceInvoiceDeductible =
          serviceInvoices?.length > 0 ? serviceInvoices[0].deductible : 0;

        // TODO: serviceInvoices.depreciation || salesInvoices.depreciation
        // priority: salesInvoice
        const salesInvoiceDepreciation =
          salesInvoices?.length > 0 ? salesInvoices[0].depreciation : 0;
        const serviceInvoiceDepreciation =
          serviceInvoices?.length > 0 ? serviceInvoices[0].depreciation : 0;

        // TODO: serviceInvoices.totalDeductibleAndDepre || salesInvoices.totalDeductibleAndDepre
        // priority: salesInvoice
        const salesInvoiceTotalDeductibleAndDepre =
          salesInvoices?.length > 0 ? salesInvoices[0].totalDeductibleDepre : 0;
        const serviceInvoiceTotalDeductibleAndDepre =
          serviceInvoices?.length > 0
            ? serviceInvoices[0].totalDeductibleDepre
            : 0;

        // fetch plate no
        const salesInvoicePlateNo =
          salesInvoices?.length > 0 ? salesInvoices.plateNo : "";
        const serviceInvoicePlateNo =
          serviceInvoices?.length > 0 ? serviceInvoices.plateNo : "";

        // fetch netAmount
        const salesInvoiceNetAmount =
          salesInvoices?.length > 0 ? salesInvoices[0].netAmount : "";
        const serviceInvoiceNetAmount =
          serviceInvoices?.length > 0 ? serviceInvoices[0].netAmount : "";

        setPolicyAsiaData({
          ...policyAsiaData,
          grossAmount: salesTotalGrossAmount + serviceTotalGrossAmount,
          vatableSales: salesTotalVatableSales + serviceTotalVatableSales,
          vatAmount: salesTotalVatAmount + serviceTotalVatAmount,
          deductible: salesInvoiceDeductible || serviceInvoiceDeductible || 0,
          depreciation:
            salesInvoiceDepreciation || serviceInvoiceDepreciation || 0,
          totalDeductibleDepre:
            salesInvoiceTotalDeductibleAndDepre ||
            serviceInvoiceTotalDeductibleAndDepre ||
            0,
          plateNo: salesInvoicePlateNo || serviceInvoicePlateNo || "",
          netAmount: removeCommas(salesInvoiceNetAmount || serviceInvoiceNetAmount || "")
        });
      })
      .catch((err: any) => {

      });
  };

  // #region handleSubmit
  const handleSubmit = async () => {
    setMainState({
      ...mainState,
      isLoading: true,
    });

    const paymentPayload = {
      data: {
        payment: {
          payeeid: policyAsiaData?.clientCode,
          payeename: policyAsiaData?.clientName,
          grossamount: policyAsiaData?.grossAmount,
          vatablesales: policyAsiaData?.vatableSales,
          claimno: policyAsiaData?.claimNo,
          policyno: searchParams?.policyNo,
          requestedby: `${toTitleCase(globalData?.firstName)} ${toTitleCase(
            globalData?.lastName
          )}`,
          paymentitems: [
            {
              netamount: "59463.30",
              category: "202-PA",
            },
            {
              netamount: "20000",
              category: "202-LA"
            }
          ],
        },
      },
      signature: "",
    };

    try {
      // fetch payment signature
      const signature = await axios.post(
        `${process.env.REACT_APP_PAYMENT_SIGNATURE_ENDPOINT}`,
        paymentPayload
      );

      paymentPayload.signature = signature?.data?.data;

      // create payment
      await axios
        .post(`${process.env.REACT_APP_PAYMENT_ENDPOINT}`, paymentPayload)
        .then((res: any) => {

          updateIsipTask(); // update isip task
        })
        .catch((err: any) => {
          console.error(err);
          setMainState({
            ...mainState,
            isLoading: false,
          });
        });
    } catch (err: any) {

      setMainState({
        ...mainState,
        isLoading: false,
      });
    }
  };

  // #region handleSelectChange
  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      taskData: { ...mainState?.data, status: selection?.value },
      selections: { status: selection },
    });
    setIsipTaskValidation({
      ...isipTaskValidation,
      status: selection?.value,
    });
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleValidateLoaBusinessRules = async () => {
    setIsLoadingLoaBusinessRules(true);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/loa-details/business-rules?transId=${transId}&id=${transId}&taskType=invoice_validation`,
        isipTaskValidation
      )
      .then((res: any) => {

        setIsLoadingLoaBusinessRules(false);
        // fetchTaskBusinessRules(Number(transId), "invoice_validation");
        setValidateLoaBusinessRules(true);
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingLoaBusinessRules(false);
        // fetchTaskBusinessRules(Number(transId), "invoice_validation");
        setValidateLoaBusinessRules(true);
      });
  };

  // #region useState
  React.useEffect(() => {
    fetchData();
    fetchIsipPolicyData();
  }, []);

  const columns = [
    {
      field: "docId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.docId === null || params.row.docId === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.docId
            )}
          </Stack>
        );
      },
    },
    {
      field: "documentType",
      headerClassName: "super-app-theme--header",
      headerName: "Document Type",
      flex: 1,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.documentType === null ||
              params.row.documentType === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              params.row.documentType
            )}
          </Stack>
        );
      },
    },
    {
      field: "channel",
      headerClassName: "super-app-theme--header",
      headerName: "Channel",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.channel === " " || params.row.channel === null
              ? // <Typography variant="inherit" style={{ color: "#ed6c02" }}>
              //   Not Available
              // </Typography>
              "Email"
              : params.row.channel === "2"
                ? "Form Builder"
                : params.row.channel === "1"
                  ? "Email"
                  : params.row.channel}
          </Stack>
        );
      },
    },
    {
      field: "dateReceived",
      headerClassName: "super-app-theme--header",
      headerName: "Date Received",
      sortable: true,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <Stack textAlign={"center"}>
            {params.row.dateReceived === " " ||
              params.row.dateReceived === null ? (
              <Typography variant="inherit" style={{ color: "#ed6c02" }}>
                Not Available
              </Typography>
            ) : (
              formatDate(params.row.dateReceived)
            )}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        let statusChip = <Chip label="Small" size="small" />;
        if (params.row.status === "1") {
          statusChip = (
            <Chip
              label="Open"
              size="small"
              variant="outlined"
              color="primary"
            />
          );
        } else if (params.row.status === "2") {
          statusChip = (
            <Chip
              label="Approve"
              size="small"
              variant="filled"
              color="success"
            />
          );
        } else if (params.row.status === "3") {
          statusChip = (
            <Chip
              label="Rejected"
              size="small"
              variant="filled"
              color="error"
            />
          );
        } else if (
          params.row.status === "For Email" ||
          params.row.status === "Emailed"
        ) {
          statusChip = (
            <Chip
              label="Emailed"
              size="small"
              variant="filled"
              color="warning"
            />
          );
        } else if (params.row.status === "11") {
          statusChip = (
            <Chip
              label="Missing"
              size="small"
              variant="outlined"
              color="warning"
            />
          );
        } else if (
          params.row.status === "Submitted" ||
          params.row.status === "For Damage Evaluation" ||
          params.row.status === "For Policy Checking"
        ) {
          statusChip = (
            <Chip
              label="Submitted"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else if (params.row.status === "Completed") {
          statusChip = (
            <Chip
              label="Completed"
              size="small"
              variant="filled"
              color="primary"
            />
          );
        } else {
          statusChip = <></>;
        }

        return <Stack textAlign={"center"}>{statusChip}</Stack>;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        const handleClickOpen = (
          scrollType: DialogProps["scroll"],
          documentType: any
        ) => {
          const isCompleted =
            params.row.status === "Approved" ||
              params.row.status === "Submitted" ||
              params.row.status === "For Damage Evaluation" ||
              params.row.status === "For Policy Checking" ||
              params.row.status === "Completed"
              ? true
              : false;

          if (documentType === "Sales Invoice") {
            setSelectedForm(0);
          } else if (documentType === "Service Invoice") {
            setSelectedForm(1);
          } else if (documentType === "Other Documents") {
            setSelectedForm(2);
          }
          setOpen(true);
          setScroll(scrollType);
        };

        let actionButton = (
          <Button
            variant="contained"
            className="tp-custom-button-primary"
            size="small"
            onClick={() => {
              handleClickOpen("paper", params.row.documentType);
              setSelectedDocId(params.row.docId);
            }}
          >
            Update
          </Button>
        );

        if (
          params.row.status === "2" ||
          params.row.status === "5" ||
          params.row.status === "1" ||
          params.row.status === "11"
        ) {
          actionButton = (
            <Button
              variant="contained"
              size="small"
              className="tp-custom-button-primary"
              onClick={() => {
                handleClickOpen("paper", params.row.documentType);
                setSelectedDocId(params.row.docId);
              }}
            >
              Update
            </Button>
          );
        } else if (
          params.row.status === "11" ||
          params.row.status === "12" ||
          params.row.status === "10"
        ) {
          actionButton = (
            <Button
              variant="contained"
              className="tp-custom-button-primary"
              size="small"
              color="error"
              disabled
            >
              Update
            </Button>
          );
        } else if (
          params.row.status === "2" ||
          params.row.status === "3" ||
          params.row.status === "5" ||
          params.row.status === "9" ||
          params.row.status === "8"
        ) {
          actionButton = (
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                handleClickOpen("paper", params.row.documentType);
                setSelectedDocId(params.row.docId);
              }}
            >
              View Details
            </Button>
          );
        } else {
          actionButton = <></>;
        }

        return <Stack textAlign={"center"}>{actionButton}</Stack>;
      },
    },
  ];

  // #region handleClose
  const handleClose = () => {
    setOpen(!open);
    // refresh data
    fetchData();
    fetchIsipPolicyData();
  };

  return (
    <Container maxWidth={"lg"} sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ minWidth: 275, my: 2 }}>
        <CardContent className="tp-padding-50px">
          <Grid container spacing={2}>
            <Grid item md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                Invoice Validation
              </Typography>
            </Grid>
            <Grid item md={3} textAlign={"center"}>
              {/* <img src={Image.logo} width={180} height={60} alt='img' /> */}
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ISIP-{transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Sender: &ensp;</span>
                {sender ?? "Not Available"}
              </Typography>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item md={7}>
              {/* <EmailMissing transId={transId} sender={sender}/> */}

              <EmailMissingIsip transId={transId} sender={sender} />
            </Grid>
            <Grid item md={5}>
              <Box display="flex" justifyContent="flex-end">
                {/* <UploadMissing transId={transId || ""} /> */}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 424,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#a42a25",
                color: "#ffffff",
              },
              "& .MuiDataGrid-columnHeaderDraggableContainer": {
                backgroundColor: "#a42a25",
              },
              "&. MuiDataGrid-footerContainer": {
                display: "none !important",
              },
            }}
          >
            {mainState?.attachments ? (
              <DataGrid
                rows={mainState?.attachments}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
              />
            ) : (
              <></>
            )}
          </Box>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          {
            // #region LOA Details
          }
          <LoaDetails
            fetchSearchLoaDetails={fetchSearchLoaDetails}
            sfSearchResult={sfSearchResult}
            setSfSearchResult={setSfSearchResult}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            sfLoaDetails={sfLoaDetails}
            setSfLoaDetails={setSfLoaDetails}
            setPolicyAsiaData={setPolicyAsiaData}
            transId={transId}
            isipTaskValidation={isipTaskValidation}
            setIsipTaskValidation={setIsipTaskValidation}
          />
          <br />
          <hr className="tp-border-hr_primary" />
          <br />
          {
            // #region Summary Details
          }
          <br />
          <Typography
            variant="h5"
            className="tp-text-header-primary"
            textAlign="left"
          >
            Summary Details
          </Typography>
          <Typography variant="body1">
            Note: Please check all Required Fields with (
            <span className="tp-text-required">*</span>) before you submit.
          </Typography>
          <hr />
          <br />
          <br />
          <IsipPolicyAsia
            policyAsiaData={policyAsiaData}
            setPolicyAsiaData={setPolicyAsiaData}
            isAmla={isAmla}
            setIsAmla={setIsAmla}
            isCashReserved={isCashReserved}
            setIsCashReserved={setIsCashReserved}
            isPaymentReceived={isPaymentReceived}
            setIsPaymentReceived={setIsPaymentReceived}
            isipTaskValidation={isipTaskValidation}
            setIsipTaskValidation={setIsipTaskValidation}
          />
          <br />
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          {/* 
            // #region BusinessRulesTask
          */}
          {validateLoaBusinessRules ? (
            <React.Fragment>
              <BusinessRulesTask
              transId={transId}
              taskType={"invoice_validation"}
            />
              <br />
              <Button
                disabled={isLoadingLoaBusinessRules}
                variant="contained"
                className="tp-custom-button-primary"
                onClick={handleValidateLoaBusinessRules}
              >
                {isLoadingLoaBusinessRules ? (
                  <>
                    <CircularProgress color="inherit" size="1.5em" />
                    &emsp; Validated LOA Details
                  </>
                ) : (
                  "Validated LOA Details"
                )}
              </Button>
              <br />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Stack>
                <Typography variant="h6" className="tp-text-header-primary">
                  Business Rules
                </Typography>
                <Typography variant="body1">
                  Note: All business rules that are violated will be shown here.
                </Typography>
              </Stack>
              <hr className="tp-border-hr_primary" />
              <br />
              <Button
                disabled={isLoadingLoaBusinessRules}
                variant="contained"
                className="tp-custom-button-primary"
                onClick={handleValidateLoaBusinessRules}
              >
                {isLoadingLoaBusinessRules ? (
                  <>
                    <CircularProgress color="inherit" size="1.5em" />
                    &emsp; Validated LOA Details
                  </>
                ) : (
                  "Validated LOA Details"
                )}
              </Button>
              <br />
            </React.Fragment>
          )}
          <br />
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Status <span className="tp-text-required">*</span>
                </Typography>
                <Select
                  id="status"
                  placeholder="Please select status here..."
                  value={mainState?.selections?.status || null}
                  // isClearable
                  onChange={handleSelectChange}
                  // isDisabled={disabled}
                  options={[
                    { value: 1, label: "Open" },
                    { value: 2, label: "Pending for Payment" },
                    { value: 3, label: "Reject" },
                  ]}
                />
                {mainState?.taskData?.status === null && (
                  <FormHelperText error id="status">
                    {/* {mainState?.formError?.status} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined" fullWidth className="tp-mb-30px">
                <Typography variant="body1" className="tp-text-bold">
                  Remarks
                </Typography>
                <OutlinedInput
                  id="remarks"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter Remarks Here...`}
                  value={mainState?.data?.remarks || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('remarks')}
                  inputProps={{
                    "aria-label": "remarks",
                  }}
                  disabled={disabled}
                // error={!!formError?.remarks}
                />
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <hr className="tp-border-hr_primary" />
          <br />
          {/* 
            // #region submit
          */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Button
              disabled={!mainState?.allStatusApproved || mainState?.isLoading}
              className="tp-custom-button-primary"
              variant="contained"
              onClick={handleSubmit}
            >
              {mainState?.isLoading ? (
                <>
                  <CircularProgress color="inherit" size="1.5em" />
                  &emsp; Submit
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
      {
        // #region MainModal
      }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll={scroll}
        TransitionComponent={Transition}
      >
        <AppBar color={"error"} sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              align="center"
            >
              Validation Process
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <IsipMainModal
          data={mainState.data}
          attachments={mainState.attachments}
          transID={transId}
          scroll={scroll}
          selectedForm={selectedForm}
          disabled={disabled}
          setIsDisabled={setIsDisabled}
          userName={globalData}
          refereshList={fetchData}
          close={handleClose}
          docId={selectedDocId}
        />
      </Dialog>
    </Container>
  );
}
