import axios from "axios";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { GlobalDataContext } from "./GlobalDataContext";

interface ICountStats {
  acapTaskOpenCount: number;
  acapTaskSubmittedCount: number;
  deTaskOpenCount: number;
  deTaskSubmittedCount: number;
  imsiTaskOpenCount: number;
  imsiTaskSubmittedCount: number;
  cncnccTaskOpenCount: number;
  cncnccTaskSubmittedCount: number;
  isipTaskOpenCount: number;
  isipTaskSubmittedCount: number;
  
}

interface ITaskData {
  id: number;
  title: string;
  slug: string;
  description: string;
  todo: number;
  submitted: number;
  rejected: number;
}

interface ICountStatsState {
  countStatus: ICountStats | null;
  acapTaskData: ITaskData[];
  isipTaskData: ITaskData[];
  imsiTaskData: ITaskData[];
  isLoading: boolean;
}

// Define a type for the context value
interface ICountStatsContextType {
  taskStatusCount: ICountStatsState;
  setTaskStatusCount: React.Dispatch<React.SetStateAction<ICountStatsState>>;
  fetchCountStats: (exclude: string[]) => void;
}

// Create the context with default values
const CountStatsContext = createContext<
  ICountStatsContextType | undefined
>(undefined);

// Create a provider component
type CountStatsProviderProps = {
  children: ReactNode;
}

export default function CountStatsStore({ children }: CountStatsProviderProps) {
  const { globalData } = React.useContext(GlobalDataContext);
  const [taskStatusCount, setTaskStatusCount] =
    React.useState<ICountStatsState>({
      countStatus: null,
      acapTaskData: [],
      isipTaskData: [],
      imsiTaskData: [],
      isLoading: false
    });

  // fetchTaskBusinessRules
  const fetchCountStats = async (exclude: string[] = []) => {
    setTaskStatusCount((prev: any) => ({
      ...prev,
      isLoading: true,
    }));
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/count-status`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
        }
        }
      )
      .then((res: any) => {

        const taskStatusCountResponse: ICountStats = res?.data?.data;
        const preAcapTaskData: ITaskData[] = [
          {
            id: 1,
            title: globalData.role !== "Claim Assistant" ? "Policy/Document Validation" : "Claim without Policy",
            slug: "document-validation",
            description: "Validation of policy and extracted documents",
            todo: taskStatusCountResponse.acapTaskOpenCount,
            submitted: taskStatusCountResponse.acapTaskSubmittedCount,
            rejected: 0
          },
          {
            id: 2,
            title: "IMSI Approval",
            slug: "imsi-approval",
            description: "Approval of IMSI",
            todo: taskStatusCountResponse.imsiTaskOpenCount,
            submitted: taskStatusCountResponse.imsiTaskSubmittedCount,
            rejected: 0
          },
          {
            id: 3,
            title: "Damage Evaluation",
            slug: "damage-evaluation",
            description: "Evaluation of vehicle damage",
            todo: taskStatusCountResponse.deTaskOpenCount,
            submitted: taskStatusCountResponse.deTaskSubmittedCount,
            rejected: 0
          },
          {
            id: 4,
            title: "Certificate of No Claim / NCC",
            slug: "cnc",
            description: "Evaluation for CNC and NCC",
            todo: taskStatusCountResponse.cncnccTaskOpenCount,
            submitted: taskStatusCountResponse.cncnccTaskSubmittedCount,
            rejected: 0
          }
        ]
        const preIsipTaskData: ITaskData[] = [
          {
            id: 1,
            title: "Invoice Validation",
            slug: "invoice-validation",
            description: "Validation of invoice documents",
            todo: taskStatusCountResponse.isipTaskOpenCount,
            submitted: taskStatusCountResponse.isipTaskSubmittedCount,
            rejected: 0
          }
        ]
        const preImsiTaskData: ITaskData[] = [
          {
            id: 1,
            title: "IMSI Approval",
            slug: "imsi-approval",
            description: "Approval of IMSI",
            todo: taskStatusCountResponse.imsiTaskOpenCount,
            submitted: taskStatusCountResponse.imsiTaskSubmittedCount,
            rejected: 0
          }
        ]

        const filteredAcapTaskData = exclude.length === 0
          ? preAcapTaskData
          : preAcapTaskData.filter(task => !exclude.includes(task.slug));
        const filteredIsipTaskData = exclude.length === 0 
          ? preIsipTaskData 
          : preIsipTaskData.filter(task => !exclude.includes(task.slug));
        const filteredImsiTaskData = exclude.length === 0 
          ? preImsiTaskData 
          : preImsiTaskData.filter(task => !exclude.includes(task.slug));

        setTaskStatusCount((prev: any) => ({
          ...prev,
          countStatus: taskStatusCountResponse,
          acapTaskData: filteredAcapTaskData,
          isipTaskData: filteredIsipTaskData,
          imsiTaskData: filteredImsiTaskData,
          isLoading: false,
        }));

      })
      .catch((err: any) => {

        setTaskStatusCount((prev: any) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  return (
    <CountStatsContext.Provider value={{ taskStatusCount, setTaskStatusCount, fetchCountStats }}>
      {children}
    </CountStatsContext.Provider>
  );
};

// Create a custom hook to use the AuthContext
export const useCountStats = (): ICountStatsContextType => {
  const context = useContext(CountStatsContext);
  if (!context) {
    throw new Error("useCountStats must be used within an BusinessRulesProvider");
  }
  return context;
};
