import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';

interface SiblingComponentProps {
  fmv: number;
  salvage: number;
  deductible: number;
}
const ComputationSheetTotalLoss: React.FC<SiblingComponentProps> = ({ fmv, salvage, deductible }) => {
  // alert(fmv);

  const totalNetClaim = fmv - salvage - deductible;
  const netClaimWithoutSalvage = fmv - deductible;

  const formatNumber = (value: number | null | undefined) => {
    if (value == null) {
      return '';
    }
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <Box sx={{ margin: '20px' }}>
      {/* Option 1 */}
      <Typography variant="h6">OPTION 1: INSURED RETAINS SALVAGE VALUE</Typography>
      <Box sx={{ border: '1px solid black', width: '300px', padding: '10px', marginBottom: '20px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>FMV/SI</TableCell>
              <TableCell align="right">{formatNumber(fmv)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deductible</TableCell>
              <TableCell align="right">({formatNumber(deductible)})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Salvage Value</TableCell>
              <TableCell align="right"> ({formatNumber(salvage)})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Claim</TableCell>
              <TableCell align="right">{formatNumber(totalNetClaim)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      {/* Option 2 */}
      <Typography variant="h6">OPTION 2: BPI/MS RETAINS SALVAGE VALUE</Typography>
      <Box sx={{ border: '1px solid black', width: '300px', padding: '10px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>FMV/SI</TableCell>
              <TableCell align="right">{formatNumber(fmv)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deductible</TableCell>
              <TableCell align="right">({formatNumber(deductible)})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Salvage Value</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Claim</TableCell>
              <TableCell align="right">{formatNumber(netClaimWithoutSalvage)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ComputationSheetTotalLoss;
