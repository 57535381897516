import React, { useState, useEffect } from "react";
import { Typography, Box, Tooltip, IconButton, Select, MenuItem, FormControl, SelectChangeEvent, Card } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const depreciationRates = [
  // For policies ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"]
  { minAge: 0, maxAge: 3, rate: 0.0, policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"] },
  { minAge: 3, maxAge: 4, rate: 0.2, policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"] },
  { minAge: 4, maxAge: 5, rate: 0.25, policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"] },
  { minAge: 5, maxAge: 6, rate: 0.3, policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"] },
  { minAge: 6, maxAge: 7, rate: 0.35, policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"] },
  {
    minAge: 7,
    maxAge: Number.MAX_SAFE_INTEGER,
    rate: 0.4,
    policies: ["PC", "DP", "LP", "OP", "OD", "LD", "PH", "MC"],
  },
  // For policies ["CV", "LT"]
  { minAge: 0, maxAge: 3, rate: 0.0, policies: ["CV", "LT"] },
  { minAge: 3, maxAge: 4, rate: 0.25, policies: ["CV", "LT"] },
  { minAge: 4, maxAge: 5, rate: 0.3, policies: ["CV", "LT"] },
  { minAge: 5, maxAge: 6, rate: 0.35, policies: ["CV", "LT"] },
  { minAge: 6, maxAge: 7, rate: 0.4, policies: ["CV", "LT"] },
  {
    minAge: 7,
    maxAge: Number.MAX_SAFE_INTEGER,
    rate: 0.45,
    policies: ["CV", "LT"],
  },
];

const getDepreciationRate = (policyPrefix: string, regDate: Date): number | null => {
  const currentDate = new Date();

  if (isNaN(regDate.getTime()) || regDate > currentDate) return null;

  if (policyPrefix === "LT") {
    // Calculate difference in months for LT policy
    const diffInTime = currentDate.getTime() - regDate.getTime();
    const diffInMonths = diffInTime / (1000 * 3600 * 24 * 30.4375);

    if (diffInMonths <= 6) {
      return 0.15;
    } else if (diffInMonths > 6 && diffInMonths <= 12) {
      return 0.25;
    } else if (diffInMonths > 12 && diffInMonths <= 24) {
      return 0.35;
    } else if (diffInMonths > 24 && diffInMonths <= 36) {
      return 0.45;
    } else if (diffInMonths > 36) {
      return 0.5;
    } else {
      return null;
    }
  } else {
    // Calculate difference in years for other policies
    const diffInYears =
      (currentDate.getTime() - regDate.getTime()) / (1000 * 3600 * 24 * 365);

    for (const rate of depreciationRates) {
      if (rate.policies.includes(policyPrefix)) {
        if (diffInYears >= rate.minAge && diffInYears < rate.maxAge) {
          return rate.rate;
        }
      }
    }
    return null;
  }
};
interface DepreciationProps {
    policyNo: string;
    crDate: string;
    onDepreciationChange: (depreciationRate: number) => void;
  }
  const Depreciation: React.FC<DepreciationProps> = ({ policyNo, crDate, onDepreciationChange }) => {
  const [depreciation, setDepreciation] = useState<number | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [computedDepreciation, setComputedDepreciation] = useState<number>(0);

  useEffect(() => {
    let msgs = [];

    if (!policyNo) {
      msgs.push("No validated policy yet");
    }
    if (!crDate) {
      msgs.push("No Registration date yet");
    }

    if (msgs.length > 0) {
      setMessages(msgs);
      setDepreciation(0);
      setComputedDepreciation(0);
      onDepreciationChange(0);
    } else {
      // Both policyNo and crDate are provided, compute depreciation
      setMessages([]);
      // Parse crDate into regDate
      const [month, day, year] = crDate.split("/");
      const regDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));

      const policyPrefix = policyNo.substring(0, 2).toUpperCase();

      // Validate policyPrefix
      if (
        !depreciationRates.some((rate) => rate.policies.includes(policyPrefix)) &&
        policyPrefix !== "LT"
      ) {
        setDepreciation(0);
        setComputedDepreciation(0);
        setMessages(["Invalid policy prefix!"]);
        onDepreciationChange(0);
        return;
      }

      const rate = getDepreciationRate(policyPrefix, regDate);
      if (rate === null) {
        setDepreciation(0);
        setComputedDepreciation(0);
        setMessages(["Invalid registration date or no depreciation rate found!"]);
        onDepreciationChange(0);
        return;
      }

      const ratePercentage = rate * 100;
      setComputedDepreciation(ratePercentage);
      setDepreciation(ratePercentage);
      onDepreciationChange(ratePercentage);
    }
  }, [policyNo, crDate]);

  const handleDepreciationSelect = (event: SelectChangeEvent<number | null>) => {
    const selectedValue = event.target.value as number;
    setDepreciation(selectedValue);
    onDepreciationChange(selectedValue);
  };

  const infoContent =
    messages.length > 0
      ? messages.join('\n')
      : `Policy No: ${policyNo}\nRegistration Date: ${crDate}\nComputed Depreciation: ${computedDepreciation}%`;

      return (
        <div>
          <Typography variant="body1" style={{ marginRight: '16px' }}>
            Global Depreciation (%):
          </Typography>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <Select
              value={depreciation}
              onChange={handleDepreciationSelect}
            >
              {Array.from({ length: 21 }, (_, index) => index * 5).map((percent) => (
                <MenuItem key={percent} value={percent}>
                  {percent}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title={infoContent} placement="right">
            <IconButton sx={{ ml: 1 }}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
};

export default Depreciation;
