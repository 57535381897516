import {
  Typography,
  Box,
  CircularProgress,
  Chip,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import CustomToolbarSelectUserTask from "./table_components/CustomToolbarSelectUserTask";

export default function UserTaskManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: "single",
    responsive: "standard",
    // resizableColumns: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelectUserTask selectedRows={selectedRows} data={rows} />
    ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const statusMap: { [key: string]: string } = {
    null: "Not Available",
    "0": "Not Available",
    "1": "Available",
  };

  const columns = [
    {
      name: "userId",
      label: "User ID",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "available",
      label: "Available",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Chip
              label={statusMap[value]}
              color={value === 1 ? "success" : "default"}
            />
          )
        },
      },
    },
    {
      name: "taskOpen",
      label: "Task Open",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "taskCompleted",
      label: "Task Completed",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
    {
      name: "roleName",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap", textAlign: "center" },
        }),
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // padding: "5px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins"
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              // textAlign: "center",
              fontFamily: "Poppins"
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      <Typography variant="h3" className="tp-text-header-primary">
        User Task Management
      </Typography>
      <Typography
        sx={{
          color: "#607274",
        }}
      >
        Manage user tasks
      </Typography>
      <hr />
      <br />
      <br />
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
