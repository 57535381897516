import { Alert, Typography } from "@mui/material";
import React from "react";
import PolicyAsiaPaymentTable from "../document_validation/tables/PolicyAsiaPaymentTable";
import { IPayment } from "../../types/Payment";

export default function PaymentHistory({ policyData, isipTaskValidation, setIsipTaskValidation }: any) {
  
  const listOfPayments: IPayment[] =
    typeof policyData.payment === "string"
      ? JSON.parse(policyData.payment)
      : policyData.payment;

  // const ornoValues = listOfPayments.map((item: IPayment) => item.orno);
  // const uniqueOrnoValues = new Set(ornoValues);
  // const isDoublePayment = uniqueOrnoValues.size < ornoValues.length;
  // // const isDoublePayment = false;
  // const isNoPayment = listOfPayments.length >= 0 ? true : listOfPayments[0].orno === "" ? true : false;

  // setIsipTaskValidation({
  //   ...isipTaskValidation,
  //   doublePayment: isDoublePayment,
  //   noPremiumPayment: isNoPayment
  // })

  return (
    <React.Fragment>
      <>
        {isipTaskValidation.noPremiumPayment ? (
          <React.Fragment>
            <Alert severity="warning">No premium payment</Alert>
            <br />
          </React.Fragment>
        ) : null}
        {isipTaskValidation.doublePayment ? (
          <React.Fragment>
            <Alert severity="warning">Double payment</Alert>
            <br />
          </React.Fragment>
        ) : null}
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Policy Number:</strong> {policyData.policyNumber}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          <strong>Long Name:</strong> {policyData.longName}
        </Typography>
        {policyData.payment && (
          <>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Payment:</strong>
            </Typography>
            <PolicyAsiaPaymentTable rows={listOfPayments} />
          </>
        )}
      </>
    </React.Fragment>
  );
}
