import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  TextField,
  CircularProgress, // Import CircularProgress
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const DETaskUploadClec = () => {
  const [sheets, setSheets] = useState<any[]>([]);
  const [totalParts, setTotalParts] = useState(0);
  const [totalLabor, setTotalLabor] = useState(0);
  const [claimDetails, setClaimDetails] = useState('');
  const [scopeOfWork, setScopeOfWork] = useState('');
  const [parts, setParts] = useState('');
  const [estimate, setEstimate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [transId, setTransId] = useState<number | null>(null);

  // State to manage email input and validation
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  // State for loading spinner
  const [isLoading, setIsLoading] = useState(false);

  // State to handle file upload
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setUploadedFile(file); // Set the uploaded file

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e?.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetsData = workbook.SheetNames.map((sheetName) => ({
          name: sheetName,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          }),
        }));

        setSheets(sheetsData);

        // Set the claimDetails, scopeOfWork, parts, and estimate from the sheets
        const findSheetData = (sheetName: string) => {
          const sheet = sheetsData.find(
            (sheet) => sheet.name.toLowerCase() === sheetName.toLowerCase()
          );
          return sheet ? JSON.stringify(sheet.data) : '';
        };

        setClaimDetails(findSheetData('claim details'));
        setScopeOfWork(findSheetData('scope of work'));
        setParts(findSheetData('parts'));
        setEstimate(findSheetData('estimate'));
      };
      reader.readAsArrayBuffer(file);
    },
  });

  useEffect(() => {
    if (sheets.length > 0) {
      const estimateSheet = sheets.find(
        (sheet) => sheet.name === 'Estimate' || sheet.name === 'estimate'
      );
      if (estimateSheet) {
        setTotalParts(estimateSheet.data[1] ? estimateSheet.data[1][4] : 0);
        setTotalLabor(estimateSheet.data[1] ? estimateSheet.data[1][3] : 0);
        // onUpdate(totalParts, totalLabor);
      }
    }
  }, [sheets]);

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      // First POST request
      const taskResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task`,
        {
          taskType: 'Document Validation',
          channel: 3,
          sender: email, // Include the email address
          priority: 0,
          status: 1,
          userId: 88,
        }
      );

      const taskId = taskResponse.data.id;

      // Second POST request
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/clec_estimate`, {
        transId: taskId,
        claimDetails: claimDetails,
        scopeOfWork: scopeOfWork,
        parts: parts,
        estimate: estimate,
      });

      // Call the postPartsData function with the parts sheet data
      const partsSheet = sheets.find(
        (sheet) =>
          sheet.name.toLowerCase() === 'parts' ||
          sheet.name.toLowerCase() === 'part'
      );
      if (partsSheet) {
        await postPartsData(partsSheet.data, taskId);
      }

      // Get the current date and time
      const currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 8);
      const formattedDateTime = currentDateTime
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');

      // Third POST request
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`, {
        transId: taskId,
        deId: taskId,
        sender: email, // Include the email address
        saveDate: formattedDateTime,
        priority: 0,
        taskType: 'Damage Evaluation',
        channel: 3,
        status: 3,
        userDetails: {
          id: 89,
        },
      });

      setTransId(taskId);
      setModalOpen(true);
    } catch (error) {
      console.error('Error creating task and estimate:', error);
      alert('Failed to create task and estimate');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const postPartsData = async (data: any[], transId: number) => {
    const partsData = data.slice(1).map((row) => {
      const part = row[0];
      const qty = 1;
      const price = parseFloat(row[1]);
      const amount = price * qty;
      const currentDate = new Date();
      const timezoneOffset = currentDate.getTimezoneOffset() * 60000;
      const localDate = new Date(currentDate.getTime() - timezoneOffset);
      const saveDate = localDate.toISOString().slice(0, -1);

      return {
        deId: transId,
        transId: transId,
        name: part,
        amount,
        qty,
        price,
        saveDate,
      };
    });

    try {
      for (const part of partsData) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/parts`,
          part,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      }

    } catch (error) {
      console.error('Error posting parts data:', error);
    }
  };

  const renderTypography = (sheet: any) => (
    <Box>
      {sheet.data[0].map((header: any, headerIndex: number) => (
        <Grid container key={headerIndex} spacing={2} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="subtitle1" component="div">
              {header}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" component="div">
              {sheet.data[1] ? sheet.data[1][headerIndex] : ''}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );

  const renderTable = (sheet: any) => {
    const headers = sheet.data[0];
    const rows = sheet.data.slice(1).map((row: any[]) => {
      const filledRow = [...row];
      while (filledRow.length < headers.length) {
        filledRow.push(''); // Fill missing values with empty strings
      }
      return filledRow;
    });

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header: any, index: number) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any[], rowIndex: number) => (
              <TableRow key={rowIndex}>
                {row.map((cell: any, cellIndex: number) => (
                  <TableCell key={cellIndex}>
                    {cell !== undefined && cell !== null ? cell : ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Email validation logic
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  // Reset function to clear uploaded files and sheet data
  const handleReset = () => {
    setSheets([]);
    setClaimDetails('');
    setScopeOfWork('');
    setParts('');
    setEstimate('');
    setTotalParts(0);
    setTotalLabor(0);
    setUploadedFile(null); // Reset the uploaded file
  };

  return (
    <div>
      <Box sx={{ padding: 2 }}>
        {!uploadedFile && ( // Hide the upload button if a file is uploaded
          <Paper
            variant="outlined"
            {...getRootProps()}
            sx={{ padding: 2, textAlign: 'center' }}
          >
            <input {...getInputProps()} />
            <Typography>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          </Paper>
        )}
        {sheets.map((sheet, sheetIndex) => (
          <Box key={sheetIndex} sx={{ marginTop: 2 }}>
            <Typography variant="h6">{sheet.name}</Typography>
            {sheet.name === 'Scope of Work' ||
            sheet.name === 'Parts' ||
            sheet.name === 'scope of work' ||
            sheet.name === 'parts' ? (
              renderTable(sheet)
            ) : (
              renderTypography(sheet)
            )}
          </Box>
        ))}
        <div className="tp-text-bold">
          Initial Total Parts Amount: ₱ {formatNumber(totalParts)}
        </div>
        <div className="tp-text-bold">
          Initial Total Labor Amount: ₱ {formatNumber(totalLabor)}
        </div>
        {uploadedFile && ( // Show reset button only when a file is uploaded
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="secondary" onClick={handleReset}>
              Reset
            </Button>
          </Box>
        )}
      </Box>
      <TextField
        label="Claimant Email Address"
        value={email}
        onChange={handleEmailChange}
        fullWidth
        margin="normal"
        required
        error={!isEmailValid && email.length > 0}
        helperText={
          !isEmailValid && email.length > 0
            ? 'Please enter a valid email address'
            : ''
        }
      />
      <hr className="tp-border-hr_primary" />

      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 5 }} // Added margin bottom
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isEmailValid || !estimate} // Disable button if email is invalid or no estimate uploaded
        >
          Submit
        </Button>
      </Box>

      {/* Display CircularProgress when loading */}
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {!isLoading && (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                Successfully made a Document and Damage Evaluation task with
                Transaction ID of ACAP-{transId}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/damage-evaluation')}
                >
                  Back to Damage Evaluation Page
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default DETaskUploadClec;
