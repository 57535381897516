import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
  Button,
  Tooltip,
  Zoom,
} from "@mui/material";
import Typography from "@mui/material/Typography";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
import * as React from "react";
import Select from "react-select";
// import IncidentTable from "./IncidentTable";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import BusinessRules from "./checklist/BusinessRules";
import { NotificationContext } from "../../contexts/NotificationContext";
import { capitalizeAndReplaceUnderscore, isValidDate, isValidTime } from "../../utils/stringUtils";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function PoliceReportForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mvData, setMvData] = React.useState<any>({
    data: {},
  });
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { validateBusinessRules } = useBusinessRules();
  const { notification, setNotification } =
    React.useContext(NotificationContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/police-report?transId=${transID}&id=${docId}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {

            const data = res.data.data;
            const statusOptions: any = {
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approved" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData(
              globalData!.accessToken!,
              {
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View police report form with document id #${docId}`,
              user: globalData.username,
              fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
              roleLevel: globalData.role
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const isNotFutureDate = (dateStr: string): boolean => {
    const enteredDate = new Date(dateStr);
    const today = new Date();

    // Normalize the dates to remove time components
    enteredDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return enteredDate <= today;
  };

  const updateData = async () => {
    // validate dates
    const isdateReportedvalid = isValidDate(mainState?.data?.dateReported);
    if (!isdateReportedvalid)
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, dateReported: "Invalid Date Format" },
      }));

    const isAdDateNotFuture = isNotFutureDate(mainState?.data?.dateReported);
    if (!isAdDateNotFuture) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, dateReported: "Date cannot be in the future!" },
      }));

      //alert("Incident Date: Date cannot be in the future!");
    }


    const istimeReportedvalid = isValidTime(mainState?.data?.timeReported);
    if (!istimeReportedvalid)
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, timeReported: "Invalid Time Format" },
      }));

    if (isdateReportedvalid && istimeReportedvalid) {
      try {
        setMainState((prevState: any) => ({ ...prevState, isLoading: true }));

        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/police-report?transId=${transID}&id=${docId}`,
          mainState.data,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        const data = res.data.data;

        const message = res.data.message;
        // const statusOptions: any = {
        //   0: null,
        //   1: { value: 1, label: "Open" },
        //   2: { value: 2, label: "Approve" },
        //   3: { value: 3, label: "Reject" },
        // };
        // setMainState((prevState: any) => ({
        //   ...prevState,
        //   data: data,
        //   selections: { status: statusOptions[data.status] },
        //   formError: data?.error,
        //   isLoading: false,
        // }));

        validateBusinessRules(globalData!.accessToken!,
          `/api/acap/document-validation/police-report/business-rules?transId=${transID}&id=${docId}&taskType=${"document_validation"}`,
          mainState?.data,
          transID,
          "document_validation"
        );

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: transID,
          taskType: "document_validation",
          event: "update_form",
          description: `Update police report form with document id #${docId}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        });

        setMainState((prev: any) => ({ ...prev, formError: {} }));

        setNotification({
          level: "success",
          showAlert: true,
          message: message,
        });
        // close();
      } catch (error) {
        console.error("Error updating data:", error);
        setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
      }
    }
  };

  const fetchMvData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/motor-vehicle-form?transId=${transID}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;

            setMvData({ ...mvData, data: data });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;

          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const apiGetDocumentStatus = async () => {
  //   axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transID}/Police Report`,
  //     // headers: {
  //     //   'Authorization': `Bearer ${globalData?.sessionToken}`
  //     // }
  //   }).then((res: any) => {

  //     setIsMissing(res.data?.status === "Missing" ? true : false);
  //   }).catch((err: any) => {
  //     console.error(err?.response?.data)
  //     let errorMessage = ""
  //     if (err?.response?.status === 401) errorMessage = err?.response?.data
  //     if (err?.response?.status === 400) errorMessage = err?.response?.data
  //   })
  // }

  const isAnyFieldEmpty = () => {

    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        key !== "sourceId" &&
        key !== "transId" &&
        key !== "saveDate" &&
        key !== "inameDriver" &&
        key !== "iplateNo" &&
        key !== "ilicenseNo" &&
        key !== "alicenseNo" &&
        key !== "aplateNo" &&
        key !== "anameDriver" &&
        key !== "accidentAddress" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {

        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {

        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    // apiGetDocumentStatus();
    fetchData();
    fetchMvData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  // const handleBlur = (id: any) => {
  //   if (datachanged[id]) { //check if have any changes in the value and then data will reflect on the Database via PUT API.
  //     updateData(transID, 'PoliceReport', false)
  //   }

  // }

  //#endregion Control Methods

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <Tooltip
                title="Ex. Dec. 21, 2021"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Accident Date <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="dateReported"
                    size="small"
                    type="text"
                    placeholder={`Enter Date Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={datadata.dateReportedConf + "%"} size="small" className={datadata.dateReportedConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.dateReported || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('dateReported')}
                    inputProps={{
                      "aria-label": "dateReported",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.dateReported === "" ||
                      mainState?.formError?.dateReported ||
                      !isValidDate(mainState?.data?.dateReported)
                    }
                  />
                  {(mainState?.data?.dateReported === "" ||
                    mainState?.formError?.dateReported ||
                    !isValidDate(mainState?.data?.dateReported)) && (
                      <FormHelperText error id="dateReported">
                        {!isValidDate(mainState?.data?.dateReported) &&
                          "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item md={6}>
              <Tooltip
                title="Ex. 08:00 pm"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Accident Time <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="timeReported"
                    size="small"
                    type="text"
                    placeholder={`Enter Accident Time Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={datadata.timeReportedConf + "%"} size="small" className={datadata.timeReportedConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.timeReported || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('timeReported')}
                    inputProps={{
                      "aria-label": "timeReported",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.timeReported === "" ||
                      mainState?.formError?.timeReported ||
                      !isValidTime(mainState?.data?.timeReported)
                    }
                  />
                  {(mainState?.data?.timeReported === "" ||
                    mainState?.formError?.timeReported ||
                    !isValidTime(mainState?.data?.timeReported)) && (
                      <FormHelperText error id="timeReported">
                        {!isValidTime(mainState?.data?.timeReported) && "Invalid Time"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Police Station <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="policeStation"
              size="small"
              type="text"
              placeholder={`Enter Police Station Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.policeStationConf + "%"} size="small" className={datadata.policeStationConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.policeStation || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('policeStation')}
              inputProps={{
                "aria-label": "policeStation",
              }}
              disabled={disabled}
              error={mainState?.data?.policeStation === ""}
            />
            {(mainState?.data?.policeStation ?? "") === "" && (
              <FormHelperText error id="policeStation">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Name of Investigator <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="nameInvestigator"
              size="small"
              type="text"
              placeholder={`Enter Name of Investigator Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.investigatorConf + "%"} size="small" className={datadata.investigatorConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.nameInvestigator || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('investigator')}
              inputProps={{
                "aria-label": "nameInvestigator",
              }}
              disabled={disabled}
              error={mainState?.data?.nameInvestigator === ""}
            />
            {(mainState?.data?.nameInvestigator ?? "") === "" && (
              <FormHelperText error id="nameInvestigator">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Accident Address <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="locationIncident"
              size="small"
              type="text"
              placeholder={`Enter Accident Address Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.investigatorConf + "%"} size="small" className={datadata.investigatorConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.locationIncident || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('investigator')}
              inputProps={{
                "aria-label": "locationIncident",
              }}
              disabled={disabled}
              error={mainState?.data?.locationIncident === ""}
            />
            {(mainState?.data?.locationIncident ?? "") === "" && (
              <FormHelperText error id="locationIncident">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Incident Details<span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="detailsIncident"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Incident Details Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={datadata.investigatorConf + "%"} size="small" className={datadata.investigatorConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.detailsIncident || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('investigator')}
              inputProps={{
                "aria-label": "detailsIncident",
              }}
              disabled={disabled}
              error={mainState?.data?.detailsIncident === ""}
              // maxRows={4}
              minRows={4}
            />
            {/* <TextareaAutosize
              id="detailsIncident"
              aria-label="detailsIncident"
              minRows={3}
              placeholder="Enter Incident Details Here..."
              value={mainState?.data?.detailsIncident || ""}
              onChange={handleInputChange}
              style={{ width: "100%" }}
              disabled={disabled}
              className={
                mainState?.data?.detailsIncident === "" ? "error-class" : ""
              }
            /> */}
            {(mainState?.data?.detailsIncident ?? "") === "" && (
              <FormHelperText error id="detailsIncident">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Insured Name Driver{" "}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="inamedriver"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Driver Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.inamedriver}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "inamedriver",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.inamedriver === ""}
                />
                {/* {(mainState?.data?.inamedriver ?? "") === "" && (
                      <FormHelperText error id="inamedriver">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Insured Plate No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="iplateno"
                  size="small"
                  type="text"
                  placeholder={`Enter Insured Plate No Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.iplateno}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "iplateno",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.iplateno === ""}
                />
                {/* {(mainState?.data?.iplateno ?? "") === "" && (
                      <FormHelperText error id="iplateno">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Name Driver
                </Typography>
                <OutlinedInput
                  id="anamedriver"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Driver Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.anamedriver || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "anamedriver",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.anamedriver === ""}
                />
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Adverse Plate No.
                </Typography>
                <OutlinedInput
                  id="aplateno"
                  size="small"
                  type="text"
                  placeholder={`Enter Adverse Plate No Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.aplateno || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "aplateno",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.aplateno === ""}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Vehicle Desription of Insured{" "}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vehicledescInsured"
                  size="small"
                  type="text"
                  placeholder={`Enter  Vehicle Desription of Insured  Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vehicledescInsured || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "vehicledescInsured",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.vehicledescInsured === ""}
                />
                {(mainState?.data?.vehicledescInsured ?? "") === "" && (
                  <FormHelperText error id="vehicledescInsured">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Vehicle Description of Adverse
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vehicledescAdverse"
                  size="small"
                  type="text"
                  placeholder={`Enter Vehicle Description of Adverse Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vehicledescAdverse || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "vehicledescAdverse",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.vehicledescAdverse === ""}
                />
                {(mainState?.data?.vehicledescAdverse ?? "") === "" && (
                  <FormHelperText error id="vehicledescAdverse">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Weather Condition
                </Typography>
                <OutlinedInput
                  id="weatherCondition"
                  size="small"
                  type="text"
                  placeholder={`Enter Weather Condition Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.weatherCondition || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "weatherCondition",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.weatherCondition === ""}
                />
                {/* {(mainState?.data?.weatherCondition ?? "") === "" && (
                      <FormHelperText error id="weatherCondition">
                        Empty required field. Please input data
                      </FormHelperText>
                    )}
                 */}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Alcohol Drugs
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="alcoholDrugs"
                  size="small"
                  type="text"
                  placeholder={`Enter Alcohol Drugs Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.alcoholDrugs || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "alcoholDrugs",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.alcoholDrugs === ""}
                />
                {/* {(mainState?.data?.alcoholDrugs ?? "") === "" && (
                      <FormHelperText error id="alcoholDrugs">
                        Empty required field. Please input data
                      </FormHelperText>
                    )} */}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-10px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Expiry date of license for the insured
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="expirydateLicense"
                  size="small"
                  type="text"
                  placeholder={`Enter Expiry date of license for the insured  Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.expirydateLicense || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "expirydateLicense",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.expirydateLicense === ""}
                />
                {/* {(mainState?.data?.expirydateLicense ?? "") === "" && (
                      <FormHelperText error id="expirydateLicense">
                        Empty required field. Please input data
                      </FormHelperText>
                    )}
                 */}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-10px">
                <Typography variant="body1" className="tp-text-bold">
                  Vehicle Damage<span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vehicleDamage"
                  size="small"
                  type="text"
                  placeholder={`Enter Vehicle Damage Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vehicleDamage || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "vehicleDamage",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.vehicleDamage === ""}
                />
                {(mainState?.data?.vehicleDamage ?? "") === "" && (
                  <FormHelperText error id="vehicleDamage">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-5px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-5px">
                <Typography variant="body1" className="tp-text-bold">
                  Claim Participant (Comma Separated)
                  {/* <span className="tp-text-required">*</span> */}
                </Typography>
                <OutlinedInput
                  id="claimParticipant"
                  size="small"
                  type="text"
                  placeholder={`Enter Claim Participant Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={datadata.locationOfIncidentConf + "%"} size="small" className={datadata.locationOfIncidentConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.claimParticipant || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('locationOfIncident')}
                  inputProps={{
                    "aria-label": "claimParticipant",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.expirydateLicense === ""}
                />
                {/* {(mainState?.data?.expirydateLicense ?? "") === "" && (
                      <FormHelperText error id="expirydateLicense">
                        Empty required field. Please input data
                      </FormHelperText>
                    )}
                 */}
              </FormControl>
            </Grid>
          </Grid>
          {/* <IncidentTable transID={transID} policeReport={true} mainState={mvData} setMainState={setMvData} disabled={disabled} />
              <br />
              <Typography variant="h6" className="tp-text-header-primary">
                Sketch of the Incident
              </Typography>
              <br />
              <hr />
              <Card>
                <CardContent>
                  {mainState?.data?.sketchOfIncidentGDriveId ?
                    <img src={"https://drive.google.com/uc?export=view&id=" + mainState?.data?.sketchOfIncidentGDriveId} width={"30%"} height={"40%"} alt='img' />
                    :
                    <Typography variant="body1" className="tp-text-muted">
                      No Available Sketch of the Incident
                    </Typography>}
                </CardContent>
              </Card> */}
          <br />
          <br />
          {/* 
            // #region BusinessRules
          */}
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"document_validation"} documentType={"police_report"} />
          <br />
          {/* 
            // #region ForProcessor
          */}
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approved" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            // error={!!dataformError?.remarks}
            />
            {/* {!!dataformError?.remarks && (
              <FormHelperText error id="remarks">
                {dataformError?.remarks}
              </FormHelperText>
            )} */}
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* {option !== 0 && <Button onClick={() => handleBack()} variant="outlined" size='medium' >Back</Button>}&emsp; */}
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/police-report"}
                    documentType={"Police Report"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                    color="error"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}



