import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Card, Box, Button, Typography, Grid, IconButton, OutlinedInput } from "@mui/material";
import { GlobalDataContext } from "../../../contexts/GlobalDataContext";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { v4 as uuidv4, validate } from "uuid";

interface IShopItemTableProps {
  transID: number;
  mainState: any;
  shopItemTable: any;
  setShopItemTable: (shopItemTable: any) => void;
  disabled: boolean;
}

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const ShopItemTable = (props: IShopItemTableProps) => {
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [editIndex, setEditIndex] = React.useState<string | null>(null);

  const handleChange = (id: string, field: string, value: string) => {
    const updatedRows = props.shopItemTable.rows.map((row: any) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    console.log("Updated rows:", updatedRows);
    props.setShopItemTable({ ...props.shopItemTable, rows: updatedRows });
  };

  const handleSave = (id: string) => {
    console.log("Save changes for row:", props.shopItemTable);
    setEditIndex(null); // Exit edit mode
  };

  const handleCancel = () => {
    setEditIndex(null); // Exit edit mode
  };

  const handleDelete = (id: string) => {
    // Filter out the row with the matching ID
    const updatedRows = props.shopItemTable.rows.filter((row: any) => row.id !== id);
    console.log("handleDelete", updatedRows);
  
    // Update the parent state with the filtered rows
    props.setShopItemTable({ ...props.shopItemTable, rows: updatedRows });
  };

  const handleAddItem = () => {
    const newRow = {
      id: uuidv4(),
      "Scope of work": "",
      "Labor cost": "",
      "Material cost": "",
      "Total cost": "", 
    };
  
    props.setShopItemTable((prev: any) => ({
      ...prev,
      rows: [...prev.rows, newRow],
    }));

    console.log("Add new item:", newRow);

    setEditIndex(newRow.id)
  };

  return (
    <Card sx={{ px: 2, py: 2 }}>
      <Grid container spacing={2}>
        <Grid item md={10}>
          <Typography variant="h6" className="tp-text-header-primary">
            List of Items
            <Typography
              variant="caption"
              display="block"
              className="tp-text-subtitle"
            >
              Note: Please add atleast one item.
            </Typography>
          </Typography>
        </Grid>
        {props.disabled === true ? (
          // <Grid item md={2} alignSelf="center" textAlign="center">
          //     <Button color="warning" size='small' variant='contained' startIcon={<SendIcon />} onClick={handleTransferParts}>
          //         Transfer
          //     </Button>
          // </Grid>
          <></>
        ) : (
          <Grid item md={2} alignSelf="center" textAlign="center">
            <Button
              color="primary"
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddItem}
              disabled={[
                "Clec",
                "Jr Clec",
                "Head Motor Claims",
                "Head Claims",
                "Head Clec",
              ].includes(globalData?.role)}
            >
              Add Item
            </Button>
          </Grid>
        )}
      </Grid>
      <br />
      <TableContainer>
        <Table
          sx={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgba(177,17,22,1)" }}>
              <TableCell
                sx={{
                  color: "white",
                }}
              >
                Scope of work
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                }}
              >
                Labor cost
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                }}
              >
                Material cost
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                }}
              >
                Total cost
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.shopItemTable.rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: "center", padding: "16px" }}>
                  No Data Available
                </TableCell>
              </TableRow>
            ) : (
              props.shopItemTable.rows.map((row: any) => (
                <TableRow key={row.id}>
                  {/* Editable or display cell based on editIndex */}
                  <TableCell sx={{ padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Scope of work"]}
                        onChange={(e) =>
                          handleChange(row.id, "Scope of work", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Scope of work"]
                    )}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Labor cost"]}
                        onChange={(e) =>
                          handleChange(row.id, "Labor cost", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Labor cost"]
                    )}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Material cost"]}
                        onChange={(e) =>
                          handleChange(row.id, "Material cost", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Material cost"]
                    )}
                  </TableCell>
                  <TableCell sx={{  padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <OutlinedInput
                        type="text"
                        size="small"
                        defaultValue={row["Total cost"]}
                        onChange={(e) =>
                          handleChange(row.id, "Total cost", e.target.value)
                        }
                        sx={{
                          border: "none",
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    ) : (
                      row["Total cost"]
                    )}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px" }}>
                    {editIndex === row.id ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => handleSave(row.id)}
                          title="Save"
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleCancel()}
                          title="Cancel"
                        >
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => setEditIndex(row.id)}
                          title="Edit"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(row.id)}
                          title="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ShopItemTable;
