import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { GlobalDataContext } from '../contexts/GlobalDataContext';

interface UpdateStatusRequest {
  status: number;
}

interface AcapTaskResponseData {
  data: {
    status: number;
    // ... other fields as needed
  };
  success: boolean;
}

const AcapTaskStatusUpdater: React.FC<{ transId: any, syncAW: boolean }> = ({ transId, syncAW }) => {
  const { globalData } = React.useContext(GlobalDataContext);
  const [status, setStatus] = useState<string>('Open');
  const [currentStatus, setCurrentStatus] = useState<number>(1); // Initialize to 'Open'
  const [loading, setLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Fetch current status on component mount
  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get<AcapTaskResponseData>(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/task/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
          }
          }
        );

        const fetchedStatus = response.data.data.status;
        setCurrentStatus(fetchedStatus);
        setStatus(mapStatusCodeToLabel(fetchedStatus));
      } catch (error) {
        console.error('Error fetching task:', error);
        setErrorMessage('Failed to fetch task details.');
      }
    };

    fetchTask();
  }, [transId]);

  // Helper functions
  const mapStatusCodeToLabel = (statusCode: number): string => {
    switch (statusCode) {
      case 1:
        return 'Open';
      case 31:
        return 'Closed Withdrawn';
      // Add other status mappings as needed
      default:
        return 'Open';
    }
  };

  const mapLabelToStatusCode = (label: string): number => {
    switch (label) {
      case 'Closed Withdrawn':
        return 31;
      case 'Open':
      default:
        return 1; // Set to 'Open' status code
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value as string);
  };

  const handleUpdateStatus = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const newStatusCode = mapLabelToStatusCode(status);

    // Determine the status to update
    const statusToUpdate = newStatusCode;

    // If the status hasn't changed, do nothing
    if (statusToUpdate === currentStatus) {
      setLoading(false);
      setSuccessMessage('No changes to update.');
      return;
    }

    // Prepare the request body
    const requestBody: UpdateStatusRequest = {
      status: statusToUpdate,
    };

    try {
      const response = await axios.patch<AcapTaskResponseData>(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/task/${transId}`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      if (response.status === 200 && response.data.success) {
        setSuccessMessage('Status updated successfully.');
        setCurrentStatus(response.data.data.status);
        setStatus(mapStatusCodeToLabel(response.data.data.status));
      } else {
        setErrorMessage('Failed to update status.');
      }
    } catch (error: any) {
      console.error('Error updating status:', error);
      setErrorMessage(error.response?.data?.message || 'An error occurred while updating status.');
    } finally {
      setLoading(false);
    }
  };

  const isStatusChanged = status !== mapStatusCodeToLabel(currentStatus);

  return (
    <div>
      <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
        <InputLabel id="status-select-label">Select Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={status}
          disabled={syncAW}
          onChange={handleStatusChange}
          label="Select Status"
        >
          <MenuItem value="Open">Open</MenuItem>
          <MenuItem value="Closed Withdrawn">Closed Withdrawn</MenuItem>
          {/* Add other MenuItems as needed */}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateStatus}
        disabled={loading || !isStatusChanged}
        style={{ marginLeft: '16px' }}
      >
        {loading ? 'Updating...' : 'Update Status'}
      </Button>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AcapTaskStatusUpdater;
