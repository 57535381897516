import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import CNCTaskTable from '../components/CNCTaskTable';

export default function CNCPage() {
    const navigate = useNavigate();

    return (
        <Container maxWidth={'xl'} sx={{
            mt: 12
        }}>
            <Box sx={{
                my: 4,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <IconButton onClick={() => navigate('/')} disableRipple={true} aria-label="open" sx={{
                    padding: 0
                }}>
                    <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                    <Typography sx={{ ml: 1 }}>Back</Typography>
                </IconButton>
                <Typography variant='h4' color="error"><b>CNC / NCC Validation</b></Typography>
            </Box>
            <CNCTaskTable task={'CNC Validation'} />
        </Container>
    )
}