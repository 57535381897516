import {
    CircularProgress,
    Container,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import axios from "axios";
import React, { Dispatch } from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
//   import ListOfLabors from "./ListOfLabors";
import ListOfParts from "./ListOfParts";
// import { ApiResponseClaim, ParsedEntry } from "../types";
//   import ClaimDetails from "./ClaimDetails";
//   import ClaimRegistrationDetail from "./ClaimRegistrationDetails";

type CartItem = {
    id?: number;
    deId?: number;
    transId?: number;
    name: string;
    amount: number;
    qty: number;
    condition: 'Original' | 'Replacement Parts' | 'Surplus' | 'Taiwan Parts' | 'Not Standard Part' | 'No Price Established' | 'Estimated Price' | 'Subject to Availability' | 'As per sample' | 'No stock' | 'Standard operating procedure when replacing parts' | '';
    globalDepreciation: boolean;
    shopPrice: number;
    totalAmount: number;
    depreciation: number;
    discount: number;
    netAmount: number;
    shopAmount: boolean;
    remarks: string;
    saveDate?: string;
};

interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
}

export interface ItemCoverage {
    itemCoverage: string;
    sumInsuredperItemCoverage: number;
}

export interface ApiData {
    deductible: number | null;
    itemCoverages: string; // This is a JSON string
    // ... include other fields if needed
}

export interface ApiResponse {
    message: string;
    data: ApiData;
    status: boolean;
    errors: any;
    success: boolean;
}

interface ClientTransactionProps {
    updateGrossTotal?: (totalAmount: number, laborPlusVat: number) => void;
    updateTotalDeductible?: (
        deductible: number,
        deductibleAmount: number
    ) => void;
    transID?: any;
    deId?: any;
    data?: any;
    totalRepairersAmount?: any;
    initialLaborAmount?: any;
    initialPartsAmount?: any;
    totalLaborAmount?: any;
    onTotalDepreciationChange: (totalDepreciation: number) => void;
    onTotalOfferAmountChange: (totalOfferAmount: number) => void;
    updateCart: (newCart: CartItem[]) => void;
    onTotalDiscountChange?: (totalDiscount: number) => void;
    onVatChange?: (vat: number) => void;
    setTotalLaborAmount?: (amount: number) => void;
    lossReserveParts: number;
    onLaborDataChange: (laborData: any) => void;
    setLossReserveParts: (amount: number) => void;
    selectedBrand: string | null;
    selectedModel: string | null;
    lossReserveLabor: number;
    onIsTotalLossChange: (percentLoss: number) => void;
    setLossReserveLabor: (amount: number) => void;
    mainState: any;
    setMainState: Dispatch<any>;
    fmv: number;
    setFmv: React.Dispatch<React.SetStateAction<number>>;
    salvage: number;
    perilType: string | null;
    setSalvage: (value: number) => void;
    policyNo: any | null;
    crDate: string | null;
}


const CanvasserClientTransaction: React.FC<ClientTransactionProps> = ({
    updateGrossTotal,
    updateTotalDeductible,
    transID,
    deId,
    data,
    onLaborDataChange,
    totalRepairersAmount,
    initialLaborAmount,
    initialPartsAmount,
    totalLaborAmount,
    perilType,
    updateCart,
    onTotalDepreciationChange,
    onTotalOfferAmountChange,
    setTotalLaborAmount,
    onTotalDiscountChange,
    onVatChange,
    lossReserveParts,
    setLossReserveParts,
    lossReserveLabor,
    selectedBrand,
    onIsTotalLossChange,
    selectedModel,
    setLossReserveLabor,
    mainState,
    setMainState,
    fmv,
    setFmv,
    salvage,
    setSalvage,
    policyNo,
    crDate,

}) => {
    // const handleLaborRowsChange = (newRows: LaborRow[]) => {
    //   onLaborRowsChange(newRows);
    // };
    const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
    const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
    const [totalAmount, setTotalAmount] = React.useState<number>(0);
    const [deductible, setDeductible] = React.useState<number>(1);
    const [deductibleAmount, setDeductibleAmount] = React.useState<number>(0);
    const [totalSumInsured, setTotalSumInsured] = React.useState<number>(0);
    const [totalLoss, setTotalLoss] = React.useState<string>("Not Total Loss");
    const [floodLevel, setFloodLevel] = React.useState<string>("N/A");
    const [flood, setFlood] = React.useState<string>("Flooded");
    const [totalDepreciation, setTotalDepreciation] = React.useState<number>(0);
    const [claimRegistrationData, setClaimRegistrationData] =
        React.useState<any>(null);


    const totalPartsAndLabors = Number(totalAmount) + Number(laborPlusVat);
    const difference = Number(totalRepairersAmount) - Number(totalAmount);
    const differenceLabor = Number(totalLaborAmount) - Number(laborPlusVat);

    // Computations for Gross Totals
    const initialGrossTotal = Number(initialLaborAmount) + Number(initialPartsAmount);
    const repairersGrossTotal = Number(totalRepairersAmount) + Number(totalLaborAmount);
    const ourOfferGrossTotal = Number(totalAmount) + Number(laborPlusVat);
    const differenceGrossTotal = Number(repairersGrossTotal) - Number(ourOfferGrossTotal);

    // Computations for VAT
    const initialLaborVat = Number(initialLaborAmount) * 0.12;
    const repairersVat = Number(totalLaborAmount) * 0.12;
    const ourOfferVat = Number(laborPlusVat) * 0.12;
    const differenceVat = Number(repairersVat) - Number(ourOfferVat);

    // Computations for Gross Amounts
    const initialGrossAmount = Number(initialGrossTotal) + Number(initialLaborVat);
    const repairersGrossAmount = Number(repairersGrossTotal) + Number(repairersVat);
    const ourOfferGrossAmount = Number(ourOfferGrossTotal) + Number(ourOfferVat);
    const differenceGrossAmount = Number(repairersGrossAmount) - Number(ourOfferGrossAmount);


    // const [fmv, setFmv] = React.useState<number>(0); // Default value set to 0
    const [isFmvManuallySet, setIsFmvManuallySet] = React.useState<boolean>(false);

    const handleChangeFmv = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numberValue = Number(value.replace(/,/g, '')); // Remove commas if formatted
        if (!isNaN(numberValue) && numberValue >= 0) {
            setFmv(numberValue);
        }
    };


    // const [salvage, setSalvage] = React.useState<number>(0); // Default value set to 0

    const handleChangeSalvage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/,/g, ''); // Remove commas
        const numericValue = Number(value);
        if (!isNaN(numericValue)) {
            setSalvage(numericValue);
        }
    };
    // #region Computation for % Loss
    const percentLoss =
        totalSumInsured !== 0 ? (ourOfferGrossAmount / totalSumInsured) * 100 : 0;
    const isTotalLoss = percentLoss >= 75;
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | null>(null);

    // Loading and error states for patching salvage value
    const [patching, setPatching] = React.useState<boolean>(false);
    const [patchError, setPatchError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (isTotalLoss) {
            const fetchSalvageValue = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${globalData?.accessToken}`
                            }
                        }
                    );
                    // Assuming the API returns { data: { salvageValue: number } }
                    const fetchedSalvage = response.data.salvageValue;
                    setSalvage(fetchedSalvage);

                } catch (err) {
                    console.error('Error fetching salvage value:', err);
                    setError('Failed to fetch salvage value.');
                } finally {
                    setLoading(false);
                }
            };

            fetchSalvageValue();
        }
    }, [isTotalLoss]);

    React.useEffect(() => {
        if (isTotalLoss) {
            const fetchFairMarketValue = async () => {
                setLoading(true);
                setError(null);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${globalData?.accessToken}`
                            }
                        }
                    );
                    // Assuming the API returns { data: { salvageValue: number } }
                    const fetchedFmv = response.data.fairMarketValue;
                    setFmv(fetchedFmv);

                } catch (err) {
                    console.error('Error fetching salvage value:', err);
                    setError('Failed to fetch salvage value.');
                } finally {
                    setLoading(false);
                }
            };

            fetchFairMarketValue();
        }
    }, [isTotalLoss]);





    //       // if (response.data.status && response.data.success === false) {
    //       //   // Handle specific API response where success is false despite status being true
    //       //   setError("API returned an unexpected response.");
    //       //   setLoading(false);
    //       //   return;
    //       // }

    //       const data = response.data.data;

    //       const parsedEntries: ParsedEntry[] = [];

    //       for (let i = 1; i <= 8; i++) {
    //         const balanceKey = `balanceOutstanding${0}${i}` as keyof typeof data;
    //         const premiumKey = `premiumClass${0}${i}` as keyof typeof data;
    //         const reserveKey = `reserveCode${0}${i}` as keyof typeof data;

    //         const balanceValue = data[balanceKey] as string;
    //         const premiumValue = data[premiumKey] as string;
    //         const reserveCodeValue = data[reserveKey] as string;

    //         if (balanceValue && premiumValue && reserveCodeValue) {
    //           // Remove peso sign and commas, then parse to number
    //           const balanceNumber = parseFloat(
    //             balanceValue.replace(/[₱,]/g, "")
    //           );
    //           const premiumNumber = parseFloat(premiumValue.replace(/,/g, ""));

    //           // Map reserve code to description
    //           const reserveDescription =
    //             reserveCodeMapping[reserveCodeValue] || reserveCodeValue;

    //           parsedEntries.push({
    //             balanceOutstanding: balanceNumber,
    //             premiumClass: premiumNumber,
    //             reserveCode: reserveDescription,
    //           });
    //         }
    //       }

    //       setEntries(parsedEntries);

    //       setLoading(false);
    //     } catch (err: any) {
    //       setError(err.message || "An error occurred while fetching data.");
    //       setLoading(false);
    //     }
    //   };

    //   fetchClaimData();
    // }, []);

    // Handler for onBlur event to patch salvage value
    const handleBlurSalvage = async () => {
        setPatching(true);
        setPatchError(null);
        try {
            await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
                salvageValue: salvage,
            },
                {
                    headers: {
                        'Authorization': `Bearer ${globalData?.accessToken}`
                    }
                });

        } catch (err) {
            console.error('Error updating salvage value:', err);
            setPatchError('Failed to update salvage value.');
        } finally {
            setPatching(false);
        }
    };

    const handleBlurFmv = async () => {
        setPatching(true);
        setPatchError(null);
        try {
            await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
                fairMarketValue: fmv,
            },
                {
                    headers: {
                        'Authorization': `Bearer ${globalData?.accessToken}`
                    }
                });

        } catch (err) {
            console.error('Error updating salvage value:', err);
            setPatchError('Failed to update salvage value.');
        } finally {
            setPatching(false);
        }
    };

    const [totalLossReserve, setTotalLossReserve] = React.useState<string>("100000.00");  // Default value set to 0.0

    const handleChangeTotalLossReserve = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        // Regular expression to allow only numbers and one decimal point
        const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

        // Allow only valid input (numbers and a single decimal point)
        if (validNumberRegex.test(inputValue)) {
            setTotalLossReserve(inputValue);
        }
    };

    const handleBlur = () => {
        // On blur, format the number
        const numberValue = parseFloat(totalLossReserve) || 0.0;
        setTotalLossReserve(formatNumber(numberValue));
    };



    const [manualOverrideParts, setManualOverrideParts] =
        React.useState<boolean>(false);
    const [manualOverrideLabor, setManualOverrideLabor] =
        React.useState<boolean>(false);

    const formatNumber = (value: number | null | undefined) => {
        if (value == null) {
            return '';
        }
        return value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transID}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${globalData?.accessToken}`
                        }
                    }
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: ApiResponse = await response.json();

                if (data.status && data.data) {
                    const { deductible, itemCoverages } = data.data; // Changed here

                    // Set deductibleAmount if available
                    if (deductible !== null && deductible !== undefined) { // Changed here
                        setDeductibleAmount(deductible); // Changed here
                    }
                    // Parse itemCoverages and set totalSumInsured for itemCoverage "202"
                    if (itemCoverages) {
                        const itemCoveragesArray: ItemCoverage[] = JSON.parse(itemCoverages);
                        const coverage202 = itemCoveragesArray.find(
                            (item) => item.itemCoverage === "202"
                        );
                        if (coverage202) {
                            setTotalSumInsured(coverage202.sumInsuredperItemCoverage);
                            if (fmv === 0) {
                                setFmv(coverage202.sumInsuredperItemCoverage);
                            }

                        } else {
                            console.warn('ItemCoverage "202" not found.');
                        }
                    }
                } else {
                    setError(data.message || 'Failed to retrieve data.');
                }
            } catch (err: any) {
                console.error('Error fetching data:', err);
                setError(err.message || 'An unexpected error occurred.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [isFmvManuallySet, setFmv]);

    React.useEffect(() => {
        if (updateGrossTotal) updateGrossTotal(totalAmount, laborPlusVat);
        if (updateTotalDeductible)
            updateTotalDeductible(deductible, deductibleAmount);
    }, [
        totalAmount,
        laborPlusVat,
        updateGrossTotal,
        deductible,
        deductibleAmount,
        updateTotalDeductible,
    ]);



    const handleDepreciationChange = (value: string) => {
        const newDepreciation = parseFloat(value);
        setTotalDepreciation(newDepreciation);
    };

    const handleTotalAmountPartsChange = (value: string) => {
        const newTotalAmount = parseFloat(value);
        setTotalAmount(newTotalAmount);
        // Only update lossReserveParts if it hasn't been manually changed
        if (!manualOverrideParts && newTotalAmount > 40000) {
            setLossReserveParts(newTotalAmount);
        } else if (!manualOverrideParts && newTotalAmount < 40000) {
            setLossReserveParts(40000);
        }
    };

    const handleLossReservePartsChange = (value: string) => {
        const newLossReserveParts = parseFloat(value);
        setLossReserveParts(newLossReserveParts);
        setManualOverrideParts(true); // Mark that the user has manually changed the value
    };

    const handleTotalAmountLaborChange = (value: any) => {
        const newTotalAmount = parseFloat(value);
        setLaborPlusVat(newTotalAmount);
        // Only update lossReserveLabor if it hasn't been manually changed
        if (!manualOverrideLabor && newTotalAmount > 20000.0) {
            setLossReserveLabor(newTotalAmount);
        } else if (!manualOverrideLabor && newTotalAmount < 20000.0) {
            setLossReserveLabor(20000.0);
        }
    };

    const handleLossReserveLaborChange = (value: string) => {
        const newLossReserveLabor = parseFloat(value);
        setLossReserveLabor(newLossReserveLabor);
        setManualOverrideLabor(true); // Mark that the user has manually changed the value
    };



    const handleDeductibleAmountChange = (value: string) => {
        setDeductibleAmount(Number(value));
    };

    const handleTotalSumInsuredChange = (value: string) => {
        const numberValue = Number(value.replace(/,/g, '')); // Remove commas
        if (!isNaN(numberValue) && numberValue >= 0) {
            setTotalSumInsured(numberValue);
            if (!isFmvManuallySet) {
                setFmv(numberValue); // Optionally update fmv if totalSumInsured changes
            }
        }
    };

    const handleTotalLossChange = (event: SelectChangeEvent<string>) => {
        setTotalLoss(event.target.value as string);
    };

    const handleFlood = (event: SelectChangeEvent<string>) => {
        setFlood(event.target.value as string);
    };

    const handleFloodLevel = (event: SelectChangeEvent<string>) => {
        setFloodLevel(event.target.value as string);
    };

    // Computation for Diff%
    const calculateDiffPercentage = (difference: number, base: number) =>
        base !== 0 ? (difference / base) * 100 : 0;
    const diffPercentageParts = calculateDiffPercentage(
        difference,
        totalRepairersAmount!
    );
    const diffPercentageLabor = calculateDiffPercentage(
        differenceLabor,
        totalLaborAmount!
    );
    const diffPercentageGrossTotal = calculateDiffPercentage(
        differenceGrossTotal,
        repairersGrossTotal
    );
    const diffPercentageVat = calculateDiffPercentage(
        differenceVat,
        repairersVat
    );
    const diffPercentageGrossAmount = calculateDiffPercentage(
        differenceGrossAmount,
        repairersGrossAmount
    );





    React.useEffect(() => {
        onIsTotalLossChange(percentLoss);
    }, [percentLoss, onIsTotalLossChange]);


    const totalDeductible = 1 * deductibleAmount;

    const grossOffer = ourOfferGrossAmount - totalDeductible;

    // #region Tracking of totalPartsAndLabors and percentLoss
    React.useEffect(() => {

        const updateStatus = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${globalData?.accessToken}`
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const deDetailsData = await response.json();

                const offerAmount = totalPartsAndLabors;
                const lossPercent = percentLoss;





                if (!isNaN(offerAmount) && !isNaN(lossPercent)) {
                    // if total offer amount > 300,000 or loss percent >= 75

                    if ((offerAmount > 300000 || lossPercent >= 75) && (deDetailsData.headclecApprove === null || deDetailsData.headclecApprove === 0) || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) {
                        const selection =
                            globalData?.role === "Head Clec"
                                ? { value: 9, label: "Approve" }
                                : { value: 4, label: "Forward To Head CLEC" };
                        setMainState({
                            ...mainState,
                            data: { ...mainState.data, status: selection?.value },
                            selections: { status: selection },
                            statusOptions:
                                globalData?.role === "Head Clec"
                                    ? [
                                        { value: 9, label: "Approve" },
                                        // { value: 9, label: "Pending for Offer" },
                                        { value: 5, label: "For Review By Clec" },
                                        { value: 18, label: "Pending Adjuster's Report" },
                                    ]
                                    : [
                                        { value: 4, label: "Forward To Head CLEC" },
                                        { value: 27, label: "Forwarded to Head Motor Claims" },
                                        { value: 28, label: "Forwarded to Head Claims" },
                                        // { value: 9, label: "Pending for Offer" }
                                    ],
                        });

                        // for total loss
                        setTotalLoss(["Actual Loss", "Constructive Loss"].includes(totalLoss) ? totalLoss : "Not Total Loss");
                    } else if ((offerAmount > 300000 || lossPercent >= 75) && deDetailsData.headclecApprove === 1 || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) {
                        const selection =
                            globalData?.role === "Head Clec"
                                ? { value: 9, label: "Approve" }
                                : { value: 4, label: "Forward To Head CLEC" };
                        setMainState({
                            ...mainState,
                            data: { ...mainState.data, status: selection?.value },
                            selections: { status: selection },
                            statusOptions:
                                globalData?.role === "Head Clec"
                                    ? [
                                        { value: 9, label: "Approve" },
                                        { value: 5, label: "For Review By Clec" },
                                        { value: 18, label: "Pending Adjuster's Report" },
                                    ]
                                    : [{ value: 4, label: "Forward To Head CLEC" },
                                    { value: 9, label: "Pending for Offer" }
                                    ],
                        });

                        // for total loss
                        setTotalLoss(["Actual Loss", "Constructive Loss"].includes(totalLoss) ? totalLoss : "Not Total Loss");
                    } else {
                        // for status
                        const selection =
                            globalData?.role === "Head Clec"
                                ? { value: 9, label: "Approve" }
                                : { value: 3, label: "Open" };
                        setMainState({
                            ...mainState,
                            data: { ...mainState.data, status: selection?.value },
                            selections: { status: selection },
                            statusOptions:
                                globalData?.role === "Head Clec"
                                    ? [
                                        { value: 3, label: "For Review by CLEC" },
                                        { value: 9, label: "Pending for Offer" },
                                        { value: 3, label: "Reject" },
                                        { value: 18, label: "Pending Adjuster's Report" },
                                    ]
                                    : isTotalLoss
                                        ? [
                                            { value: 18, label: "Pending Adjuster's Report" },
                                            { value: 4, label: "Forward To Head CLEC" },
                                        ]
                                        : [
                                            { value: 3, label: "Open" },
                                            { value: 9, label: "Pending for Offer" },
                                            { value: 18, label: "Pending Adjuster's Report" },

                                        ],
                        });
                        // for total loss
                        setTotalLoss("Not Total Loss");
                    }
                }
            } catch (error) {
                console.error("Error fetching deDetailsData:", error);
                // Optionally, set an error state here
            }

        };
        updateStatus();
    }, [initialPartsAmount, percentLoss, totalLoss]);

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    // if (error) {
    //   return (
    //     <Container>
    //       <Alert severity="error">{error}</Alert>
    //     </Container>
    //   );
    // }

    return (
        <div>
            <Typography variant="h6" className="tp-text-header-primary">
                Our Offer Estimate
                <Typography
                    variant="caption"
                    display="block"
                    className="tp-text-subtitle"
                >
                    Note: These are the the parts and labors that we will offer to the
                    client.
                </Typography>
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {/* 
        // #region ListOfParts
      */}
            <Typography variant="h6" className="tp-text-header-primary">
                List of Parts
            </Typography>
            <br />
            <ListOfParts
                onTotalAmountChange={handleTotalAmountPartsChange}
                onTotalDepreciationChange={onTotalDepreciationChange}
                onTotalOfferAmountChange={onTotalOfferAmountChange}
                onTotalDiscountChange={onTotalDiscountChange}
                onVatChange={onVatChange}
                updateCart={updateCart}
                perilType={perilType}
                initialBrand={selectedBrand}
                initialModel={selectedModel}
                policyNo={policyNo || ''}
                crDate={crDate || ''}

            />
            <hr className="tp-border-hr_dark" />
            <br />
            {/* 
        // #region ListOfLabors
      */}
            <div className="tp-text-bold">
                Total Offer Amount: ₱ {formatNumber(totalPartsAndLabors)}
            </div>
            <br />
            <br />
        </div>
    );
};

export default CanvasserClientTransaction;
