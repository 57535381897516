import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
//import { GlobalDataContext } from '../contexts/GlobalDataContext';

export default function TaskCardAdmin({ task }: any) {
  //const {globalData, setGlobalData} = React.useContext(GlobalDataContext);
  const [taskStatuses, setTaskStatuses] = React.useState<any>({});
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    //getStatusByTaskType(task.title);
  }, []);

  return (
    <Card
      onClick={() => {
        if (task.isAvailable) { navigate(task.slug);}
      }}
      sx={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        my: 2,
        px: 2,
        background: task.isAvailable ? "default" : "#F5F5F5",
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={4}
          mt={2}
        >
          {/* <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={4}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyItem: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='h4' sx={{
                                color: "#52b202"
                            }}>
                                1
                            </Typography>
                            <Typography sx={{
                                color: '#607274',
                            }}>
                                Submitted
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyItem: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='h4' sx={{
                                color: "#52b202"
                            }}>
                                2
                            </Typography>
                            <Typography sx={{
                                color: '#607274',
                            }}>
                                Open Task
                            </Typography>
                        </Box>
                    </Stack> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: task.isAvailable ? "default" : "#bfbdbd",
                  fontWeight: "bold",
                }}
              >
                {task.title}
              </Typography>
              <Typography
                sx={{
                  color: task.isAvailable ? "#607274" : "#c9c7c7",
                }}
              >
                {task.description}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </CardContent>
      <IconButton disableRipple={true} aria-label="open">
        <ArrowForwardIcon sx={{ height: 38, width: 38 }} />
      </IconButton>
    </Card>
  );
}
