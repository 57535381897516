import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

interface FileUploadProps {
  transId: any; // This is the folder name you want to pass
  onFileUpload: () => void; // Callback function to notify file upload completion
  claimRegistrationData: any;
}

const FileUploadCliamType: React.FC<FileUploadProps> = ({
  transId,
  onFileUpload,
  claimRegistrationData,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadMessage, setUploadMessage] = useState(""); // State for success/error message
  const [uploadUrl, setUploadUrl] = useState<string | null>(null); // Store the URL
  const [open, setOpen] = useState(false); // State to handle modal open/close
  const [isUploading, setIsUploading] = useState(false); // State to show loader
  const [premiumClass, setPremiumClass] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const { globalData } = React.useContext(GlobalDataContext);
  const [documentValidationData, setDocumentValidationData] = React.useState<
    any[]
  >([]);

  // Function to handle modal open
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Function to handle modal close
  const handleClose = () => {
    setOpen(false);
    setSelectedFiles([]); // Clear selected files when modal closes
    setUploadMessage(""); // Clear message on close
    setUploadUrl(null); // Clear the URL on close
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (premiumClass == "") {
      setIsShowError(true);
    } else {
      const files = Array.from(e.target.files as FileList);
      if (files.length > 10) {
        alert("You can only upload a maximum of 10 files.");
      } else {
        setSelectedFiles(files as File[]);
      }
    }
  };


  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select files to upload.");
      return;
    }

    setIsUploading(true); // Show loader during upload

    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append("files", file));

    // Add the folder name (transId) to the form data
    formData.append("folderName", transId);

    try {
      // Upload files and get the URL from the backend
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      const s3Urls = uploadResponse.data; // Assuming the backend returns the URL
      console.log("s3Urls", s3Urls);
      // After getting the S3 URL, send the second POST request
      const documentsData = selectedFiles.map((file, index) => ({
        transId: transId,
        filename: file.name, // Use the original filename
        url: s3Urls,  // URL received from the first request
        channel: 1, // Default value
        status: 32, // Default value
        premiumClass: premiumClass, // For claimtype_id
      }));

      const documentResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/other-document/sf`,
        { documents: documentsData },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      setUploadMessage(
        "Files uploaded successfully and document information saved."
      );
      setUploadUrl(documentResponse.data.url); // Store the URL for clickable link

      // Notify the parent component after upload
      onFileUpload();
    } catch (error) {
      console.error(
        "Error uploading files or saving document information",
        error
      );
      setUploadMessage("Error uploading files or saving document information.");
    } finally {
      setIsUploading(false); // Hide loader after upload
    }
  };

  const getDocumentValidationData = async () => {
    try {
      const docValidationGetResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      if (!docValidationGetResponse.ok) {
        throw new Error(`Error: ${docValidationGetResponse.statusText}`);
      }
      const validationData = await docValidationGetResponse.json();
      setDocumentValidationData(validationData?.data);
    } catch (error) {
      console.error("Error fetching document validation data:", error);
    }
  };

  React.useEffect(() => {
    getDocumentValidationData();
  }, []);

  return (
    <div>
      {/* Button to open the modal */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<FilePresentIcon />}
      >
        Upload Files to AW
      </Button>

      {/* File upload modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid>
            <TextField
              select
              label="Premium Class"
              value={premiumClass}
              onChange={(e) => {
                setPremiumClass(e?.target?.value);
              }}
              fullWidth
            >
              {documentValidationData.map((option: any) => (
                <MenuItem
                  key={option?.id}
                  value={option?.id}
                >
                  {option?.perilType}
                </MenuItem>
              ))}
            </TextField>
            {isShowError && (
              <p style={{ color: "red", fontSize: "small" }}>
                This is required field
              </p>
            )}
          </Grid>
        </DialogTitle>
        <DialogTitle>Upload Files (Max of 10 Files)</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .pdf, .tiff"
            multiple
            onChange={handleFileChange}
          />
          {selectedFiles.length > 0 && (
            <Typography variant="body2" color="textSecondary">
              {selectedFiles.length} file(s) selected
            </Typography>
          )}
          {uploadMessage && (
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ marginTop: "16px" }}
            >
              {uploadMessage}{" "}
              {uploadUrl && (
                <a href={uploadUrl} target="_blank" rel="noopener noreferrer">
                  Click here to view the document
                </a>
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {uploadMessage ? "Close" : "Cancel"}
          </Button>
          {!uploadMessage && (
            <Button
              onClick={handleUpload}
              color="primary"
              variant="contained"
              disabled={selectedFiles.length === 0 || isUploading} // Disable if no files or during upload
            >
              {isUploading ? <CircularProgress size={24} /> : "Upload"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileUploadCliamType;
