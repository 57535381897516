import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
  MenuItem,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import { useGlobalData} from '../../contexts/GlobalDataContext';

function ProcessorValidation() {
  const { globalData, updateGlobalData } = useGlobalData();
  const isManager = globalData.role === "Isip Manager";
  const [showDecision, setShowDecision] = React.useState<any>(isManager ? "Approve" : "Validated");

 

 
  const [selectedProcessor, setSelectedProcessor] =
    React.useState<boolean>(true);
  const [remarks, setRemarks] = React.useState<string>();
  const { transId } = useParams();

  const handleSelectChangeDecision = (event: SelectChangeEvent) => {
    setShowDecision(event.target.value);
    updateGlobalData({ decision: event.target.value });
  };

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRemarks = event.target.value;
    setRemarks(newRemarks);
    updateGlobalData({ remarks: newRemarks });
  };
  React.useEffect(() => {
    if (isManager && showDecision === "Validated") {
      setShowDecision(""); // Set to an empty string or another appropriate value
    }
  }, [isManager, showDecision]);


  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11} md={11}>
          <Typography variant="h5" className="tp-text-header-primary">
            Processor Validation
          </Typography>
          <Typography
            variant="caption"
            display="block"
            className="tp-text-input-sub"
          >
            Note: Please check all Required Fields with (
            <span className="tp-text-required">*</span>) before you submit.
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <IconButton color="primary" aria-label="colapse">
            {selectedProcessor ? (
              <KeyboardArrowUpIcon
                fontSize="large"
                color="primary"
                onClick={() => setSelectedProcessor(!selectedProcessor)}
                className="tp-cursor-pointer"
              />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="large"
                color="primary"
                onClick={() => setSelectedProcessor(!selectedProcessor)}
                className="tp-cursor-pointer"
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
      {selectedProcessor ? (
        <div>
          {/* For Reviewer */}
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            className="tp-mb-20px"
          >
            <Typography variant="body1" className="tp-text-bold">
              Decision <span className="tp-text-required">*</span>
            </Typography>
        
            <Select
              labelId="select-decision"
              id="select-decision"
              value={showDecision}
              defaultValue="Open"
              label="Decision"
              onChange={handleSelectChangeDecision}
            >
              <MenuItem disabled value="">
                <em>Please select your decision...</em>
                </MenuItem>
      {/* Conditionally render "Validated" option based on `isManager` */}
      {!isManager && <MenuItem value="Validated">Validated</MenuItem>}
      {isManager && <MenuItem value="Approve">Approve</MenuItem>}
      <MenuItem value="Rejected">Rejected</MenuItem>
      <MenuItem value="Forwarded">Forwarded</MenuItem>
      {/* You might also want to conditionally add "Approve" for managers */}
     
              
            </Select>
          </FormControl>

         

          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Remarks / Comments
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              className="tp-mb-30px"
              placeholder={`Enter Remarks Here...`}
              value={remarks}
              onChange={handleRemarksChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              // error={!!processorState.formError?.remarks}
            />
         
          </FormControl>
        
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProcessorValidation;
