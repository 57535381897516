import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  Grid,
  IconButton,
  CircularProgress,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { formatDateEmail } from '../api/FormatDateEmail';

interface EmailMissingProps {
  transId: any; // Ensure transId is a number
  sender: string;
}

interface EmailHistoryEntry {
  id: number;
  trans_id: number;
  peril_type: string;
  documents: string;
  sent_date: string | null;
}

const EmailMissing = ({ transId, sender }: EmailMissingProps) => {
  const [open, setOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [emailHistory, setEmailHistory] = useState<EmailHistoryEntry[]>([]);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = event.target.name;
    if (event.target.checked) {
      setSelectedDocuments([...selectedDocuments, document]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((doc) => doc !== document));
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/email/send`, {
        transId,
        sender,
        documents: selectedDocuments,
      });

      // Fetch the updated email history
      await fetchEmailHistory();
    } catch (error) {
      console.error('Error sending email:', error);
    } finally {
      setLoading(false);
      setSelectedDocuments([]);
    }
  };

  const fetchEmailHistory = async () => {
    try {
      const response = await axios.get<EmailHistoryEntry[]>(`${process.env.REACT_APP_BACKEND_URL}/api/email/history/${transId}`);
      setEmailHistory(response.data);
    } catch (error) {
      console.error('Error fetching email history:', error);
    }
  };

  useEffect(() => {
    fetchEmailHistory();
  }, [transId]);

  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleOpen} startIcon={<EmailIcon />}>
        Send Missing Documents Email
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, backgroundColor: 'white', maxWidth: 800, margin: 'auto', marginTop: '5%', position: 'relative' }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Send Missing Documents Email</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={selectedDocuments.includes('Service Invoice')} onChange={handleCheckboxChange} name="Service Invoice" />}
                  label="Service Invoice"
                />
                <FormControlLabel
                  control={<Checkbox checked={selectedDocuments.includes('Sales Invoice')} onChange={handleCheckboxChange} name="Sales Invoice" />}
                  label="Sales Invoice"
                />
              </FormGroup>
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendEmail}
                  disabled={selectedDocuments.length === 0 || loading}
                  startIcon={loading ? <CircularProgress size={24} /> : undefined}
                >
                  {loading ? 'Sending...' : 'Send Email'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" textAlign="center">
                Email History
              </Typography>
              <Box sx={{ maxHeight: 400, overflowY: 'auto', pr: 1 }}>
                <List>
                  {emailHistory.map((email) => (
                    <ListItem key={email.id} alignItems="flex-start">
                      <ListItemText
                        primary={`Email sent: ${email.sent_date ? formatDateEmail(email.sent_date) : 'No date available'}`}
                        secondary={
                          <>
                            <Typography variant="body2" color="textPrimary">
                              Missing Documents:
                              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                                {email.documents.split(', ').map((doc, idx) => (
                                  <ListItem key={idx} sx={{ display: 'list-item', p: 0 }}>
                                    {doc}
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailMissing;
