import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Box,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import BusinessRules from "./checklist/BusinessRules";
import { NotificationContext } from "../../contexts/NotificationContext";
import { capitalizeAndReplaceUnderscore, isValidDate } from "../../utils/stringUtils";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";
import Zoom from "@mui/material/Zoom";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DriverLicenseForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { notification, setNotification } =
    React.useContext(NotificationContext);
  const { validateBusinessRules } = useBusinessRules();

  const fetchData = async () => {

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license?transId=${transID}&id=${docId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const data = res.data.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };

      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data?.status] },
      }));

      createAuditTrailData(
        globalData!.accessToken!,
        {
        transId: transID,
        taskType: "document_validation",
        event: "view_form",
        description: `View driver license form with document id #${docId}`,
        user: globalData.username,
        fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
        roleLevel: globalData.role
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    // validate date format
    const isDateOfBirthValid = isValidDate(mainState?.data?.dateBirth);
    if (!isDateOfBirthValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, dateBirth: "Invalid Date Format" },
      }));

      alert("Date of Birth: Invalid Date Format");
    }

    const isExpirationDateValid = isValidDate(mainState?.data?.expirationDate);
    if (!isExpirationDateValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, expirationDate: "Invalid Date Format" },
      }));

      alert("Expiration Date: Invalid Date Format");
    }

    const isIssuanceDateValid = isValidDate(mainState?.data?.issuanceDate);
    if (!isIssuanceDateValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, issuanceDate: "Invalid Date Format" },
      }));

      alert("Issuance Date: Invalid Date Format");
    }

    const isDlCodesValid = mainState?.data?.dlCodes !== "";
    if (!isDlCodesValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, dlCodes: "Empty required field. Please input data" },
      }));

      alert("DL Codes: Empty required field. Please input data");
    }

    const isConditionsValid = mainState?.data?.conditions !== "";
    if (!isConditionsValid) {
      setMainState((prev: any) => ({
        ...prev,
        formError: { ...prev.formError, conditions: "Empty required field. Please input data" },
      }));

      alert("Condition: Empty required field. Please input data");
    }


    if (isDateOfBirthValid && isExpirationDateValid && isIssuanceDateValid && isDlCodesValid && isConditionsValid) {
      try {
        setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/driver-license?transId=${transID}&id=${docId}`,
          mainState.data,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        const data = res.data.data;
        const message = res.data.message;
        // const statusOptions: any = {
        //   0: null,
        //   1: { value: 1, label: "Open" },
        //   2: { value: 2, label: "Approve" },
        //   3: { value: 3, label: "Reject" },
        // };
        // setMainState((prevState: any) => ({
        //   ...prevState,
        //   data: data,
        //   selections: { status: statusOptions[data.status] },
        //   formError: data?.error,
        //   isLoading: false,
        // }));

        validateBusinessRules(globalData!.accessToken!,
          `/api/acap/document-validation/driver-license/business-rules?transId=${transID}&id=${docId}&taskType=${"document_validation"}`,
          mainState?.data,
          transID,
          "document_validation"
        );

        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: transID,
          taskType: "document_validation",
          event: "update_form",
          description: `Update driver license form with document id #${docId}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        });

        setMainState((prev: any) => ({
          ...prev,
          formError: {},
        }));

        setNotification({
          level: "success",
          showAlert: true,
          message: message,
        });
        // close();
      } catch (error: any) {
        setNotification({
          level: "error",
          showAlert: true,
          message: error.response.data.message,
        });
        console.error("Error updating data:", error);
        setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
      }
    }
  };

  const isAnyFieldEmpty = () => {
    for (const key in mainState?.data) {
      const fieldValue = mainState?.data[key];
      if (
        key !== "remarks" &&
        key !== "id" &&
        key !== "status" &&
        key !== "signatureGdriveId" &&
        key !== "dlIssuedDate" &&
        (typeof fieldValue !== "string" || !fieldValue.trim())
      ) {
        return true;
      } else if (
        key === "status" &&
        (typeof fieldValue !== "number" || fieldValue === 0)
      ) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, [id]: value },
    }));
  };

  const handleSelectChange = (selection: any) => {
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, status: selection.value },
      selections: { status: selection },
    }));
  };

  return (
    <div>
      {isMissing ? (
        <MissingDocument />
      ) : (
        <>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Driver's Name
              <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="driverName"
              size="small"
              type="text"
              placeholder={`Enter Driver's Name Here...`}
              value={mainState?.data?.driverName || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "driverName",
              }}
              error={mainState?.data?.driverName === ""}
            />
            {mainState?.data?.driverName === "" && (
              <FormHelperText error id="driverName">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl size="small" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Nationality <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="nationality"
                  size="small"
                  type="text"
                  placeholder={`Enter Nationality Here...`}
                  value={mainState?.data?.nationality || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "nationality",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.nationality === ""}
                />
                {mainState?.data?.nationality === "" && (
                  <FormHelperText error id="nationality">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl size="small" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Sex <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="sex"
                  size="small"
                  type="text"
                  placeholder={`Enter Received From Here...`}
                  value={mainState?.data?.sex || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "sex",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.sex === ""}
                />
                {mainState?.data?.sex === "" && (
                  <FormHelperText error id="sex">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <Tooltip
                title="Ex. 2012/12/21"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Date of Birth <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="dateBirth"
                    size="small"
                    type="text"
                    placeholder={`Enter Date of Birth Here...`}
                    value={mainState?.data?.dateBirth || ""}
                    onChange={handleInputChange}
                    inputProps={{
                      "aria-label": "dateBirth",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.dateBirth === "" ||
                      mainState?.formError?.dateBirth ||
                      !isValidDate(mainState?.data?.dateBirth)
                    }
                  />
                  {(mainState?.data?.dateBirth === "" ||
                    mainState?.formError?.dateBirth ||
                    !isValidDate(mainState?.data?.dateBirth)) && (
                      <FormHelperText error id="orDate">
                        {!isValidDate(mainState?.data?.orDate) && "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Weight <span className="tp-text-subtitle">(kg)</span>
                </Typography>
                <OutlinedInput
                  id="weight"
                  size="small"
                  type="text"
                  placeholder={`Enter Weight Here...`}
                  value={mainState?.data?.weight || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.weight === ""}
                />
                {mainState?.data?.weight === "" && (
                  <FormHelperText error id="weight">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Height <span className="tp-text-subtitle">(m)</span>
                </Typography>
                <OutlinedInput
                  id="height"
                  size="small"
                  type="text"
                  placeholder={`Enter Height Here...`}
                  value={mainState?.data?.height || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "height",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.height === ""}
                />
                {mainState?.data?.height === "" && (
                  <FormHelperText error id="height">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Address
            </Typography>
            <OutlinedInput
              id="address"
              size="small"
              type="text"
              placeholder={`Enter Address Here...`}
              value={mainState?.data?.address || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "address",
              }}
              disabled={disabled}
              error={mainState?.data?.address === ""}
            />
            {mainState?.data?.address === "" && (
              <FormHelperText error id="address">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  License No.
                </Typography>
                <OutlinedInput
                  id="licenseNo"
                  size="small"
                  type="text"
                  placeholder={`Enter License No. Here...`}
                  value={mainState?.data?.licenseNo || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "licenseNo",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.licenseNo === ""}
                />
                {mainState?.data?.licenseNo === "" && (
                  <FormHelperText error id="licenseNo">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <Tooltip
                title="Ex. 2012/12/21"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Expiration Date <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="expirationDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Expiration Date Here...`}
                    value={mainState?.data?.expirationDate || ""}
                    onChange={handleInputChange}
                    inputProps={{
                      "aria-label": "expirationDate",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.expirationDate === "" ||
                      mainState?.formError?.expirationDate ||
                      !isValidDate(mainState?.data?.expirationDate)
                    }
                  />
                  {(mainState?.data?.expirationDate === "" ||
                    mainState?.formError?.expirationDate ||
                    !isValidDate(mainState?.data?.expirationDate)) && (
                      <FormHelperText error id="expirationDate">
                        {!isValidDate(mainState?.data?.expirationDate) && "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Agency Code
                </Typography>
                <OutlinedInput
                  id="agencyCode"
                  size="small"
                  type="text"
                  placeholder={`Enter Agency Code Here...`}
                  value={mainState?.data?.agencyCode || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "agencyCode",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.agencyCode === ""}
                />
                {mainState?.data?.agencyCode === "" && (
                  <FormHelperText error id="agencyCode">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Blood Type <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="bloodType"
                  size="small"
                  type="text"
                  placeholder={`Enter Blood Type Here...`}
                  value={mainState?.data?.bloodType || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "bloodType",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.bloodType === ""}
                />
                {mainState?.data?.bloodType === "" && (
                  <FormHelperText error id="bloodType">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Eyes Color
                </Typography>
                <OutlinedInput
                  id="eyesColor"
                  size="small"
                  type="text"
                  placeholder={`Enter Eyes Color Here...`}
                  value={mainState?.data?.eyesColor || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "eyesColor",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.eyesColor === ""}
                />
                {mainState?.data?.eyesColor === "" && (
                  <FormHelperText error id="eyesColor">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Codes <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="dlCodes"
                  size="small"
                  type="text"
                  placeholder={`Enter DL Codes Here...`}
                  value={mainState?.data?.dlCodes || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodes",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.dlCodes === ""}
                />
                {mainState?.data?.dlCodes === "" && (
                  <FormHelperText error id="dlCodes">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Conditions <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="conditions"
                  size="small"
                  type="text"
                  placeholder={`Enter Conditions Here...`}
                  value={mainState?.data?.conditions || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "conditions",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.conditions === ""}
                />
                {mainState?.data?.conditions === "" && (
                  <FormHelperText error id="conditions">
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Restrictions
                </Typography>
                <OutlinedInput
                  id="restrictions"
                  size="small"
                  type="text"
                  placeholder={`Enter Restrictions Here...`}
                  value={mainState?.data?.restrictions || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "restrictions",
                  }}
                  disabled={disabled}
                // error={mainState?.data?.restrictions === ""}
                />
                {/* {mainState?.data?.restrictions === "" && (
                  <FormHelperText error id="restrictions">
                    Empty required field. Please input data
                  </FormHelperText>
                )} */}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Type of License
                </Typography>
                <OutlinedInput
                  id="typeOfLicense"
                  size="small"
                  type="text"
                  placeholder={`Enter Type of License Here...`}
                  value={mainState?.data?.typeOfLicense || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "typeOfLicense",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.typeOfLicense === ""}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code A
                </Typography>

                <OutlinedInput
                  id="dlCodeA"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code A Here...`}
                  value={mainState?.data?.dlCodeA || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeA",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code B
                </Typography>

                <OutlinedInput
                  id="dlCodeB"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code B Here...`}
                  value={mainState?.data?.dlCodeB || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeB",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code B1
                </Typography>

                <OutlinedInput
                  id="dlCodeB1"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code B1 Here...`}
                  value={mainState?.data?.dlCodeB1 || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeB1",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code B2
                </Typography>

                <OutlinedInput
                  id="dlCodeB2"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code B2 Here...`}
                  value={mainState?.data?.dlCodeB2 || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeB2",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code C
                </Typography>

                <OutlinedInput
                  id="dlCodeC"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code C Here...`}
                  value={mainState?.data?.dlCodeC || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeC",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code D
                </Typography>

                <OutlinedInput
                  id="dlCodeD"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code D Here...`}
                  value={mainState?.data?.dlCodeD || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeD",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code Be
                </Typography>

                <OutlinedInput
                  id="dlCodeBe"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code Be Here...`}
                  value={mainState?.data?.dlCodeBe || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeBe",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  DL Code Ce
                </Typography>

                <OutlinedInput
                  id="dlCodeCe"
                  size="small"
                  type="text"
                  multiline
                  placeholder={`Enter DL Code Ce Here...`}
                  value={mainState?.data?.dlCodeCe || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "dlCodeCe",
                  }}
                  disabled={disabled}
                />
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <Tooltip
                title="Ex. 2012/12/21"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Issuance Date <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="issuanceDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Issuance Date Here...`}
                    value={mainState?.data?.issuanceDate || ""}
                    onChange={handleInputChange}
                    inputProps={{
                      "aria-label": "issuanceDate",
                    }}
                    disabled={disabled}
                    error={
                      mainState?.data?.issuanceDate === "" ||
                      mainState?.formError?.issuanceDate ||
                      !isValidDate(mainState?.data?.issuanceDate)
                    }
                  />
                  {(mainState?.data?.issuanceDate === "" ||
                    mainState?.formError?.issuanceDate ||
                    !isValidDate(mainState?.data?.issuanceDate)) && (
                      <FormHelperText error id="issuanceDate">
                        {!isValidDate(mainState?.data?.issuanceDate) && "Invalid Date"}
                      </FormHelperText>
                    )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={12}>
              <Typography variant="body1" className="tp-text-bold">
                With Signature ? <span className="tp-text-required">*</span>
              </Typography>
              Yes
            </Grid>
          </Grid>
          <br />
          {/* 
            // #region BusinessRules
          */}
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"document_validation"} documentType={"driver_license"} />
          <br />
          {/* 
            // #region ForProcessor
          */}
          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box sx={{ display: "flex" }}>
              {/* {option !== 0 && <Button onClick={handleBack} variant="outlined" size='medium'>Back</Button>}&emsp; */}
              {disabled ? (
                <Button
                  onClick={handleNext}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Next
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/driver-license"}
                    documentType={"Driver License"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    onClick={updateData}
                    variant="contained"
                    color="error"
                    size="medium"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
