import {
  Container,
  Card,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  MenuItem,
  Button,
  IconButton,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserFormSkeleton from "../loadings/UserFormSkeleton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../api/FormatDate";
import React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { convertKeysToSnakeCase } from "../../utils/SnakeCaseConverter";
import { getCurrentUser } from "../../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";

export default function EditUser() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [isChangePass, setIsChangePass] = React.useState<boolean>(false);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    isLoading: false,
  });
  const [formError, setFormError] = React.useState<any>({});
  const { globalData, setGlobalData, updateGlobalData } = React.useContext(GlobalDataContext);

  const userToUpdate = async () => {
    setMainState({ ...mainState, isLoading: true });
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`,
          headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const resData = res.data.data;
            const snakeCaseResponse = convertKeysToSnakeCase(resData);
           
            setMainState({
              ...mainState,
              data: snakeCaseResponse,
              isLoading: false,
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
            setMainState({ ...mainState, isLoading: false });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateUser = async () => {
    const loggedInUser = await getCurrentUser(globalData);
    if (loggedInUser?.status === 0) {
      updateGlobalData({ role: "", username: null, idToken: null, isUserLogOut: true, lastLogin: null, lastLogout: null });
      secureLocalStorage.removeItem('username');
      secureLocalStorage.removeItem('role');
      secureLocalStorage.removeItem('idToken');
      secureLocalStorage.removeItem('lastLogin');
      secureLocalStorage.removeItem('lastLogout');
      secureLocalStorage.removeItem('status');
      alert("Deactivated user cannot perform such action.");
      const cognitoLoginUrl = process.env.COGNITO_LOGIN_PAGE || 'https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions';
      window.location.href = cognitoLoginUrl;
      return;
    } else {
        setMainState({ ...mainState, isLoading: true });
        try {
          (async () => {
            axios({
              method: "put",
              url: `${process.env.REACT_APP_BACKEND_URL}/api/accounts/${username}`,
              data: {
                email: mainState.data?.email,
                givenName: mainState.data?.given_name === null ? "N/A" :  mainState.data?.given_name,
                familyName: mainState.data?.family_name === null ? "N/A" : mainState.data?.family_name,
                lastLogin: mainState.data?.last_login === null ? "N/A" : mainState.data?.last_login,
                lastLogout: mainState.data?.last_logout === null ? "N/A" : mainState.data?.last_logout,
                roleName: mainState.data?.role_name === null ? "N/A" : mainState.data?.role_name,
                passwordAttempt: mainState.data?.password_attempt === null ? 0 : mainState.data?.password_attempt,
                lastPasswordUpdate: mainState.data?.last_password_update === null ? "N/A" : mainState.data?.last_password_update,
                passwordExpiry: 90,
                maxPasswordAttempts: 5,
                roleLevel: mainState.data?.role_level === null ? "1" : mainState.data?.role_level,
                status: mainState.data?.status === null ? "0" : mainState.data?.status
              },
              headers: {
                  'Authorization': `Bearer ${globalData?.accessToken}`
              }
            })
              .then((res: any) => {
                navigate(-1);
                setMainState({...mainState,
                    isLoading: false
                })
              })
              .catch((err: any) => {
                console.error(err?.response?.data);
                let errorMessage = "";
                if (err?.response?.status === 401)
                  errorMessage = err?.response?.data;
                if (err?.response?.status === 400)
                  errorMessage = err?.response?.data;
                setMainState({ ...mainState, isLoading: false });
              });
          })();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
  };

  const validateForm = () => {
    let isValid = true;
    const errors: any = {};

    // validate password if exists
    // if (isChangePass) {
    //     if (mainState.data?.newPassword.trim() === "") {
    //         errors.newPassword = 'Password should not be empty.';
    //         isValid = false;
    //     }
    //     if (mainState.data?.newPassword !== mainState.data?.confirmPassword) {
    //         errors.confirmPassword = 'Confirm Password and Password should be the same.';
    //         isValid = false;
    //     }
    // }

    // validate userName
    // if (mainState.data?.userName.trim() === '') {
    //     errors.userName = 'Username should not be empty.';
    //     isValid = false;
    // }

    // validate email
    if (mainState.data?.email.trim() === "") {
      errors.email = "Email should not be empty.";
      isValid = false;
    } else {
      // validate email format
      let re = /\S+@\S+\.\S+/;
      let isEmail = re.test(mainState.data?.email);
      if (!isEmail) {
        errors.email = "Invalid email format.";
        isValid = false;
      }
    }
    // validate email format
    // let re = /\S+@\S+\.\S+/;
    // let isEmail = re.test(mainState.data?.email);

    // if (!isEmail) {
    //     errors.email = 'Invalid email format.';
    //     isValid = false;
    // }

    // validate firstName
    // if (mainState.data?.firstName.trim() === '') {
    //     errors.firstName = 'Firstname should not be empty.';
    //     isValid = false;
    // }

    // validate lasttName
    // if (mainState.data?.lastName.trim() === '') {
    //     errors.lastName = 'Lastname should not be empty.';
    //     isValid = false;
    // }

    // validate role
    if (mainState.data?.role_name === "") {
      errors.role_name = "Role should not be empty.";
      isValid = false;
    }

    // validate status
    if (mainState.data?.status === null) {
      errors.status = "Status should not be empty.";
      isValid = false;
    }

    setFormError(errors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      updateUser();
    }
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setMainState({
      ...mainState,
      data: { ...mainState.data, role_name: event.target.value },
    });
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: event.target.value },
    });
  };

  // const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setIsChangePass(!isChangePass);
  //     setMainState({
  //         ...mainState,
  //         data: { ...mainState.data, newPassword: "", confirmPassword: "" }
  //     })
  // };

  React.useEffect(() => {
    userToUpdate();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 12,
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{
          mb: 2,
          padding: 0,
        }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 275,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#2683e0",
                  }}
                >
                  Edit User:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    color: "black",
                  }}
                >
                  {mainState.data?.user_name}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#607274",
                }}
              >
                Modify user account details
              </Typography>
            </Box>
            {/* <FormControlLabel
                            label="Change password?"
                            control={
                                <Switch checked={isChangePass} onChange={handleChangePass} />
                            }
                        /> */}
          </Box>
          <hr />
          <br />
          <br />
          {mainState.isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="tp-mb-20px">
                {/* <Grid item md={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Typography variant="body1" className="tp-text-bold">
                                                    Username <span className="tp-text-required">*</span>
                                                </Typography>
                                                <OutlinedInput
                                                    id="userName"
                                                    size="small"
                                                    type="text"
                                                    placeholder={`Enter Username Here...`}
                                                    // endAdornment={<InputAdornment position="end">
                                                    //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                                                    // </InputAdornment>}
                                                    value={mainState?.data?.userName || ''}
                                                    onChange={handleInputChange}
                                                    // onBlur={() => handleBlur('crNo')}
                                                    inputProps={{
                                                        "aria-label": "userName",
                                                    }}
                                                    // disabled={disabled}
                                                    error={!!formError.userName}
                                                />
                                                {!!formError?.userName && (
                                                    <FormHelperText error id="userName">
                                                        {formError?.userName}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid> */}

                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Email <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="email"
                      size="small"
                      type="text"
                      placeholder={`Enter Email Here...`}
                      value={mainState?.data?.email || ""}
                      onChange={handleInputChange}
                      disabled={true}
                      inputProps={{
                        "aria-label": "email",
                      }}
                      // disabled={disabled}
                      error={!!formError?.email}
                    />
                    {!!formError?.email && (
                      <FormHelperText error id="email">
                        {formError?.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {/* {
                                            isChangePass ?
                                                (
                                                    <>
                                                        <Grid item md={6}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Typography variant="body1" className="tp-text-bold">
                                                                    Password <span className="tp-text-required">*</span>
                                                                </Typography>
                                                                <OutlinedInput
                                                                    id="newPassword"
                                                                    size="small"
                                                                    type="password"
                                                                    placeholder={`Enter Password Here...`}
                                                                    // endAdornment={<InputAdornment position="end">
                                                                    //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                                                                    // </InputAdornment>}
                                                                    value={mainState?.data?.newPassword || ''}
                                                                    onChange={handleInputChange}
                                                                    // onBlur={() => handleBlur('crNo')}
                                                                    inputProps={{
                                                                        "aria-label": "newPassword",
                                                                    }}
                                                                    // disabled={disabled}
                                                                    error={!!formError?.newPassword}
                                                                />
                                                                {!!formError?.newPassword && (
                                                                    <FormHelperText error id="newPassword">
                                                                        {formError?.newPassword}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item md={6}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <Typography variant="body1" className="tp-text-bold">
                                                                    Confirm Password <span className="tp-text-required">*</span>
                                                                </Typography>
                                                                <OutlinedInput
                                                                    id="confirmPassword"
                                                                    size="small"
                                                                    type="password"
                                                                    placeholder={`Enter Password Here...`}
                                                                    // endAdornment={<InputAdornment position="end">
                                                                    //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                                                                    // </InputAdornment>}
                                                                    value={mainState?.data?.confirmPassword || ''}
                                                                    onChange={handleInputChange}
                                                                    // onBlur={() => handleBlur('crNo')}
                                                                    inputProps={{
                                                                        "aria-label": "confirmPassword",
                                                                    }}
                                                                    // disabled={disabled}
                                                                    error={!!formError?.confirmPassword}
                                                                />
                                                                {!!formError?.confirmPassword && (
                                                                    <FormHelperText error id="confirmPassword">
                                                                        {formError?.confirmPassword}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                )
                                                :
                                                (<></>)
                                        } */}

                {/* <Grid item md={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Typography variant="body1" className="tp-text-bold">
                                                    First Name <span className="tp-text-required">*</span>
                                                </Typography>
                                                <OutlinedInput
                                                    id="firstName"
                                                    size="small"
                                                    type="text"
                                                    placeholder={`Enter First Name Here...`}
                                                    // endAdornment={<InputAdornment position="end">
                                                    //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                                                    // </InputAdornment>}
                                                    value={mainState?.data?.firstName || ''}
                                                    onChange={handleInputChange}
                                                    // onBlur={() => handleBlur('crNo')}
                                                    inputProps={{
                                                        "aria-label": "firstName",
                                                    }}
                                                    // disabled={disabled}
                                                    error={!!formError?.firstName}
                                                />
                                                {!!formError?.firstName && (
                                                    <FormHelperText error id="firstName">
                                                        {formError?.firstName}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid> */}

                {/* <Grid item md={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <Typography variant="body1" className="tp-text-bold">
                                                    Last Name <span className="tp-text-required">*</span>
                                                </Typography>
                                                //
                                                <OutlinedInput
                                                    id="lastName"
                                                    size="small"
                                                    type="text"
                                                    placeholder={`Enter Last Name Here...`}
                                                    // endAdornment={<InputAdornment position="end">
                                                    //   <Chip label={mainState.crNoConf + "%"} size="small" className={mainState.crNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                                                    // </InputAdornment>}
                                                    value={mainState?.data?.lastName || ''}
                                                    onChange={handleInputChange}
                                                    // onBlur={() => handleBlur('crNo')}
                                                    inputProps={{
                                                        "aria-label": "lastName",
                                                    }}
                                                    // disabled={disabled}
                                                    error={!!formError?.lastName}
                                                />
                                                {!!formError?.lastName && (
                                                    <FormHelperText error id="lastName">
                                                        {formError?.lastName}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid> */}

                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="tp-mb-20px"
                  >
                    <Typography variant="body1" className="tp-text-bold">
                      Role <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      id="roleName"
                      placeholder="Please select role here..."
                      onChange={handleRoleChange}
                      size="small"
                      value={mainState?.data?.role_name || ""}
                    >
                      <MenuItem value={"Processor"}>Registration Processor</MenuItem>
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Clec"}>Senior Clec</MenuItem>
                      {/* <MenuItem value={"Isip Processor"}>Isip Processor</MenuItem>
                      <MenuItem value={"Isip Manager"}>Isip Manager</MenuItem> */}
                      <MenuItem value={"Jr Clec"}>Jr Clec</MenuItem>
                      <MenuItem value={"Head Clec"}>Head Clec</MenuItem>
                      <MenuItem value={"Imsi Encoder"}>Imsi Encoder</MenuItem>
                      <MenuItem value={"Imsi Admin"}>Imsi Admin</MenuItem>
                       <MenuItem value={"Canvasser"}>Canvasser</MenuItem>
                       <MenuItem value={"Claims Assistant"}>Claims Assistant</MenuItem>
                       <MenuItem value={"Claims Officer Approver"}>Claims Officer Approver</MenuItem>
                        <MenuItem value={"Claims Head Approver"}>Claims Head Approver</MenuItem>
                        <MenuItem value={"Head Motor Claims"}>Head Motor Claims</MenuItem>
                        <MenuItem value={"Head Claims"}>Head Claims</MenuItem>
                    </Select>
                    {!!formError?.role && (
                      <FormHelperText error id="role">
                        {formError?.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="tp-mb-20px"
                  >
                    <Typography variant="body1" className="tp-text-bold">
                      Status <span className="tp-text-required">*</span>
                    </Typography>
                    <Select
                      id="status"
                      placeholder="Please select status here..."
                      size="small"
                      value={mainState?.data?.status || 0}
                      onChange={handleStatusChange}
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Deactivate</MenuItem>
                    </Select>
                    {!!formError?.status && (
                      <FormHelperText error id="status">
                        {formError?.status}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
