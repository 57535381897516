import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatToCurrency } from "../../../utils/stringUtils";

export default function PolicyAsiaPaymentTable({ rows }: any) {
  return (
    <Box
      sx={{
        minWidth: 100,
        maxHeight: 200, // Corrected casing
        overflow: "auto",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 100, maxHeight: 200 }} // Corrected casing
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">OR No</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Effective Date</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right">{row?.orno}</TableCell>
                  <TableCell align="right">{row?.trandate.slice(4, 6)}/{row?.trandate.slice(6, 8)}/{row?.trandate.slice(0, 4)}</TableCell>
                  <TableCell align="right">{row?.effectivedate.slice(4, 6)}/{row?.effectivedate.slice(6, 8)}/{row?.effectivedate.slice(0, 4)}</TableCell>
                  <TableCell align="right">{formatToCurrency(row?.amount || 0)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
