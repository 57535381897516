import Typography from '@mui/material/Typography'
import { Divider, Stack } from '@mui/material';

export default function NotFoundPage() {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <Stack direction={"row"} gap={4}>
                <Typography variant='h4'>404</Typography>
                <Divider orientation="vertical"/>
                <Typography variant='h4'>Not Found</Typography>
            </Stack>
        </div>
    )
}