import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import TaskTable from '../components/TaskTable';
import documentValidationTasks from '../data/documentValidationTasks.json';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import ImsiTaskTable from '../components/ImsiTaskTable';

export default function ImsiApprovalPage() {
    const navigate = useNavigate();

    return (
        <Container maxWidth={'xl'} sx={{
            mt: 12
        }}>
            <Box sx={{
                my: 4,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <IconButton onClick={() => navigate('/')} disableRipple={true} aria-label="open" sx={{
                    padding: 0
                }}>
                    <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                    <Typography sx={{ ml: 1 }}>Back</Typography>
                </IconButton>
                <Typography variant='h4'>IMSI Approval</Typography>
            </Box>
            <ImsiTaskTable task={'IMSI Task'}/>
        </Container>
    )
}