import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import {
  ITaskBusinessRules,
  useBusinessRules,
} from "../../../contexts/BusinessRulesContext";
import {
  capitalizeAndReplaceUnderscore,
  convertUtcToPht,
  formatPht,
} from "../../../utils/stringUtils";
import { GlobalDataContext } from "../../../contexts/GlobalDataContext";
import CommentIcon from "@mui/icons-material/Comment";
import { parse, format } from 'date-fns';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function BusinessRulesTask({ transId, taskType }: any) {
  const { globalData } = React.useContext(GlobalDataContext);
  const {
    businessRules,
    fetchTaskBusinessRules,
    setBusinessRules,
    overrideBusinessRules,
  } = useBusinessRules();

  // Local state to manage comment inputs
  const [commentInputs, setCommentInputs] = useState<{ [key: number]: string }>(
    {}
  );
  // State to manage which accordion is expanded
  const [expanded, setExpanded] = useState<number | false>(false);

  // Manage accordion expansion
  const handleAccordionChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // Toggle the 'validated' state of a rule
  const handleToggle = (index: number, docId: number, documentType: string) => {
    const updatedAt = convertUtcToPht(new Date());
    if (businessRules.taskBusinessRules) {
      // get the business rule by docId and transId
      const currentBusinessRuleTask = businessRules.taskBusinessRules.find(
        (item: ITaskBusinessRules) =>
          item.docId === docId &&
          item.transId === transId &&
          item.documentType === documentType
      ) as ITaskBusinessRules;
      const updatedRules = currentBusinessRuleTask?.rules.map(
        (rule: any, idx: number) =>
          idx === index ? { ...rule, validated: !rule.validated, user: `${globalData.firstName} ${globalData.lastName}`,
        updatedAt: updatedAt, } : rule
      );

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );
    }
  };

  // Handle comment input changes
  const handleCommentChange = (index: number, comment: string) => {
    setCommentInputs((prev) => ({
      ...prev,
      [index]: comment,
    }));
  };

  // Save or update the comment for a specific rule
  const handleSaveComment = (
    index: number,
    docId: number,
    documentType: string
  ) => {
    const comment = commentInputs[index] || "";
    const updatedAt = convertUtcToPht(new Date());

    if (businessRules.taskBusinessRules) {
      // get the business rule by docId and transId
      const currentBusinessRuleTask = businessRules.taskBusinessRules.find(
        (item: ITaskBusinessRules) =>
          item.docId === docId &&
          item.transId === transId &&
          item.documentType === documentType
      ) as ITaskBusinessRules;
      const updatedRules = currentBusinessRuleTask?.rules.map(
        (rule: any, idx: number) =>
          idx === index
            ? {
                ...rule,
                comment: comment,
                user: `${globalData.firstName} ${globalData.lastName}`,
                updatedAt: updatedAt,
              }
            : rule
      );

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );

      setCommentInputs({});
    }
  };

  // handle override
  const handleOverride = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    docId: number,
    documentType: string
  ) => {
    const updatedAt = convertUtcToPht(new Date());
    if (businessRules.taskBusinessRules) {
      // get the business rule by docId and transId
      const currentBusinessRuleTask = businessRules.taskBusinessRules.find(
        (item: ITaskBusinessRules) =>
          item.docId === docId &&
          item.transId === transId &&
          item.documentType === documentType
      ) as ITaskBusinessRules;
      const updatedRules = currentBusinessRuleTask?.rules.map(
        (rule: any, idx: number) =>
          idx === index ? { ...rule, override: event.target.checked, user: `${globalData.firstName} ${globalData.lastName}`,
        updatedAt: updatedAt } : rule
      );

      overrideBusinessRules(
        globalData!.accessToken!,
        docId,
        transId,
        taskType,
        documentType,
        updatedRules
      );
    }
  };

  const countFalseValidations = (arr: []) => {
    return arr.reduce((total: number, item: any) => {
      const falseCount = item.rules.filter(
        (rule: any) => rule.validated === false
      ).length;
      return total + falseCount;
    }, 0);
  };

  React.useEffect(() => {
    fetchTaskBusinessRules( globalData!.accessToken!,transId, taskType);
  }, []);

  return (
    <div>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant="h6" className="tp-text-header-primary">
            Business Rules
          </Typography>
          <Typography variant="body1" color="GrayText">
            Note: All business rules that are violated will be shown here.
          </Typography>
        </Stack>

        {businessRules.taskBusinessRules &&
          businessRules.taskBusinessRules.length > 0 && (
            <Typography variant="h6" className="tp-text-header-error">
              {countFalseValidations(businessRules.taskBusinessRules as [])}{" "}
              violated rules
            </Typography>
          )}
      </Stack>
      <hr />
      {businessRules.isLoading !== true ? (
        <React.Fragment>
          {businessRules.taskBusinessRules &&
          businessRules.taskBusinessRules.length > 0 ? (
            businessRules.taskBusinessRules.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls={"panel-a-content"}
                  id={"panel-a-header"}
                  style={{ backgroundColor: "#130d46" }}
                >
                  <Typography
                    className="tp-text-bold"
                    style={{ color: "white" }}
                  >
                    {`#${item.docId} ${capitalizeAndReplaceUnderscore(
                      item.documentType
                    )}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.rules && item.rules.length > 0 ? (
                    item.rules.map((rule, index) =>
                      !["Claims Assistant", "Claims Officer Approver"].includes(
                        globalData?.role
                      ) ? (
                        <Box
                          key={rule.description}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          my={0.5}
                          p={0.5}
                          bgcolor={rule?.validated ? "#f7e3e3" : "transparent"}
                        >
                           <div>
                                <Typography variant="body1">
                                  {rule.description}
                                  {rule.comment && (
                                    <Typography variant="body2">
                                      Comment:&ensp;
                                      <span style={{ color: "#303ca3" }}>
                                        {rule.comment}
                                      </span>
                                    </Typography>
                                  )}
                                </Typography>
                              </div>
                          {/* {rule?.validated ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )} */}

                          <FormControlLabel
                            control={
                              <Switch
                                checked={rule?.validated}
                                // onChange={() => handleToggle(item.description)}
                                name={rule.description}
                                color="error"
                              />
                            }
                            label={rule?.validated ? "Yes" : "No"}
                          />
                        </Box>
                      ) : (
                        <Accordion
                          key={index} // Using index as key since 'id' is unavailable
                          expanded={expanded === index}
                          onChange={handleAccordionChange(index)}
                          elevation={1}
                          sx={{ mb: 1 }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${index}-content`}
                            id={`panel-${index}-header`}
                          >
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <div>
                                <Typography variant="body1">
                                  {rule.description}
                                  {rule.comment && (
                                    <Typography variant="body2">
                                      Comment:&ensp;
                                      <span style={{ color: "#303ca3" }}>
                                        {rule.comment}
                                      </span>
                                    </Typography>
                                  )}
                                </Typography>
                              </div>
                              <Box display="flex" alignItems="center">
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={rule.validated}
                                      onChange={() =>
                                        handleToggle(
                                          index,
                                          item.docId,
                                          item.documentType
                                        )
                                      }
                                      name={rule.description}
                                      color="error"
                                      disabled={
                                        (rule.override === false
                                          ? true
                                          : false) ||
                                        [
                                          "Clec",
                                          "Jr Clec",
                                          "Head Motor Claims",
                                          "Head Claims",
                                          "Head Clec",
                                        ].includes(globalData?.role)
                                      }
                                    />
                                  }
                                  label={rule.validated ? "Yes" : "No"}
                                />
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={rule.override}
                                        onChange={(event) =>
                                          handleOverride(
                                            index,
                                            event,
                                            item.docId,
                                            item.documentType
                                          )
                                        }
                                        disabled={
                                          !rule ||
                                          (rule.comment?.length ?? 0) === 0 ||
                                          [
                                            "Clec",
                                            "Jr Clec",
                                            "Head Motor Claims",
                                            "Head Claims",
                                            "Head Clec",
                                          ].includes(globalData?.role)
                                        }
                                      />
                                    }
                                    label="Override"
                                  />
                                </FormGroup>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent accordion toggle
                                    setExpanded(
                                      expanded === index ? false : index
                                    );
                                  }}
                                  aria-label={
                                    rule.comment
                                      ? "Update Comment"
                                      : "Add Comment"
                                  }
                                >
                                  <CommentIcon
                                    color={rule.comment ? "primary" : "action"}
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box display="flex" flexDirection="column" gap={2}>
                              <TextField
                                label={
                                  rule.comment
                                    ? "Update Comment"
                                    : "Add Comment"
                                }
                                variant="outlined"
                                fullWidth
                                value={commentInputs[index] || ""}
                                onChange={(e) =>
                                  handleCommentChange(index, e.target.value)
                                }
                                multiline
                                rows={3}
                                disabled={[
                                  "Clec",
                                  "Jr Clec",
                                  "Head Motor Claims",
                                  "Head Claims",
                                  "Head Clec",
                                ].includes(globalData?.role)}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSaveComment(
                                    index,
                                    item.docId,
                                    item.documentType
                                  )
                                }
                                disabled={[
                                  "Clec",
                                  "Jr Clec",
                                  "Head Motor Claims",
                                  "Head Claims",
                                  "Head Clec",
                                ].includes(globalData?.role)}
                              >
                                {rule.comment
                                  ? "Update Comment"
                                  : "Add Comment"}
                              </Button>
                              {rule?.user && (
                                <Typography variant="body2">
                                Last Update By:&ensp;
                                <span style={{ color: "#303ca3" }}>
                                  {rule?.user}
                                </span>
                              </Typography>
                              )}
                              {rule?.updatedAt && (
                                <Typography variant="body2">
                                Updated On:&ensp;
                                <span style={{ color: "#303ca3" }}>
                                  {formatPht(rule?.updatedAt)}
                                </span>
                              </Typography>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )
                    )
                  ) : (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      sx={{ my: 4 }}
                      align="center"
                    >
                      No violated rules
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <>
              <br />

              <Card
                sx={{
                  boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                  borderRadius: 2,
                  padding: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ my: 4 }}
                  align="center"
                >
                  No Data Found, Please Try to Validate First
                </Typography>
              </Card>
            </>
          )}
        </React.Fragment>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
