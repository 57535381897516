export interface IFieldCheckList {
    id: string;
    description: string;
    value: number;
    comment?: string;
}

export interface ITempCheckListComment {
    id: string;
    comment?: string;
}

export interface IManualCheckList {
    id: number;
    transId: number;
    fieldCheckList: IFieldCheckList[];
    override_12: boolean;
}

export const initChecklistItems: IFieldCheckList[] = [
    { id: "fraud_1", description: 'Suspicious Behavior', value: 0, comment: "" },
    { id: "fraud_2", description: 'Unmatched Signature on Documents', value: 0, comment: "" },
    { id: "fraud_3", description: 'Inconsistent Damage', value: 0, comment: "" },
    { id: "fraud_4", description: 'Doc Submitted (blacklisted shop/person)', value: 0, comment: "" },
    { id: "fraud_5", description: 'Claim Follow Up (blacklisted shop/person)', value: 0, comment: "" },
    { id: "fraud_6", description: 'Insured is Hostile When Questioned', value: 0, comment: "" },
    { id: "fraud_7", description: 'Financial Problem', value: 0, comment: "" },
    { id: "fraud_8", description: 'Will Accept Smaller Settlement Than Full', value: 0, comment: "" },
    { id: "fraud_9", description: 'No Proof of Ownership/Lost Papers', value: 0, comment: "" },
    { id: "fraud_10", description: 'Keys Found in Recovered Vehicle', value: 0, comment: "" },
    { id: "fraud_11", description: 'Insured has No Insurance History', value: 0, comment: "" },
    { id: "fraud_12", description: 'Claim Registration is available', value: 0, comment: "" },
];

export const initChecklistComments: ITempCheckListComment[] = [
    { id: "fraud_1", comment: "" },
    { id: "fraud_2", comment: "" },
    { id: "fraud_3", comment: "" },
    { id: "fraud_4", comment: "" },
    { id: "fraud_5", comment: "" },
    { id: "fraud_6", comment: "" },
    { id: "fraud_7", comment: "" },
    { id: "fraud_8", comment: "" },
    { id: "fraud_9", comment: "" },
    { id: "fraud_10", comment: "" },
    { id: "fraud_11", comment: "" },
    { id: "fraud_12", comment: "" },
];

export const initManualCheckList: IManualCheckList = {
    id: 0,
    transId: 0,
    fieldCheckList: initChecklistItems,
    override_12: false
};