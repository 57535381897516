import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import * as React from "react";
import LogoIsip from "../../assets/logo_isip.json";
import SalesInvoiceForm from "./SalesInvoiceForm";
import IsipDocumentSummary from "./IsipDocumentSummary";
import ServiceInvoiceForm from "./ServiceInvoiceForm";
import IsipOtherForm from "./IsipOtherForm";
import LoaDetails from "./LoaDetails";
import LoaInformation from "../loa_details/LoaInformation";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

type OptionType = 0 | 1 | 2;
export default function IsipMainModal({
  data,
  attachments,
  transID,
  scroll,
  // policyNoField,
  // onChangePolicy,
  selectedForm,
  userName,
  disabled,
  setIsDisabled,
  refereshList,
  close,
  docId,
}: any) {
  const [option, setOption] = React.useState<OptionType>(selectedForm);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });


  const handleNext = () => {
    setOption(option === 2 ? 0 : ((option + 1) as OptionType));
  };

  const handleBack = () => {
    setOption((option - 1) as OptionType);
  };

  //#endregion Control Methods
  React.useEffect(() => {

    setIsDisabled(disabled);

  }, []);

  return (
    <>
      <DialogContent dividers={scroll === "paper"}>
        <div style={{ margin: "40px" }}>
          <Grid container spacing={2}>
            <Grid item md={9}>
              {/* Official Receipt */}
              {option === 0 && (
                <div>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Sales Invoice Validation
                  </Typography>
                  <Typography variant="body1">
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you
                    submit.
                  </Typography>
                </div>
              )}

              {/* Certification of Registration */}
              {option === 1 && (
                <div>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Service Invoice Validation
                  </Typography>
                  <Typography variant="body1">
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you
                    submit.
                  </Typography>
                </div>
              )}

              {option === 2 && (
                <div>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Other Documents
                  </Typography>
                  <Typography variant="body1">
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you
                    submit.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item md={3} textAlign={"center"}>
              <img src={LogoIsip.image} width={180} height={60} alt="img" />
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <br />
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h6" className="tp-text-header-primary">
                Transaction No.: &ensp;
                <span className="tp-text-default">ISIP-{data?.transId}</span>
              </Typography>
              <Typography
                variant="h6"
                className="tp-text-header-primary tp-mb-30px"
              >
                Document ID.: &ensp;
                <span className="tp-text-default">{docId}</span>
              </Typography>

              {option === 0 && (
                <>
                  <SalesInvoiceForm
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>
              )}

              {option === 1 && (
                <>
                  <ServiceInvoiceForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>
              )}

              {option === 2 && (
                <>
                  <IsipOtherForm
                    option={option}
                    setOption={setOption}
                    transID={transID}
                    disabled={disabled}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    close={close}
                    userData={userName}
                    docId={docId}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={4} sm={4} md={4}>
              <Typography
                variant="h6"
                className="tp-text-header-primary tp-mb-30px"
              >
                Document Summary
              </Typography>
              <IsipDocumentSummary
                data={data}
                attachments={attachments}
                option={option}
                docId={docId}
              />
              {/* 
                // #region LoaDetails
              */}
              <Typography variant="h6" className="tp-text-header-primary tp-mt-30px tp-mb-30px">
                LOA Details
              </Typography>
              <LoaInformation transId={transID}/>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </>
  );
}
