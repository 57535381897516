import React from "react";
import { Button, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

interface CustomToolbarSelectProps {
  selectedRows: {
    data: { index: number; dataIndex: number }[];
  };
  data: any;
  endpoint: string;
}

const GenericToolbarSelect: React.FC<CustomToolbarSelectProps> = ({
  selectedRows,
  data,
  endpoint
}) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    // Assuming the first selected row should be edited
    // const rowIndex = selectedRows.data[0].dataIndex;
    // const user = data[rowIndex];
    navigate(`${endpoint}/${data}`);
    console.log(`${endpoint}/${data}`);
  };

  return (
    <React.Fragment>
      <Button
            variant="contained"
            className="tp-custom-button-primary "
            size="small"
            startIcon={<EditIcon />}
            onClick={handleEditClick}
              sx={{ my: 1, mx: 2 }}
          >
            Edit
          </Button>
    </React.Fragment>
  );
};

export default GenericToolbarSelect;
