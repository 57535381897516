export interface IShopEstimate {
  id?: number;
  transId?: number;
  referenceNo: string;
  vendorName: string;
  issuedDate: string;
  customerName: string;
  tinNo: string;
  mobileNo: string;
  emailAddress: string;
  contactPerson: string;
  tableItems: string;
  totalAmount: string;
  saveDate: string;
  channel: string;
  filename: string;
  url: string;
  status: number;
  remarks: string;
  discount: string;
  plateNo: string;
  modelYear: string;
  serialNo: string;
  discPartslabors: string;
  scopeOfWorks: string;
  jobInstruction: string;
  partsName: string;
  tinsmith: string;
  bodyRepairlabor: string;
  painting: string;
  totalSublet: string;
  totalOillubricant: string;
  materials: string;
  miscellaneous: string;
  sundries: string;
  totalLabor: string;
  totalParts: string;
  vat: string;
  address: string;
}

export const ShopEstimateDataState = {
  id: 0,
  transId: 0,
  referenceNo: "",
  vendorName: "",
  issuedDate: "",
  customerName: "",
  tinNo: "",
  mobileNo: "",
  emailAddress: "",
  contactPerson: "",
  tableItems: "",
  totalAmount: "",
  saveDate: "",
  channel: "",
  filename: "",
  url: "",
  status: 0,
  remarks: "",
  discount: "",
  plateNo: "",
  modelYear: "",
  serialNo: "",
  discPartslabors: "",
  scopeOfWorks: "",
  jobInstruction: "",
  partsName:"",
  tinsmith: "",
  bodyRepairlabor: "",
  painting: "",
  totalSublet: "",
  totalOillubricant:"",
  materials: "",
  miscellaneous: "",
  sundries: "",
  totalLabor: "",
  totalParts: "",
  vat:"",
  address:""
}