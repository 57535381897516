import { Chip, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { formatDate } from '../../utils/FormatDate';
import axios from 'axios';
// import logs from '../../data/isip_activity_logs.json';
import { useParams } from 'react-router-dom';
import { GlobalDataContext } from '../../contexts/GlobalDataContext';
import secureLocalStorage from "react-secure-storage";
//#region Other Methods

//#endregion Other Methods
interface UserData {
    userName?: string;
    roleName?: string;
}
export default function Logs({ transID }: any) {

    const { globalData, updateGlobalData } = React.useContext(GlobalDataContext);

    const { transId } = useParams();
    const userDataString = secureLocalStorage.getItem('me');

    // Initialize userData with an appropriate type or as undefined.
    let userData: UserData | undefined;

    // Check if userDataString is a string before parsing it.
    if (typeof userDataString === 'string') {
        userData = JSON.parse(userDataString);
    } else {
        // Handle the case where userDataString is not a string,
        // which might involve setting userData to a default value or leaving it as undefined.
        userData = undefined; // Or set some default value
    }

    React.useEffect(() => {

        const needsUpdate = userData && (globalData.username !== userData.userName || globalData.role !== userData.roleName);
        if (needsUpdate) {
            updateGlobalData({
                username: userData?.userName,
                role: userData?.roleName,
            });
        }
    }, [userData, globalData.username, globalData.role]);





    const [logState, setLogState] = React.useState<any>({
        data: [], // Initialize as an array
        isLoading: false, //
    })



    const handleSubmit = React.useCallback(async (e?: React.FormEvent) => {
        if (e) e.preventDefault(); // Prevent default form submission behavior
        const submissionData = {
            decision: globalData.decision,
            transId: transID,
            step: globalData.role,
            comment: globalData.remarks,
            user: globalData.username,
            timeStarted: globalData.startTime?.toISOString(),
            timeEnd: new Date().toISOString(), // Capture the time at submission
        };

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/activity-logs`, submissionData, {
                headers: { 'Authorization': `Bearer ${globalData?.accessToken}` },
            });

            // Handle successful submission (e.g., displaying a success message)
        } catch (error) {
            console.error('Submission Failed:', error);
            // Handle errors (e.g., displaying an error message)
        }
    }, [
        // List all dependencies this function relies on:
        globalData.decision, globalData.transId, globalData.role, globalData.remarks, globalData.username, globalData.startTime
    ]);


    const fetchLogs = async () => {
        try {
            (async () => {
                setLogState({ ...logState, isLoading: true })
                axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_BACKEND_URL}/api/activity-logs/transId/${transId}`,
                    headers: {
                        'Authorization': `Bearer ${globalData?.accessToken}`
                    }
                }).then((res: any) => {
                    //const data = res.data[0];
                    //const attachmentsData = data['attachments']


                    setLogState((prevState: any) => ({ // Explicitly define the type of prevState
                        ...prevState,
                        data: res.data, // Now setting the entire fetched array
                        isLoading: false, // Assuming you want to set loading to false here
                    }));
                }).catch((err: any) => {
                    console.error(err?.response?.data)
                    let errorMessage = ""
                    if (err?.response?.status === 401) errorMessage = err?.response?.data
                    if (err?.response?.status === 400) errorMessage = err?.response?.data
                    setLogState({ error: errorMessage, isLoading: false })
                })
            })();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // Use useEffect to fetch data when the component mounts
    React.useEffect(() => {
        fetchLogs();
    }, []); // Empty dependency array means th

    // Update the global data context
    React.useEffect(() => {

        updateGlobalData({ submitForm: handleSubmit });
    }, [handleSubmit]);


    return (
        <div>

            {logState.data ?
                logState.data?.map((resData: any, index: number) => (
                    <div key={index}>
                        <Grid container spacing={2}>
                            <Grid item md={11}>
                                <Typography variant="body1" className="tp-text-bold">
                                    {resData.user}
                                </Typography>
                                <Typography variant="caption" className="tp-text-primary tp-mb-15px">
                                    {formatDate(resData.timeStarted)} {resData.timeEnd === "" ? "" : "to"} {formatDate(resData.timeEnd)}
                                </Typography>
                                <Typography variant="body1">
                                    {/* {resData.decision}  */}
                                    {resData.decision} {resData.decision === "forward" ? "to" : ""} {resData.assignedTo} {resData.comments === "" ? "" : "with comment of "} {resData.comments}
                                </Typography>
                            </Grid>
                            {resData.user === "test@email.com" ? // Check if it's a specific user
                                <Grid item md={1} textAlign={"center"}>
                                    <Chip label="My Logs" size="small" variant="filled" color="primary" />
                                </Grid> : null} {/* Use null if you don't want to render anything */}
                        </Grid>
                        <hr />
                    </div>
                ))
                :
                <Typography variant="h6" textAlign="center">No Available Logs</Typography>
            }





        </div>

    )
}