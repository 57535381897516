import * as React from 'react';
import TaskCard from '../components/TaskCard'
import { Card, Container, IconButton, Typography } from '@mui/material';
import homeData from '../data/homeDataImsi.json'
import { useCountStats } from '../contexts/CountStatsContext';
import acaplogo from "../assets/acap.png";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type HomeDataProps = {
    exclude: string[];
};

export function CanvasserHomeData({exclude = []} : HomeDataProps) {
    const { taskStatusCount, fetchCountStats } = useCountStats();
    const navigate = useNavigate();
    React.useEffect(() => {
        fetchCountStats(exclude);
    },[])

    return (
        <div>
            {
                taskStatusCount.acapTaskData.map((task: any, index: number) => (
                    <TaskCard key={index} task={task}/>
                ))
                
            }
            <Card sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
            <IconButton
              onClick={() => navigate("task-list")}
              disableRipple={true}
              aria-label="open"
              sx={{
                padding: 0,
              }}
            >
              <ArrowBackIcon sx={{ height: 28, width: 28 }} />
            </IconButton>
            <Typography sx={{ ml: 1 }}>Task List</Typography>
          </Card>
        </div>
    )
} 


export default function HomePageCanvasser({exclude = []} : HomeDataProps) {

    return (
        <Container maxWidth="lg" sx={{
            mt: 12
        }}>
            <img src={acaplogo} width={180} height={60} alt='img' />
            <CanvasserHomeData exclude={exclude}/>
        </Container>
    )
}