import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
// import Select from "react-select";
// import { toTitleCase } from "../../api/utils";
import { formatDate } from "../../api/FormatDate";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import { useParams} from "react-router-dom";
// import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { capitalizeAndReplaceUnderscore } from "../../utils/stringUtils";

export default function AuditTrail() {
  const { auditTrailData, setAuditTrailData, fetchAuditTrailData } =
    React.useContext(AuditTrailDataContext);
  const { transId } = useParams();
  const { globalData } = React.useContext(GlobalDataContext);

  const handleChange = (event: any, value: any) => {

    setAuditTrailData((prev: any) => ({
      ...prev,
      pagination: {
        ...prev?.pagination,
        page: value,
      },
    }));


    fetchAuditTrailData(globalData!.accessToken!, Number(transId), value, 3)
  };

  React.useEffect(() => {
    fetchAuditTrailData(globalData!.accessToken!, Number(transId), 1, 3)
  }, []);
  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="body1" className="tp-text-bold">
            Activity Trail
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* 
            // headers
          */}
          <Grid container spacing={6} sx={{ my: 2 }}>
            <Grid item md={4}>
              <Typography variant="body1" className="tp-text-header-primary">
                Event
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body1" className="tp-text-header-primary">
                User
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body1" className="tp-text-header-primary">
                Details
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          {/* 
            // rows
          */}
          {!auditTrailData.isLoading ? (
            <>
              {auditTrailData.data.map((item: any, idx: number) => (
                <React.Fragment key={idx}>
                  <Grid container spacing={6}>
                    <Grid item md={4}>
                      <Stack direction="column" justifyContent="space-between">
                        <Typography
                          sx={{ fontWeight: "bold" }}
                          color={"primary"}
                          variant="body1"
                          component="div"
                        >
                          {capitalizeAndReplaceUnderscore(item?.event)}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {item?.description}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {item?.fullName}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Stack direction="column">
                        <Stack direction="row">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Date Time:
                          </Typography>
                          &nbsp; &nbsp;
                          <Typography variant="body1">
                            {formatDate(item?.saveDate)}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Role Level:
                          </Typography>
                          &nbsp; &nbsp;
                          <Typography variant="body1">
                            {item?.roleLevel}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Task Type:
                          </Typography>
                          &nbsp; &nbsp;
                          <Typography variant="body1">
                            {capitalizeAndReplaceUnderscore(item?.taskType)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <hr />
                </React.Fragment>
              ))}
              {auditTrailData.data.length > 0 ? (
                <React.Fragment>
                  <br />
                  <Pagination
                    count={auditTrailData.pagination.count}
                    page={auditTrailData.pagination.page}
                    onChange={handleChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
