import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "../document_validation/TransferButton";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
export default function IsipOtherForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [isFieldEmpty, setIsFieldEmpty] = React.useState(true);

  // #region fetchData
  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/other-documents?transId=${transID}&id=${docId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {

            const data = res.data.data;
            const statusOptions: any = {
              // 0: null,
              // 1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // TODO update the endpoint
  const updateData = async () => {

    (async () => {
      setMainState({ ...mainState, isLoading: true });
      axios({
        method: "put",
        url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/other-documents?transId=${transID}&id=${docId}`,
        data: mainState.data,
        // headers: {
        //   'Authorization': `Bearer ${globalData?.sessionToken}`
        // }
      })
        .then((res: any) => {

          // const data = res.data;

          const statusOptions: any = {
            0: null,
            1: { value: 1, label: "Open" },
            2: { value: 2, label: "Approve" },
            3: { value: 3, label: "Reject" },
          };
          setMainState({
            ...mainState,
            data: res.data,
            selections: { status: statusOptions[res.data.status] },
            formError: res.data?.error,
          });
          close();

        })
        .catch((err: any) => {
          console.error(err?.response?.data);
          let errorMessage = "";
          if (err?.response?.status === 401) errorMessage = err?.response?.data;
          if (err?.response?.status === 400) errorMessage = err?.response?.data;
        });
    })();
  };

  // const apiGetDocumentStatus = async () => {
  //   axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transID}/Official Receipt`,
  //     // headers: {
  //     //   'Authorization': `Bearer ${globalData?.sessionToken}`
  //     // }
  //   }).then((res: any) => {

  //     setIsMissing(res.data?.status === "Missing" ? true : false);
  //   }).catch((err: any) => {
  //     console.error(err?.response?.data)
  //     let errorMessage = ""
  //     if (err?.response?.status === 401) errorMessage = err?.response?.data
  //     if (err?.response?.status === 400) errorMessage = err?.response?.data
  //   })
  // }

  const isAnyFieldEmpty = (data: any) => {
    const keys = ["status"];
    for (let key of keys) {
      if (key !== "status") {
        if (data[key] === null || data[key] === undefined || data[key] === "") {
          return true;
        }
      }
    }
    return false;
  };

  React.useEffect(() => {
    //apiGetDocumentStatus();
    fetchData();
  }, []);

  React.useEffect(() => {
    setIsFieldEmpty(isAnyFieldEmpty(mainState?.data));
  }, [mainState?.data]);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px"></Grid>

          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                // { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null ||
              mainState?.data?.status === 0 ||
              (mainState?.data?.status === 1 && (
                <FormHelperText error id="status">
                  {/* {mainState?.formError?.status} */}
                  Required field. Please select an item
                </FormHelperText>
              ))}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
              // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* {option !== 3 && (
                <Button
                  onClick={() => handleBack()}
                  variant="outlined"
                  size="medium"
                >
                  Back
                </Button>
              )}
              &emsp; */}
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  {/* <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/document-validation/other-documents"}
                    close={close}
                  /> */}
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/isip/document-validation/other-documents"}
                    documentType={"Isip Other Documents"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    disabled={isFieldEmpty}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    variant="contained"
                    size="medium"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
