import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { NotificationContext } from "../../contexts/NotificationContext";
import Slide, { SlideProps } from "@mui/material/Slide";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

export default function AlertNotification() {
  const { notification, setNotification } =
    React.useContext(NotificationContext);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification((prev: any) => ({
      ...prev,
      showAlert: false
    }));
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={notification?.showAlert}
      autoHideDuration={2000}
      onClose={handleClose}
      key={"bottom" + "right"}
      TransitionComponent={SlideTransition}
    >
      <Alert
        onClose={handleClose}
        severity={notification.level}
        color="error"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}
