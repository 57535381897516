import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Typography
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { AuditTrailDataContext } from "../contexts/AuditTrailContext";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { capitalizeAndReplaceUnderscore } from "../utils/stringUtils";

interface ClientListProps {
  clientCodeField: string;
  claimRegistrationData: any;
  clientValidated: boolean,
  setClientValidated: (clientValidated: boolean) => void;
}

export default function ClientList({
  clientCodeField,
  claimRegistrationData,
  clientValidated,
  setClientValidated
}: ClientListProps) {
  interface ClientListData {
    id?: number;
    transId?: number;
    clientNumber: string | undefined;
    clientType: string;
    surName: string;
    givenName: string;
    clientSex: string;
    civilStatus: string;
    addressLine01: string;
    addressLine02: string;
    addressLine03: string;
    addressLine04: string;
    addressLine05: string;
    postalCode: string;
    homePhone: string;
    officePhone: string;
    emailAddress: string;
    riskProfileLevel: string;
    mobilePhone: string;
    birthday: string;
    clientStatus: number;
    countryofOrigin: number;
    registrationDate: number;
    taxIdNumber: number;
    marketSector: number;
    registationNumber: number;
    validatedBy?: string;
    saveDate?: string;
    remarks?: string;
    status?: number;
  }

  const [modalOpen, setModalOpen] = React.useState(false);
  const [clientData, setClientData] = React.useState<ClientListData | null>(
    null
  );

  const [loading, setLoading] = React.useState(false);
  const [mainState, setMainState] = React.useState<any>({
    data: { clientNo: clientCodeField || "" },
    clientData: {},
  });
  const { globalData } = React.useContext(GlobalDataContext);
  const { transId } = useParams();
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  // #region createClient
  const createClient = async (clientDataReq: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
        JSON.stringify(clientDataReq),
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      )
      .then((res: any) => {


        createAuditTrailData(
          globalData!.accessToken!,
          {
          transId: Number(transId),
          taskType: "document_validation",
          event: "validate_client_details",
          description: `Validate client details with #${mainState?.data?.clientNo}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        })
        setClientValidated(true);
        setModalOpen(false);
      })
      .catch((err: any) => {

      });
  };

  // #region deletePolicyAsiaRecord
  const deleteClientRecord = async (transactionId: any) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transactionId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        if (res?.data?.data) {
          createAuditTrailData(
            globalData!.accessToken!,
            {
            transId: Number(transId),
            taskType: "document_validation",
            event: "reset_client_details",
            description: `Reset client details with #${mainState?.data?.clientNo}`,
            user: globalData.username,
            fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
            roleLevel: globalData.role
          })
          setClientData(null);
          setMainState({
            data: { clientNo: "" },
            clientData: {},
          });
          setClientValidated(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // #region useEffect
  React.useEffect(() => {
    const getClient = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        )
        .then((res: any) => {

          const clientNoRes = res?.data?.data;
          let clientCode: string | null = "";
          if (clientNoRes) {
            clientCode = clientNoRes?.clientNumber;
            setClientValidated(true);
          } else if (clientCodeField) {
            clientCode = clientCodeField;
          } else {
            clientCode = null;
          }
          setMainState((prevState: any) => ({
            ...prevState,
            data: { ...prevState.data, clientNo: clientCode || "" },
          }));
        })
        .catch((err: any) => {

        });
    };

    getClient();
  }, [clientCodeField]);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState((prevState: any) => ({
      ...prevState,
      data: { ...prevState.data, [id]: value },
    }));
  };

  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const handleModalOpen = async () => {
    const clientNo = mainState.data.clientNo;
    if (!clientNo) {
      alert("Please enter a client number");
      return;
    }
    setLoading(true);



    try {
      const signatureResponse = await fetch(
        `${process.env.REACT_APP_SIGNATURE_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              clientInfo: {
                clientNumber: clientNo,
              },
            },
          }),
        }
      );

      if (!signatureResponse.ok) {
        throw new Error(`Error: ${signatureResponse.statusText}`);
      }

      const signatureData = await signatureResponse.json();


      const signature = signatureData.data;

      const clientEnquiryResponse = await fetch(
        `${process.env.REACT_APP_CLIENT_ENDPOINT}`,
        {
          method: "POST",
          body: JSON.stringify({
            data: {
              clientInfo: {
                clientNumber: clientNo,
              },
            },
            signature: signature,
          }),
        }
      );

      if (!clientEnquiryResponse.ok) {
        throw new Error(`Error: ${clientEnquiryResponse.statusText}`);
      }

      const clientData = await clientEnquiryResponse.json();
      if (clientData.data && !isEmptyObject(clientData.data)) {
        setClientData(clientData.data);
      } else {
        setClientData(null);
      }
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching client data:", error);
      setClientData(null);
      setModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // #region handleConfirm
  const handleConfirm = () => {
    // prepare request data
    const clientDataReq = {
      ...clientData,
      transId: Number(transId),
      status: 1,
    };

    // TODO: create api
    createClient(clientDataReq);
  };

  const handleReset = () => {
    // setMainState({ ...mainState, data: { ...mainState.data, policyNo: "" } });
    deleteClientRecord(transId);
  };

  return (
    <>
      <Grid item sm={12} md={12} lg={12}>
        <FormControl variant="outlined" fullWidth>
          <Typography variant="body1" className="tp-text-bold">
            Client No. <span className="tp-text-required">*</span>
          </Typography>
          <OutlinedInput
            id="clientNo"
            size="small"
            type="text"
            placeholder={`Enter Client No. Here...`}
            value={mainState.data.clientNo}
            onChange={handleInputChange}
            inputProps={{
              "aria-label": "clientNo",
            }}
            disabled={
              !!(claimRegistrationData && claimRegistrationData.claimNumber)
            } // !! will return as boolean type, so if claimNumber is visible return true
            error={mainState?.data?.clientNo === ""}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleReset}
                  edge="end"
                  disabled={
                    !!(claimRegistrationData && claimRegistrationData.claimNumber)
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleModalOpen}
          fullWidth
          style={{ marginTop: "15px" }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : clientValidated ? (
            "Validated / View Details"
          ) : (
            "Check Client Details"
          )}
        </Button>
        <div>
          <Modal
            open={modalOpen}
            onClose={handleModalClose}
            sx={{ overflowY: "scroll" }}
          >
            <Box
              sx={{
                padding: 4,
                backgroundColor: "white",
                margin: "auto",
                width: "70%",
                position: "relative",
              }}
            >
              <IconButton
                onClick={handleModalClose}
                sx={{ position: "absolute", top: 20, right: 25 }}
              >
                <CloseIcon color="error" fontSize="large" />
              </IconButton>
              <Typography variant="h4" className="tp-text-header-primary">
                Client Details
              </Typography>
              <hr className="tp-border-hr_primary tp-mb-20px" />
              <br />
              {clientData ? (
                <>
                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Client Number:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.clientNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Client Type:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.clientType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Registration Number:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.registationNumber}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Surname:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.surName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Given Name:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.givenName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Client Sex:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.clientSex}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Client Status:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.civilStatus}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Postal Code:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.postalCode}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Mobile Phone:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.mobilePhone}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Home Phone:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.homePhone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Office Phone:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.officePhone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Risk Profile Level:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.riskProfileLevel}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Birthday:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {`${clientData.birthday.slice(4, 6)}/${clientData.birthday.slice(6, 8)}/${clientData.birthday.slice(0, 4)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Client Status:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.clientStatus}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Country of Origin:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.countryofOrigin}
                      </Typography>
                    </Grid>
                  </Grid>


                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Registration Date:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.registrationDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Tax ID Number:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.taxIdNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Typography variant="body1">Market Sector:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.marketSector}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">Email Address:</Typography>
                      <Typography
                        variant="h5"
                        className="tp-text-header-primary"
                      >
                        {clientData.emailAddress}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">Address:</Typography>
                      <Typography
                        variant="body1"
                        className="tp-text-header-primary"
                      >
                        {clientData.addressLine01} {clientData.addressLine02} {clientData.addressLine03} {clientData.addressLine04} {clientData.addressLine05}
                      </Typography>
                    </Grid>
                  </Grid>

                  {!clientValidated && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleModalClose}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={handleConfirm}>
                        Confirm
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <Typography variant="body2" color="error">
                  Client Doesn't Exist
                </Typography>
              )}
            </Box>
          </Modal>
        </div>
      </Grid>
    </>
  );
}
