import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, } from '@mui/material';
import Box from '@mui/material/Box';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import boldFontUrl from '../../assets/arial-bold.ttf';
import regularFontUrl from '../../assets/arial.ttf';
import italicFontUrl from '../../assets/ArialCEItalic.ttf';
import italicBoldFontUrl from '../../assets/ArialCEBoldItalic.ttf';
import adjusterPdf from '../../assets/adjuster_slip.pdf';

interface CoverageItem {
    itemCoverage: string;
    sumInsuredperItemCoverage: number;
}

interface CheckboxState {
    one: boolean;
    two: boolean;
    three: boolean;
    four?: boolean;
    five?: boolean;
    six?: boolean;
    seven?: boolean;

}


function getCurrentFormattedDate(): string {
    const currentDate = new Date();
    return currentDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });
}

const AdjusterSlip = ({
    transId, claimRegistrationData, policyData }: any) => {

    const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
    const [isRendering, setIsRendering] = useState(false);
    //   const { transId } = useParams<{ transId: string }>();

    const [policyNo, setPolicyNo] = useState<string>("");
    const [medicalEdit, setMedicalEdit] = useState<string>("");
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasPositive, setCanvasPositive] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [formData, setFormData] = useState("");
    const [adjusters, setAdjusters] = useState("BA INTERNATIONAL ADJUSTERS & SURVEYORS COMPANY, INC.");
    const [approver, setApprover] = useState("");
    const [claimAssistant, setClaimAssistant] = useState("");

    const handleChangeMedical = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMedicalEdit(event.target.value);
    }

    const handleChangeApprover = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApprover(event.target.value);
    };

    const handleChangeCA = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClaimAssistant(event.target.value);
    };

    const handleSelectAdjuster = (event: SelectChangeEvent) => {
        setAdjusters(event.target.value as string);
    };

    // Initialize state with all checkboxes set to false
    const [checkboxes, setCheckboxes] = useState<CheckboxState>({
        one: false,
        two: false,
        three: false,
    });

    // Handler for checkbox changes
    const handleChangeBox = (name: keyof CheckboxState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const isChecked = event.target.checked;

        setCheckboxes((prev) => ({
            ...prev,
            [name]: isChecked, // Toggle only the clicked checkbox
        }));
    };

    const jsonString = policyData.itemCoverages;
    const coverageData: CoverageItem[] = JSON.parse(jsonString);

    const coverage202: number = coverageData.reduce((acc, item) => {
        return item.itemCoverage === "202" ? item.sumInsuredperItemCoverage : acc;
    }, 0);

    const coverage206: number = coverageData.reduce((acc, item) => {
        return item.itemCoverage === "206" ? item.sumInsuredperItemCoverage : acc;
    }, 0);

    const coverage205: number = coverageData.reduce((acc, item) => {
        return item.itemCoverage === "205" ? item.sumInsuredperItemCoverage : acc;
    }, 0);

    const coverage207: number = coverageData.reduce((acc, item) => {
        return item.itemCoverage === "207" ? item.sumInsuredperItemCoverage : acc;
    }, 0);


    const handleChange = (
        event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const { name, value } = event.target as HTMLInputElement;
        setFormData(value);
    };

    const generatePdfBytes = async () => {
        try {
            const existingPdfBytes = await fetch(adjusterPdf).then((res) => res.arrayBuffer());
            const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
            const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());
            const italicBoldFontBytes = await fetch(italicBoldFontUrl).then((res) => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.registerFontkit(fontkit);
            const italicFontBytes = await fetch(italicFontUrl).then((res) => res.arrayBuffer());
            const italicFont = await pdfDoc.embedFont(italicFontBytes);
            const italicBoldFont = await pdfDoc.embedFont(italicBoldFontBytes);
            pdfDoc.registerFontkit(fontkit)

            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const boldFont = await pdfDoc.embedFont(boldFontBytes);
            const regularFont = await pdfDoc.embedFont(regularFontBytes);
            const { width, height } = firstPage.getSize();

            const textDate = getCurrentFormattedDate();


            // const textWidth1 = regularFont.widthOfTextAtSize(text1, 10);
            // const textWidth2 = boldFont.widthOfTextAtSize(text2, 10);

            const drawReverseText = (text: any, endX: any, y: any, font = regularFont, color = rgb(0, 0, 0)) => {
                const textWidth = font.widthOfTextAtSize(text, 7);
                firstPage.drawText(text, {
                    x: endX - textWidth,
                    y,
                    size: 10,
                    font: font,
                    color: color,
                });
            };

            // drawReverseText(`₱${repairersEstimate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, 246, height - 382, boldFont, rgb(0, 0, 0));

            drawReverseText(textDate, 499, height - 174, regularFont, rgb(0, 0, 0));

            firstPage.drawText(adjusters, {
                x: 91,
                y: height - 194,
                size: 10,
                font: boldFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(policyData.longName, {
                x: 277,
                y: height - 226,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(new Date(claimRegistrationData.dateLoss).toLocaleDateString('en-US'), {
                x: 277.5,
                y: height - 238,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(claimRegistrationData.claimNumber, {
                x: 277,
                y: height - 250,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });



            if (checkboxes.one) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 301,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.two) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 315,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.three) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 329,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.four) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 343,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.five) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 357,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.six) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 371,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }

            if (checkboxes.seven) {
                firstPage.drawText('x', {
                    x: 199.5,
                    y: height - 385,
                    size: 10,
                    font: regularFont,
                    color: rgb(0, 0, 0),
                });
            }


            firstPage.drawText(formData, {
                x: 125,
                y: height - 419,
                size: 10,
                font: boldFont,
                color: rgb(0, 0, 0),
                lineHeight: 10, // Adjust the line height as needed
            });


            const lines = formData.split('\n').length;
            const lineHeight = 10;
            const padding = 15;
            const yPosition = height - 419 - (lines * lineHeight) - padding;

            firstPage.drawText("For your guidance, hereunder are the details of Insured's policy coverage with us:", {
                x: 91,
                y: yPosition,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Policy No.         :", {
                x: 140,
                y: yPosition - 20,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(claimRegistrationData.policyNumber, {
                x: 218,
                y: yPosition - 20,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Unit                   :", {
                x: 140,
                y: yPosition - 32,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(`${policyData?.motorMaker  ?? ''} ${policyData?.model  ?? ''} ${policyData?.interestDescription  ?? ''}`, {
                x: 218,
                y: yPosition - 32,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Plate No.           :", {
                x: 140,
                y: yPosition - 44,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(policyData?.vehicleRegistrationNumber ?? '', {
                x: 218,
                y: yPosition - 44,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Serial No.          :", {
                x: 140,
                y: yPosition - 56,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(policyData?.vehicleChassis  ?? '', {
                x: 218,
                y: yPosition - 56,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Engine No.        :", {
                x: 140,
                y: yPosition - 68,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(policyData?.vehicleEngine  ?? '', {
                x: 218,
                y: yPosition - 68,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Coverage          :", {
                x: 140,
                y: yPosition - 88,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("OD/THEFT        PHP", {
                x: 218,
                y: yPosition - 88,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(coverage202.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","), {
                x: 313,
                y: yPosition - 88,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("VTPL-PD           PHP", {
                x: 218,
                y: yPosition - 100,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(coverage206.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","), {
                x: 313,
                y: yPosition - 100,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("VTPL-BI             PHP", {
                x: 218,
                y: yPosition - 112,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(coverage205.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","), {
                x: 313,
                y: yPosition - 112,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("UPPA  PHP", {
                x: 218,
                y: yPosition - 124,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(coverage207.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","), {
                x: 273,
                y: yPosition - 124,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Medical Reimb:", {
                x: 327,
                y: yPosition - 124,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(medicalEdit, {
                x: 397,
                y: yPosition - 124,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Agency              :", {
                x: 140,
                y: yPosition - 144,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(policyData?.agentCode  ?? '', {
                x: 218,
                y: yPosition - 145,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Claims Asst.      :", {
                x: 140,
                y: yPosition - 156,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(claimAssistant, {
                x: 218,
                y: yPosition - 156,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("We shall be expecting your interim preliminary report ", {
                x: 91,
                y: yPosition - 176,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("within 7 days", {
                x: 329,
                y: yPosition - 176,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });
            firstPage.drawLine({
                start: { x: 329, y: yPosition - 180 },
                end: { x: 329 + regularFont.widthOfTextAtSize("within 7 days", 10), y: yPosition - 180 },
                color: rgb(0, 0, 0),
                thickness: 1,
            });
            firstPage.drawText("from receipt of this assignment.", {
                x: 391,
                y: yPosition - 176,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Very Truly Yours,", {
                x: 420,
                y: yPosition - 201,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText(approver, {
                x: 420,
                y: yPosition - 221,
                size: 10,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Received by:", {
                x: 91,
                y: yPosition - 233,
                size: 8,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            firstPage.drawLine({
                start: { x: 91, y: yPosition - 260 },
                end: { x: 91 + regularFont.widthOfTextAtSize("within 7 days and test of todays", 10), y: yPosition - 260 },
                color: rgb(0, 0, 0),
                thickness: 1,
            });

            firstPage.drawText("Pursuant to IC Circular Letter No. 50-2016, Section 5: Fraud Warning", {
                x: 91,
                y: yPosition - 330,
                size: 7,
                font: italicBoldFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText('"Section 251 of the Insurance Code, as amended, imposes a fine not exceeding twice the amount claimed and/or imprisonment of two (2)', {
                x: 91,
                y: yPosition - 350,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("years, or both, at the discretion of the court, to any person who presents or causes to be presented any fraudulent claim for the payment of a", {
                x: 91,
                y: yPosition - 360,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("loss under a contract of insurance, and who fraudulently prepares, makes or subscribes any writing with intent to present or use the same, or to", {
                x: 91,
                y: yPosition - 370,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText('allow it to be presented in support of any claim."', {
                x: 91,
                y: yPosition - 380,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Data Privacy.", {
                x: 91,
                y: yPosition - 400,
                size: 7,
                font: italicBoldFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Pursuant to the foregoing Claim, I consent to the collection, use, processing and transfer of my personal data as described in this", {
                x: 135,
                y: yPosition - 400,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("address and telephone number, date of birth, social security number, tax identification number, etc.) for the purpose of processing my/ the", {
                x: 91,
                y: yPosition - 410,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("Claim. I also understand that the Company may transfer this Data to any third parties assisting the Company in the", {
                x: 91,
                y: yPosition - 420,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("administering and managing my/ the Claim, and that the Company may also transfer this Data to any third parties assisting the Company in the", {
                x: 91,
                y: yPosition - 430,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("processing, administration and management of the Claim. I authorize them to receive, possess, use, retain and transfer the Data, in electronic or", {
                x: 91,
                y: yPosition - 440,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("other form, for these purposes. I also understand that I may, at any time, review the Data, require any necessary changes to the Data or withdraw", {
                x: 91,
                y: yPosition - 450,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("my consent in writing by contacting the Company. I further understand that withdrawing my consent may substantially affect my ability to", {
                x: 91,
                y: yPosition - 460,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });

            firstPage.drawText("further process and collect on my/ the Claim.", {
                x: 91,
                y: yPosition - 470,
                size: 7,
                font: italicFont, // Ensure italicFont is properly embedded
                color: rgb(0, 0, 0),
            });



            const pdfBytes = await pdfDoc.save();
            setPdfBytes(pdfBytes);

            // Directly preview the PDF after generating it
            previewPdf(new Uint8Array(pdfBytes), 1);
        } catch (error) {
            console.error("Error generating PDF bytes:", error);
        }
    };

    const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
        if (!bytes) return;

        if (isRendering) return; // Prevent overlapping render operations
        setIsRendering(true);

        const loadingTask = pdfjsLib.getDocument({ data: bytes });
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(pageNum);

        const viewport = page.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        setCanvasPositive(canvas)
        const context = canvas?.getContext('2d');

        if (canvas && context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;
        }

        setIsRendering(false); // Mark rendering as completed
    };

    useEffect(() => {
        if (pdfBytes) {
            previewPdf(new Uint8Array(pdfBytes), currentPage); // Clone the pdfBytes for preview and specify the page number
        }
    }, [pdfBytes, currentPage]);

    const savePdf = () => {
        if (pdfBytes) {
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
            const fileName = `Adjuster Slip_${formattedDate}.pdf`;
            saveAs(blob, fileName);
        } else {
            console.error("No PDF bytes to save.");
        }
    };



    return (
        <div>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                        <InputLabel>Adjusters</InputLabel>
                        <Select
                            name="status"
                            value={adjusters}
                            onChange={handleSelectAdjuster}
                        >
                            <MenuItem value="BA INTERNATIONAL ADJUSTERS & SURVEYORS COMPANY, INC.">
                                BA INTERNATIONAL ADJUSTERS & SURVEYORS COMPANY, INC.
                            </MenuItem>
                            <MenuItem value="BAYNE ADJUSTER">BAYNE ADJUSTER</MenuItem>
                            <MenuItem value="CARES ADJUSTERS AND SURVEYORS, INC.">
                                CARES ADJUSTERS AND SURVEYORS, INC.
                            </MenuItem>
                            <MenuItem value="CHARTERED ADJUSTERS, INC.">
                                CHARTERED ADJUSTERS, INC.
                            </MenuItem>
                            <MenuItem value="CRAWFORD & COMPANY PHILIPPINES, INC.">
                                CRAWFORD & COMPANY PHILIPPINES, INC.
                            </MenuItem>
                            <MenuItem value="EAGLE PROSPERITY ADJUSTMENT AND SURVEYOR CORP.">
                                EAGLE PROSPERITY ADJUSTMENT AND SURVEYOR CORP.
                            </MenuItem>
                            <MenuItem value="MANILA ADJUSTERS AND SURVEYORS COMPANY">
                                MANILA ADJUSTERS AND SURVEYORS COMPANY
                            </MenuItem>
                            <MenuItem value="MCLARENS INSURANCE ADJUSTERS PHILS., INC.">
                                MCLARENS INSURANCE ADJUSTERS PHILS., INC.
                            </MenuItem>
                            <MenuItem value="OPTIMUM CLAIMS SOLUTIONS INSURANCE ADJUSTMENT, INC.">
                                OPTIMUM CLAIMS SOLUTIONS INSURANCE ADJUSTMENT, INC.
                            </MenuItem>
                            <MenuItem value="PALM PROPERTY ADJUSTERS">
                                PALM PROPERTY ADJUSTERS
                            </MenuItem>
                            <MenuItem value="PHILIPPINE-JAPAN MARINE SURVEYORS & SWORN MEASURES CORPORATION (P-NKKK)">
                                PHILIPPINE-JAPAN MARINE SURVEYORS & SWORN MEASURES CORPORATION (P-NKKK)
                            </MenuItem>
                            <MenuItem value="SUBURBAN EAST MERCHANT ADJUSTMENT CO. INC.">
                                SUBURBAN EAST MERCHANT ADJUSTMENT CO. INC.
                            </MenuItem>
                            <MenuItem value="TECHNICAL INSPECTION GROUP ADJUSTMENT & SURVEYORS CORPORATION">
                                TECHNICAL INSPECTION GROUP ADJUSTMENT & SURVEYORS CORPORATION
                            </MenuItem>
                            <MenuItem value="TOTAL CLAIMS SPECIALIST, INC.">
                                TOTAL CLAIMS SPECIALIST, INC.
                            </MenuItem>
                            <MenuItem value="TRADERS ADJUSTER & CARGO SURVEYORS, INC.">
                                TRADERS ADJUSTER & CARGO SURVEYORS, INC.
                            </MenuItem>
                            <MenuItem value="UNIFIED ADJUSTERS AND SURVEYORS (FAR EAST), INC.">
                                UNIFIED ADJUSTERS AND SURVEYORS (FAR EAST), INC.
                            </MenuItem>
                            <MenuItem value="UNIVERSAL ADJUSTERS-APPRAISERS CO., INC.">
                                UNIVERSAL ADJUSTERS-APPRAISERS CO., INC.
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" align="center">
                        Subject
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.one}
                                    onChange={handleChangeBox('one')}
                                    name="one"
                                />
                            }
                            label="Own Damage"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.two}
                                    onChange={handleChangeBox('two')}
                                    name="two"
                                />
                            }
                            label="Theft"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.three}
                                    onChange={handleChangeBox('three')}
                                    name="three"
                                />
                            }
                            label="Third Party Property Damage"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.four}
                                    onChange={handleChangeBox('four')}
                                    name="four"
                                />
                            }
                            label="Third Party Bodily Injury"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.five}
                                    onChange={handleChangeBox('five')}
                                    name="five"
                                />
                            }
                            label="Third Party Death Claim"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.six}
                                    onChange={handleChangeBox('six')}
                                    name="six"
                                />
                            }
                            label="UPPA"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.seven}
                                    onChange={handleChangeBox('seven')}
                                    name="seven"
                                />
                            }
                            label="Subrogation Recovery"
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>

                <Grid item xs={12} md={4}>
                    <TextField
                        label="Medical Reimbursment"
                        name="medicalReimbursment"
                        value={medicalEdit}
                        onChange={handleChangeMedical}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Claim Assistant"
                        name="claimAssistant"
                        value={claimAssistant}
                        onChange={handleChangeCA}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Approver"
                        name="approver"
                        value={approver}
                        onChange={handleChangeApprover}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Special Instructions"
                        name="specialInstructions"
                        value={formData}
                        onChange={handleChange}
                        fullWidth
                        multiline
                        rows={10}
                    />
                </Grid>
            </Grid>
            <br />
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" gap={2} mb={2}>
                    <Button variant="contained" onClick={generatePdfBytes} sx={{ width: 200 }}>
                        Preview PDF
                    </Button>

                    <Button disabled={!canvasRef} sx={{ width: 200 }} variant="contained" onClick={savePdf}>
                        Download PDF
                    </Button>
                </Box>
                <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px', marginBottom: '20px' }}></canvas>
            </Box>

        </div>
    );
};

export default AdjusterSlip;
