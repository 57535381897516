import axios from "axios";
import { ISfManualChecklist } from "../types/SfManualChecklist";
import { ISfClaimRegistration } from "../types/SfClaimRegistration";
import { ISfRepairShop } from "../types/SfRepairShop";

const authenticate = async () => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/salesforce/auth`
  );
};

export const updateSfManualChecklist = async (
  data: ISfManualChecklist,
  caseId: string
) => {
  try {
    if (caseId) {
      const authResponse = await authenticate();
      const { access_token, instance_url } = await authResponse.data.data;

      await axios.patch(
        `${instance_url}/services/data/v56.0/sobjects/Case/${caseId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
  } catch (error: any) {
    console.error(error);
  }
};

export const updateSfClaimRegistration = async (
  data: ISfClaimRegistration,
  caseId: string
) => {
  try {
    const authResponse = await authenticate();
    const { access_token, instance_url } = await authResponse.data.data;

    await axios.patch(
      `${instance_url}/services/data/v56.0/sobjects/Case/${caseId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      }
    );

  } catch (error: any) {
    console.error(error);
  }
};

export const getSfRepairShops = async () => {
  try {
    const authResponse = await authenticate();
    const { access_token, instance_url } = await authResponse.data.data;

    const response = await axios({
      method: 'get',
      url: `${instance_url}/services/data/v57.0/query?q=SELECT Id, Name FROM Repair_Shop__c`,
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      }
    });

    const updatedRepairShops: ISfRepairShop[] = [
      { Id: null, Name: "Not Listed" },
      ...response.data.records
    ]
    
    return updatedRepairShops;

  } catch (error) {
    console.error(error)
    return [{ Id: null, Name: "Not Listed" }];
  }
}
