import axios from "axios";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { filteredArray } from "../utils/stringUtils";
import { ILoaDetails } from "../types/LoaDetails";

interface ILoaDetailsState {
  loaInformation: ILoaDetails | null;
  isLoading: boolean;
}

// Define a type for the context value
interface ILoaDetailsContextType {
  loaDetails: ILoaDetailsState;
  fetchLoaDetails: (transId: number) => void;
  createLoaDetails: (request: ILoaDetails) => void;
  removeLoaDetails: (transId: number) => void;
}

// Create the context with default values
const LoaDetailsContext = createContext<ILoaDetailsContextType | undefined>(
  undefined
);

// Create a provider component
type LoaDetailsProviderProps = {
  children: ReactNode;
};

export default function LoaDetailsStore({ children }: LoaDetailsProviderProps) {
  const [loaDetails, setLoaDetails] = React.useState<ILoaDetailsState>({
    loaInformation: null,
    isLoading: false,
  });

  // fetchLoaDetails
  const fetchLoaDetails = async (transId: number) => {
    setLoaDetails((prev: any) => ({
      ...prev,
      isLoading: true,
    }));
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/loa-details?transId=${transId}`
      )
      .then((res: any) => {

        const loaInfo = res?.data?.data;

        setLoaDetails((prev: any) => ({
          ...prev,
          loaInformation: loaInfo,
          isLoading: false,
        }));
      })
      .catch((err: any) => {

        setLoaDetails((prev: any) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  // createLoaDetails
  const createLoaDetails = async (request: ILoaDetails) => {
    setLoaDetails((prev: any) => ({
      ...prev,
      isLoading: true,
    }));

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/loa-details`, request
      )
      .then((res: any) => {

        const loaInfo = res?.data?.data;

        setLoaDetails((prev: any) => ({
          ...prev,
          loaInformation: loaInfo,
          isLoading: false,
        }));
      })
      .catch((err: any) => {

        setLoaDetails((prev: any) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  // removeLoaDetails
  const removeLoaDetails = async (transId: number) => {
    setLoaDetails((prev: any) => ({
      ...prev,
      isLoading: true,
    }));
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip-task/loa-details?transId=${transId}`
      )
      .then((res: any) => {


        setLoaDetails({
          loaInformation: null,
          isLoading: false,
        });
      })
      .catch((err: any) => {

        setLoaDetails((prev: any) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };
  

  return (
    <LoaDetailsContext.Provider
      value={{
        loaDetails,
        fetchLoaDetails,
        createLoaDetails,
        removeLoaDetails,
      }}
    >
      {children}
    </LoaDetailsContext.Provider>
  );
}

// custom hook
export const useLoaDetails= (): ILoaDetailsContextType => {
  const context = useContext(LoaDetailsContext);
  if (!context) {
    throw new Error(
      "useLoaDetails must be used within an BusinessRulesProvider"
    );
  }
  return context;
};
