import {
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import GenericToolbarSelect from "../user_management/table_components/GenericToolbarSelect";

export default function DealersManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: "single",
    responsive: "standard",
    // resizableColumns: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <GenericToolbarSelect selectedRows={selectedRows} data={rows[selectedRows.data[0].dataIndex].id} endpoint="edit-dealer"/>
    ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const statusMap: { [key: string]: string } = {
    null: "Not Active",
    "0": "Not Active",
    "1": "Active",
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
        }),
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "pre-line" }, // Allows line breaks (\n)
        }),
      },
    },
    {
      name: "serviceManager",
      label: "Service Manager",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    },
    {
      name: "contactNo",
      label: "Contact No.",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    }
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // padding: "5px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins"
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // padding: "2px !important",
              // marginLeft: "12px !important",
              // marginRight: "12px !important",
              // textAlign: "center",
              fontFamily: "Poppins"
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={
                <Button
                  className="tp-custom-button-primary "
                  size="small"
                  variant="contained"
                  startIcon={<AddBusinessIcon />}
                  onClick={() => navigate("add-dealer")}
                >
                  Add dealer
                </Button>
              }
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
