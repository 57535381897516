import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import MUIDataTable, { Responsive, SelectableRows } from "mui-datatables";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  GlobalDataContext,
} from "../contexts/GlobalDataContext";

export function ViewButton({ url, sender }: any) {
  const navigate = useNavigate();

  return (
    <Button
      className="tp-custom-button-primary"
      variant="contained"
      size="small"
      onClick={() => navigate(`/task/${url}`, { state: { sender } })}
    >
      View
    </Button>
  );
}

export default function TaskListTable({ task }: any) {
  const { globalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });

  const getAllTaskByTaskType = async (taskType: String) => {
    setMainState({ ...mainState, isLoading: true });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/open-task/user?userId=${globalData.id}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );



      if (Array.isArray(res.data)) {
        const transformedData = res.data.map((item: any, index: number) => {
          const numericStatus = item.status;
          const username = item.assignToUser
            ? item.assignToUser.username
            : "In Progress";

          return {
            id: "ACT-" + item.id,
            transId: item.acapTaskId !== 0 ? item.acapTaskId : "",
            deId: item.deId !== 0 ? item.deId : "",
            subject: item.subject,
            priority: item.priority,
            status: item.status,
            assign: username,
            name: item.name,
            action: <ViewButton url={`${item.id}`} sender={item.sender} />,
          };
        });

        setRows(transformedData);

        const transformedCols = [
          {
            name: "id",
            label: "Task ID",
            options: { filter: true, sort: true },
          },
          {
            name: "transId",
            label: "Trans-ID",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: any) => (
                <Button
                  color="primary"
                  onClick={() =>
                    window.open(
                      `${window.location.protocol}//${window.location.host}/document-validation/${value}`,
                      "_blank"
                    )
                  }
                >{`ACAP-${value}`}</Button>
              ),
            },
          },
          {
            name: "deId",
            label: "Damage Evaluation ID",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value: any, tableMeta: any) => {
                // Assuming transId is in the 2nd column (index 1) of your data array
                const transId = tableMeta.rowData[1]; // Adjust the index as needed

                return (
                  <Button
                    color="primary"
                    onClick={() =>
                      window.open(
                        `${window.location.protocol}//${window.location.host}/damage-evaluation/${transId}/${value}`,
                        "_blank"
                      )
                    }
                  >
                    {`${value}`}
                  </Button>
                );
              },
            },
          },
          {
            name: "subject",
            label: "Subject",
            options: { filter: true, sort: true },
          },
          {
            name: "priority",
            label: "Priority",
            options: { filter: true, sort: true },
          },
          {
            name: "status",
            label: "Status",
            options: {
              filter: true,
              sort: false,
              filterList: ["Open"], // Sets the default filter to "Open"
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "name",
            label: "Assign To",
            options: { filter: true, sort: true },
          },
          {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
        ];

        setColumns(transformedCols);
      } else {
        console.error("Unexpected response format:", res.data);
      }

      setMainState({ ...mainState, isLoading: false });
    } catch (err: any) {
      console.error("Error:", err);

      let errorMessage = "";
      if (err?.response?.status === 401) errorMessage = err?.response?.data;
      if (err?.response?.status === 400) errorMessage = err?.response?.data;

      setMainState({ error: errorMessage, isLoading: false });
    }
  };

  const options = {
    download: false,
    print: false,
    selectableRows: "none" as SelectableRows,
    responsive: "standard" as Responsive,
    resizableColumns: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              padding: "5px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              textAlign: "center",
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
      },
    });

  React.useEffect(() => {
    getAllTaskByTaskType(task);
  }, []);

  return (
    <Box
      sx={{
        my: 4,
      }}
    >
      {!mainState.isLoading ? (
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Task List"}
            data={rows}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
