import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
import DamageEvaluationTask from './DamageEvaluationTask';
import { RefreshProvider } from './RefreshContext';

const DamageEvaluationTaskWithProvider: React.FC = () => {
//   const { transId } = useParams<{ transId: string }>();

  // const [totalRepairersAmount, setTotalRepairersAmount] = useState<number>(3900.00);
  // const [totalLaborAmount, setTotalLaborAmount] = useState<number>(5000.00);
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);

  const handleBrandModelUpdate = (brand: string | null, model: string | null) => {
    setSelectedBrand(brand);
    setSelectedModel(model);
  };

//   if (!transId) {
//     return <div>Error: transId is missing</div>;
//   }

  return (
    <RefreshProvider>
      <DamageEvaluationTask
        // totalRepairersAmount={totalRepairersAmount}
        // setTotalRepairersAmount={setTotalRepairersAmount}
        // setTotalLaborAmount={setTotalLaborAmount}
        // totalLaborAmount={totalLaborAmount}

        selectedBrand={selectedBrand}
        selectedModel={selectedModel}
        onBrandModelUpdate={handleBrandModelUpdate} // Pass the callback
      />
    </RefreshProvider>
  );
};

export default DamageEvaluationTaskWithProvider;
