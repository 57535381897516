import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { GlobalDataContext } from '../contexts/GlobalDataContext';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { globalData } = React.useContext(GlobalDataContext);
  const location = useLocation(); // Get the current location to pass as 'from'

  return globalData?.token ? (
    element
  ) : (
    // Pass the current location to the state so the user is redirected back after login
    <Navigate to="/" state={{ from: location }} />
  );
};

export default PrivateRoute;
