import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Stack,
  styled,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import bpims from "../assets/bpims_logo.png";
import { BpiMsForm } from "../types/BpiMsForm";
import React, { useCallback } from "react";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FileRejection, useDropzone } from "react-dropzone";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  getContentType,
  isPhoneValid,
  isValidDate,
  isValidEmail,
} from "../utils/stringUtils";
import AWS from "aws-sdk";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { PhoneInput } from "react-international-phone";
import { MuiPhone } from "../components/web_form/MuiPhone";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;
const REGION = process.env.REACT_APP_REGION!;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY!;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY!;

const themeLight = createTheme({
  palette: {
    background: {
      default: "#F7FBFC",
    },
    primary: {
      main: "#1C1364", // Replace this with your desired primary color
      contrastText: "#fff", // This is the text color that appears on primary buttons
    },
    warning: {
      main: "#ddbc19", // Replace this with your desired primary color
      contrastText: "#fff", // This is the text color that appears on primary buttons
    },
    error: {
      main: "#a42a25", // Replace this with your desired primary color
      contrastText: "#fff", // This is the text color that appears on primary buttons
    },
  },
  typography: {
    fontFamily: '"Poppins", "system-ui", "sans-serif"', // Use System-ui with sans-serif as fallback
  },
});

const listStyle = {
  listStyleType: "disc",
  marginLeft: 5,
};

const listItemStyle = {
  display: "list-item",
  p: 0,
  mb: 0.5,
};

const listItemText = [
  "Copy Police Report (if available) or Notarized Affidavit or BPI MS Accident Report Form",
  "Photocopy of Vehicle's Registration Certificate and current Official Receipt",
  "Photocopy of Driver's License and Official Receipt of Authorized Driver",
  "Preferred shop estimate, if available",
  "Photos of accident scene, if available",
];

const listPoliceReportItemText = [
  "Total Loss",
  "Theft",
  "Substantial Third Party Property Damage and Bodily Injuiry claims",
  "Where recovery/subrogation is possible against a negligent adverse vehicle"
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Dropzone: React.FC<{ onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void }> = ({
  onDrop
}) => {

  const maxSize = 157286400;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/tiff": [],
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "video/mp4": [],
      "application/pdf": [],
    },
    onDrop,
    minSize: 0,
    maxSize,
    maxFiles: 8
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>Choose files or drag here</p>
    </div>
  );
};

type DocumentFields = {
  driverLicense: string;
  policeReport: string;
  certificateRegistration: string;
  officialReceipt: string;
  affidavit: string;
  motorvehicleForm: string;
  shopEstimate: string;
  otherDocument: string;
  otherDocument2: string;
};

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

interface AlertDialogProps {
  title: string;
  message: string;
  showDialog: boolean;
  handleClose: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ title, message, showDialog, handleClose }) => {
  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function WebFormPage() {
  const [formState, setFormState] = React.useState<BpiMsForm>({
    nameOfInsured: "",
    policyNumber: "",
    emailAddress: "",
    mobileNumber: "",
    comment: "",
    uploadFiles: [] as File[],
    isDataPrivacy: false,
    isDeclaration: false,
    username: "",
  });
  const [formError, setFormError] = React.useState({
    nameOfInsured: "",
    emailAddress: "",
    mobileNumber: "",
    uploadFile: "",
    isDataPrivacy: "",
    isDeclaration: "",
    comment: ""
  });
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { globalData } = React.useContext(GlobalDataContext);
  const [dialogState, setDialogState] = React.useState({
    open: false,
    title: "",
    message: "",
  });

  const handleClose = () => {
    setDialogState({ ...dialogState, open: false });
  };

  const isAuthenticated = () => {
    return globalData?.token && globalData.role;
  };

  const uploadFiles = async (files: File[]) => {
    setUploading(true);
    const folderName = `ACAP-Manual-Uploads-`;
    const urls: string[] = [];

    for (const file of files) {
      const contentType = getContentType(file);
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `${folderName}${file.name}`,
        ContentType: contentType,
      };

      await new Promise<void>((resolve, reject) => {
        myBucket.putObject(params).send((err) => {
          if (err) {
            reject(err);
          } else {
            const fileUrl = `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${folderName}${file.name}`;
            urls.push(fileUrl);
            resolve();
          }
        });
      }).catch(() => {
        setDialogState({
          open: true,
          title: "Error on file upload",
          message: "Error uploading file",
        });
        setUploading(false);
        return;
      });
    }

    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 8);
    const formattedDateTime = currentDateTime
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const data: DocumentFields & {
      transId: string | null;
      status: number;

      insurer: string;
      emailAddress: string;
      policyNumber: string;
      mobile: string;
      comment: string;
      username: string | null;
    } = {
      transId: null,
      status: 0,
      insurer: formState.nameOfInsured,
      emailAddress: formState.emailAddress,
      policyNumber: formState.policyNumber,
      mobile: formState.mobileNumber,
      comment: formState.comment,
      driverLicense: urls[0] || "",
      policeReport: urls[1] || "",
      certificateRegistration: urls[2] || "",
      officialReceipt: urls[3] || "",
      affidavit: urls[4] || "",
      motorvehicleForm: urls[5] || "",
      shopEstimate: urls[6] || "",
      otherDocument: urls[7] || "",
      otherDocument2: urls[8] || "",
      username: isAuthenticated() ? globalData?.username : null,
    };


    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/form-builder/internal`,
        data,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      setUploading(false);
      setDialogState({
        open: true,
        title: "Success",
        message: 'Your document(s) are "For Processing".',
      });
      setFormError({
        nameOfInsured: "",
        emailAddress: "",
        mobileNumber: "",
        uploadFile: "",
        isDataPrivacy: "",
        isDeclaration: "",
        comment: ""
      });
      setFormState({
        nameOfInsured: "",
        policyNumber: "",
        emailAddress: "",
        mobileNumber: "",
        comment: "",
        uploadFiles: [],
        isDataPrivacy: false,
        isDeclaration: false,
      });
    } catch (error) {
      setDialogState({
        open: true,
        title: "Error on file upload",
        message: "Error posting file URL(s).",
      });
    }

    setUploading(false);
  };

  // Handler for input change
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handler for file input change
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], formField: keyof BpiMsForm) => {

      const errorCount = 0;

      // if one of the files is too large (max of 150mb)
      const maxSize = 150 * 1024 * 1024;
      const fileTooLarge = fileRejections.find(item => item.file.size > maxSize);


      if (fileTooLarge) {
        setDialogState({
          open: true,
          title: "Error on file upload",
          message: `One or more files are too large. The maximum file size is 150MB each. Filename: ${fileRejections.map(item => item.file.name).join(', ')}`,
        });
      }

      if (formState.uploadFiles.length > 8) {
        setDialogState({
          open: true,
          title: "Error on file upload",
          message: "You can upload a maximum of 8 files.",
        });
      }

      if (errorCount === 0) {
        setFormState((prev) => ({
          ...prev,
          [formField]: [...(prev[formField] as File[]), ...acceptedFiles],
        }));
      }
    },
    [formState]
  );

  const handleDelete = (file: File) => {

    const newFiles = [...formState.uploadFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFormState(() => ({ ...formState, uploadFiles: newFiles }));
  };

  // Handler for checkbox change

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  // Form submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let error = {
      nameOfInsured: "",
      emailAddress: "",
      mobileNumber: "",
      uploadFile: "",
      // driverLicense: "",
      // officialReceipt: "",
      // certificateOfRegistration: "",
      isDataPrivacy: "",
      isDeclaration: "",
      comment: ""
    };
    let errorCount = 0;

    const data = new FormData();
    if (!formState.nameOfInsured) {
      setDialogState({
        open: true,
        title: "Error on insured",
        message: "Name of Insured is an Empty required field. Please input data",
      });
      error.nameOfInsured = "Empty required field. Please input data";
      errorCount += 1;
    }
    if (!formState.emailAddress && !isValidEmail(formState.emailAddress)) {
      setDialogState({
        open: true,
        title: "Error on email",
        message: "Invalid email address. Please input correct data",
      });
      error.emailAddress = "Invalid email address. Please input correct data";
      errorCount += 1;
    }
    if (!isPhoneValid(formState.mobileNumber)) {
      setDialogState({
        open: true,
        title: "Error on mobile number",
        message: "Mobile Number is invalid",
      });
      error.mobileNumber = "Invalid phone number. Please input correct data";
      errorCount += 1;
    }
    if (formState.uploadFiles.length === 0) {
      setDialogState({
        open: true,
        title: "Error on data file upload",
        message: "No file data. Please input file",
      });
      errorCount += 1;
    }
    if (formState.uploadFiles.length > 8) {
      setDialogState({
        open: true,
        title: "Error on data file upload",
        message: "You can upload a maximum of 8 files.",
      });
      errorCount += 1;
    }

    if (!isAuthenticated()) {
      if (!formState.isDataPrivacy) {
        setDialogState({
          open: true,
          title: "Error on data privacy",
          message: "Please agree to the terms",
        });
        error.isDataPrivacy = "Please agree to the terms";
        errorCount += 1;
      }
      if (!formState.isDeclaration) {
        setDialogState({
          open: true,
          title: "Error on declaration",
          message: "Please agree to the terms",
        });
        error.isDeclaration = "Please agree to the terms";
        errorCount += 1;
      }
    }

    if (formState.comment.length === 0) {
      setDialogState({
        open: true,
        title: "Error on comment",
        message: "Empty required field. Please input data",
      });
      error.comment = "Empty required field. Please input data";
      errorCount += 1;
    }

    if (formState.comment.length > 255) {
      setDialogState({
        open: true,
        title: "Error on comment",
        message: "Comment should not be more than 255 chracters.",
      });
      error.comment = "Comment should not be more than 255 chracters.";
      errorCount += 1;
    }

    setFormError(error);

    if (errorCount === 0) {
      uploadFiles(formState.uploadFiles);
    }
  };

  return (
    <ThemeProvider theme={themeLight}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          my: 12,
        }}
      >
        <CssBaseline />
        <Card sx={{ minWidth: 275, my: 2, p: 4, boxShadow: 0 }}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Horizontally center the content
                  alignItems: "center", // Vertically center the content (if needed)
                  width: "100%",
                  height: "100%", // Ensure the Box takes full height if needed
                }}
              >
                <img
                  src={
                    "https://s3-us-west-2.amazonaws.com/userdata123/www/imagefields/112812/112812790.png?_=1725447997876"
                  }
                  alt="img"
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">
                  Name of Insured <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="nameOfInsured"
                  size="medium"
                  type="text"
                  placeholder={`Enter Name of Insured Here...`}
                  value={formState.nameOfInsured || ""}
                  onChange={handleChange}
                  inputProps={{
                    "aria-label": "nameOfInsured",
                  }}
                  error={formError.nameOfInsured !== ""}
                />
                {formError.nameOfInsured !== "" && (
                  <FormHelperText error id="nameOfInsured">
                    {formError.nameOfInsured}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">Policy Number</Typography>
                <OutlinedInput
                  id="policyNumber"
                  size="medium"
                  type="text"
                  placeholder={`Enter Policy Number Here...`}
                  value={formState.policyNumber || ""}
                  onChange={handleChange}
                  inputProps={{
                    "aria-label": "policyNumber",
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">
                  Email Address <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="emailAddress"
                  size="medium"
                  type="text"
                  placeholder={`Enter Email Address Here...`}
                  value={formState.emailAddress || ""}
                  onChange={handleChange}
                  inputProps={{
                    "aria-label": "emailAddress",
                  }}
                  error={
                    (formState.emailAddress.length > 0 &&
                      !isValidEmail(formState.emailAddress)) ||
                    formError.emailAddress !== ""
                  }
                />

                {formState.emailAddress.length > 0 &&
                  !isValidEmail(formState.emailAddress) && (
                    <FormHelperText error id="emailAddress">
                      Email is not valid
                    </FormHelperText>
                  )}
                {formError.emailAddress !== "" && (
                  <FormHelperText error id="emailAddress">
                    {formError.emailAddress}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">
                  Mobile Number <span className="tp-text-required">*</span>
                </Typography>
                {/* <OutlinedInput
                  id="mobileNumber"
                  size="medium"
                  type="text"
                  placeholder={`Enter Mobile Number Here...`}
                  value={formState.mobileNumber || ""}
                  onChange={handleChange}
                  inputProps={{
                    "aria-label": "mobileNumber",
                  }}
                  error={formError.mobileNumber !== ""}
                /> */}
                <MuiPhone
                  value={formState.mobileNumber}
                  onChange={(phone) =>
                    setFormState({ ...formState, mobileNumber: phone })
                  }
                  formError={formError.mobileNumber}
                />
                {formError.mobileNumber !== "" && (
                  <FormHelperText error id="mobileNumber">
                    {formError.mobileNumber}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">Comment <span className="tp-text-required">*</span></Typography>
                <OutlinedInput
                  id="comment"
                  size="medium"
                  type="text"
                  placeholder={`Enter Comment Here...`}
                  value={formState.comment || ""}
                  onChange={handleChange}
                  error={(formState.comment.length > 0 &&
                    formState.comment.length > 255) ||
                    formError.comment !== ""}
                  inputProps={{
                    "aria-label": "comment",
                  }}
                  multiline
                />
                <Typography variant="body2" color={formState.comment.length > 255 ? "error" : "textSecondary"}>
                  {formState.comment.length}/255
                </Typography>
                {formError.comment !== "" && (
                  <FormHelperText error id="comment">
                    {formError.comment}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <br />
          <br />
          <Divider />
          <br />

          <Typography variant="h6">
            What are the documents I need to submit?
          </Typography>
          <List sx={listStyle}>
            {listItemText.map((item, idx) => (
              <ListItem sx={listItemStyle} key={idx}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6">
            A Police Report is necessary for:
          </Typography>
          <List sx={listStyle}>
            {listPoliceReportItemText.map((item, idx) => (
              <ListItem sx={listItemStyle} key={idx}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>

          <br />

          <Grid container spacing={4}>
            <Grid item md={12} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"column"} spacing={1}>
                    <Typography variant="h6">
                      Upload File <span className="tp-text-required">*</span>
                    </Typography>
                    <Typography color={"text.secondary"} variant="subtitle2">
                      Supports file documents (like .tiff, .jpeg, .png, .jpg, .pdf, .mp4).
                    </Typography>
                    <Typography color={"text.secondary"} variant="subtitle2">
                      You can upload up to 8 files.
                    </Typography>
                    <Typography color={"text.secondary"} variant="subtitle2">
                      The maximum file size is 150mb per file.
                    </Typography>
                  </Stack>

                  {formState.uploadFiles.length > 0 && (
                    <Button
                      className="tp-custom-button-primary"
                      variant="contained"
                      size="small"
                      onClick={() =>
                        setFormState({ ...formState, uploadFiles: [] })
                      }
                    >
                      Clear All
                    </Button>
                  )}
                </Stack>
                <Dropzone
                  onDrop={(acceptedFiles, fileRejections) =>
                    onDrop(acceptedFiles, fileRejections, "uploadFiles")
                  }
                />

                {formState.uploadFiles.length > 0 && (
                  <Typography>
                    Selected files:{" "}
                    {formState.uploadFiles.map((file, idx) => (
                      <Chip
                        key={idx}
                        label={file.name}
                        variant="outlined"
                        onDelete={() => handleDelete(file)}
                      />
                    ))}
                  </Typography>
                )}
                {formError.uploadFile !== "" && (
                  <FormHelperText error id="uploadFile">
                    {formError.uploadFile}
                  </FormHelperText>
                )}
                {formState.uploadFiles.length > 8 && (
                  <FormHelperText error id="uploadFile">
                    Only 8 files are allowed
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* <Grid item md={6} xs={12}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="h6">
                  Driver License <span className="tp-text-required">*</span>
                </Typography>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    onDrop(acceptedFiles, "driverLicense")
                  }
                />
                {formState.driverLicense.name && (
                  <Typography color={"primary"}>
                    Selected file: {formState.driverLicense.name}
                  </Typography>
                )}
                {formError.driverLicense !== "" && (
                  <FormHelperText error id="driverLicense">
                    {formError.driverLicense}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">Police Report</Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "policeReport")
                }
              />
              {formState.policeReport.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.policeReport.name}
                </Typography>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">Affidavit</Typography>
              <Dropzone
                onDrop={(acceptedFiles) => onDrop(acceptedFiles, "affidavit")}
              />
              {formState.affidavit.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.affidavit.name}
                </Typography>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">
                Official Receipt <span className="tp-text-required">*</span>
              </Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "officialReceipt")
                }
              />
              {formState.officialReceipt.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.officialReceipt.name}
                </Typography>
              )}
              {formError.officialReceipt !== "" && (
                <FormHelperText error id="officialReceipt">
                  {formError.officialReceipt}
                </FormHelperText>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">
                Certificate Of Registration{" "}
                <span className="tp-text-required">*</span>
              </Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "certificateOfRegistration")
                }
              />
              {formState.certificateOfRegistration.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.certificateOfRegistration.name}
                </Typography>
              )}
              {formError.certificateOfRegistration !== "" && (
                <FormHelperText error id="certificateOfRegistration">
                  {formError.certificateOfRegistration}
                </FormHelperText>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">Preferred Shop Estimate</Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "shopEstimate")
                }
              />
              {formState.shopEstimate.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.shopEstimate.name}
                </Typography>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">Motor Vehicle Form</Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "motorVehicleForm")
                }
              />
              {formState.motorVehicleForm.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.motorVehicleForm.name}
                </Typography>
              )}
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography variant="h6">Photos of Incident/Accident</Typography>
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDrop(acceptedFiles, "accidentPhoto")
                }
              />
              {formState.accidentPhoto.name && (
                <Typography color={"primary"}>
                  Selected file: {formState.accidentPhoto.name}
                </Typography>
              )}
            </Grid> */}
          </Grid>

          <br />
          <Divider />
          <br />

          {!isAuthenticated() && (
            <React.Fragment>
              <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                Pursuant to IC Circular Letter No. 50-2016, Section 5: Fraud
                Warning
              </Typography>
              <Typography variant="subtitle1">
                “Section 251 of the Insurance Code, as amended, imposes a fine
                not exceeding twice the amount claimed and/orimprisonment of two
                (2) years, or both, at the discretion of the court, to any
                person who presents or causes to bepresented any fraudulent
                claim for the payment of a loss under a contract of insurance,
                and who fraudulentlyprepares, makes or subscribes any writing
                with intent to present or use the same, or to allow it to be
                presented insupport of any claim.”
              </Typography>

              <br />

              <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                Data Privacy <span className="tp-text-required">*</span>
              </Typography>
              {formError.isDataPrivacy !== "" && !isAuthenticated() ? (
                <FormHelperText error id="isDataPrivacy">
                  {formError.isDataPrivacy}
                </FormHelperText>
              ) : null}
              <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
                <Checkbox
                  id="isDataPrivacy"
                  checked={formState.isDataPrivacy}
                  onChange={handleCheckboxChange}
                />
                <Typography>
                  Pursuant to the foregoing Claim, I consent to the collection,
                  use, processing and transfer of mypersonal data as described
                  in this paragraph. I understand that the Company and/or its
                  related companies holdcertain personal information about me
                  (including my name, address and telephone number, date of
                  birth, socialsecurity number, tax identification number, etc.)
                  for the purpose of processing my/ the Claim. I also
                  understandthat the Company may transfer this Data amongst its
                  related companies as necessary for the purpose ofprocessing,
                  administering and managing my/ the Claim, and that the Company
                  may also transfer this Data to anythird parties assisting the
                  Company in the processing, administration and management of
                  the Claim. I authorizethem to receive, possess, use, retain
                  and transfer the Data, in electronic or other form, for these
                  purposes. I alsounderstand that I may, at any time, review the
                  Data, require any necessary changes to the Data or withdraw
                  myconsent in writing by contacting the Company. I further
                  understand that withdrawing my consent maysubstantially affect
                  my ability to further process and collect on my/ the Claim.
                </Typography>
              </Stack>

              <br />

              <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                Declaration <span className="tp-text-required">*</span>
              </Typography>
              {formError.isDeclaration !== "" && !isAuthenticated() ? (
                <FormHelperText error id="isDeclaration">
                  {formError.isDeclaration}
                </FormHelperText>
              ) : null}
              <Stack direction={"row"} spacing={1} alignItems={"flex-start"}>
                <Checkbox
                  id="isDeclaration"
                  checked={formState.isDeclaration}
                  onChange={handleCheckboxChange}
                />
                <Typography>
                  I/We hereby declared that the above statements and facts are
                  true and correct, to the best of my knowledge and belief and
                  that I/We have not withheld from the company any information
                  within my/our knowledge connected with the above-mentioned
                  accident.
                </Typography>
              </Stack>

              <br />
            </React.Fragment>
          )}

          <Button
            fullWidth
            size="large"
            className="tp-custom-button-primary"
            onClick={handleSubmit}
            disabled={uploading}
          >
            {uploading ? (
              <>
                <CircularProgress color="inherit" size="1.5em" />
                &emsp;
              </>
            ) : (
              <></>
            )}
            {uploading ? "Submitting..." : "Submit"}
          </Button>
        </Card>
      </Container>

      <AlertDialog
        title={dialogState.title}
        message={dialogState.message}
        showDialog={dialogState.open}
        handleClose={handleClose}
      />
    </ThemeProvider>
  );
}
