import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import TaskListTable from "../components/TaskListTable";

export default function TaskList() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth={"xl"}
      sx={{
        mt: 12,
      }}
    >
      <Box
        sx={{
          my: 4,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => navigate("/")}
          disableRipple={true}
          aria-label="open"
          sx={{
            padding: 0,
          }}
        >
          <ArrowBackIcon sx={{ height: 28, width: 28 }} />
          <Typography sx={{ ml: 1 }}>Back</Typography>
        </IconButton>
        <Typography variant="h4">Task List</Typography>
      </Box>
      <TaskListTable task={"Task List"} />
    </Container>
  );
}
