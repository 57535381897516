import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { deleteLaborById, getLaborsByDeId, postLabor } from './ApiServiceLabor';

interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
}

interface ListOfLaborProps {
    onLaborPlusVatChange: (value: number) => void;
    onLaborDataChange: (data: {
        rows: LaborRow[],
        laborRate: number,
        totalTimeHours: number,
        calculatedLaborCost: number,
        paintWorkMaterials: number,
        totalLaborCost: number
    }) => void;
    transId: string;
}

interface ScopeOfWorkOption {
    id: number;
    name: string;
}

const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default function ListOfLabors({ transId, onLaborPlusVatChange, onLaborDataChange }: ListOfLaborProps) {
    const scopeOfWorkOptions: ScopeOfWorkOption[] = [
        { id: 1, name: "Front Bumper Cover" },
        { id: 2, name: "Lift Gate Panel" },
        { id: 3, name: "Rear Bumper Cover" },
        { id: 4, name: "RH Step Board Cover" },
        { id: 5, name: "Paint RRH Door" }
    ];

    const repairConditions = ["X - Replace", "R - Repair", "P - Polish", "A - Align"];
    const [rows, setRows] = useState<LaborRow[]>([]);
    const [laborRate, setLaborRate] = useState<number>(500);
    const [scopeOfWorks, setScopeOfWorks] = useState<string>('');
    const [repairCondition, setRepairCondition] = useState<string>('');
    const [repair, setRepair] = useState<number>(0);
    const [replace, setReplace] = useState<number>(0);
    const [painting, setPainting] = useState<number>(0);

    const laborRateOptions = [
        { value: 500, label: '500 - Dealer' },
        { value: 370, label: '370 - Non-dealer' },
        { value: 700, label: '700 - Luxury' }
    ];

    const laborData = useMemo(() => {
        const totalTimeHours = rows.reduce((acc, row) => acc + row.repair + row.replace + row.painting, 0);
        const calculatedLaborCost = totalTimeHours * laborRate;
        const paintingLabor = rows.reduce((acc, row) => acc + row.painting, 0) * laborRate;
        const paintWorkMaterials = paintingLabor * 0.85 * 1.35 / 1.12;
        const totalLaborCost = calculatedLaborCost + paintWorkMaterials;
        const repairLabor = rows.reduce((acc, row) => acc + row.repair + row.replace, 0) * laborRate;
        const laborPlusVAT = (repairLabor + paintingLabor + paintWorkMaterials);

        return {
            rows,
            laborRate,
            totalTimeHours,
            calculatedLaborCost,
            paintWorkMaterials,
            totalLaborCost,
            laborPlusVAT,
            repairLabor,
            paintingLabor,
            shopMaterials: paintWorkMaterials
        };
    }, [rows, laborRate]);




    useEffect(() => {
        onLaborDataChange(laborData);
        onLaborPlusVatChange(laborData.laborPlusVAT);
    }, [rows, laborData, onLaborDataChange, onLaborPlusVatChange]);



    // useEffect(() => {
    //     const fetchLabors = async () => {
    //         try {
    //             const response = await getLaborsByDeId(Number(transId));
    //             const labors = Array.isArray(response.data) ? response.data : [];
    //             setRows(labors.map((labor: any) => ({
    //                 ...labor,
    //                 repair: labor.repair ? parseFloat(labor.repair) : 0,
    //                 replace: labor.replace ? parseFloat(labor.replace) : 0,
    //                 painting: labor.painting ? parseFloat(labor.painting) : 0
    //             })));
    //         } catch (error) {
    //             console.error("Error fetching labors:", error);
    //         }
    //     };

    //     if (transId) {
    //         fetchLabors();
    //     }
    // }, [transId]);


    const handleAddLabor = useCallback(() => {
        const newLabor: LaborRow = {
            scopeOfWorks,
            repairCondition,
            repair,
            replace,
            painting,
            transId: transId,
            saveDate: new Date().toISOString().slice(0, -1)
        };

        // postLabor(newLabor)
        //     .then(response => {
        //         const savedLabor = response?.data?.data;
        //         setRows(prevRows => [...prevRows, { ...newLabor, id: savedLabor.id }]);
        //         // Reset input fields
        //         setScopeOfWorks('');
        //         setRepairCondition('');
        //         setRepair(0);
        //         setReplace(0);
        //         setPainting(0);
        //     })
        //     .catch(error => {
        //         console.error("Error adding labor:", error);
        //     });
    }, [scopeOfWorks, repairCondition, repair, replace, painting, transId]);

    const handleDeleteRow = useCallback((index: number) => {
        const rowToDelete = rows[index];

        // if (rowToDelete.id) {
        //     deleteLaborById(rowToDelete.id)
        //         .then(() => {
        //             setRows(prevRows => prevRows.filter((_, i) => i !== index));
        //         })
        //         .catch(error => {
        //             console.error("Error deleting labor:", error);
        //         });
        // } else {
        //     console.error("Cannot delete item without an id");
        // }
    }, [rows]);

    const renderTableRows = useCallback(() => {
        return rows.map((row, index) => {
            const total = row.repair + row.replace + row.painting;
            return (
                <TableRow key={row.id ?? index}>
                    <TableCell>{row.scopeOfWorks}</TableCell>
                    <TableCell>{row.repairCondition}</TableCell>
                    <TableCell align="right">{row.repair.toFixed(2)}</TableCell>
                    <TableCell align="right">{row.replace.toFixed(2)}</TableCell>
                    <TableCell align="right">{row.painting.toFixed(2)}</TableCell>
                    <TableCell align="right">{total.toFixed(2)}</TableCell>
                    <TableCell align="right">
                        <Button variant="outlined" color="secondary" onClick={() => handleDeleteRow(index)}>Delete</Button>
                    </TableCell>
                </TableRow>
            );
        });
    }, [rows, handleDeleteRow]);



    const repairLabor = rows.reduce((acc, row) => acc + row.repair + row.replace, 0) * laborRate;
    const paintingLabor = rows.reduce((acc, row) => acc + row.painting, 0) * laborRate;
    const shopMaterials = paintingLabor * 0.85 * 1.35 / 1.12;
    const laborPlusVAT = (repairLabor + paintingLabor + shopMaterials);



    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <Autocomplete
                        options={scopeOfWorkOptions}
                        getOptionLabel={(option) => option.name}
                        value={scopeOfWorks ? { name: scopeOfWorks, id: 0 } : null}
                        onChange={(event, newValue) => setScopeOfWorks(newValue ? newValue.name : '')}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Scope of Work" variant="outlined" fullWidth />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Repair Conditions</InputLabel>
                        <Select
                            value={repairCondition}
                            label="Repair Condition"
                            onChange={e => setRepairCondition(e.target.value)}
                        >
                            {repairConditions.map(condition => (
                                <MenuItem key={condition} value={condition}>{condition}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="Repair"
                        type="number"
                        value={repair}
                        onChange={e => setRepair(parseFloat(e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="Replace"
                        type="number"
                        value={replace}
                        onChange={e => setReplace(parseFloat(e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        label="Painting"
                        type="number"
                        value={painting}
                        onChange={e => setPainting(parseFloat(e.target.value))}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} alignItems="center">
                    <Button variant="contained" className="tp-custom-button-primary" onClick={handleAddLabor}>Add Labor</Button>
                </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Scope of Works</TableCell>
                            <TableCell>Repair Condition</TableCell>
                            <TableCell align="right">Repair</TableCell>
                            <TableCell align="right">Replace</TableCell>
                            <TableCell align="right">Painting</TableCell>
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <FormControl fullWidth>
                <InputLabel>Labor Rate</InputLabel>
                <Select
                    value={laborRate}
                    onChange={e => setLaborRate(parseFloat(e.target.value as string))}
                    label="Labor Rate"
                >
                    {laborRateOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{ textAlign: "right" }}>
                <div>Repair Labor: {formatNumber(laborData.repairLabor)}</div>
                <div>Painting Labor: {formatNumber(laborData.paintingLabor)}</div>
                <div>Shop Materials: {formatNumber(laborData.shopMaterials)}</div>
            </div>
            <br />
            <div className="tp-text-bold">Total Labor: ₱ {formatNumber(laborData.laborPlusVAT)}</div>
        </div>
    );
}
