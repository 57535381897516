import * as React from 'react';
import TaskCard from '../components/TaskCard'
import { Container } from '@mui/material';
import homeData from '../data/homeDataIsip.json'
import isipLogo from "../assets/isip.png"
import { useCountStats } from '../contexts/CountStatsContext';

type HomeDataProps = {
    exclude: string[];
};

export function HomeData({exclude = []} : HomeDataProps) {
    const { taskStatusCount, fetchCountStats } = useCountStats();
    
    React.useEffect(() => {
        fetchCountStats(exclude);
    },[])

    return (
        <div>
            {
                taskStatusCount.isipTaskData.map((task: any, index: number) => (
                    <TaskCard key={index} task={task}/>
                ))
            }
        </div>
    )
} 


export default function HomePage({exclude = []} : HomeDataProps) {

    return (
        <Container maxWidth="lg" sx={{
            mt: 12
        }}>
            <img src={isipLogo} width={180} height={60} alt='img' />
            <HomeData exclude={exclude}/>
        </Container>
    )
}