import {
  Button,
  Box,
  CircularProgress,
  Chip,
} from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, {
  MUIDataTableOptions,
} from "mui-datatables";
import { useNavigate } from "react-router-dom";
import GenericToolbarSelect from "../user_management/table_components/GenericToolbarSelect";

export default function ShopListManagement({ globalData, mainState, rows }: any) {
  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: "single",
    responsive: "standard",
    // resizableColumns: true,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <GenericToolbarSelect selectedRows={selectedRows} data={rows[selectedRows.data[0].dataIndex].id} endpoint="edit-shop"/>
    ),
    // tableBodyHeight: "400px", // Set a fixed height for the table to control the vertical scroll
    // tableBodyMaxHeight: "",
  };
  const navigate = useNavigate();

  const valueMap: { [key: string]: string } = {
    null: "No",
    0: "No",
    1: "Yes",
  };

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap" },
        }),
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    },
    {
      name: "nonVat",
      label: "Non VAT",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Chip
              label={valueMap[value]}
              color={value === 1 ? "success" : "default"}
            />
          )
        },
      },
    },
    {
      name: "twoOnePercent",
      label: "2% 1%",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Chip
              label={valueMap[value]}
              color={value === 1 ? "success" : "default"}
            />
          )
        },
      },
    },
    {
      name: "sfId",
      label: "Salesforce ID",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            fontWeight: "bold",
          },
        }),
        setCellProps: () => ({
          style: { whiteSpace: "nowrap"},
        }),
      },
    }
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              // padding: "5px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
              fontFamily: "Poppins"
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // padding: "2px !important",
              // marginLeft: "12px !important",
              // marginRight: "12px !important",
              // textAlign: "center",
              fontFamily: "Poppins"
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#a42a25 !important",
            },
          },
        },
      },
    });

  return (
    <>
      {mainState.isLoading ? (
        // (<Skeleton variant="rectangular" animation="wave" width={"100%"} height={500} />)
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={
                <Button
                  className="tp-custom-button-primary "
                  size="small"
                  variant="contained"
                  startIcon={<AddBusinessIcon />}
                  onClick={() => navigate("add-shop")}
                >
                  Add Shop
                </Button>
              }
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      )}
    </>
  );
}
