import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
  ButtonGroup,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  Zoom,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Stack,
  MenuItem
} from "@mui/material";
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "../document_validation/TransferButton";
import { channel } from "diagnostics_channel";
import AlertNotification from "../notifications/AlertNotification";
import { toTitleCase } from "../../api/utils";
import {
  capitalizeAndReplaceUnderscore,
  isValidDate,
  isValidNumber,
  removeTrailingCommas,
} from "../../utils/stringUtils";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { useBusinessRules } from "../../contexts/BusinessRulesContext";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { ISalesInvoice } from "../../types/SalesInvoice";
import { IServiceInvoice } from "../../types/ServiceInvoice";
import BusinessRules from "../document_validation/checklist/BusinessRules";

interface IServiceInvoiceState {
  data: IServiceInvoice;
  oriData: {};
  selections: {
    status: {label: any, value: any} | null;
  };
  changed: {};
  formError: {
    invoiceDate: string
  };
  isError: false;
  attachments: [];
  isLoading: boolean;
  isDisabled: boolean;
  isNotDone: boolean;
  tableItems: { header: any; rows: any; } | null;
  vatSelections: { vatNonVat: string };
}


export default function ServiceInvoiceForm({
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<IServiceInvoiceState>({
    data: {
      id: 0,
      transId: 0,
      status: 0,
      statusName: "",
      saveDate: "",
      url: "",
      claimTypeC: "",
      repairShopCL: "",
      vendorName: "",
      address: "",
      recepientName:"",
      customerName: "",
      invoiceNo: "",
      invoiceDate: "",
      claimNo: "",
      tin: "",
      plateNo: "",
      make: "",
      model: "",
      engineNo: "",
      chassisNo: "",
      tableItems: "",
      vatableSales: "",
      vatExemptSales: "",
      zeroRatedSales: "",
      totalSales: "",
      vatAmount: "",
      total: "",
      deductible: "",
      depreciation: "",
      atp: "",
      totalDeductibleDepre: "",
      totalDeductableDepre: "",
      netAmount: "",
      grossAmount: "",
      vatNonVat: "",
      deliveryReceipt: "",
      deliveryReceiptDate: "",
      deliveredTo: "",
      witholdingTax: "",
      ackCertValid: false,
      ackCertNotValid: false,
    },
    oriData: {},
    selections: {
      status: null,
    },
    changed: {},
    formError: {
      invoiceDate: ""
    },
    isError: false,
    attachments: [],
    isLoading: false,
    isDisabled: false,
    isNotDone: false,
    tableItems: null,
    vatSelections: { vatNonVat: "" },
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } = React.useContext(AuditTrailDataContext);
  const { notification, setNotification } =
    React.useContext(NotificationContext);
  const { validateBusinessRules } = useBusinessRules();
  const [isFieldEmpty, setIsFieldEmpty] = React.useState(true);
  const [taxableShops, setTaxableShops] = React.useState<string[]>([]);

  const tablePreProcess = (tableItems: any | null | undefined) => {
    if (tableItems === null || tableItems === undefined || tableItems === "") {
      return null;
    } else {
      const tableItemsPreProcess = removeTrailingCommas(tableItems);
      const parsedTableItems = JSON.parse(tableItemsPreProcess);
      const transformedTable = {
        header: parsedTableItems?.header.map((header: any) =>
          header.replace(/ work/i, " Work")
        ),
        rows: parsedTableItems?.rows.map((row: any) => {
          return parsedTableItems?.header.reduce(
            (acc: any, header: any, index: any) => {
              acc[header.replace(/ work/i, " Work")] = row[index];
              return acc;
            },
            {}
          );
        }),
      };
      return transformedTable;
    }
  };

  // #region fetchTaxableShops
  const fetchTaxableShops = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/taxable-shops`
      )
      .then((res: any) => {
        const data = res.data.data;

        const tdata = data.map((el: any) => {
          return el.shop;
        });

        setTaxableShops(tdata);
      })
      .catch((err: any) => {

      });
  };

  // #region fetchData
  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/service-invoice?transId=${transID}&id=${docId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {
            const data = res?.data?.data;
            const errors = res?.data?.errors;

            // table item processing
            const transformedtable = tablePreProcess(data?.tableItems);
            // status options
            const statusOptions: any = {
              // 0: null,
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 3, label: "Reject" },
            };

            const vatOptions: any = {
              Vat: { value: "Vat", label: "VAT Registered" },
              "Non Vat": { value: "Non Vat", label: "Not VAT Registered" },
            };
            setMainState({
              ...mainState,
              data: data,
              tableItems: transformedtable,
              selections: {
                ...mainState.selections,
                status: statusOptions[data?.status],
              },
              vatSelections: { vatNonVat: vatOptions[data?.vatNonVat] },
              isDisabled: data?.status === 2 || data?.status === 3,
            });

            // createAuditTrailData({
            //   transId: transID,
            //   taskType: "invoice_validation",
            //   event: "view_form",
            //   description: `View service invoice form with document id #${docId}`,
            //   user: globalData.username,
            //   fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
            //   roleLevel: globalData.role
            // });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // TODO update the endpoint and API
  const updateData = async () => {
    const currentDateTime = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/isip/document-validation/service-invoice?transId=${transID}&id=${docId}`,
      {
        method: "PUT",
        body: JSON.stringify(mainState?.data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {

        // setAlertMessage(data.message);
        // setShowAlert(true);
        // close();

        // validateBusinessRules(
        //   `/api/acap/document-validation/driver-license/business-rules?transId=${transID}&id=${docId}`,
        //   mainState?.data,
        //   transID,
        //   "document_validation"
        // );

        // validateBusinessRules(
        //   `/api/isip/document-validation/service-invoice/business-rules?transId=${transID}&id=${docId}&taskType=${"invoice_validation"}`,
        //   mainState?.data,
        //   transID,
        //   "invoice_validation"
        // );

        // createAuditTrailData({
        //   transId: transID,
        //   taskType: "invoice_validation",
        //   event: "update_form",
        //   description: `Update service invoice form with document id #${docId}`,
        //   user: globalData.username,
        //   fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
        //   roleLevel: globalData.role
        // });

        setMainState((prev: any) => ({
          ...prev,
          formError: {},
        }));

        setNotification({
          level: "success",
          showAlert: true,
          message: data?.message,
        });
      })
      .catch((err) => {

      });
  };

  const isAnyFieldEmpty = (data: any) => {
    const keys = [
      "invoiceNo",
      "invoiceDate",
      "vendorName",
      "customerName",
      "deductible",
      "depreciation",
      "totalDeductibleDepre",
      "atp",
      "netAmount",
      "vatableSales",
      "grossAmount",
      "vatNonVat",
      "status",
    ];
    for (let key of keys) {
      if (key !== "status") {
        if (data[key] === null || data[key] === undefined || data[key] === "") {
          return true;
        }
      } else {
        if (data[key] === null || data[key] === 0 || data[key] === 1) {
          return true;
        }
      }
    }
    return false;
  };

  const handleAckNotValidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainState((prev: any) => ({
      ...prev,
      data: {
        ...prev.data,
        ackCertNotValid: event.target.checked
      }
    }));
  };

  const handleAckValidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainState((prev: any) => ({
      ...prev,
      data: {
        ...prev.data,
        ackCertValid: event.target.checked
      }
    }));
  };

  React.useEffect(() => {
    fetchTaxableShops();
    fetchData();
  }, []);

  React.useEffect(() => {
    setIsFieldEmpty(isAnyFieldEmpty(mainState?.data));
  }, [mainState?.data]);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {
    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  const handleVatSelectChange = (selection: any) => {
    setMainState({
      ...mainState,
      data: { ...mainState.data, vatNonVat: selection.value },
      vatSelections: { vatNonVat: selection },
    });
  };

  const options: string[] = ["Vat","Non Vat",];

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Invoice No.
                </Typography>
                <OutlinedInput
                  id="invoiceNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice No. Here...`}
                  value={mainState?.data?.invoiceNo || ""}
                  onChange={handleInputChange}
                  inputProps={{
                    "aria-label": "invoiceNo",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.invoiceNo === ""}
                />
                {mainState?.data?.invoiceNo === "" && (
                  <FormHelperText error id="invoiceNo">
                    {/* {mainState?.formError?.orNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <Tooltip
                title="Ex. 2012/12/21"
                placement="top-start"
                TransitionComponent={Zoom}
              >
                <FormControl variant="outlined" fullWidth>
                  <Typography variant="body1" className="tp-text-bold">
                    Invoice Date <span className="tp-text-required">*</span>
                  </Typography>
                  <OutlinedInput
                    id="invoiceDate"
                    size="small"
                    type="text"
                    placeholder={`Enter Invoice Date Here...`}
                    // endAdornment={<InputAdornment position="end">
                    //   <Chip label={data.orDateConf + "%"} size="small" className={data.orDateConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                    // </InputAdornment>}
                    value={mainState?.data?.invoiceDate || ""}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur('or_date')}
                    inputProps={{
                      "aria-label": "invoiceDate",
                    }}
                    disabled={mainState?.isDisabled}
                    error={
                      mainState?.data?.invoiceDate === "" ||
                      !isValidDate(mainState?.data?.invoiceDate)
                    }
                  />
                  {(mainState?.data?.invoiceDate === "" ||
                    mainState?.formError?.invoiceDate ||
                    !isValidDate(mainState?.data?.invoiceDate)) && (
                    <FormHelperText error id="invoiceDate">
                      {!isValidDate(mainState?.data?.invoiceDate) &&
                        "Invalid Date"}
                    </FormHelperText>
                  )}
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Repair Shop Name
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                {/* <OutlinedInput
                  id="vendorName"
                  size="small"
                  type="text"
                  placeholder={`Enter Repair Shop Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vendorName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "vendorName",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.vendorName === ""}
                /> */}
                <Autocomplete
                  options={taxableShops}
                  defaultValue={mainState?.data?.vendorName}
                  value={mainState?.data?.vendorName || null}
                  onInputChange={(
                    _event: any,
                    value: string,
                    reason: string
                  ) => {

                    setMainState((prev: any) => ({
                      ...prev,
                      data: { ...prev.data, vendorName: value },
                    }));
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  freeSolo
                  renderInput={(params) => <TextField {...params} />}
                  // disabled={!brand || !model}
                  size="small"
                />
                {(mainState?.data?.vendorName === "" ||
                  mainState?.data?.vendorName === null) && (
                  <FormHelperText error id="vendorName">
                    Invalid Repair Shop. Please select a valid one
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Customer Name
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                </Typography>
                <OutlinedInput
                  id="customerName"
                  size="small"
                  type="text"
                  placeholder={`Enter Customer Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.customerName || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "customerName",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.customerName === ""}
                />
                {mainState?.data?.customerName === "" && (
                  <FormHelperText error id="customerName">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {
            // #region Table Items
            // if row have items, loop table items, if not show not available
          }
          {/* <br />
          <Typography variant="h6" className="tp-text-header-primary">
            Items
          </Typography>
          <hr />
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" className="tp-text-bold">
                      Scope of Work
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" className="tp-text-bold">
                      Labor cost
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" className="tp-text-bold">
                      Material cost
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1" className="tp-text-bold">
                      Total cost
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mainState?.tableItems?.rows ? (
                  mainState?.tableItems?.rows.map(
                    (item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item["Scope of Work"]}
                        </TableCell>
                        <TableCell align="left">{item["Labor cost"]}</TableCell>
                        <TableCell align="left">
                          {item["Material cost"]}
                        </TableCell>
                        <TableCell align="right">
                          {item["Total cost"]}
                        </TableCell>

                        <TableCell align="right">
                          <Button
                          // onClick={() => handleDeleteFromCart(index)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            // onClick={() => handleDeleteFromCart(index)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
          <br /> */}
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Deductible
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="deductible"
                  size="small"
                  type="text"
                  placeholder={`Enter Deductible Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.deductible || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "deductible",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.deductible === "" ||
                    !isValidNumber(mainState?.data?.deductible)
                  }
                />
                {(mainState?.data?.deductible === "" ||
                  !isValidNumber(mainState?.data?.deductible)) && (
                  <FormHelperText error id="deductible">
                    {!isValidNumber(mainState?.data?.deductible) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Depreciation
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="depreciation"
                  size="small"
                  type="text"
                  placeholder={`Enter Depreciation Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.depreciation || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "depreciation",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.depreciation === "" ||
                    !isValidNumber(mainState?.data?.depreciation)
                  }
                />
                {(mainState?.data?.depreciation === "" ||
                  !isValidNumber(mainState?.data?.depreciation)) && (
                  <FormHelperText error id="depreciation">
                    {!isValidNumber(mainState?.data?.depreciation) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Total Deductible and Depreciation
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="totalDeductibleDepre"
                  size="small"
                  type="text"
                  placeholder={`Enter Total Deductible and Depreciation Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.totalDeductibleDepre || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "totalDeductibleDepre",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.totalDeductibleDepre === "" ||
                    !isValidNumber(mainState?.data?.totalDeductibleDepre)
                  }
                />
                {(mainState?.data?.totalDeductibleDepre === "" ||
                  !isValidNumber(mainState?.data?.totalDeductibleDepre)) && (
                  <FormHelperText error id="totalDeductibleDepre">
                    {!isValidNumber(mainState?.data?.totalDeductibleDepre) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  BIR ATP
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="atp"
                  size="small"
                  type="text"
                  placeholder={`Enter BIR ATP Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.atp || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "atp",
                  }}
                  disabled={mainState?.isDisabled}
                  error={mainState?.data?.atp === ""}
                />
                {mainState?.data?.atp === "" && (
                  <FormHelperText error id="atp">
                    {/* {!!mainState?.formError?.address} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
                <FormControlLabel
                  className="tp-my-20px"
                  control={
                    <Checkbox checked={mainState?.data?.ackCertNotValid} onChange={handleAckNotValidChange} />
                  }
                  label={
                    <Stack direction={"row"} spacing={1}>
                      <Typography>
                        BIR Acknowledgment Cert, Not Valid for Claiming Input
                        Taxes
                      </Typography>
                      <Tooltip
                        title={`The Invoice should contain BIR Authority to Print or PTU CAS/BIR Acknowledgement Cert and the statement "This document is not valid for claim of input tax". Valid until Oct 26, 2024 for PTU CAS and Dec 31, 2024 for those with BIR Authority to Print`}
                        placement="top"
                      >
                        <HelpOutlineOutlinedIcon />
                      </Tooltip>
                    </Stack>
                  }
                />
                <FormControlLabel
                  className="tp-my-20px"
                  control={<Checkbox checked={mainState?.data?.ackCertValid} onChange={handleAckValidChange} />}
                  label={
                    <Stack direction={"row"} spacing={1}>
                      <Typography>
                        BIR Acknowledgment Cert, Valid for Claiming Input Taxes
                      </Typography>
                      <Tooltip
                        title={`The Invoice should contain BIR Authority to Print or PTU CAS/BIR Acknowledgement Cert and the statement "This document is valid for claiming of input tax". Starting Apr 27, 2024`}
                        placement="top"
                      >
                        <HelpOutlineOutlinedIcon />
                      </Tooltip>
                    </Stack>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Net Amount
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="netAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Net Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.netAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "netAmount",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.netAmount === "" ||
                    !isValidNumber(mainState?.data?.netAmount)
                  }
                />
                {(mainState?.data?.netAmount === "" ||
                  !isValidNumber(mainState?.data?.netAmount)) && (
                  <FormHelperText error id="netAmount">
                    {!isValidNumber(mainState?.data?.netAmount) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Vatable Sales
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vatableSales"
                  size="small"
                  type="text"
                  placeholder={`Enter Vatable Sales Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.addressConf + "%"} size="small" className={data.addressConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.vatableSales || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('address')}
                  inputProps={{
                    "aria-label": "vatableSales",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.vatableSales === "" ||
                    !isValidNumber(mainState?.data?.vatableSales)
                  }
                />
                {(mainState?.data?.vatableSales === "" ||
                  !isValidNumber(mainState?.data?.vatableSales)) && (
                  <FormHelperText error id="vatableSales">
                    {!isValidNumber(mainState?.data?.vatableSales) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Gross Amount
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="grossAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Gross Amount Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.grossAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "grossAmount",
                  }}
                  disabled={mainState?.isDisabled}
                  error={
                    mainState?.data?.grossAmount === "" ||
                    !isValidNumber(mainState?.data?.grossAmount)
                  }
                />
                {(mainState?.data?.grossAmount === "" ||
                  !isValidNumber(mainState?.data?.grossAmount)) && (
                  <FormHelperText error id="grossAmount">
                    {!isValidNumber(mainState?.data?.grossAmount) &&
                      "Invalid Number"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* 
              // #region Vat Non Vat
            */}
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  VAT / Non VAT
                  {/* <span className="tp-text-subtitle">(No, Street, City, Municipality, Province, Zip Code)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <MuiSelect
                  id="vatNonVat"
                  placeholder="Please select VAT here..."
                  value={mainState?.data?.vatNonVat}
                  onChange={(event: SelectChangeEvent) => {
                    setMainState((prev: any) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        vatNonVat: event.target.value as string
                      }
                    }));
                  }}
                  disabled={mainState?.isDisabled}
                  size="small"
                >
                  <MenuItem value={"Vat"}>Vat</MenuItem>
                  <MenuItem value={"Non-Vat"}>Non-Vat</MenuItem>
                  </MuiSelect>
                {mainState?.data?.vatNonVat === null ||
                  (mainState?.data?.vatNonVat === "" && (
                    <FormHelperText error id="vatNonVat">
                      {/* {mainState?.formError?.status} */}
                      Empty required field. Please select an item
                    </FormHelperText>
                  ))}
              </FormControl>
            </Grid>
          </Grid>
          {/* 
            // #region BusinessRules
          */}
          <br/>
          <BusinessRules docId={docId} transId={Number(transID)} taskType={"invoice_validation"} documentType={"service_invoide"}/>
          <br/>

          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={mainState?.isDisabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approve" },
                { value: 3, label: "Reject" },
              ]}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={mainState?.isDisabled}
              // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/isip/document-validation/sales-invoice"}
                    documentType={"Sales Invoice"}
                    close={close}
                  />
                  {/* <Button
                    variant="contained"
                    size="medium"
                    color="warning"
                    disabled
                  >
                    Archive
                  </Button> */}
                  &emsp;
                  <Button
                    // disabled={isFieldEmpty}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    // disabled={mainState?.isNotDone}
                    variant="contained"
                    size="medium"
                    color="error"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
