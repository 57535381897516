import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import { useCountStats } from '../contexts/CountStatsContext';
//import { GlobalDataContext } from '../contexts/GlobalDataContext';

export default function TaskCard({ task }: any) {
    //const {globalData, setGlobalData} = React.useContext(GlobalDataContext);
    const [taskStatuses, setTaskStatuses] = React.useState<any>({});
    const [mainState, setMainState] = React.useState<any>({
        error: "",
        isLoading: false
    });
    const navigate = useNavigate();

    // const getStatusByTaskType = (taskType: String) => {
    //     (async () => {
    //         setMainState({ ...mainState, isLoading: true })
    //         axios({
    //             method: 'get',
    //             url: `${process.env.REACT_APP_BACKEND_URL}/api/todo/status/${taskType}`,
    //             // headers: {
    //             //     'Authorization': `Bearer ${globalData?.sessionToken}`
    //             //   }
    //         }).then((res: any) => {
    //             setMainState({ ...mainState, isLoading: false })
    //             setTaskStatuses(res.data)
    //         }).catch((err: any) => {
    //             console.error(err?.response?.data)
    //             let errorMessage = ""
    //             if (err?.response?.status === 401) errorMessage = err?.response?.data
    //             if (err?.response?.status === 400) errorMessage = err?.response?.data
    //             setMainState({ error: errorMessage, isLoading: false })
    //         })
    //     })();
    // }

    React.useEffect(() => {
        //getStatusByTaskType(task.title);
    }, [])

    return (
       
        <Card
            onClick={() => navigate(task.slug)}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                my: 2,
                px: 2,
            }}
        >
            <CardContent>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={4}
                    mt={2}
                >
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={4}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyItem: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='h4' sx={{
                                color: "#a42a25"
                            }}>
                                { task.submitted }
                            </Typography>
                            <Typography sx={{
                                color: '#607274',
                            }}>
                                Submitted
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyItem: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography variant='h4' sx={{
                                color: "#a42a25"
                            }}>
                                { task.todo }
                            </Typography>
                            <Typography sx={{
                                color: '#607274',
                            }}>
                                Open Task
                            </Typography>
                        </Box>
                    </Stack>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <Typography variant='h6' color={"primary"} sx={{
                                fontWeight: 'bold',
                            }}>
                                { task.title }
                            </Typography>
                            <Typography sx={{
                                    color: '#607274',
                            }}>
                                { task.description }
                            </Typography>
                        </Box>
                    </Box>
                </Stack>
            </CardContent>
            <IconButton disableRipple={true} aria-label="open">
                <ArrowForwardIcon sx={{ height: 38, width: 38 }} />
            </IconButton>
        </Card>
    )
}