import {
  Box,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import React, { Dispatch } from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import ListOfLabors from "./ListOfLabors";
import ListOfParts from "./ListOfParts";
// import { ApiResponseClaim, ParsedEntry } from "../types";
import ClaimRegistrationDetail from "./ClaimRegistrationDetails";
// import { set } from "lodash";

type CartItem = {
  id?: number;
  deId?: number;
  transId?: number;
  name: string;
  amount: number;
  qty: number;
  condition: 'Original' | 'Replacement Parts' | 'Surplus' | 'Taiwan Parts' | 'Not Standard Part' | 'No Price Established' | 'Estimated Price' | 'Subject to Availability' | 'As per sample' | 'No stock' | 'Standard operating procedure when replacing parts' | '';
  globalDepreciation: boolean;
  shopPrice: number;
  totalAmount: number;
  depreciation: number;
  discount: number;
  netAmount: number;
  shopAmount: boolean;
  remarks: string;
  saveDate?: string;
};

// interface LaborRow {
//   id?: number;
//   scopeOfWorks: string;
//   repairCondition: string;
//   repair: number;
//   replace: number;
//   painting: number;
//   saveDate?: string;
//   transId?: string;
// }

export interface ItemCoverage {
  itemCoverage: string;
  sumInsuredperItemCoverage: number;
}

export interface ApiData {
  deductible: number | null;
  itemCoverages: string; // This is a JSON string
  // ... include other fields if needed
}

export interface ApiResponse {
  message: string;
  data: ApiData;
  status: boolean;
  errors: any;
  success: boolean;
}

interface FormData {
  dateInspection: string;
  placeInspection: string;
  inspectedBy: string;
  dateReinspected: string;
  reinspectedBy: string;
  dateAddtionalest: string;
}

interface ClientTransactionProps {
  updateGrossTotal?: (totalAmount: number, laborPlusVat: number) => void;
  updateTotalDeductible?: (
    deductible: number,
    deductibleAmount: number
  ) => void;
  transID?: any;
  deId?: any;
  data?: any;
  tag: string;
  totalRepairersAmount?: any;
  initialLaborAmount?: any;
  initialPartsAmount?: any;
  totalLaborAmount?: any;
  onTotalDepreciationChange: (totalDepreciation: number) => void;
  onTotalOfferAmountChange: (totalOfferAmount: number) => void;
  updateCart: (newCart: CartItem[]) => void;
  onTotalDiscountChange?: (totalDiscount: number) => void;
  onVatChange?: (vat: number) => void;
  setTotalLaborAmount?: (amount: number) => void;
  lossReserveParts: number;
  onLaborDataChange: (laborData: any) => void;
  setLossReserveParts: (amount: number) => void;
  selectedBrand: string | null;
  selectedModel: string | null;
  lossReserveLabor: number;
  onIsTotalLossChange: (percentLoss: number) => void;
  onTotalLossChange: (totalLoss: string) => void;
  setLossReserveLabor: (amount: number) => void;
  mainState: any;
  setMainState: Dispatch<any>;
  fmv: number;
  setFmv: React.Dispatch<React.SetStateAction<number>>;
  salvage: number;
  setSalvage: (value: number) => void;
  policyNo: any | null;
  crDate: string | null;
  perilType: string | null;
  isResend: boolean;
}


//

const ClientTransaction: React.FC<ClientTransactionProps> = ({
  updateGrossTotal,
  updateTotalDeductible,
  transID,
  deId,
  data,
  tag,
  onLaborDataChange,
  totalRepairersAmount,
  initialLaborAmount,
  initialPartsAmount,
  totalLaborAmount,
  updateCart,
  onTotalDepreciationChange,
  onTotalOfferAmountChange,
  setTotalLaborAmount,
  onTotalDiscountChange,
  onVatChange,
  lossReserveParts,
  setLossReserveParts,
  lossReserveLabor,
  selectedBrand,
  onIsTotalLossChange,
  onTotalLossChange,
  selectedModel,
  setLossReserveLabor,
  mainState,
  setMainState,
  fmv,
  setFmv,
  salvage,
  setSalvage,
  policyNo,
  crDate,
  perilType,
  isResend
}) => {
  // const handleLaborRowsChange = (newRows: LaborRow[]) => {
  //   onLaborRowsChange(newRows);
  // };
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [deductible, setDeductible] = React.useState<number>(1);
  const [deductibleAmount, setDeductibleAmount] = React.useState<number>(0);
  const [totalSumInsured, setTotalSumInsured] = React.useState<number>(0);
  const [totalLoss, setTotalLoss] = React.useState<string>("");
  const [floodLevel, setFloodLevel] = React.useState<string>("N/A");
  const [flood, setFlood] = React.useState<string>("");
  const [totalDepreciation, setTotalDepreciation] = React.useState<number>(0);
  const [claimRegistrationData, setClaimRegistrationData] =
    React.useState<any>(null);

  // const [dateInspected, setDateInspected] = React.useState<string>(
  //   new Date().toISOString().split('T')[0]
  // );
  // const [placeInspection, setPlaceInspection] = React.useState<string>('');
  // const [inspectedBy, setInspectedBy] = React.useState<string>('');


  // State to manage edit mode
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  // State to manage form fields
  const [formData, setFormData] = React.useState<FormData>({
    dateInspection: new Date().toISOString().split('T')[0],
    placeInspection: '',
    inspectedBy: '',
    reinspectedBy: '',
    dateReinspected: new Date().toISOString().split('T')[0],
    dateAddtionalest: new Date().toISOString().split('T')[0],
  });

  // State to keep a copy of original data for cancelling edits
  const [originalData, setOriginalData] = React.useState(formData);

  // // State for handling loading and errors
  // const [loading, setLoading] = useState<boolean>(false);
  // const [error, setError] = useState<string | null>(null);

  // Handler to toggle edit mode
  const handleEdit = () => {
    setOriginalData(formData); // Save current data in case of cancel
    setIsEditing(true);
    setError(null);
  };

  const handleCancel = () => {
    setFormData(originalData); // Revert to original data
    setIsEditing(false);
    setError(null);
  };

  // Handler for input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handler to save changes
  const handleSave = async () => {
    // Simple validation (optional)
    if (
      !formData.dateInspection.trim() ||
      !formData.placeInspection.trim() ||
      !formData.inspectedBy.trim()
    ) {
      setError('All fields are required.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      setIsEditing(false);
      // setSuccess(true);
      // Optionally, refetch data or update originalData
      setOriginalData(formData);
    } catch (err: any) {
      console.error('Failed to update inspection details', err);
      setError('Failed to update inspection details. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const totalPartsAndLabors = Number(totalAmount) + Number(laborPlusVat);
  const difference = Number(totalRepairersAmount) - Number(totalAmount);
  const differenceLabor = Number(totalLaborAmount) - Number(laborPlusVat);

  // Computations for Gross Totals
  const initialGrossTotal = Number(initialLaborAmount) + Number(initialPartsAmount);
  const repairersGrossTotal = Number(totalRepairersAmount) + Number(totalLaborAmount);
  const ourOfferGrossTotal = Number(totalAmount) + Number(laborPlusVat);
  const differenceGrossTotal = Number(repairersGrossTotal) - Number(ourOfferGrossTotal);

  // Computations for VAT
  const initialLaborVat = Number(initialLaborAmount) * 0.12;
  const repairersVat = Number(totalLaborAmount) * 0.12;
  const ourOfferVat = Number(laborPlusVat) * 0.12;
  const differenceVat = Number(repairersVat) - Number(ourOfferVat);

  // Computations for Gross Amounts
  const initialGrossAmount = Number(initialGrossTotal) + Number(initialLaborVat);
  const repairersGrossAmount = Number(repairersGrossTotal) + Number(repairersVat);
  const ourOfferGrossAmount = Number(ourOfferGrossTotal) + Number(ourOfferVat);
  const differenceGrossAmount = Number(repairersGrossAmount) - Number(ourOfferGrossAmount);


  // const [fmv, setFmv] = React.useState<number>(0); // Default value set to 0
  // const [isFmvManuallySet, setIsFmvManuallySet] = React.useState<boolean>(false);

  // const handleChangeFmv = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   const numberValue = Number(value.replace(/,/g, '')); // Remove commas if formatted
  //   if (!isNaN(numberValue) && numberValue >= 0) {
  //     setFmv(numberValue);
  //   }
  // };

  // React.useEffect(() => {
  //   onTotalLossChange(totalLoss);
  // }, [totalLoss, onTotalLossChange]);


  // const [salvage, setSalvage] = React.useState<number>(0); // Default value set to 0
  // const [sVAdjustment, setSvAdjustment] = React.useState<number>(0);

  const [isFmvEditing, setIsFmvEditing] = React.useState<boolean>(false);
  const [fmvInput, setFmvInput] = React.useState<string>('0');

  const handleEditFmv = () => {
    setFmvInput(fmv ? fmv.toString() : '');
    setIsFmvEditing(true);
  };



  // Function to handle input change


  const handleSaveFmv = async () => {
    // Validate the input
    const parsedValue = parseFloat(fmvInput);
    if (isNaN(parsedValue)) {
      // Optionally, show an error message to the user
      alert("Please enter a valid number for SV.");
      return;
    }

    // Update the state
    setFmv(parsedValue);
    setIsFmvEditing(false);

    // Make the API call
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
        body: JSON.stringify({
          fairMarketValue: parsedValue.toFixed(2),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update Salvage Value');
      }

      // Optionally, handle response data

    } catch (error) {
      console.error('Error updating SV:', error);
      // Optionally, revert the state or notify the user
      alert("Failed to update SV. Please try again.");
    }
  };

  const handleCancelFmv = () => {
    setIsFmvEditing(false);
  };

  // Function to handle input change
  const handleFmvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFmvInput(event.target.value);
  };




  const [isSvEditing, setIsSvEditing] = React.useState<boolean>(false);
  const [sVInput, setSvInput] = React.useState<string>('0');

  const handleEditSv = () => {
    setSvInput(salvage ? salvage.toString() : '');
    setIsSvEditing(true);
  };



  // Function to handle input change


  const handleSaveSv = async () => {
    // Validate the input
    const parsedValue = parseFloat(sVInput);
    if (isNaN(parsedValue)) {
      // Optionally, show an error message to the user
      alert("Please enter a valid number for SV.");
      return;
    }

    // Update the state
    setSalvage(parsedValue);
    setIsSvEditing(false);

    // Make the API call
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalData?.accessToken}`
        },
        body: JSON.stringify({
          salvageValue: parsedValue.toFixed(2),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update Salvage Value');
      }

      // Optionally, handle response data

    } catch (error) {
      console.error('Error updating SV:', error);
      // Optionally, revert the state or notify the user
      alert("Failed to update SV. Please try again.");
    }
  };

  const handleCancelSv = () => {
    setIsSvEditing(false);
  };

  // Function to handle input change
  const handleSvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSvInput(event.target.value);
  };

  // const handleChangeSalvage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value.replace(/,/g, ''); // Remove commas
  //   const numericValue = Number(value);
  //   if (!isNaN(numericValue)) {
  //     setSalvage(numericValue);
  //   }
  // };
  // #region Computation for % Loss
  const percentLoss =
    totalSumInsured !== 0 ? (ourOfferGrossAmount / totalSumInsured) * 100 : 0;
  const isTotalLoss = percentLoss >= 75;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const handleDeductibleChange = (value: number) => {
    setDeductible(Number(value));

    try {
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, { noDeductibles: value },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {
      console.error('Failed to update total loss:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  // Loading and error states for patching salvage value
  // const [patching, setPatching] = React.useState<boolean>(false);
  // const [patchError, setPatchError] = React.useState<string | null>(null);



  React.useEffect(() => {

    const fetchFairMarketValue = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        // Assuming the API returns { data: { salvageValue: number } }
        const fetchedFmv = response.data.fairMarketValue;
        setFmv(fetchedFmv);

        const fetchedSalvage = response.data.salvageValue;
        setSalvage(fetchedSalvage);
        const fetchLossType = response.data.lossType;
        setTotalLoss(fetchLossType);
        setDeductible(response.data.noDeductibles);
        setFlood(response.data.flood);
        setFloodLevel(response.data.floodLevel);
        const fetchedDateInspection = response.data.dateInspection;
        const fetchedPlaceInspection = response.data.placeInspection;
        const fetchedInspectedBy = response.data.inspectedBy;
        const fetchedReinspectedBy = response.data.reinspectedBy;
        const fetchedDateReinspection = response.data.dateReinspected;
        const fetchedDateAdditionalest = response.data.dateAdditionalest;

        setFormData({
          dateInspection: fetchedDateInspection,
          placeInspection: fetchedPlaceInspection,
          inspectedBy: fetchedInspectedBy,
          reinspectedBy: fetchedReinspectedBy,
          dateReinspected: fetchedDateReinspection,
          dateAddtionalest: fetchedDateAdditionalest,
        });

        setOriginalData({
          dateInspection: fetchedDateInspection,
          placeInspection: fetchedPlaceInspection,
          inspectedBy: fetchedInspectedBy,
          reinspectedBy: fetchedReinspectedBy,
          dateReinspected: fetchedDateReinspection,
          dateAddtionalest: fetchedDateAdditionalest,
        });

      } catch (err) {
        console.error('Error fetching salvage value:', err);
        setError('Failed to fetch salvage value.');
      } finally {
        setLoading(false);
      }
    };

    fetchFairMarketValue();
  }, []);



  const [totalLossReserve, setTotalLossReserve] = React.useState<string>("100000.00");  // Default value set to 0.0

  const handleChangeTotalLossReserve = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Regular expression to allow only numbers and one decimal point
    const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

    // Allow only valid input (numbers and a single decimal point)
    if (validNumberRegex.test(inputValue)) {
      setTotalLossReserve(inputValue);
    }
  };

  // const handleBlur = () => {
  //   // On blur, format the number
  //   const numberValue = parseFloat(totalLossReserve) || 0.0;
  //   setTotalLossReserve(formatNumber(numberValue));
  // };



  const [manualOverrideParts, setManualOverrideParts] =
    React.useState<boolean>(false);
  const [manualOverrideLabor, setManualOverrideLabor] =
    React.useState<boolean>(false);

  const formatNumber = (value: number | null | undefined) => {
    if (value == null) {
      return '';
    }
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const coverageMap: Record<string, string> = React.useMemo(() => ({
    "own damage": "202",
    "partial theft": "203",
    "acts of nature": "209",
    "third party": "206",
  }), []);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Ensure loading state is set at the start
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        if (!backendUrl) {
          throw new Error('Backend URL is not defined in environment variables.');
        }

        const response = await fetch(
          `${backendUrl}/api/acap/policy-asia?transId=${transID}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status && data.data) {
          const { deductible, itemCoverages: itemCoveragesRaw } = data.data;

          // Set deductibleAmount if available
          if (deductible != null) { // More concise null/undefined check
            setDeductibleAmount(deductible);
          }

          let parsedItemCoverages = [];
          if (itemCoveragesRaw) {
            try {
              parsedItemCoverages = JSON.parse(itemCoveragesRaw);


              // Convert perilType to lowercase for case-insensitive mapping
              const normalizedPerilType = perilType ? perilType.toLowerCase() : '';

              if (normalizedPerilType === "n/a") {
                setTotalSumInsured(0);

              } else {
                const selectedCoverageCode = coverageMap[normalizedPerilType];

                if (selectedCoverageCode) {
                  const selectedCoverage = parsedItemCoverages.find(
                    (item: { itemCoverage: string; }) => item.itemCoverage === selectedCoverageCode
                  );

                  if (selectedCoverage) {
                    setTotalSumInsured(selectedCoverage.sumInsuredperItemCoverage);
                    if (fmv === null) {
                      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
                        method: 'PATCH',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${globalData?.accessToken}`
                        },
                        body: JSON.stringify({
                          fairMarketValue: selectedCoverage.sumInsuredperItemCoverage.toFixed(2),
                        }),
                      });
                      setFmv(selectedCoverage.sumInsuredperItemCoverage);
                    }

                  } else {
                    console.warn(
                      `ItemCoverage "${selectedCoverageCode}" not found for perilType "${perilType}".`
                    );
                  }
                } else {
                  console.warn(`No coverage code mapped for perilType "${perilType}".`);
                }
              }
            } catch (parseError) {
              console.error('Error parsing itemCoverages JSON:', parseError);
              setError('Failed to parse item coverages data.');
            }
          } else {
            console.warn('No itemCoverages data received.');
          }
        } else {
          setError(data.message || 'Failed to retrieve data.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        if (err instanceof Error) {
          setError(err.message || 'An unexpected error occurred.');
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setFmv, transID, perilType, coverageMap, fmv]);

  React.useEffect(() => {
    if (updateGrossTotal) updateGrossTotal(totalAmount, laborPlusVat);
    if (updateTotalDeductible)
      updateTotalDeductible(deductible, deductibleAmount);
  }, [
    totalAmount,
    laborPlusVat,
    updateGrossTotal,
    deductible,
    deductibleAmount,
    updateTotalDeductible,
  ]);



  const handleDepreciationChange = (value: string) => {
    const newDepreciation = parseFloat(value);
    setTotalDepreciation(newDepreciation);
  };

  const handleTotalAmountPartsChange = (value: string) => {
    const newTotalAmount = parseFloat(value);
    setTotalAmount(newTotalAmount);
    // Only update lossReserveParts if it hasn't been manually changed
    if (!manualOverrideParts && newTotalAmount > 40000) {
      setLossReserveParts(newTotalAmount);
    } else if (!manualOverrideParts && newTotalAmount < 40000) {
      setLossReserveParts(40000);
    }
  };

  const handleLossReservePartsChange = (value: string) => {
    const newLossReserveParts = parseFloat(value);
    setLossReserveParts(newLossReserveParts);
    setManualOverrideParts(true); // Mark that the user has manually changed the value
  };

  const handleTotalAmountLaborChange = (value: any) => {
    const newTotalAmount = parseFloat(value);
    setLaborPlusVat(newTotalAmount);
    // Only update lossReserveLabor if it hasn't been manually changed
    if (!manualOverrideLabor && newTotalAmount > 20000.0) {
      setLossReserveLabor(newTotalAmount);
    } else if (!manualOverrideLabor && newTotalAmount < 20000.0) {
      setLossReserveLabor(20000.0);
    }
  };

  const handleLossReserveLaborChange = (value: string) => {
    const newLossReserveLabor = parseFloat(value);
    setLossReserveLabor(newLossReserveLabor);
    setManualOverrideLabor(true); // Mark that the user has manually changed the value
  };



  const handleDeductibleAmountChange = (value: string) => {
    setDeductibleAmount(Number(value));
  };

  const handleTotalSumInsuredChange = (value: string) => {
    const numberValue = Number(value.replace(/,/g, '')); // Remove commas
    if (!isNaN(numberValue) && numberValue >= 0) {
      setTotalSumInsured(numberValue);

    }
  };

  const handleTotalLossChange = async (event: SelectChangeEvent<string>) => {
    const newTotalLoss = event.target.value as string;
    setTotalLoss(newTotalLoss);
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, { lossType: newTotalLoss },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {
      console.error('Failed to update total loss:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  const handleFlood = (event: SelectChangeEvent<string>) => {
    const newFlood = event.target.value as string;
    setFlood(newFlood);
    try {
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, { flood: newFlood },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {
      console.error('Failed to update flood:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  const handleFloodLevel = (event: SelectChangeEvent<string>) => {
    const newFloodLevel = event.target.value as string;
    setFloodLevel(newFloodLevel);
    try {
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, { floodLevel: newFloodLevel },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {
      console.error('Failed to update flood level:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  // Computation for Diff%
  const calculateDiffPercentage = (difference: number, base: number) =>
    base !== 0 ? (difference / base) * 100 : 0;
  const diffPercentageParts = calculateDiffPercentage(
    difference,
    totalRepairersAmount!
  );
  const diffPercentageLabor = calculateDiffPercentage(
    differenceLabor,
    totalLaborAmount!
  );
  const diffPercentageGrossTotal = calculateDiffPercentage(
    differenceGrossTotal,
    repairersGrossTotal
  );
  const diffPercentageVat = calculateDiffPercentage(
    differenceVat,
    repairersVat
  );
  const diffPercentageGrossAmount = calculateDiffPercentage(
    differenceGrossAmount,
    repairersGrossAmount
  );



  React.useEffect(() => {
    onTotalLossChange?.(totalLoss);
  }, [totalLoss, onTotalLossChange]);

  React.useEffect(() => {
    onIsTotalLossChange(percentLoss);
  }, [percentLoss, onIsTotalLossChange]);


  const totalDeductible = deductible * deductibleAmount;

  const grossOffer = ourOfferGrossAmount - totalDeductible;

  // #region Tracking of totalPartsAndLabors and percentLoss
  React.useEffect(() => {

    const updateStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const deDetailsData = await response.json();

        const offerAmount = totalPartsAndLabors;
        const lossPercent = percentLoss;






        if (!isNaN(offerAmount) && !isNaN(lossPercent)) {
          // if total offer amount > 300,000 or loss percent >= 75

          if ((offerAmount > 300000 || lossPercent >= 75) && (deDetailsData.headclecApprove === null || deDetailsData.headclecApprove === 0) || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) {
            const selection =
              globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                ? { value: 9, label: "Pending for Offer" }
                : { value: 4, label: "Forward To Head CLEC" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                  ? [
                    { value: 9, label: "Pending for Offer" },
                    // { value: 9, label: "Pending for Offer" },
                    { value: 5, label: "For Review By Clec" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : [
                    { value: 4, label: "Forward To Head CLEC" },
                    { value: 27, label: "Forward to Head Motor Claims" },
                    { value: 28, label: "Forward to Head Claims" },
                    // { value: 9, label: "Pending for Offer" }
                  ],
            });

            // for total loss
            setTotalLoss(["Actual Loss", "Constructive Loss"].includes(totalLoss) ? totalLoss : "Not Total Loss");
          } else if ((offerAmount > 300000 || lossPercent >= 75) && deDetailsData.headclecApprove === 1 || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) {
            const selection =
              globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                ? { value: 9, label: "Pending for Offer" }
                : { value: 4, label: "Forward To Head CLEC" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                  ? [
                    { value: 9, label: "Pending for Offer" },
                    { value: 5, label: "For Review By Clec" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : [{ value: 4, label: "Forward To Head CLEC" },
                  { value: 9, label: "Pending for Offer" }
                  ],
            });

            // for total loss
            setTotalLoss(["Actual Loss", "Constructive Loss"].includes(totalLoss) ? totalLoss : "Not Total Loss");
          } else {
            // for status
            const selection =
              globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                ? { value: 9, label: "Pending for Offer" }
                : { value: 1, label: "Open" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec" || globalData?.role === "Head Motor Claims" || globalData?.role === "Head Claims"
                  ? [
                    { value: 3, label: "For Review by CLEC" },
                    { value: 9, label: "Pending for Offer" },
                    { value: 3, label: "Reject" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : isTotalLoss
                    ? [
                      { value: 18, label: "Pending Adjuster's Report" },
                      { value: 4, label: "Forward To Head CLEC" },
                      { value: 27, label: "Forward to Head Motor Claims" },
                      { value: 28, label: "Forward to Head Claims" },
                    ]
                    : [
                      { value: 3, label: "Open" },
                      { value: 9, label: "Pending for Offer" },
                      { value: 18, label: "Pending Adjuster's Report" },

                    ],
            });
            // for total loss
            // setTotalLoss("Not Total Loss");
          }
        }
      } catch (error) {
        console.error("Error fetching deDetailsData:", error);
        // Optionally, set an error state here
      }

    };
    updateStatus();
  }, [initialPartsAmount, percentLoss, totalLoss]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }



  // if (error) {
  //   return (
  //     <Container>
  //       <Alert severity="error">{error}</Alert>
  //     </Container>
  //   );
  // }

  return (
    <div>
      <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
        <br />
        <Grid container spacing={2}>
          {/* <Grid item md={3}>
          <Typography variant="body1" className="tp-text-bold">
            Date of Inspection:
          </Typography>
        </Grid> */}
          {/* Date of Inspection */}
          <Grid item md={4} xs={12}>
            {isEditing ? (
              <TextField
                label="Date of Inspection"
                size="small"
                type="date"
                name="dateInspection"
                value={formData.dateInspection}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isResend || loading}
                required
              />
            ) : (
              <Typography variant="body1">
                <strong>Date of Inspection:</strong> {formData.dateInspection}
              </Typography>
            )}
          </Grid>

          {/* Place of Inspection */}
          <Grid item md={4} xs={12}>
            {isEditing ? (
              <TextField
                size="small"
                label="Place of Inspection"
                variant="outlined"
                name="placeInspection"
                value={formData.placeInspection}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isResend || loading}
                required
              />
            ) : (
              <Typography variant="body1">
                <strong>Place of Inspection:</strong> {formData.placeInspection}
              </Typography>
            )}
          </Grid>

          {/* Inspected By */}
          <Grid item md={4} xs={12}>
            {isEditing ? (
              <TextField
                size="small"
                label="Inspected By"
                variant="outlined"
                name="inspectedBy"
                value={formData.inspectedBy}
                onChange={handleChange}
                fullWidth
                margin="normal"
                disabled={isResend || loading}
                required
              />
            ) : (
              <Typography variant="body1">
                <strong>Inspected By:</strong> {formData.inspectedBy}
              </Typography>
            )}
          </Grid>

          {isResend && (
            <Grid item md={4} xs={12}>
              {isEditing ? (
                <TextField
                  size="small"
                  label="Date Reinspected"
                  variant="outlined"
                  type="date"
                  name="dateReinspected"
                  value={formData.dateReinspected}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={loading}
                />
              ) : (
                <Typography variant="body1">
                  <strong>Date Reinspected:</strong> {formData.dateReinspected}
                </Typography>
              )}
            </Grid>
          )}



          {isResend && (
            <Grid item md={4} xs={12}>
              {isEditing ? (
                <TextField
                  size="small"
                  label="Date Additional Estimate"
                  variant="outlined"
                  type="date"
                  name="dateAddtionalest"
                  value={formData.dateAddtionalest}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={loading}
                />
              ) : (
                <Typography variant="body1">
                  <strong>Date Additional Estimate:</strong> {formData.dateAddtionalest}
                </Typography>
              )}
            </Grid>
          )}

          {isResend && (
            <Grid item md={4} xs={12}>
              {isEditing ? (
                <TextField
                  size="small"
                  label="Re-Inspected By"
                  variant="outlined"
                  name="reinspectedBy"
                  value={formData.reinspectedBy}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled={loading}
                  required
                />
              ) : (
                <Typography variant="body1">
                  <strong>Re-Inspected By:</strong> {formData.reinspectedBy}
                </Typography>
              )}
            </Grid>
          )}

          {/* Action Buttons */}
          <Grid item xs={12}>
            {isEditing ? (
              <>
                <IconButton
                  color="primary"
                  onClick={handleSave}
                  aria-label="save"
                  disabled={loading}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={handleCancel}
                  aria-label="cancel"
                  disabled={loading}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <IconButton
                color="primary"
                onClick={handleEdit}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>

          {/* Error Message */}
          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* <Grid container spacing={2}>
        <Grid item md={3}>
          <Typography variant="body1" className="tp-text-bold">
            Place of Inspection:
          </Typography>
        </Grid>
     
      </Grid> */}
        <br /><br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Typography variant="h6" className="tp-text-header-primary">
              Our Offer Estimate
              <Typography
                variant="caption"
                display="block"
                className="tp-text-subtitle"
              >
                Note: These are the the parts and labors that we will offer to the
                client.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <hr className="tp-border-hr_primary" />
        {/* 
        // #region ListOfParts
      */}
        <ListOfParts
          onTotalAmountChange={handleTotalAmountPartsChange}
          onTotalDepreciationChange={onTotalDepreciationChange}
          onTotalOfferAmountChange={onTotalOfferAmountChange}
          onTotalDiscountChange={onTotalDiscountChange}
          onVatChange={onVatChange}
          updateCart={updateCart}
          perilType={perilType}
          policyNo={policyNo || ''}
          crDate={crDate || ''}
          initialBrand={selectedBrand}
          initialModel={selectedModel}

        />
      </Card>
      <br /><br />
      {/* 
        // #region ListOfLabors
      */}
      <ListOfLabors
        onLaborPlusVatChange={handleTotalAmountLaborChange}
        onLaborDataChange={onLaborDataChange}
      />
      <Card style={{ width: "100%", marginTop: "15px", padding: "20px", border: "solid 2px #1c1364" }}>
        <Grid item xs={12} md={4} lg={4} textAlign="center">
          <Typography variant="h6" className="tp-text-header-error">
            <Typography
              variant="caption"
              display="block"
              className="tp-text-subtitle"
            >
              Total Offer Amount:
            </Typography>
            ₱ {formatNumber(totalPartsAndLabors)}
          </Typography>
        </Grid>
      </Card>
      <br /><br />
      <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
        <Typography
          variant="h5"
          textAlign="center"
          className="tp-text-header-primary"
        >
          Cost of Claims
        </Typography>
        <br /><br />
        {perilType !== "Third Party" && (
          <Grid container spacing={2}>
            <Grid item md={3} />
            <Grid item md={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel>No. of Deductibles</InputLabel>
                <Select
                  value={deductible}
                  onChange={(e) => handleDeductibleChange(Number(e.target.value))}
                  label="No. of Deductibles"
                >
                  {Array.from({ length: 14 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <TextField
                label="Deductible Amount"
                variant="outlined"
                type="text"
                value={formatNumber(deductibleAmount)}
                onChange={(e) => handleDeductibleAmountChange(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        )}
        <br /><br />
        <Grid container spacing={2}>
          <Grid item md={3} />
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Initial Estimate
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Repairer's
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Our Offer
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Difference
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" className="tp-text-header-primary">
              Diff%
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">Parts:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialPartsAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalRepairersAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(totalAmount)}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(difference)}</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageParts.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">Total Labor:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialLaborAmount!)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalLaborAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(laborPlusVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(differenceLabor)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageLabor.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2} marginBottom="15px">
          <Grid item md={3}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              Gross Total:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(initialGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(repairersGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(ourOfferGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(differenceGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              {diffPercentageGrossTotal.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">
              + VAT (Total Labour only) 12.00/12.00%:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialLaborVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(repairersVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(ourOfferVat)}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(differenceVat)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageVat.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2} marginBottom="15px">
          <Grid item md={3}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              Gross Amount:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(initialGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(repairersGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(ourOfferGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(differenceGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              {diffPercentageGrossAmount.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography variant="body1">- Deductible:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalDeductible)}
            </Typography>
          </Grid>
        </Grid>

        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              Approve Gross Offer:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(grossOffer)}
            </Typography>
          </Grid>
        </Grid>
        <br />
      </Card>

      <br /><br />

      <Box>
        <Card
          sx={{
            boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
            borderRadius: 2,
            padding: "40px"
          }}
        >
          <Typography variant="h5" textAlign="center" className="tp-text-header-primary">
            Total Sum Insured
          </Typography>
          <br />
          <Grid container spacing={2} textAlign="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="tp-text-header-error">
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  Total Sum Insured:
                </Typography>
                ₱ {formatNumber(totalSumInsured)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="tp-text-header-error">
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  % Loss:
                </Typography>
                {percentLoss.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <br /><br />

      <Box>
        <Card
          sx={{
            boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
            borderRadius: 2,
            padding: "40px"
          }}
        >
          <Typography variant="h5" textAlign="center" className="tp-text-header-primary">
            Extent of Damage Details
          </Typography>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

              <FormControl fullWidth margin="normal">
                <InputLabel>Extent of Damage</InputLabel>
                <Select
                  value={flood}
                  size="small"
                  onChange={handleFlood}
                  label="Extent of Damage"
                >
                  <MenuItem value="" ></MenuItem>
                  <MenuItem value="Flooded" >Flooded</MenuItem>
                  <MenuItem value="Not Flooded">Not flooded</MenuItem>
                  <MenuItem value="For Confirmation">For Confirmation</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>

              <FormControl fullWidth margin="normal">
                <InputLabel>Flood Level</InputLabel>
                <Select
                  size="small"
                  value={floodLevel}
                  onChange={handleFloodLevel}
                  label="Flood Level"
                  disabled={flood !== "Flooded" ? true : false}
                >
                  <MenuItem value="N/A" >N/A</MenuItem>
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="C">C</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {
            // #region Total Loss
          }
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Typography variant="body1" className="tp-text-bold">
                Total Loss:
              </Typography>
            </Grid>
            <Grid item md={isTotalLoss || ["Actual Loss", "Constructive Loss"].includes(totalLoss) ? 3 : 9}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Total Loss</InputLabel>
                <Select
                  value={totalLoss}
                  onChange={handleTotalLossChange}
                  label="Total Loss"
                >
                  <MenuItem value="Not Total Loss">Not Total Loss</MenuItem>
                  <MenuItem value="Actual Loss">Actual Loss</MenuItem>
                  <MenuItem value="Constructive Loss">Constructive Loss</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {(isTotalLoss || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) && (
              isFmvEditing ? (
                <Grid item md={3}>
                  <Grid item>
                    <TextField
                      label="Fair Market Value"
                      value={fmvInput}
                      onChange={handleFmvChange}
                      type="number"
                      size="small"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={handleSaveFmv} aria-label="save">
                      <CheckIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton color="secondary" onClick={handleCancelFmv} aria-label="cancel">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={3}>
                  <Grid item>
                    <Typography variant="body1" className="tp-text-bold">
                      Fair Market Value: ₱ {formatNumber(fmv)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={handleEditFmv} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            )}

            {(isTotalLoss || ["Actual Loss", "Constructive Loss"].includes(totalLoss)) && (
              isSvEditing ? (
                <Grid item md={3}>
                  <Grid item>
                    <TextField
                      label="Salvage Value"
                      value={sVInput}
                      onChange={handleSvChange}
                      type="number"
                      size="small"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={handleSaveSv} aria-label="save">
                      <CheckIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton color="secondary" onClick={handleCancelSv} aria-label="cancel">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={3}>
                  <Grid item>
                    <Typography variant="body1" className="tp-text-bold">
                      Salvage Value: ₱ {formatNumber(salvage)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={handleEditSv} aria-label="edit">
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            )}

          </Grid>
        </Card>
      </Box>
      {
        // #endregion Total Loss
      }
      <br /><br />
      <Box>
        <Card
          sx={{
            boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
            borderRadius: 2,
            padding: "40px"
          }}
        >
          {/* Premium class */}
          <Typography variant="h5" textAlign="center" className="tp-text-header-primary">
            Premium Class
          </Typography>
          <ClaimRegistrationDetail
            tag={tag}
            transId={transID}
            claimRegistrationData={claimRegistrationData}
            setClaimRegistrationData={setClaimRegistrationData}
          />
          <br />
        </Card>
      </Box>
    </div>
  );
};

export default ClientTransaction;
