import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import axios from "axios";
import MUIDataTable, { Responsive, SelectableRows } from "mui-datatables";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../api/FormatDate";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

export function ViewButton({ url, sender }: any) {
  const navigate = useNavigate();

  return (
    <Button
      // color="primary"
      variant="contained"
      size="small"
      className="tp-custom-button-primary"
      onClick={() => navigate(url, { state: { sender } })}
    >
      View
    </Button>
  );
}

export default function TaskTableIsip({ task }: any) {
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [mainState, setMainState] = React.useState<any>({
    error: "",
    isLoading: false,
  });

  const convertStatus = (status: number) => {
    if (status === 0) {
      return {
        label: "Bot Processing",
        component: (
          <Chip label="Bot Processing" size="small" variant="outlined" />
        ),
      };
    } else if (status === 11) {
      return {
        label: "Missing Documents",
        component: (
          <Chip
            label="Missing Documents"
            size="small"
            variant="outlined"
            color="warning"
          />
        ),
      };
    } else if (status === 10) {
      return {
        label: "Manual",
        component: (
          <Chip
            label="Manual"
            size="small"
            variant="outlined"
            color="warning"
          />
        ),
      };
    } else if (status === 15 || status === 1) {
      return {
        label: "Open",
        component: (
          <Chip label="Open" size="small" variant="outlined" color="primary" />
        ),
      };
    } else if (status === 20) {
      return {
        label: "Pending For Offer",
        component: (
          <Chip
            label="Pending For Offer"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 2) {
      return {
        label: "Pending For Payment",
        component: (
          <Chip
            label="Pending For Payment"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 3) {
      return {
        label: "Rejected",
        component: (
          <Chip
            label="Rejected"
            size="small"
            variant="outlined"
            color="error"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const convertPriority = (priority: number) => {
    if (priority == 1) {
      return {
        label: "High",
        component: (
          <Chip label="High" size="small" variant="outlined" color="warning" />
        ),
      };
    } else if (priority == 0) {
      return {
        label: "Low",
        component: (
          <Chip label="Low" size="small" variant="outlined" color="primary" />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const convertChannel = (channel: number) => {
    if (channel === 1) {
      return {
        label: "Email",
        component: (
          <Chip label="Email" size="small" variant="outlined" color="primary" />
        ),
      };
    } else if (channel === 2) {
      return {
        label: "Web Form",
        component: (
          <Chip
            label="Web Form"
            size="small"
            variant="outlined"
            color="primary"
          />
        ),
      };
    } else if (channel === 3) {
      return {
        label: "From Clec",
        component: (
          <Chip
            label="From Clec"
            size="small"
            variant="outlined"
            color="primary"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  const getAllTaskByTaskType = async () => {
    setMainState({ error: "", isLoading: true });
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/isip-task`)
      .then((res: any) => {

        const transformedData = res?.data?.map((item: any, index: number) => {
          const numericStatus = item?.status;
          const displayStatus = convertStatus(item?.status);
          const username = item.userDetails
            ? item.userDetails.username
            : "In Progress";

          return {
            transId: `ISIP-${item?.id}`,
            sender: item.sender,
            channel: 1, // temp for Email
            channel_display: convertChannel(1).component,
            priority: item.priority,
            priority_display: convertPriority(item.priority).component,
            priority_desc: item.priorityDesc,
            status: numericStatus,
            status_display: displayStatus.component,
            username: username,
            dateCreated: formatDate(item?.saveDate),
            action: <ViewButton url={`${item.id}`} sender={item.sender} />,
          };
        });
        setRows(transformedData);

        const channelMap: { [key: string]: string } = {
          "1": "Email",
          "2": "Web Form",
          "3": "From Clec",
        };
        const statusMap: { [key: string]: string } = {
          "0": "Bot Processing",
          "1": "Open",
          "10": "Manual",
          "11": "Missing Documents",
          "2": "Pending For Payment",
          "3": "Rejected",
        };
        const transformedCols = [
          {
            name: "transId",
            label: "Trans ID",
            options: {
              filter: true,
              sort: true,
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "sender",
            label: "Sender",
            options: {
              filter: true,
              sort: true,
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "dateCreated",
            label: "Date Created",
            options: {
              filter: true,
              sort: true,
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "channel_display",
            label: "CHANNEL",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "channel",
            label: "CHANNEL",
            options: {
              display: false,
              filter: true,
              filterOptions: {
                names: Object.keys(channelMap).map((key) => channelMap[key]),
                logic(channel: any, filterVal: any) {
                  return !filterVal.includes(channelMap[channel]);
                },
                display: (
                  filterList: any,
                  onChange: any,
                  index: any,
                  column: any
                ) => (
                  <div>
                    <label>Channel</label>
                    <select
                      onChange={(event) => {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option value="">All</option>
                      {Object.entries(channelMap).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
            },
          },
          {
            name: "status_display",
            label: "STATUS",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "status",
            label: "STATUS",
            options: {
              display: false,
              filter: true,
              filterOptions: {
                names: Object.keys(statusMap).map((key) => statusMap[key]),
                logic(status: any, filterVal: any) {
                  return !filterVal.includes(statusMap[status]);
                },
                display: (
                  filterList: any,
                  onChange: any,
                  index: any,
                  column: any
                ) => (
                  <div>
                    <label>Status</label>
                    <select
                      onChange={(event) => {
                        filterList[index] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: "100%" }}
                    >
                      <option value="">All</option>
                      {Object.entries(statusMap).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
            },
          },
          {
            name: "username",
            label: "USERNAME",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value: any) => value,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
          {
            name: "action",
            label: "ACTION",
            options: {
              filter: false,
              sort: false,
              setCellHeaderProps: () => ({
                style: { textAlign: "center", fontWeight: "bold" },
              }),
              setCellProps: () => ({
                style: { textAlign: "center" },
              }),
            },
          },
        ];
        setColumns(transformedCols);
        setMainState({ ...mainState, isLoading: false });
      })
      .catch((err: any) => {
        console.error(err?.response?.data);
        let errorMessage = "";
        if (err?.response?.status === 401) errorMessage = err?.response?.data;
        if (err?.response?.status === 400) errorMessage = err?.response?.data;
        setMainState({ error: errorMessage, isLoading: false });
      });
  };

  const options = {
    download: false,
    print: false,
    selectableRows: "none" as SelectableRows,
    responsive: "standard" as Responsive,
    resizableColumns: true,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              padding: "5px !important",
              backgroundColor: "#a42a25 !important",
              color: "white !important",
            },
            toolButton: {
              fontWeight: "bold !important",
              width: "100%",
              justifyContent: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "2px !important",
              marginLeft: "12px !important",
              marginRight: "12px !important",
            },
          },
        },
        MUIDataTableResize: {
          styleOverrides: {
            resizer: {
              border: "0.05px solid rgba(0, 0, 0, 0.1) !important",
            },
          },
        },
      },
    });

  React.useEffect(() => {
    getAllTaskByTaskType();
  }, []);

  return (
    <Box
      sx={{
        my: 4,
      }}
    >
      {!mainState?.isLoading ? (
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Task List"}
            data={rows}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
