import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import { formatNumber } from "../../utils/stringUtils";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { IPolicyData } from "../../types/PolicyData";
import React from "react";
import PaymentHistory from "../policy_details/PaymentHistory";
import { useLoaDetails } from "../../contexts/LoaDetailsContext";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function LoaInformation({ transId }: any) {
  const { loaDetails, fetchLoaDetails, createLoaDetails, removeLoaDetails } =
    useLoaDetails();

  return (
    <div>
      {loaDetails.loaInformation && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel-a-content"}
            id={"panel-a-header"}
            className={"tp-bg-lightPrimary"}
          >
            <Typography className="tp-text-bold">LOA Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <Grid container sx={{ my: 1 }}>
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Claim Number
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.claimNo ?? "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Policy Number
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.policyNo ?? "N/A"}
                  </Typography>
                </Grid>
                {/* </Grid> */}
                {/* <Grid container sx={{ my: 1 }}> */}

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Claimant Email
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.claimantEmail ?? "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Insured Name
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.insuredName ?? "N/A"}
                  </Typography>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container sx={{ my: 1 }}> */}
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    In House Estimate
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.inHouseEstimateAmount ?? "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Repair Shop
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.vendorName ?? "N/A"}
                  </Typography>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container sx={{ my: 1 }}> */}
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Depreciation
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {
                      parseFloat(
                        loaDetails.loaInformation!.depreciationValue as string
                      )
                     ?? "0.00"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Deductible
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {
                      parseFloat(
                        loaDetails.loaInformation!.deductible as string
                      )
                     ?? "0.00"}
                  </Typography>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container sx={{ my: 1 }}> */}
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Make
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.make ?? "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Model
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.model ?? "N/A"}
                  </Typography>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container sx={{ my: 1 }}> */}
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Unit
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.unit ?? "N/A"}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Total
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {
                      parseFloat(loaDetails.loaInformation!.total as string)
                     ?? "0.00"}
                  </Typography>
                </Grid>
                {/* </Grid> */}

                {/* <Grid container sx={{ my: 1 }}> */}
                <Grid item xs={12} md={12}>
                  <Typography
                    fontWeight={"bold"}
                    variant="body2"
                    // className="tp-text-bold"
                  >
                    Plate Number
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {loaDetails.loaInformation!.plateNo ?? "N/A"}
                  </Typography>
                </Grid>
                {/* <Grid item xs={6} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#8a8888" }}
                      // className="tp-text-bold"
                    >
                      Model
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {sfLoaDetails?.model ?? "N/A"}
                    </Typography>
                  </Grid> */}
              </Grid>
            </>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
