import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalDataContext } from "../contexts/GlobalDataContext";

const themeLight = createTheme({
  palette: {
    background: { default: "#F7FBFC" },
    primary: { main: "#1C1364", contrastText: "#fff" },
    warning: { main: "#ddbc19", contrastText: "#fff" },
    error: { main: "#a42a25", contrastText: "#fff" },
  },
  typography: {
    fontFamily: '"Poppins", "system-ui", "sans-serif"',
  },
});

interface MainState {
  apiError: string;
  formError: string;
  isLoading: boolean;
}

interface User {
  id: string;
  name?: string;
  firstName: string;
  lastName: string;
}

export default function TaskDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [taskData, setTaskData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const todayDate = new Date().toISOString().split("T")[0];
  const { globalData } = useContext(GlobalDataContext);
  const [mainState, setMainState] = useState<MainState>({
    apiError: "",
    formError: "",
    isLoading: false,
  });

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/open-task/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        const formData = {
          date: res.data.dueDate,
          role: res.data.assignToRole,
          assignedTo: res.data.assignToUser,
          status: res.data.status,
          subject: res.data.subject,
          priority: res.data.priority,
          description: res.data.description,
        }
        setTaskData(formData);
        setLoading(false);
      } catch (err: any) {
        setError("Failed to load task data.");
        setLoading(false);
      }
    };

    fetchTaskData();
  }, [id]);

  useEffect(() => {
    const fetchUsersByRole = async () => {
      if (taskData?.role) {


        if (taskData.role === "SELF") {
          let selfData: any = JSON.parse(
            secureLocalStorage.getItem("me") as string
          );
          setUsers([
            {
              id: selfData.id,
              firstName: selfData.firstName,
              lastName: selfData.lastName,
            },
          ]);
        } else {
          try {


            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all?rolename=${taskData.role}`,
              {
                headers: {
                  'Authorization': `Bearer ${globalData?.accessToken}`
                }
              }
            );
            setUsers(response.data.data);



          } catch (error) {
            setMainState((prevState) => ({
              ...prevState,
              apiError: "Error fetching users. Please try again later.",
            }));
          }
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsersByRole();
  }, [taskData?.role]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      !taskData.date ||
      !taskData.assignedTo ||
      !taskData.status ||
      !taskData.priority ||
      !taskData.subject ||
      !taskData.role
    ) {
      setMainState((prevState) => ({
        ...prevState,
        formError: "All fields are required",
      }));
      return;
    }

    setMainState((prevState) => ({
      ...prevState,
      isLoading: true,
      formError: "",
      apiError: "",
    }));

    const payload = {
      assignTo: taskData.assignedTo,
      dueDate: taskData.date,
      description: taskData.description,
      subject: taskData.subject,
      status: taskData.status,
      priority: taskData.priority,
      role: taskData.role
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/open-task/${id}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      navigate("/task-list  ");
    } catch (error) {
      setMainState((prevState) => ({
        ...prevState,
        apiError: "Error submitting form. Please try again later.",
      }));
    } finally {
      setMainState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={themeLight}>
      <Container component="main" maxWidth="xl" sx={{ height: "110vh" }}>
        <CssBaseline />
        {/* Back Button */}
        <IconButton
          onClick={() => window.history.back()} // Navigate back
          disableRipple={true}
          aria-label="open"
          sx={{
            my: 2,
            padding: 0,
            paddingTop: 12,
          }}
        >
          <ArrowBackIcon sx={{ height: 28, width: 28 }} />
          <Typography sx={{ ml: 1 }}>Back</Typography>
        </IconButton>

        {/* Card Layout */}
        <Card sx={{ minWidth: 275, my: 2 }}>
          <CardContent className="tp-padding-50px">
            <Typography variant="h4" className="tp-text-header-primary">
              Task Form
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 2 }}>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {taskData ? (
                <>
                  <Grid container spacing={2} className="tp-mb-20px">
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Select Date
                          <span className="tp-text-required">*</span>
                        </Typography>
                        <OutlinedInput
                          type="date"
                          disabled
                          value={taskData.date}
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              date: e.target.value,
                            })
                          }
                          error={!taskData.date}
                          inputProps={{
                            "aria-label": "Select Date",
                            min: todayDate,
                          }}
                        />
                        {!taskData.date && (
                          <FormHelperText error>Select a date</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Role
                          <span className="tp-text-required">*</span>
                        </Typography>
                        <Select
                          value={taskData.role}
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              role: e.target.value,
                            })
                          }
                          disabled
                          displayEmpty
                          inputProps={{
                            "aria-label": "Role",
                          }}
                          error={!taskData.role}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="SELF">SELF</MenuItem>
                          <MenuItem value="OTHER RA">OTHER RA</MenuItem>
                          <MenuItem value="Jr Clec">JR CLEC</MenuItem>
                          <MenuItem value="Sr Clec">SR CLEC</MenuItem>
                          <MenuItem value="Claims Assistant">Claims Assistant</MenuItem>
                          <MenuItem value="Claims Officer Approver">Claims Officer Approver</MenuItem>
                        </Select>
                        {!taskData.role && (
                          <FormHelperText error>Select a role</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Assign To
                          <span className="tp-text-required">*</span>
                        </Typography>
                        <Select

                          value={taskData.assignedTo}
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              assignedTo: e.target.value,
                            })
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Assign To",
                          }}
                          error={!taskData.assignedTo}
                          disabled
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                        {!taskData.assignedTo && (
                          <FormHelperText error>
                            Select an assignee
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="tp-mb-20px">
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Status
                          <span className="tp-text-required">*</span>
                        </Typography>
                        <Select
                          value={taskData.status}
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              status: e.target.value,
                            })
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Status",
                          }}
                          error={!taskData.status}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Open">Open</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>
                        </Select>
                        {!taskData.status && (
                          <FormHelperText error>Select a status</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Subject
                          <span className="tp-text-required">*</span>
                        </Typography>

                        <OutlinedInput
                          value={taskData.subject}
                          disabled
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              subject: e.target.value,
                            })
                          }
                          placeholder="Enter Subject..."
                          inputProps={{
                            "aria-label": "Subject",
                          }}
                        />
                        {!taskData.subject && (
                          <FormHelperText error>
                            Select a subject
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item md={4}>
                      <FormControl variant="outlined" fullWidth margin="normal">
                        <Typography variant="body1" className="tp-text-bold">
                          Priority
                          <span className="tp-text-required">*</span>
                        </Typography>
                        <Select
                          value={taskData.priority}
                          disabled
                          onChange={(e) =>
                            setTaskData({
                              ...taskData,
                              priority: e.target.value,
                            })
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Priority",
                          }}
                          error={!taskData.priority}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Normal">Normal</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                        </Select>
                        {!taskData.priority && (
                          <FormHelperText error>
                            Select a priority
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Typography variant="body1" className="tp-text-bold">
                      Description
                    </Typography>
                    <OutlinedInput
                      multiline
                      rows={4}
                      disabled
                      value={taskData.description}
                      onChange={(e) =>
                        setTaskData({
                          ...taskData,
                          description: e.target.value,
                        })
                      }
                      placeholder="Enter description here..."
                      inputProps={{
                        "aria-label": "Description",
                      }}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    disabled={mainState.isLoading}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                  >
                    {mainState.isLoading ? (
                      <CircularProgress color="inherit" size="1.5em" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </>
              ) : (
                <p>No task data found</p>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
