import axios from 'axios';
// import {GlobalDataContext } from "../../contexts/GlobalDataContext";
// import React from 'react';

export const createApiClient = (accessToken: string) => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/acap`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
  });
};
export const getPartsByDeId = async (accessToken: string | null, deId: number) => {
  if (!accessToken) {
    throw new Error('Access token is required');
  }
  const apiClient = createApiClient(accessToken);
  const response = await apiClient.get(`/parts?deId=${deId}`);
  return response.data;
};
export const postParts = async (accessToken: string | null, part: any) => {
  if (!accessToken) {
    throw new Error('Access token is required');
  }
  const apiClient = createApiClient(accessToken);
  return apiClient.post('/parts', part);
};
export const updatePart = async (accessToken: string | null, id: any, part: any) => {
  if (!accessToken) {
    throw new Error('Access token is required');
  }
  const apiClient = createApiClient(accessToken);
  return apiClient.put(`/parts/${id}`, part);
};
export const deletePartsById = async (accessToken: string | null, id: number) => {
  if (!accessToken) {
    throw new Error('Access token is required');
  }
  const apiClient = createApiClient(accessToken);
  return apiClient.delete(`/parts/${id}`);
};

// export const deletePartsById = async (id: number) => {
//     return apiClient.delete(`/parts/${id}`);
// };

const API_URL = 'https://1exrvm6ita.execute-api.ap-southeast-1.amazonaws.com/api/claims/estimation-module/lookup/parts';

export const fetchBrands = async (search: string = '') => {
  return axios.post(API_URL, {
    brand: search,
    model: '',
    part: '',
    dealer: '',
  });
};

export const fetchModels = async (brand: string) => {
  return axios.post(API_URL, {
    brand: brand.toLowerCase(),
    model: '',
    part: '',
    dealer: '',
  });
};

export const fetchParts = async (brand: string, model: string) => {
  return axios.post(API_URL, {
    brand: brand.toLowerCase(),
    model: model,
    part: '',
    dealer: '',
  });
};

