import {
  Container,
  Card,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  InputLabel,
  Input,
  FormHelperText,
  Grid,
  MenuItem,
  Button,
  IconButton,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserFormSkeleton from "../loadings/UserFormSkeleton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../api/FormatDate";
import React from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import { convertKeysToSnakeCase } from "../../utils/SnakeCaseConverter";
import { getCurrentUser } from "../../utils/GetCurrentUser";
import secureLocalStorage from "react-secure-storage";

export default function EditDealer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isChangePass, setIsChangePass] = React.useState<boolean>(false);
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    isLoading: false,
  });
  const [formError, setFormError] = React.useState<any>({});
  const { globalData, setGlobalData, updateGlobalData } = React.useContext(GlobalDataContext);

  const dealerName = mainState?.data?.name || "";

  const dealerToUpdate = async () => {
    setMainState({ ...mainState, isLoading: true });
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/repair-shops/${id}`,
          headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const resData = res.data.data;
            console.log(resData)
            setMainState({
              ...mainState,
              data: resData,
              isLoading: false,
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
            setMainState({ ...mainState, isLoading: false });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateDealer = async () => {
    const loggedInUser = await getCurrentUser(globalData);
    if (loggedInUser?.status === 0) {
      updateGlobalData({ role: "", username: null, idToken: null, isUserLogOut: true, lastLogin: null, lastLogout: null });
      secureLocalStorage.removeItem('username');
      secureLocalStorage.removeItem('role');
      secureLocalStorage.removeItem('idToken');
      secureLocalStorage.removeItem('lastLogin');
      secureLocalStorage.removeItem('lastLogout');
      secureLocalStorage.removeItem('status');
      alert("Deactivated user cannot perform such action.");
      const cognitoLoginUrl = process.env.COGNITO_LOGIN_PAGE || 'https://parallax-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=7eh1v67d71ttli5civvt4rm73n&response_type=code&scope=email+openid+phone+profile&redirect_uri=https%3A%2F%2Fbpims.3pparallax.solutions';
      window.location.href = cognitoLoginUrl;
      return;
    } else {
        setMainState({ ...mainState, isLoading: true });
        try {
          (async () => {
            axios({
              method: "put",
              url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/repair-shops/${id}`,
              data: mainState.data,
              headers: {
                  'Authorization': `Bearer ${globalData?.accessToken}`
              }
            })
              .then((res: any) => {
                navigate(-1);
                setMainState({...mainState,
                    isLoading: false
                })
              })
              .catch((err: any) => {
                console.error(err?.response?.data);
                let errorMessage = "";
                if (err?.response?.status === 401)
                  errorMessage = err?.response?.data;
                if (err?.response?.status === 400)
                  errorMessage = err?.response?.data;
                setMainState({ ...mainState, isLoading: false });
              });
          })();
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
  };

  const validateForm = () => {
    let isValid = true;
    const errors: any = {};

    if (mainState.data?.name === "") {
      errors.name = "Name should not be empty.";
      isValid = false;
    }

    if (mainState.data?.brand === "") {
      errors.brand = "Brand should not be empty.";
      isValid = false;
    }

    if (mainState.data?.serviceManager === "") {
      errors.serviceManager = "Service Manager should not be empty.";
      isValid = false;
    }

    if (mainState.data?.contactNo === "") {
      errors.contact = "Contact should not be empty.";
      isValid = false;
    }

    if (mainState.data?.remarks === "") {
      errors.remarks = "Remarks should not be empty.";
      isValid = false;
    }

    setFormError(errors);
    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (validateForm()) {
      updateDealer();
    }
  };

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({ ...mainState, data: { ...mainState.data, [id]: value } });
  };

  React.useEffect(() => {
    dealerToUpdate();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 12,
      }}
    >
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{
          mb: 2,
          padding: 0,
        }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 275,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "#2683e0",
                  }}
                >
                  Edit Dealer:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    ml: 2,
                    color: "black",
                  }}
                >
                  {dealerName}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#607274",
                }}
              >
                Modify dealer details
              </Typography>
            </Box>
          </Box>
          <hr />
          <br />
          <br />
          {mainState.isLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2} className="tp-mb-20px">
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Name <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="name"
                      size="small"
                      type="text"
                      placeholder={`Enter Name Here...`}
                      value={mainState?.data?.name || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "name",
                      }}
                      error={!!formError?.name}
                    />
                    {!!formError?.name && (
                      <FormHelperText error id="name">
                        {formError?.name}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Brand <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="brand"
                      size="small"
                      type="text"
                      placeholder={`Enter Brand Here...`}
                      value={mainState?.data?.brand || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "brand",
                      }}
                      error={!!formError?.brand}
                    />
                    {!!formError?.brand && (
                      <FormHelperText error id="brand">
                        {formError?.brand}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Service Manager <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="serviceManager"
                      size="small"
                      type="text"
                      placeholder={`Enter Service Manager Here...`}
                      value={mainState?.data?.serviceManager || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "serviceManager",
                      }}
                      error={!!formError?.serviceManager}
                    />
                    {!!formError?.serviceManager && (
                      <FormHelperText error id="serviceManager">
                        {formError?.serviceManager}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Contact No. <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="contactNo"
                      size="small"
                      type="text"
                      placeholder={`Enter Contact No. Here...`}
                      value={mainState?.data?.contactNo || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "contactNo",
                      }}
                      error={!!formError?.contactNo}
                    />
                    {!!formError?.contactNo && (
                      <FormHelperText error id="contactNo">
                        {formError?.contactNo}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={12}>
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1" className="tp-text-bold">
                      Remarks <span className="tp-text-required">*</span>
                    </Typography>
                    <OutlinedInput
                      id="remarks"
                      size="small"
                      type="text"
                      multiline
                      minRows={3}
                      placeholder={`Enter Remarks Here...`}
                      value={mainState?.data?.remarks || ""}
                      onChange={handleInputChange}
                      inputProps={{
                        "aria-label": "remarks",
                      }}
                      error={!!formError?.remarks}
                    />
                    {!!formError?.remarks && (
                      <FormHelperText error id="remarks">
                        {formError?.remarks}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                
              </Grid>
              <Grid container>
                <Grid item md={2}>
                  <Button type="submit" fullWidth variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
