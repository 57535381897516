import {
  Container,
  Button,
  Chip,
  CardContent,
  Card,
  Typography,
  Divider,
  IconButton,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { GlobalDataContext } from "../contexts/GlobalDataContext";
import { formatDate } from "../api/FormatDate";
import axios from "axios";
import UserManagement from "../components/user_management/UserManagement";
import LogsManagement from "../components/audit_trail_management/LogsManagement";
import { toTitleCase } from "../api/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { capitalizeAndReplaceUnderscore } from "../utils/stringUtils";
import AuditTrailTable from "../components/audit_trail_management/AuditTrailTable";
import { AuditTrailTableMainState } from "../types/AuditTrailTableMainState";
import AdminTrailTable from "../components/audit_trail_management/AdminTrailTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ViewButton({ url }: any) {
  const navigate = useNavigate();

  return (
    <Button
      color="warning"
      variant="contained"
      size="small"
      onClick={() => navigate(url)}
    >
      Edit
    </Button>
  );
}

export default function ActivityTrailPage() {
  const { globalData } = React.useContext(GlobalDataContext);
  const [rows, setRows] = React.useState<any>([]);
  //   const [columns, setColumns] = React.useState<any>([]);
  const [mainState, setMainState] = React.useState<AuditTrailTableMainState>({
    error: "",
    isLoading: false,
    pagination: {
      page: 0,
      size: 10,
      total: 0,
      searchKey: "",
    },
  });
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const convertStatus = (status: number) => {
    if (status === 1) {
      return {
        label: "Active",
        component: (
          <Chip
            label="Active"
            size="small"
            variant="outlined"
            color="success"
          />
        ),
      };
    } else if (status === 2 || status === null) {
      return {
        label: "Not Active",
        component: (
          <Chip
            label="Not Active"
            size="small"
            variant="outlined"
            color="warning"
          />
        ),
      };
    } else {
      return { label: "", component: "" };
    }
  };

  // #region getLogs
  const getLogs = async (page: number, size: number, searchKey: string) => {
    // const getLogs = async () => {
    const pageNumber = page;
    const encodedSearchKey = encodeURIComponent(searchKey);
    setMainState({ ...mainState, isLoading: true });

    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/audit-trail/pagination/search?key=${encodedSearchKey}&page=${pageNumber}&size=${size}`,
          
          // url: `${process.env.REACT_APP_BACKEND_URL}/api/audit-trail`,
          headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;
            const transformedData = data.content.map(
              (item: any, index: number) => {
                // const displayStatus = convertStatus(item.status);
                return {
                  id: item.id,
                  transId: item.transId,
                  taskType: item.taskType,
                  event: item.event,
                  description: item.description,
                  user: item.user,
                  fullName: item.fullName,
                  datetime: item.saveDate,
                };
              }
            );
            setRows(transformedData);

            setMainState({
              ...mainState,
              isLoading: false,
              pagination: {
                ...mainState.pagination,
                total: data.totalElements,
                page: page,
                size: size,
                searchKey: searchKey
              },
            });
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
            setMainState({ ...mainState, isLoading: false });
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    getLogs(
      mainState?.pagination?.page,
      mainState?.pagination?.size,
      mainState?.pagination?.searchKey
    );
    // getLogs();
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card
        sx={{
          minWidth: 650,
          mb: 4,
          padding: 2,
        }}
      >
        <CardContent>
          <Typography variant="h3" className="tp-text-header-primary">
            Audit Logs
          </Typography>
          <Typography
            sx={{
              color: "#607274",
            }}
          >
            Monitor Logs
          </Typography>
          <hr />
          <br />

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" TabIndicatorProps={{
                style: {
                  backgroundColor: 'rgb(177, 17, 22)', // This changes the indicator (bottom border) color for the active tab
                },
              }}>
                <Tab label="Transactional Logs" {...a11yProps(0)} />
                <Tab label="Admin Logs" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Box sx={{ width: "100%" }}>
                <AuditTrailTable rows={rows} mainState={mainState} setMainState={setMainState} getLogs={getLogs} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Box sx={{ width: "100%" }}>
                <AdminTrailTable rows={rows} mainState={mainState} setMainState={setMainState} getLogs={getLogs} />
              </Box>
            </CustomTabPanel>
          </Box>

        </CardContent>
        <br />
        <br />
        <Typography variant="h6" className="tp-text-header-primary">
          Login As User: &ensp;
          <span style={{ color: "#525252" }}>
            {toTitleCase(globalData?.firstName)}{" "}
            {toTitleCase(globalData?.lastName)}
          </span>
        </Typography>
      </Card>
    </Container>
  );
}
