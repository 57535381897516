import axios from "axios";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {GlobalDataContext } from "../../contexts/GlobalDataContext";

interface ClientListData {
  id?: number;
  transId?: number;
  clientNumber: string | undefined;
  clientType: string;
  surName: string;
  givenName: string;
  clientSex: string;
  civilStatus: string;
  addressLine01: string;
  addressLine02: string;
  addressLine03: string;
  addressLine04: string;
  addressLine05: string;
  postalCode: string;
  homePhone: string;
  officePhone: string;
  emailAddress: string;
  riskProfileLevel: string;
  mobilePhone: string;
  birthday: string;
  clientStatus: number;
  countryofOrigin: number;
  registrationDate: number;
  taxIdNumber: number;
  marketSector: number;
  registationNumber: number;
  validatedBy?: string;
  saveDate?: string;
  remarks?: string;
  status?: number;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function ClientDetails({ transId }: any) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [clientData, setClientData] = React.useState<ClientListData | null>(
    null
  );

  const { globalData } = React.useContext(GlobalDataContext);

  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

  const getClientLIstRecord = async () => {

    setIsLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
        {
          headers: {
                         'Authorization': `Bearer ${globalData?.accessToken}`
                       }
         }
      )
      .then((res: any) => {

        const clientDataRes = res?.data?.data;
        setClientData(clientDataRes);
        setIsLoading(false);
      })
      .catch((err: any) => {
        // if motor vehicle has policy no but not validated
        // set validate to false
        console.error(err);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getClientLIstRecord();
  }, []);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel-a-content"}
          id={"panel-a-header"}
          className={"tp-bg-lightPrimary"}
        >
          <Typography className="tp-text-bold">Client Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading !== true ? (
            <React.Fragment>
              {clientData && !isEmptyObject(clientData) ? (
               <>
               <Typography variant="body2">
                 <strong>Client Number:</strong> {clientData.clientNumber}
               </Typography>
               <Typography variant="body2">
                 <strong>Client Type:</strong> {clientData.clientType}
               </Typography>
               <Typography variant="body2">
                 <strong>Surname:</strong> {clientData.surName}
               </Typography>
               <Typography variant="body2">
                 <strong>Given Name:</strong> {clientData.givenName}
               </Typography>
               <Typography variant="body2">
                 <strong>Client Sex:</strong> {clientData.clientSex}
               </Typography>
               <Typography variant="body2">
                 <strong>Civil Status:</strong> {clientData.civilStatus}
               </Typography>
               <Typography variant="body2">
                 <strong>Address Line 01:</strong> {clientData.addressLine01}
               </Typography>
               <Typography variant="body2">
                 <strong>Address Line 02:</strong> {clientData.addressLine02}
               </Typography>
               <Typography variant="body2">
                 <strong>Address Line 03:</strong> {clientData.addressLine03}
               </Typography>
               <Typography variant="body2">
                 <strong>Address Line 04:</strong> {clientData.addressLine04}
               </Typography>
               <Typography variant="body2">
                 <strong>Address Line 05:</strong> {clientData.addressLine05}
               </Typography>
               <Typography variant="body2">
                 <strong>Postal Code:</strong> {clientData.postalCode}
               </Typography>
               <Typography variant="body2">
                 <strong>Home Phone:</strong> {clientData.homePhone}
               </Typography>
               <Typography variant="body2">
                 <strong>Office Phone:</strong> {clientData.officePhone}
               </Typography>
               <Typography variant="body2">
                 <strong>Email Address:</strong> {clientData.emailAddress}
               </Typography>
               <Typography variant="body2">
                 <strong>Risk Profile Level:</strong>{" "}
                 {clientData.riskProfileLevel}
               </Typography>
               <Typography variant="body2">
                 <strong>Mobile Phone:</strong> {clientData.mobilePhone}
               </Typography>
               <Typography variant="body2">
                 <strong>Birthday:</strong> {clientData.birthday}
               </Typography>
               <Typography variant="body2">
                 <strong>Client Status:</strong> {clientData.clientStatus}
               </Typography>
               <Typography variant="body2">
                 <strong>Country of Origin:</strong>{" "}
                 {clientData.countryofOrigin}
               </Typography>
               <Typography variant="body2">
                 <strong>Registration Date:</strong>{" "}
                 {clientData.registrationDate}
               </Typography>
               <Typography variant="body2">
                 <strong>Tax ID Number:</strong> {clientData.taxIdNumber}
               </Typography>
               <Typography variant="body2">
                 <strong>Market Sector:</strong> {clientData.marketSector}
               </Typography>
               <Typography variant="body2">
                 <strong>Registration Number:</strong>{" "}
                 {clientData.registationNumber}
               </Typography>
             </>
              ) : (
                <Typography variant="body2" color="error">
                  Client Doesn't Exist
                </Typography>
              )}
            </React.Fragment>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
