import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { convertStringToNumber, formatNumber, isValidNumber } from "../../utils/stringUtils";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useLoaDetails } from "../../contexts/LoaDetailsContext";
import { IIsipTaskValidation } from "../../types/IsipTaskValidation";
import { IPolicyAsiaState } from "../../types/PolicyAsiaState";
import React from "react";

interface IIsipPolicyAsiaProps {
  policyAsiaData: IPolicyAsiaState;
  setPolicyAsiaData: (policyAsiaData: IPolicyAsiaState) => void;
  isAmla: boolean;
  setIsAmla: (isAmla: boolean) => void;
  isCashReserved: boolean;
  setIsCashReserved: (isCashReserved: boolean) => void;
  isPaymentReceived: boolean;
  setIsPaymentReceived: (isPaymentReceived: boolean) => void;
  isipTaskValidation: IIsipTaskValidation;
  setIsipTaskValidation: (isipTaskValidation: IIsipTaskValidation) => void;
}

export default function IsipPolicyAsia({
  policyAsiaData,
  setPolicyAsiaData,
  isAmla,
  setIsAmla,
  isCashReserved,
  setIsCashReserved,
  isPaymentReceived,
  setIsPaymentReceived,
  isipTaskValidation,
  setIsipTaskValidation,
}: IIsipPolicyAsiaProps) {

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;

    let newValue = value;


    setPolicyAsiaData({ ...policyAsiaData, [id]: newValue });
  };
  const { loaDetails } = useLoaDetails();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="claimNo"
            label="Claim Number"
            type="text"
            value={
              loaDetails.loaInformation?.claimNo ||
              policyAsiaData?.claimNo ||
              ""
            }
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="clientCode"
            label="Payee Code"
            type="text"
            value={policyAsiaData?.clientCode || ""}
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="clientName"
            label="Payee Name"
            type="text"
            value={policyAsiaData?.clientName || ""}
            onChange={handleInputChange}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="grossAmount"
            label="Gross Amount"
            type="text"
            onChange={handleInputChange}
            fullWidth
            value={policyAsiaData?.grossAmount ?? 0}
            size="medium"
            required
            // defaultValue="1"
            error={
              (parseFloat(policyAsiaData?.grossAmount) !==
              convertStringToNumber(
                loaDetails.loaInformation?.inHouseEstimateAmount
              )) || !isValidNumber(policyAsiaData?.grossAmount)
            }
          />
          {parseFloat(policyAsiaData?.grossAmount) !==
            convertStringToNumber(
              loaDetails.loaInformation?.inHouseEstimateAmount
            ) ? (
            <FormHelperText error id="grossAmount">
              Gross amount in invoice does not match Gross amount in LOA
            </FormHelperText>
          ) : null}
          {!isValidNumber(policyAsiaData?.grossAmount) ? (
            <FormHelperText error id="grossAmount">
              Invalid Number
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="vatAmount"
            label="VAT Amount"
            type="text"
            // onChange={handleInputChange}
            value={policyAsiaData?.vatAmount ?? 0}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="witholdingTaxAmount"
            label="Witholding Tax Amount"
            type="text"
            onChange={handleInputChange}
            fullWidth
            size="medium"
            // defaultValue="1"
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            id="deductible"
            label="Deductible"
            type="text"
            onChange={handleInputChange}
            value={policyAsiaData?.deductible ?? 0.0}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="depreciation"
            label="Depreciation"
            type="text"
            onChange={handleInputChange}
            value={
              policyAsiaData?.depreciation ?? 0.0
            }
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="netAmount"
            label="Net Amount"
            type="text"
            onChange={handleInputChange}
            value={policyAsiaData?.netAmount ?? 0.0}
            fullWidth
            size="medium"
            required
            error={
              parseFloat(policyAsiaData?.netAmount) !==
              convertStringToNumber(
                loaDetails.loaInformation?.inHouseEstimateAmount
              ) -
              convertStringToNumber(loaDetails.loaInformation?.deductible) -
              convertStringToNumber(
                loaDetails.loaInformation?.depreciationValue
              )
            }
          />
          {parseFloat(policyAsiaData?.netAmount) !==
            convertStringToNumber(
              loaDetails.loaInformation?.inHouseEstimateAmount
            ) -
            convertStringToNumber(loaDetails.loaInformation?.deductible) -
            convertStringToNumber(
              loaDetails.loaInformation?.depreciationValue
            ) ? (
            <FormHelperText error id="totalDeductibleDepre">
              Net amount in invoice does not match Net amount in LOA
            </FormHelperText>
          ) : null}
          {parseFloat(policyAsiaData?.netAmount) >= 500000 ? (
            <FormControlLabel
              className="tp-my-20px"
              control={
                <Checkbox
                  checked={isipTaskValidation.amla}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsipTaskValidation({
                      ...isipTaskValidation,
                      amla: event.target.checked,
                    });
                  }}
                />
              }
              label={
                <Stack direction={"row"} spacing={1}>
                  <Typography>AMLA CTR</Typography>
                  <Tooltip
                    title={`For payments 500,000 and above and the AMLA CTR has been accomplished`}
                    placement="top"
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Stack>
              }
            />
          ) : null}
          {(parseFloat(policyAsiaData?.netAmount) - parseFloat(policyAsiaData?.deductible) - parseFloat(policyAsiaData?.depreciation)) >= 100000 ? (
            <FormControlLabel
              className="tp-my-20px"
              control={
                <Checkbox
                  checked={isipTaskValidation.crf}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsipTaskValidation({
                      ...isipTaskValidation,
                      crf: event.target.checked,
                    });
                  }}
                />
              }
              label={
                <Stack direction={"row"} spacing={1}>
                  <Typography>Cash Reserve Form</Typography>
                  <Tooltip
                    title={`For payments 100,000 and above`}
                    placement="top"
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip>
                </Stack>
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="totalDeductibleDepre"
            label="Total Deductible and Depreciation"
            type="text"
            onChange={handleInputChange}
            value={
              policyAsiaData?.totalDeductibleDepre ??
              0.0
            }
            fullWidth
            size="medium"
            required
            error={
              isipTaskValidation.paymentReceived === false &&
              parseFloat(policyAsiaData?.totalDeductibleDepre) >
              convertStringToNumber(
                loaDetails.loaInformation?.inHouseEstimateAmount
              ) || !isValidNumber(policyAsiaData?.totalDeductibleDepre)
            }
          />
          {isipTaskValidation.paymentReceived === false &&
            parseFloat(policyAsiaData?.totalDeductibleDepre) >
            convertStringToNumber(
              loaDetails.loaInformation?.inHouseEstimateAmount
            ) ? (
            <FormHelperText error id="totalDeductibleDepre">
              No payment received: participation fee needs to be paid first;
              mismatched amount
            </FormHelperText>
          ) : null}
          {!isValidNumber(policyAsiaData?.totalDeductibleDepre) ? (
            <FormHelperText error id="totalDeductibleDepre">
              Invalid Number
            </FormHelperText>
          ) : null}
          <FormControlLabel
            className="tp-my-20px"
            control={
              <Checkbox
                checked={isipTaskValidation.paymentReceived}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsipTaskValidation({
                    ...isipTaskValidation,
                    paymentReceived: event.target.checked,
                  });
                }}
              />
            }
            label={
              <Stack direction={"row"} spacing={1}>
                <Typography>Payment received</Typography>
                {/* <Tooltip
                    title={`For payments 100,000 and above`}
                    placement="top"
                  >
                    <HelpOutlineOutlinedIcon />
                  </Tooltip> */}
              </Stack>
            }
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            id="category"
            label="Category"
            type="text"
            fullWidth
            // defaultValue="1"
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            id="vatableSales"
            label="Vatable Sales"
            type="text"
            // onChange={handleInputChange}
            value={policyAsiaData?.vatableSales ?? 0}
            fullWidth
            size="medium"
            required
          // defaultValue="1"
          />

        </Grid>
      </Grid>
    </div>
  );
}
