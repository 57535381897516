import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  Menu,
  ListItem,
  MenuItem,
  Modal,
  Select as MuiSelect,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ActionMeta, SingleValue } from "react-select";
import ClientTransaction from "./damage_evaluation/ClientTransaction";
import ForProcessor from "./damage_evaluation/ForProcessor";
// import FieldChecklistClec from "./processor_validation/FieldChecklistClec";
import ComputationSheet from "./ComputationSheet";
import { DEDetails as DEDetailsType } from "./DEDetails";
import DocumentTable from "./DocumentTable";
import GenerateInsurer from "./GenerateInsurer";
import ComputationSheetTotalLoss from "./ComputationSheetTotalLoss";
import GenerateInsurerTotalLoss from "./GenerateInsurerTotalLoss";
import { IRepairShop } from "../types/RepairShops";
import { IUserDetail } from "../types/UserDetail";
import { IShopEstimate } from "../types/ShopEstimate";
// import EstimationTable from "./document_validation/EstimationTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";
import { GlobalData, GlobalDataContext } from "../contexts/GlobalDataContext";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../api/FormatDate";
import TaskRemarks from "./activity_logs/TaskRemarks";
import { IOpenTask } from "../types/OpenTask";
import InfoIcon from "@mui/icons-material/Info";
import DocumentViewer from "./document_validation/DocumentViewer";

// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationTask({
  // totalRepairersAmount,
  // setTotalRepairersAmount,
  // totalLaborAmount,
  // setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  // totalRepairersAmount: number;
  // setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  // setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
  // totalLaborAmount: number;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
}) {
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };

  type OptionType = { value: number; label: string };
  type CartItem = {
    id?: number;
    deId?: number;
    transId?: number;
    name: string;
    amount: number;
    qty: number;
    condition:
    | "Original"
    | "Replacement Parts"
    | "Surplus"
    | "Taiwan Parts"
    | "Not Standard Part"
    | "No Price Established"
    | "Estimated Price"
    | "Subject to Availability"
    | "As per sample"
    | "No stock"
    | "Standard operating procedure when replacing parts"
    | "";
    globalDepreciation: boolean;
    shopPrice: number;
    totalAmount: number;
    depreciation: number;
    discount: number;
    netAmount: number;
    shopAmount: boolean;
    remarks: string;
    saveDate?: string;
  };

  interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
  }

  interface IShopItemTable {
    shopEstimate: {
      totalParts: string | null;
      totalLabors: string | null;
    } | null;
    headers: string[] | null;
    rows: Object[] | null;
  }

  interface SEDetails {
    data: {
      id: number;
      transId: number;
      vendorName: string;
      customerName: string | null;
      url: string;

      totalLabor: string | null;
      totalParts: string | null;
    };
  }

  interface IRepairShopDetails {
    data: string[];
    isShow: boolean;
  }

  interface AcapTaskResponseData {
    data: {
      policyNo: string;
      caseId: string;
      // ... other fields as needed
    };
    success: boolean;
  }

  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [laborRows, setLaborRows] = React.useState<LaborRow[]>([]);
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const laborAndMaterials = laborPlusVat;
  const regularParts = totalAmount;
  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const { claimNo, docId } = location.state || {};
  const [claimNumber, setClaimNumber] = React.useState<string | null>(null);
  const [policyNo, setPolicyNo] = React.useState<string | null>(null);
  const [deFromDb, setDeFromDb] = React.useState<any>({
    data: {},
  });
  const [shopItemTable, setShopItemTable] = React.useState({
    headers: [],
    rows: [],
  });
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [totalRepairersAmount, setTotalRepairersAmount] = React.useState(0);
  const [totalLaborAmount, setTotalLaborAmount] = React.useState(0);
  const [users, setUsers] = React.useState<IUserDetail[]>([]);
  const [assignedUser, setAssignedUser] = React.useState<
    IUserDetail | undefined
  >({
    id: 0,
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    roleName: "",
  });
  const [openTasks, setOpenTasks] = React.useState<IOpenTask[]>([]);
  const [insuredName, setInsuredName] = React.useState<string | null>(null);
  const [perilType, setPerilType] = React.useState<string | null>(null);
  const [crDate, setCrDate] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openTaskHistoryAnchor = Boolean(anchorEl);
  const handleOpenTaskHistoryClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenTaskHistoryClose = () => {
    setAnchorEl(null);
  };

  const [laborData, setLaborData] = React.useState({
    rows: [],
    laborRate: 410,
    totalTimeHours: 0,
    calculatedLaborCost: 0,
    paintWorkMaterials: 0,
    totalLaborCost: 0,
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const insurerRef = React.useRef<any>(null);

  const handleExternalCall = () => {

    if (insurerRef.current) {
      insurerRef.current.generateAndUploadPdf(); // Call the exposed method
    }
  };

  const handleLaborDataChange = (newLaborData: any) => {
    setLaborData(newLaborData);
  };
  const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);
  const [deDetails, setDeDetails] = React.useState<DEDetailsType | null>(null);
  const [shopEstimate, setShopEstimate] = React.useState<SEDetails | null>(
    null
  );

  const handleUpdate = (parts: number, labor: number) => {
    setTotalParts(parts);
    setTotalLabor(labor / 1.12);
  };

  const [raEmail, setRaEmail] = React.useState<string | null>(null);

  const handleRaEmailValueChange = (newEmail: string) => {
    setRaEmail(newEmail);
  };



  const [isTotalLoss, setIsTotalLoss] = React.useState<boolean>(false);
  const [isResend, setIsResend] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState<string>("insured");

  const handleTagChange = async (event: SelectChangeEvent<string>) => {
    const newTag = event.target.value as string;
    setTag(newTag);
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, { tag: newTag },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (error) {
      console.error('Failed to update total loss:', error);
      // Optionally, handle the error (e.g., show a notification to the user)
    }
  };

  const handleIsTotalLossChange = (percentLoss: number) => {
    const newIsTotalLoss = percentLoss >= 75;
    setIsTotalLoss(newIsTotalLoss);
  };

  const [totalLoss, setTotalLoss] = React.useState<string>("Not Total Loss");

  const handleTotalLossChange = (newTotalLoss: string) => {
    setTotalLoss(newTotalLoss);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = amount * totalNumber;
    setTotalDeductibleAmount(totalDeductible);
  };

  // console.log("totalDeductibleAmount", totalDeductibleAmount);
  const handleUpdateCart = (newCart: CartItem[]) => {
    setCart(newCart);
  };

  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };

  const [repairShopDetails, setRepairShopDetails] =
    React.useState<IRepairShopDetails>({
      data: [],
      isShow: false,
    });

  const [repairShops, setRepairShops] = React.useState<string[]>([]);
  const [vendorList, setVendorList] = React.useState<IRepairShop[]>([]);

  const handleShowRepairShopDetails = () => {

    const dataFound =
      vendorList.find(
        (item: IRepairShop) => item.name === mainState?.data?.vendorName
      ) || null;

    const dataList = dataFound !== null ? dataFound.remarks.split("\n") : [];

    setRepairShopDetails({
      data: dataList,
      isShow: true,
    });
  };

  // #region fetchClaimData

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<AcapTaskResponseData>(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap-task/task/${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        const fetchedPolicyNo = response.data.data.policyNo;
        const fetchedCaseId = response.data.data.caseId;
        if (fetchedCaseId !== null && globalData?.role === "Clec") {
          setIsResend(true);
        }


        setPolicyNo(fetchedPolicyNo);

        let crData: any = {};
        try {
          const crResponse = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/lto-cr/${transId}`,
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
          if (
            Array.isArray(crResponse.data.data) &&
            crResponse.data.data.length > 0
          ) {
            crData = crResponse.data.data[0].orDate;
          } else {
            crData = crResponse.data.data.orDate;
          }
          setCrDate(crData);
        } catch (error) {
          if (
            axios.isAxiosError(error) &&
            error.response &&
            error.response.status === 400
          ) {
            crData = {}; // Make crData blank if the response status is 400
          } else {
            throw error; // Re-throw other errors
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchInsured = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/client-list?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        const fetchedInsuredName =
          response.data.data.givenName + " " + response.data.data.surName;
        setInsuredName(fetchedInsuredName);
      } catch (error) {
        console.error("Error fetching task:", error);
      }
    };

    fetchInsured();
  }, []);

  // #region fetchRepairShops
  const fetchRepairShops = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/repair-shops`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {
            const data = res.data.data;

            const tdata = data.map((el: any) => {
              return el.name;
            });
            setRepairShops(tdata);
            setVendorList(data);
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // region fetchAllImsiAdminUsers
  const fetchAllUsers = async () => {
    try {
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/available-users?userId=${globalData?.id}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const usersData: IUserDetail[] = usersResponse?.data.data;

      setUsers(usersData);

      const openTasksResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/open-task/?transId=${transId}&deId=${deId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const openTasksData: IOpenTask[] = openTasksResponse?.data?.data;
      setOpenTasks(openTasksData);


      if (openTasksData && usersData) {
        const selectedUserId = openTasksData[0].assignTo;
        const selectedUser = usersData?.find(
          (user: IUserDetail) => user?.id === Number(selectedUserId)
        );

        setAssignedUser(selectedUser);
      }
    } catch (err) {
      console.error("Error fetching Users:", err);
    }
  };

  const handleImsiAdminUserChange = (event: SelectChangeEvent<string>) => {
    const selectedUserId = event.target.value;
    const selectedUser: IUserDetail | undefined = users?.find(
      (user: IUserDetail) => user?.id === Number(selectedUserId)
    );


    setAssignedUser(selectedUser);
  };

  const assignTaskUser = async () => {
    if (assignedUser === null || assignedUser === undefined) {
      alert("Select User before submit");
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/assign`,
        {
          transId: transId,
          deId: deId,
          userId: assignedUser?.id,
          assignor: globalData.id,
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      )
      .then((res: any) => {

        alert(res?.data?.message);
      })
      .catch((err: any) => {
        alert(err?.response?.data?.message);
        console.error(err?.response?.data?.message);
      });

    const openTasksResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/open-task/?transId=${transId}&deId=${deId}`,
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      }
    );
    const openTasksData: IOpenTask[] = openTasksResponse?.data?.data;
    setOpenTasks(openTasksData);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch DE Details
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const deDetailsData: DEDetailsType = await response.json();

        setDeDetails(deDetailsData);
        setTag(deDetailsData.tag || "");
      } catch (err: any) {
        setError(err.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchAllUsers();
  }, []);

  // React.useEffect(() => {
  //   const fetchClaimData = async () => {
  //     try {
  //       const claimResponse = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`
  //       );
  //       // const claimData = claimResponse.data.data; // Removed unused variable
  //     } catch (error) {
  //       console.error("Error fetching claim data:", error);
  //     }
  //   };
  //   fetchClaimData();
  // }, []);

  React.useEffect(() => {
    const fetchShopEstimate = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        )
        .then((res: any) => {
          const data: IShopEstimate = res.data.data;

          const tblShopItemsJSON = data.tableItems
            ? JSON.parse(data.tableItems)
            : null;

          if (tblShopItemsJSON) {
            const tblShopItemRows = tblShopItemsJSON.rows.map((row: any) => {
              // Generate a unique ID for each row
              const id = uuidv4();

              // Handle the case where row is empty
              if (row.length === 0) {
                return tblShopItemsJSON.header.reduce(
                  (acc: any, key: any) => {
                    acc[key] = "";
                    return acc;
                  },
                  { id }
                ); // Include unique id in the object
              }

              return row.reduce(
                (acc: any, value: any, index: any) => {
                  acc[tblShopItemsJSON.header[index]] = value;
                  return acc;
                },
                { id }
              ); // Include unique id in the object
            });



            setShopItemTable({
              headers: tblShopItemsJSON.header,
              rows: tblShopItemRows,
            });
          }
          setTotalRepairersAmount(parseNumber(data.totalParts || "0"));
          setTotalLaborAmount(parseNumber(data.totalLabor || "0"));
          setShopEstimate(res.data);
        })
        .catch((err: any) => {

          setError(err.message || "An unexpected error occurred");
          setLoading(false);
        });
    };
    //   try {
    //     const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`);

    //     if (!response.ok) {
    //       throw new Error(`Error: ${response.status} ${response.statusText}`);
    //     }

    //     const data: SEDetails = await response.json();

    //     setTotalRepairersAmount(parseNumber(data.data.totalParts || '0'));
    //     setTotalLaborAmount(parseNumber(data.data.totalLabor || '0'));
    //     setShopEstimate(data);
    //   } catch (err: any) {
    //     setError(err.message || 'An unexpected error occurred');
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    fetchRepairShops();
    fetchShopEstimate();
  }, []);

  // #region mainstate
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    statusOptions:
      globalData?.role === "Head Clec"
        ? [
          { value: 24, label: "Approve" },
          { value: 5, label: "For Review By Clec" },
          { value: 18, label: "Pending Adjuster's Report" },
        ]
        : isTotalLoss
          ? [
            { value: 4, label: "Forward To Head CLEC" },
            { value: 27, label: "Forwarded To Head Motor Claims" },
            { value: 28, label: "Forwarded To Head Claims" },
          ]
          : [
            { value: 3, label: "Open" },
            { value: 9, label: "Pending for Offer" },
            // { value: 4, label: "Forward To Head CLEC" },
            { value: 18, label: "Pending Adjuster's Report" },
          ],
  });

  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const [totalPartsAmount, setTotalOfferAmount] = React.useState(0);
  const [totalDiscount, setTotalDiscount] = React.useState(0);
  const [vat, setVat] = React.useState(0);

  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const handleTotalOfferAmountChange = (newTotalOfferAmount: number) => {
    setTotalOfferAmount(newTotalOfferAmount);
  };

  const handleTotalDiscountChange = (newTotalDiscount: number) => {
    setTotalDiscount(newTotalDiscount);
  };

  const handleVatChange = (newVat: number) => {
    setVat(newVat);
  };

  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const { transId } = useParams();
  const { deId } = useParams();
  const navigate = useNavigate();
  const [fmv, setFmv] = React.useState<number>(0);
  const [salvage, setSalvage] = React.useState<number>(100000);

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchDeTaskData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const data = res.data.data;

      setDeFromDb({
        ...deFromDb,
        data: data,
      });
      setIsDisable(
        (globalData?.role === "Clec" && data?.status === 4) ||
        data?.submitted === 1
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const apiSubmitEmail = async (endpoint: string) => {
  //   try {
  //     await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
  //   } catch (err: any) {
  //     console.error(err?.response?.data);
  //   }
  // };

  React.useEffect(() => {
    const fetchClaimRegistrationData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration?transId=${transId}`,
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        const premiumClasses = [
          data.data.premiumClass01,
          data.data.premiumClass02,
          data.data.premiumClass03,
          data.data.premiumClass04,
          data.data.premiumClass05,
          data.data.premiumClass06,
          data.data.premiumClass07,
          data.data.premiumClass08,
          data.data.premiumClass09,
          data.data.premiumClass10,
        ];

        setClaimNumber(data.data.claimNumber);



        if (premiumClasses.includes("202")) {
          setPerilType("Own Damage");
        } else if (premiumClasses.includes("203")) {
          setPerilType("Partial Theft");
        } else if (premiumClasses.includes("209")) {
          setPerilType("Acts Of Nature");
        } else if (premiumClasses.includes("206")) {
          setPerilType("Third Party");
        } else {
          setPerilType("N/A");
        }




      } catch (error) {
        console.error("Error fetching claim registration data:", error);
      }
    };
    fetchClaimRegistrationData();
  }, []);

  const patchStatusAndUser = async (docIdFromDe: Number, approver: String) => {
    try {
      // 1. Fetch Head CLEC Users
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all`,
        {
          params: { rolename: approver },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      const headClecUsers = response.data.data;
      if (!headClecUsers || headClecUsers.length === 0) {
        throw new Error("No Head Clec users found.");
      }

      // 2. Determine the Head CLEC User ID
      const headClecUser =
        globalData?.role === approver ? 89 : headClecUsers[0].id;

      // 3. Determine Document Status
      const docStatus = mainState?.selections?.status?.value;
      if (typeof docStatus !== "number") {
        throw new Error("Invalid document status.");
      }

      // 4. First PATCH Request: Update Dedetails

      // const headclecApprove = globalData?.role === approver ? 1 : 0;
      // const headclecStatus = globalData?.role === approver ? 3 : 9;

      // axios.patch(
      //   `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
      //   {
      //     status: 25,
      //     headclecApprove: headclecApprove,
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );
      axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
        {
          status: 4,
          userId: headClecUser,
        },
        {
          params: {
            transId: transId,
            id: docIdFromDe,
          },
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${globalData?.accessToken}`
          },
        }
      );

      // 6. Navigate After Successful Updates
      navigate("/damage-evaluation");
    } catch (error: any) {
      console.error("Error in patchStatusAndUser:", error);

      // Optionally, display an error message to the user
      // For example, using a state variable to show a notification
      // setErrorMessage(error.message || 'An unexpected error occurred.');
    }
  };

  // State to handle snackbar visibility
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const [snackbarMessage, setSnackbarMessage] = React.useState("");

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 8);
  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  const handleSubmit = async () => {
    setSubmitting(true);
    if (
      (mainState?.selections?.status?.value === 4 ||
        mainState?.selections?.status?.value === 28 ||
        mainState?.selections?.status?.value === 27) &&
      globalData?.role === "Clec"
    ) {
      // alert('pasok kay head clec')
      const approver =
        mainState?.selections?.status?.value === 4
          ? "Head Clec"
          : mainState?.selections?.status?.value === 27
            ? "Head Motor Claims"
            : mainState?.selections?.status?.value === 28
              ? "Head Claims"
              : "";
      setSubmitting(false);
      handleExternalCall();
      patchStatusAndUser(docId, approver);
    } else if (
      mainState?.selections?.status?.value === 9 &&
      globalData?.role === "Clec"
    ) {
      // alert('pasok sa else if')
      setSubmitting(true);

      if (perilType === "") {
        alert("Please register the correct peril type.");
        return;
      }


      handleExternalCall();
      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );
        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update`,
            {
              "perilCode": perilType === "Own Damage" ? 202 : perilType === "Partial Theft" ? 203 : perilType === "Acts Of Nature" ? 209 : perilType === "Third Party" ? 206 : "",
              "transId": transId,
              "statusNo": 10,
              "docComplete": 1
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
        }

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending for Offer"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
        }
      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }
      // const endpoint = `/api/acap/email/ca`
      // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

      // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      //   email: emailRequest.email,
      //   claimNo: emailRequest.claimNo,
      // })
      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 18 &&
      globalData?.role === "Clec"
    ) {
      // alert('pasok sa else if')
      setSubmitting(true);

      if (perilType === "") {
        alert("Please register the correct peril type.");
        return;
      }


      handleExternalCall();
      try {
        // 1. Fetch Head CLEC Users
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 19,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: deId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update`,
            {
              "perilCode": perilType === "Own Damage" ? 202 : perilType === "Partial Theft" ? 203 : perilType === "Acts Of Nature" ? 209 : perilType === "Third Party" ? 206 : "",
              "transId": transId,
              "statusNo": 20,
              "docComplete": 1
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating docvalidation:", error);
        }

        try {
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
            {
              claimStatus: "Pending Adjuster's Report"
            },
            {
              headers: {
                'Authorization': `Bearer ${globalData?.accessToken}`
              }
            }
          );
        } catch (error) {
          console.error("Error updating claim registration:", error);
        }


      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }
      // const endpoint = `/api/acap/email/ca`
      // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

      // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      //   email: emailRequest.email,
      //   claimNo: emailRequest.claimNo,
      // })
      navigate("/damage-evaluation");
    }
    else if (
      mainState?.selections?.status?.value === 9 &&
      (globalData?.role === "Head Clec" ||
        globalData?.role === "Head Motor Claims" ||
        globalData?.role === "Head Claims")
    ) {
      // alert('pasok sa pending offer ni head clec')
      setSubmitting(true);
      // patchStatusAndUser(docId);
      handleExternalCall();
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: docId,
            },
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${globalData?.accessToken}`
            },
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
          {
            submittedBy: globalData?.firstName + " " + globalData?.lastName,
            datetimeSubmitted: formattedDateTime,
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/docvalidation/update`,
          {
            "perilCode": perilType === "Own Damage" ? 202 : perilType === "Partial Theft" ? 203 : perilType === "Acts Of Nature" ? 209 : perilType === "Third Party" ? 206 : "",
            "transId": transId,
            "statusNo": 10,
            "docComplete": 1
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );

        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/claim-registration/status/${transId}`,
          {
            claimStatus: "Pending for Offer"
          },
          {
            headers: {
              'Authorization': `Bearer ${globalData?.accessToken}`
            }
          }
        );





      } catch (error: any) {
        console.error("Error in patchStatusAndUser:", error);
      }

      navigate("/damage-evaluation");
    } else {
      navigate("/damage-evaluation");
    }
  };

  const handleSubmitResend = async () => {
    setSubmitting(true);
    handleExternalCall();

    const endpoint = `/api/acap/email/ca`;
    const emailRequest = { email: raEmail, claimNo: claimNo };

    axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
      email: emailRequest.email,
      claimNo: emailRequest.claimNo,
    },
      {
        headers: {
          'Authorization': `Bearer ${globalData?.accessToken}`
        }
      });

    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`,
        {
          submittedBy: globalData?.firstName + " " + globalData?.lastName,
          datetimeSubmitted: formattedDateTime,
          status: 1,
        },
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
    } catch (error: any) {
      console.error("Error in patchStatusAndUser:", error);
    }
    // const endpoint = `/api/acap/email/ca`
    // const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };

    // axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
    //   email: emailRequest.email,
    //   claimNo: emailRequest.claimNo,
    // })
    setSubmitting(false);
    navigate("/damage-evaluation");
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
    fetchDeTaskData();
    // fetchShopItems();
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar> */}
      <IconButton
        onClick={() => navigate(-1)}
        disableRipple={true}
        aria-label="open"
        sx={{ my: 2, padding: 0 }}
      >
        <ArrowBackIcon sx={{ height: 28, width: 28 }} />
        <Typography sx={{ ml: 1 }}>Back</Typography>
      </IconButton>
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <Typography variant="h4" className="tp-text-header-primary">
                    Damage Evaluation
                  </Typography>
                  <Typography
                    variant="body1"
                    display="block"
                    className="tp-text-input-sub"
                  >
                    Note: Please check all Required Fields with (
                    <span className="tp-text-required">*</span>) before you
                    submit.
                  </Typography>
                </Box>

                <Stack direction={"row"} spacing={1}>
                  {["Clec", "Jr Clec", "Canvasser"].includes(
                    globalData.role
                  ) && (
                      <>
                        <IconButton
                          aria-label="delete"
                          id="basic-button"
                          aria-controls={
                            openTaskHistoryAnchor ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openTaskHistoryAnchor ? "true" : undefined
                          }
                          onClick={handleOpenTaskHistoryClick}
                        >
                          <InfoIcon />
                        </IconButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openTaskHistoryAnchor}
                          onClose={handleOpenTaskHistoryClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {openTasks &&
                            openTasks.map((item: IOpenTask, idx: number) => (
                              <MenuItem
                                key={idx}
                                onClick={handleOpenTaskHistoryClose}
                              >{`Task #${item.id} is assigned to ${item.name} (${item.role})`}</MenuItem>
                            ))}
                        </Menu>
                        <FormControl>
                          <InputLabel>Assign User</InputLabel>
                          <MuiSelect
                            value={assignedUser?.id?.toString() || ""}
                            onChange={handleImsiAdminUserChange}
                            label="Assign User"
                            sx={{ width: 200, maxWidth: 300 }}
                          >
                            {users &&
                              users.map((item: IUserDetail) => (
                                <MenuItem
                                  key={item.id}
                                  value={item.id.toString()}
                                >
                                  {`${item.userName} - ${item.roleName}`}
                                </MenuItem>
                              ))}
                          </MuiSelect>
                        </FormControl>
                        <Button
                          className="tp-custom-button-primary"
                          variant="contained"
                          onClick={assignTaskUser}
                        >
                          Assign
                        </Button>
                      </>
                    )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Peril Type: <span className="tp-text-default">{perilType}</span>
              </Typography>
            </Grid>
            {isResend && (
              <Grid item>
                <Typography
                  variant="h5"
                  className="tp-text-header-error"
                  color="error"
                >
                  Resend to AW
                </Typography>
              </Grid>
            )}
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">{claimNumber}</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />
          <Box>
            <Card
              sx={{
                boxShadow: 3, // Adjust shadow intensity (1-25 or 'none')
                borderRadius: 2,
              }}
            >
              <div style={{ textAlign: "center", marginTop: "35px" }}>
                <Typography variant="body1">Transaction No.:</Typography>
                <Button
                  size="medium"
                  className="tp-custom-button-primary"
                  onClick={() =>
                    window.open(
                      `${window.location.protocol}//${window.location.host}/document-validation/${transId || deDetails?.transId}`, "_blank")
                  }
                >
                  <Typography variant="h3">
                    ACAP - {transId || deDetails?.transId}
                  </Typography>
                </Button>
                <br />
                <Typography variant="body1">
                  Peril Type:{" "}
                  <span style={{ fontWeight: "bold", color: "navy" }}>
                    {perilType}
                  </span>
                </Typography>
                <Typography variant="body1">
                  Claim No:{" "}
                  <span style={{ fontWeight: "bold", color: "navy" }}>
                    {claimNumber}
                  </span>
                </Typography>
              </div>
              <br />
              <center>

                <FormControl style={{ width: "300px" }}>
                  <InputLabel>Tag</InputLabel>
                  <MuiSelect
                    defaultValue="insured"
                    label="Tag"
                    value={tag}
                    onChange={handleTagChange}
                  >
                    <MenuItem value="insured">Insured</MenuItem>
                    <MenuItem value="tp1">TP1</MenuItem>
                    <MenuItem value="tp2">TP2</MenuItem>
                    <MenuItem value="tp3">TP3</MenuItem>
                    <MenuItem value="tp4">TP4</MenuItem>
                    <MenuItem value="tp5">TP5</MenuItem>
                  </MuiSelect>
                </FormControl>

              </center>
              <br />
              {/* <hr className="tp-border-hr_primary" /> */}
              {/* <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h5" className="tp-text-header-primary">
                Initial Estimate
              </Typography>
              <br />

              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Clec Estimate: &ensp;</span>
              </Typography>

              <UploadClec
                claimNo={claimNo}
                onUpdate={handleUpdate}
                transId={transId}
                onBrandModelUpdate={onBrandModelUpdate}
              />
              <br />
              <br />
              <div className="tp-text-bold">
                Initial Total Parts Amount: ₱ {formatNumber(initialTotalParts)}
              </div>
              <div className="tp-text-bold">
                Initial Total Labor Amount: ₱ {formatNumber(initialTotalLabor)}
              </div>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br /> */}
              <br />

              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={shopEstimate?.data.url ? 8 : 12}>
                  <br />
                  <br />

                  <div style={{ textAlign: "center" }}>
                    <Typography variant="body1">Insured:</Typography>
                    <Typography variant="h4" className="tp-text-header-primary">
                      {insuredName || ""}
                    </Typography>
                    <Typography variant="body1">
                      Policy No.:{" "}
                      <span style={{ fontWeight: "bold", color: "navy" }}>
                        {policyNo || ""}
                      </span>
                    </Typography>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <TextField
                      label="Total Parts Amount"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={formatNumber(totalRepairersAmount)}
                      onChange={(e) =>
                        handleNumberChange(e, setTotalRepairersAmount)
                      }
                      style={{ width: "500px" }}
                      margin="normal"
                    />
                    {shopEstimate?.data.url ? "" : <span>&emsp;</span>}
                    {/* Total Labor Amount */}
                    <TextField
                      label="Total Labor Amount"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={formatNumber(totalLaborAmount)}
                      onChange={(e) =>
                        handleNumberChange(e, setTotalLaborAmount)
                      }
                      style={{ width: "500px" }}
                      margin="normal"
                    />
                  </div>
                  <br />
                  <br />
                  {/* Conditionally Rendered Components */}
                  {shopEstimate && (
                    <div style={{ textAlign: "center", justifySelf: "center" }}>
                      <Typography variant="body1" className="tp-text-bold">
                        Vendor Name:
                      </Typography>
                      <Autocomplete
                        size="small"
                        disablePortal
                        freeSolo
                        style={{ width: "500px" }}
                        value={shopEstimate?.data?.vendorName}
                        options={repairShops}
                        renderInput={(params) => <TextField {...params} />}
                        onInputChange={(
                          _event: any,
                          value: string,
                          reason: string
                        ) => {

                          setMainState((prev: any) => ({
                            ...prev,
                            data: { ...prev.data, vendorName: value },
                          }));
                        }}
                      />
                      <br />
                      <Button
                        // disabled={isLoading}
                        onClick={() => {
                          handleShowRepairShopDetails();
                        }}
                        variant="contained"
                        size="medium"
                        color="error"
                        style={{ width: "300px" }}
                      >
                        See Details
                      </Button>
                      <br />
                      {/* {shopEstimate?.data?.customerName?.toLowerCase().includes('jimmy') ? (
                    <EstimationTableJimmy transID={transId} />
                  ) : (
                    <EstimationTable transID={transId} />
                  )} */}

                      {/* <EstimationTable
                    transID={transId}
                    mainState={null}
                    shopItemTable={shopItemTable}
                    setShopItemTable={setShopItemTable}
                    disabled
                  /> */}
                    </div>
                  )}
                </Grid>

                {/* Conditionally Rendered Document Viewer */}
                {shopEstimate && (
                  <Grid item xs={4} sm={4} md={shopEstimate.data.url ? 4 : 1}>
                    <DocumentViewer documentUrl={shopEstimate.data.url} />
                  </Grid>
                )}
              </Grid>
              <br />
              <br />
            </Card>
          </Box>

          {
            // #endregion Estimation Table
          }

          <hr className="tp-border-hr_primary" />
          <br />
          {
            // #region ClientTransaction
          }
          {/* Client Transaction */}
          <ClientTransaction
            transID={transId}
            deId={deId}
            tag={tag}
            data={mainState?.data}
            lossReserveParts={lossReserveParts}
            setLossReserveParts={setLossReserveParts}
            lossReserveLabor={lossReserveLabor}
            setLossReserveLabor={setLossReserveLabor}
            initialLaborAmount={initialTotalLabor}
            initialPartsAmount={initialTotalParts}
            updateGrossTotal={updateGrossTotal}
            onLaborDataChange={handleLaborDataChange}
            updateTotalDeductible={updateTotalDeductible}
            updateCart={handleUpdateCart}
            onTotalDepreciationChange={handleTotalDepreciationChange}
            onTotalDiscountChange={handleTotalDiscountChange}
            onVatChange={handleVatChange}
            onTotalOfferAmountChange={handleTotalOfferAmountChange}
            totalRepairersAmount={totalRepairersAmount}
            totalLaborAmount={totalLaborAmount}
            setTotalLaborAmount={setTotalLaborAmount}
            selectedBrand={selectedBrand}
            selectedModel={selectedModel}
            mainState={mainState}
            setMainState={setMainState}
            onIsTotalLossChange={handleIsTotalLossChange}
            onTotalLossChange={handleTotalLossChange}
            fmv={fmv}
            setFmv={setFmv}
            salvage={salvage}
            setSalvage={setSalvage}
            policyNo={policyNo}
            crDate={crDate}
            perilType={perilType}
            isResend={isResend}
          />
          {
            // #endregion ClientTransaction
          }

          {
            // #endregion Insurer Report
          }
          {
            // #region Computation Sheet
          }

          {
            // #endregion Computation Sheet
          }

          {/* <br />
          <FieldChecklistClec /> */}
          {
            // #region Document Table
          }

          {
            // #endregion Document Table
          }

          {
            // #region For Processor
          }

          <br />
          <br />
          <br />

          {
            // #endregion For Processor
          }
          <hr className="tp-border-hr_primary" />
          <br />

          <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h5"
                  className="tp-text-header-primary"
                  textAlign="center"
                >
                  CLEC Reports
                </Typography>
              </Grid>
            </Grid>
            <br />
            <br />

            {
              // #region Insurer Report
            }
            <Typography variant="h6" className="tp-text-header-primary">
              Completed In-House Survey
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {isTotalLoss ? (
              <GenerateInsurerTotalLoss
                ref={insurerRef}
                repairersParts={totalRepairersAmount}
                repairersLabor={totalLaborAmount}
                laborData={laborData}
                laborPlusVat={laborPlusVat}
                totalAmount={totalAmount}
                totalDepreciation={totalDepreciation}
                totalPartsAmount={totalPartsAmount}
                totalDiscountAmount={totalDiscount}
                vat={vat}
                totalDeductible={totalDeductibleAmount}
                ourOfferGrossTotal={ourOfferGrossTotal}
                partsCart={cart}
                transId={transId}
                deId={deId}
                salvage={salvage}
              />
            ) : (
              <GenerateInsurer
                ref={insurerRef}
                repairersParts={totalRepairersAmount}
                repairersLabor={totalLaborAmount}
                laborData={laborData}
                laborPlusVat={laborPlusVat}
                totalAmount={totalAmount}
                totalDiscountAmount={totalDiscount}
                vat={vat}
                totalDepreciation={totalDepreciation}
                totalPartsAmount={totalPartsAmount}
                totalDeductible={totalDeductibleAmount}
                ourOfferGrossTotal={ourOfferGrossTotal}
                partsCart={cart}
                transId={transId}
                deId={deId}
                perilType={perilType}
              />
            )}
            {
              // #endregion Insurer Report
            }
            {
              // #region Computation Sheet
            }
            <Typography variant="h6" className="tp-text-header-primary">
              Computation Sheet
            </Typography>
            <hr className="tp-border-hr_primary" />
            <br />
            {isTotalLoss ? (
              <ComputationSheetTotalLoss
                fmv={fmv}
                salvage={salvage}
                deductible={totalDeductibleAmount}
              />
            ) : (
              <ComputationSheet
                laborAndMaterials={laborAndMaterials + laborAndMaterials * 0.12}
                totalLabor={laborAndMaterials}
                regularParts={regularParts}
                depreciation={totalDepreciation}
                deductible={totalDeductibleAmount}
                totalLossReserve={lossReserveParts + lossReserveLabor}
                deDetails={deDetails}
                deId={deId}
              />
            )}
            <br />
            <br />
            <DocumentTable />
          </Card>
          {
            // #endregion Computation Sheet
          }
          {
            // #region For Processor
          }
          <br />
          <br />
          <br />
          {globalData.role !== "Jr Clec" || "Processor" ? (
            <ForProcessor
              mainState={mainState}
              setMainState={setMainState}
              isDisable={isDisable}
              isResend={isResend}
              handleSelectChange={handleSelectChange}
              transId={transId}
              onRaEmailValueChange={handleRaEmailValueChange}
              isTotalLoss={isTotalLoss}
            />
          ) : null}

          <TaskRemarks />
          {
            // #endregion For Processor
          }
          <hr className="tp-border-hr_primary" />
          <br />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            {globalData.role !== "Jr Clec" && globalData.role !== "Processor" && isResend === false ? (
              <Button
                disabled={isDisable || submitting}
                className="tp-custom-button-primary"
                variant="contained"
                onClick={handleSubmit}
                aria-label="submit-button"
              >
                {submitting ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            ) : null}

            {globalData.role !== "Jr Clec" && globalData.role !== "Processor" && isResend === true ? (
              <Button
                disabled={isDisable || submitting}
                className="tp-custom-button-primary"
                variant="contained"
                onClick={handleSubmitResend}
                aria-label="submit-button"
              >
                {submitting ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            ) : null}
          </Box>
          <Modal
            open={repairShopDetails.isShow}
            onClose={() =>
              setRepairShopDetails((prev: any) => ({
                data: [],
                isShow: false,
              }))
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                p: 4,
              }}
            >
              {repairShopDetails?.data.length > 0 ? (
                <React.Fragment>
                  <Typography variant="h6" className="tp-text-header-primary">
                    Negotiated Agreed Rates
                  </Typography>
                  <hr />
                  <List>
                    {repairShopDetails?.data.map((item: any) => (
                      <ListItem>{item}</ListItem>
                    ))}
                  </List>
                </React.Fragment>
              ) : (
                <Typography>
                  No details, make sure that vendor name is valid
                </Typography>
              )}
            </Box>
          </Modal>
          <br />
        </CardContent>
      </Card>
    </Container>
  );
}
