import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import axios from "axios";
import { AlertColor } from "@mui/material";

export interface INotification {
  level: AlertColor;
  showAlert: boolean;
  message: string;
};

interface INotificationState {
  level: AlertColor;
  showAlert: boolean;
  message: string; 
}

interface INotificationContext {
  notification: INotificationState;
  setNotification: React.Dispatch<React.SetStateAction<INotificationState>>;
}

const initialState: INotificationState = {
  level: "success",
  showAlert: false,
  message: "" 
};

const initialContextState: INotificationContext = {
  notification: initialState,
  setNotification: () => {}
};

export const NotificationContext = createContext<INotificationContext>(initialContextState);

type NotificationProviderProps = {
  children: ReactNode;
};

export default function NotificationStore({
  children,
}: NotificationProviderProps) {
  const [notification, setNotification] = useState<INotificationState>(initialState);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
