import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import './styles.css'; // Import your CSS
import { styled } from '@mui/material/styles';

const CircularButton = styled(Button)({
  minWidth: '0',         // Remove default min width
  width: '50px',         // Set width to make it circular
  height: '50px',        // Set height to make it circular
  borderRadius: '50%',   // Make it round
  padding: '0',          // Adjust padding if needed
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface DocumentViewerProps {
  documentUrl: any;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1.0); // Initial scale factor
  const [dragging, setDragging] = useState<boolean>(false);
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [offset, setOffset] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    if (!documentUrl) {
      console.error("Invalid document URL:", documentUrl);
    }
  }, [documentUrl]);

  const getFileType = (url: string) => {
    if (!url) {
      return '';
    }
    const extension = url.split('.').pop();
    if (extension) {
      return extension.toLowerCase();
    }
    return '';
  };

  const fileType = getFileType(documentUrl);

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.25); // Increase scale by 0.25
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.25)); // Decrease scale by 0.25, with a minimum value of 0.25
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    setPosition({ x: 0, y: 0 }); // Reset position when document changes
  }, [documentUrl]);

  const renderPdf = () => (
    <Document
      file={documentUrl}
      onLoadSuccess={({ numPages }: { numPages: number }) => setNumPages(numPages)}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          scale={scale}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  const renderImage = () => (
    <img
      src={documentUrl}
      alt="Document"
      style={{
        transform: `scale(${scale}) translate(${position.x / scale}px, ${position.y / scale}px)`,
        transformOrigin: 'top left',
        cursor: dragging ? 'grabbing' : 'grab',
      }}
    />
  );

  return (
    <div>
      <div className="pdf-viewer-controls">
      </div>
      <div
        className="pdf-container"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <CircularButton onClick={handleZoomIn} size='small' color="primary"><AddIcon fontSize="small" /></CircularButton>
          <CircularButton onClick={handleZoomOut} size='small' color="primary" disabled={scale <= 0.25}><RemoveIcon fontSize="small" /></CircularButton>
        </Box>

        {/* <Box sx={{ display: 'flex', gap: 2 }}>
          <IconButton color="primary" aria-label="ZoomIn" onClick={handleZoomIn}>
            <AddIcon />
          </IconButton>
          <IconButton color="secondary" aria-label="ZoomOut" disabled={scale <= 0.25} onClick={handleZoomOut}>
            <RemoveIcon />
          </IconButton>
        </Box> */}
        <div className="pdf-content" style={{ transform: `translate(${position.x}px, ${position.y}px)` }}>
          {fileType === 'pdf' ? renderPdf() : renderImage()}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;
