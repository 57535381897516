import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import InvoiceTable from "./InvoiceTable";
import { formatDate } from "../../utils/FormatDate";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../../css/general.css";
import NoImageFound from "../../assets/noFoundImage.json";
import {useGlobalData } from '../../contexts/GlobalDataContext';
import EmailMissing from "../EmailMissingIsip";


export default function InvoiceValidation({ transID }: any) {
  const [invoiceState, setInvoiceState] = React.useState<any>({
    data: {},
    selections: {},
    changed: {},
    formError: {},
  });

  const { globalData } = useGlobalData(); // Assuming this is how you access global data
  const isManager = globalData.role === "Isip Manager";
  const { transId } = useParams();
  //#region Private Methods



  // // Use useEffect to fetch data when the component mounts
  React.useEffect(() => {
    const statusOptions: any = {
      0: null,
      1: { value: 1, label: "Approved" },
      2: { value: 2, label: "Reject" },
    };
    // setInvoiceState({
    //   ...invoiceState,
    //   data: invoiceData[0],
    //   selections: { status: statusOptions[invoiceData[0]?.status] },
    // });
  }, []); // Empty dependency array means this effect runs only once after the initial render
//
  const fetchData = async () => {
    try {
        (async () => {
            setInvoiceState({ ...invoiceState, isLoading: true })
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_BACKEND_URL}/api/isip/invoice-claim?transId=${transId}`,
                // headers: {
                //     'Authorization': `Bearer ${globalData?.sessionToken}`
                //   }
            }).then((res: any) => {
                const data = res.data[0];
                //const attachmentsData = data['attachments']

                const statusOptions: any = {
                    0: null,
                    1: { value: 1, label: 'Approved' },
                    2: { value: 2, label: 'Reject' }
                }
                setInvoiceState({
                    ...invoiceState,
                    data: data,
                    selections: { status: statusOptions[data?.status] }
                })
            }).catch((err: any) => {
                console.error(err?.response?.data)
                let errorMessage = ""
                if (err?.response?.status === 401) errorMessage = err?.response?.data
                if (err?.response?.status === 400) errorMessage = err?.response?.data
                setInvoiceState({ error: errorMessage, isLoading: false })
            })
        })();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
React.useEffect(() => {
    fetchData();
} , [])
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    setInvoiceState({
      ...invoiceState,
      data: { ...invoiceState.data, [id]: value }, // if the value of the params change then set the id of changed variable in to true for saving in the database using onBlur.
    });
  };

  
  return (
    <div>
      <Grid container spacing={2}>

        <Grid item xs={8} sm={8} md={8}>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vendor Name <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vendorName"
                  size="small"
                  type="text"
                  placeholder={`Enter Vendor Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={invoiceState.data.vendorNameConf + "%"} size="small" className={invoiceState.data.vendorNameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={invoiceState?.data?.vendorName || ""}
                  onChange={handleInputChange}
                  className={isManager ? "readOnlyInput" : ""}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.vendorName}
                />
                {!!invoiceState.formError?.vendorName && (
                  <FormHelperText error id="vendorName">
                    {invoiceState.formError?.vendorName}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  TIN No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="tinNo"
                  size="small"
                  type="text"
                  placeholder={`Enter TIN No. Here...`}
                
                  value={invoiceState.data.tinNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("tinNo")}
                  className={isManager ? "readOnlyInput" : ""}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.tinNo}
                />
                {!!invoiceState.formError?.tinNo && (
                  <FormHelperText error id="tinNo">
                    {invoiceState.formError?.tinNo}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Customer Name <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="customerName"
              size="small"
              type="text"
              // 
              placeholder={`Enter Customer Name Here...`}
           
              value={invoiceState.data.customerName || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("customerName")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              error={!!invoiceState.formError?.customerName}
            />
            {!!invoiceState.formError?.customerName && (
              <FormHelperText error id="customerName">
                {invoiceState.formError?.customerName}
              </FormHelperText>
            )}
          </FormControl>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Invoice No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="invoiceNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice No. Here...`}
                
                  value={invoiceState.data.invoiceNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("invoiceNo")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.invoiceNo}
                />
                {!!invoiceState.formError?.invoiceNo && (
                  <FormHelperText error id="invoiceNo">
                    {invoiceState.formError?.invoiceNo}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Invoice Date <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="invoiceDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice Date Here...`}
                
                  value={invoiceState.data.invoiceDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("invoiceDate")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.invoiceDate}
                />
                {!!invoiceState.formError?.invoiceDate && (
                  <FormHelperText error id="invoiceDate">
                    {invoiceState.formError?.invoiceDate}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Plate No. <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice No. Here...`}
                 
                  value={invoiceState.data.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("invoiceNo")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  error={!!invoiceState.formError?.invoiceNo}
                />
                {!!invoiceState.formError?.invoiceNo && (
                  <FormHelperText error id="invoiceNo">
                    {invoiceState.formError?.plateNo}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Make <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="make"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice Date Here...`}
                
                  value={invoiceState.data.make || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("invoiceDate")}
                  className={`${isManager ? "readOnlyInput" : ""}`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  error={!!invoiceState.formError?.invoice_date}
                />
                {!!invoiceState.formError?.invoiceDate && (
                  <FormHelperText error id="invoiceDate">
                    {invoiceState.formError?.invoiceDate}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Type <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="type"
                  size="small"
                  type="text"
                  placeholder={`Enter Invoice Date Here...`}
                
                  value={invoiceState.data.type || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("invoiceDate")}
                  className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.invoiceDate}
                />
                {!!invoiceState.formError?.invoiceDate && (
                  <FormHelperText error id="invoiceDate">
                    {invoiceState.formError?.invoiceDate}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
         
          <InvoiceTable transID={transID} />
          {/* <SampleTable/> */}

          <Grid container spacing={2} className="tp-mb-20px tp-mt-15px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vatable Sales <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vatableSales"
                  size="small"
                  type="text"
                  placeholder={`Enter Vatable Sales Here...`}
                
                  value={invoiceState.data.vatableSales || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatableSales")}
                  className={`${isManager ? "readOnlyInput" : ""}`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
                  error={!!invoiceState.formError?.vatableSales}
                />
                {!!invoiceState.formError?.vatableSales && (
                  <FormHelperText error id="vatableSales">
                    {invoiceState.formError?.vatableSales}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Vat Amount <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="vatAmount"
                  size="small"
                  type="text"
                  placeholder={`Enter Vat Amount Here...`}
            
                  value={invoiceState.data.vatAmount || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur("vatAmount")}
                  className={`${isManager ? "readOnlyInput" : ""} `}
      
                  inputProps={{
                    "aria-label": "vendorName",
                    readOnly: isManager, 
                  }}
                  error={!!invoiceState.formError?.vatAmount}
                />
                {!!invoiceState.formError?.vatAmount && (
                  <FormHelperText error id="vatAmount">
                    {invoiceState.formError?.vatAmount}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" className="tp-text-bold">
              Total <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="totalAmount"
              size="small"
              type="text"
           
              placeholder={`Enter Total Here...`}
       
              value={invoiceState.data.totalAmount || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur("totalAmount")}
              className={`${isManager ? "readOnlyInput" : ""} tp-mb-20px`}
      
              inputProps={{
                "aria-label": "vendorName",
                readOnly: isManager, 
              }}
              error={!!invoiceState.formError?.totalAmount}
            />
            {!!invoiceState.formError?.totalAmount && (
              <FormHelperText error id="totalAmount">
                {invoiceState.formError?.totalAmount}
              </FormHelperText>
            )}
          </FormControl>
        
        </Grid>

        <Grid item xs={4} sm={4} md={4}>
          <Typography variant="h6" className="tp-text-header-primary">
            Document Summary
          </Typography>
          {invoiceState.data?.gdriveFileId ? (
            <>
              <Typography variant="body1" className="tp-mt-15px">
                Filename: &ensp;
                <span className="tp-text-primary">
                  {invoiceState.data?.fileName
                    ? invoiceState.data?.fileName
                    : "No Attachment"}
                </span>
              </Typography>
              <Typography variant="body1" className="tp-mb-15px">
                Date Received: &ensp;
                <span className="tp-text-primary">
                  {invoiceState.data?.createdAt
                    ? formatDate(invoiceState.data?.createdAt)
                    : "No Attachment"}
                </span>
              </Typography>
              <hr />
              {invoiceState.data?.fileName?.includes(".pdf") ? (
            
                <iframe
                  width={"100%"}
                  height={"750"}
                  src={`https://drive.google.com/file/d/${invoiceState.data.gdriveFileId}/preview`}
                  title="pdf"
                ></iframe>
              ) : (
                <iframe
                  width={"100%"}
                  height={"750"}
                  src={NoImageFound.image}
                  title="pdf"
                ></iframe>
              )}
            </>
          ) : (
            <>
              <img
                src={NoImageFound.image}
                width="100%"
                height="60%"
                alt="img"
              />
              <br />
              <Typography variant="h6" textAlign="center">
                No Available Document
              </Typography>
            </>
          )}
        </Grid>
        
      </Grid>
      <hr className="tp-border-hr_primary" />
      <br />
      <br />
    </div>
  );
}
