import * as React from 'react';
import TaskCard from '../components/TaskCard'
import { Container } from '@mui/material';
import TaskCardAdmin from '../components/TaskCardAdmin';

const homeData = [
    {
        "id": 1,
        "title": "User Management",
        "slug": "user-management",
        "description": "Monitor, add, edit and remove user",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 2,
        "title": "Audit Logs",
        "slug": "activity-trail",
        "description": "Monitor user activity logs",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 3,
        "title": "User Task Management",
        "slug": "user-task-management",
        "description": "Manage user tasks",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    },
    {
        "id": 4,
        "title": "Repair Shop Management",
        "slug": "repair-shop-management",
        "description": "Manage repair shops",
        "todo": 1,
        "submitted": 1,
        "rejected": 0,
        "isAvailable": true 
    }
   
]

export function HomeData() {
    const [projects, setProjects] = React.useState<any>([]);
    
    React.useEffect(() => {
        const data = homeData;
        setProjects(data);//
    },[])

    return (
        <div>
            {
                projects.map((task: any, index: number) => (
                    <TaskCardAdmin key={index} task={task}/>
                ))
            }
        </div>
    )
} 


export default function HomePage() {

    return (
        <Container maxWidth="lg" sx={{
            mt: 12
        }}>
            <HomeData/>
        </Container>
    )
}