import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useEffect } from 'react';
import { IInsurerReportData } from "../types/InsurerReport";
import { getFilenameInUrl, getFormatDate } from "../utils/stringUtils";

type InsurerReportsProps = {
    data: IInsurerReportData[] | null
}

const InsurerReportHistory: React.FC<InsurerReportsProps> = ({ data }) => {
    const [fileErrorOpen, setFileErrorOpen] = React.useState(false);

    const handleOpenDocument = (url: string) => {
        if (!url || url.trim() === "") {
            // If fileUrl is null or blank, show the error modal
            setFileErrorOpen(true);
        } else {
            // If fileUrl is valid, open it in a new tab
            window.open(url, '_blank');
        }
    };

    const handleFileErrorClose = () => {
        setFileErrorOpen(false);
    };

    return (
        <>
            <div>
                <Accordion sx={{ backgroundColor: "#1C1364", color: "white", width: "775px" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Insurer Report History
                    </AccordionSummary>
                    <AccordionDetails>
                        {data && data.map((item: IInsurerReportData) => (
                            <Grid container marginBottom={2}>
                                <Grid item xs={4} sm={4} md={4}>
                                {/* {formatDate(item.saveDate)} */}
                                    <Typography sx={{ justifySelf: "center", paddingTop: "7px" }}>{getFormatDate(item?.url)} : </Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="contained" color='warning' sx={{ backgroundColor: "white", color: "#1C1364" }} onClick={() => handleOpenDocument(item.url)}>
                                            {getFilenameInUrl(item.url)}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </div>

            <Dialog
                open={fileErrorOpen}
                onClose={handleFileErrorClose}
                aria-labelledby="file-error-dialog-title"
            >
                <DialogTitle id="file-error-dialog-title">File Error</DialogTitle>
                <DialogContent>
                    <Typography>File error. Please contact Admin.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFileErrorClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default InsurerReportHistory;