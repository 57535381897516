import {
    Accordion,
    AccordionDetails,
    Grid,
    Typography,
} from "@mui/material";
import React from "react";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { IWebFormDetails } from "../../types/WebFormDetails";
import { formatDate } from "../../api/FormatDate";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

interface WebFormDetailsProps {
    data: IWebFormDetails | null
}

export default function WebFormDetails({ data }: WebFormDetailsProps) {

    return (
        <React.Fragment>
            <Typography variant="h6" className="tp-text-header-primary">
                Web Form Details
            </Typography>
            <hr />
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"panel-a-content"}
                    id={"panel-a-header"}
                    className={"tp-bg-lightPrimary"}
                >
                    <Typography className="tp-text-bold">
                        Form Information
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {data && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Transaction ID:{" "}
                                    {`ACAP-${data?.transId}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Date Time Submitted:{" "}
                                    {formatDate(data?.submittedDate)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Insurer:{" "}
                                    {data?.insurer}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Email Address:{" "}
                                    {data?.emailAddress}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Policy Number:{" "}
                                    {data?.policyNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Mobile Number:{" "}
                                    {data?.mobile}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1">
                                    Comment:{" "}
                                    {data?.comment}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}