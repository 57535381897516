import axios from 'axios';
// import {GlobalDataContext } from "../../contexts/GlobalDataContext";



// const apiClient = axios.create({
//     baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/acap`,
//     headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${globalData.accessToken}`,
//     },
// });

export const apiClient = (accessToken: string) => {
    return axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/acap`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });
  };

interface AdditionalCosts {
    tinsmith: number;
    paintwork: number;
    materials: number;
    reform: number;
    outsideWorks: number;
    airconWorks: number;
    glassWorks: number;
    upholsteryWorks: number;
    mechanicalWorks: number;
    electricalWorks: number;
    laborAdjustment: number;
    miscellaneousCost: number;
}

// export const postLabor = (labor: any) => {
//     return apiClient.post(`/labor`, labor);
// };

export const postLabor = async (accessToken: string | null, labor: any) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    return apiClient(accessToken).post(`/labor`, labor);
}

export const deleteLaborById = (accessToken: string | null, id: number) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    return apiClient(accessToken).delete(`/labor/${id}`);
};

export const updateLaborById = async (accessToken: string | null, id: any, labor: any) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    return apiClient(accessToken).put(`/labor/${id}`, labor);
};

export const updateDedetailsByField = async (accessToken: string | null, deId: number, field: keyof AdditionalCosts, value: number) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            [field]: value.toFixed(2),
        }),
    });
    if (!response.ok) {
        throw new Error(`Failed to update ${field}`);
    }
    return response.json();
};

export const getDedetailsByDeId = async (accessToken:string | null, deId: number) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${deId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
             'Authorization': `Bearer ${accessToken}`
        },
    });
    if (!response.ok) {
        throw new Error('Failed to fetch dedetails');
    }
    return response.json();
};


export const getLaborsByDeId = async (accessToken: string | null, deId: number) => {
    if (!accessToken) {
        throw new Error('Access token is required');
      }
    const response = await apiClient(accessToken).get(`/labor?deId=${deId}`);
    return response.data;
};
