import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import * as React from "react";
import Select from "react-select";
import axios from "axios";
import MissingDocument from "../MissingDocument";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import TransferButton from "./TransferButton";
import { AuditTrailDataContext } from "../../contexts/AuditTrailContext";
import { capitalizeAndReplaceUnderscore } from "../../utils/stringUtils";

function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}
export default function ORForm({
  option,
  transID,
  disabled,
  handleBack,
  handleNext,
  close,
  userData,
  docId,
}: any) {
  const [mainState, setMainState] = React.useState<any>({
    data: {},
    oriData: {},
    selections: {},
    changed: {},
    formError: {},
    isError: false,
    attachments: [],
    isLoading: false,
  });
  const [isMissing, setIsMissing] = React.useState<boolean>(false);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const { createAuditTrailData } =
    React.useContext(AuditTrailDataContext);

  const fetchData = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/affidavit?transId=${transID}&id=${docId}`,
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        })
          .then((res: any) => {

            const data = res.data.data;
            const statusOptions: any = {
              1: { value: 1, label: "Open" },
              2: { value: 2, label: "Approve" },
              3: { value: 2, label: "Reject" },
            };
            setMainState({
              ...mainState,
              data: data,
              selections: { status: statusOptions[data?.status] },
            });

            createAuditTrailData(
              globalData!.accessToken!,{
              transId: transID,
              taskType: "document_validation",
              event: "view_form",
              description: `View affidavit form with document id #${docId}`,
              user: globalData.username,
              fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
              roleLevel: globalData.role
            })
          })
          .catch((err: any) => {
            console.error(err?.response?.data);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateData = async () => {
    try {
      setMainState((prevState: any) => ({ ...prevState, isLoading: true }));
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/affidavit?transId=${transID}&id=${docId}`,
        mainState.data,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

      const data = res.data;
      const statusOptions: any = {
        0: null,
        1: { value: 1, label: "Open" },
        2: { value: 2, label: "Approve" },
        3: { value: 3, label: "Reject" },
      };
      setMainState((prevState: any) => ({
        ...prevState,
        data: data,
        selections: { status: statusOptions[data.status] },
        formError: data?.error,
        isLoading: false,
      }));

      createAuditTrailData(
        globalData!.accessToken!,
        {
          transId: transID,
          taskType: "document_validation",
          event: "update_form",
          description: `Update affidavit form with document id #${docId}`,
          user: globalData.username,
          fullName: `${capitalizeAndReplaceUnderscore(globalData.firstName)} ${capitalizeAndReplaceUnderscore(globalData.lastName)}`,
          roleLevel: globalData.role
        }
      );
      
      close();
    } catch (error) {
      console.error("Error updating data:", error);
      setMainState((prevState: any) => ({ ...prevState, isLoading: false }));
    }
  };

  // const apiGetDocumentStatus = async () => {
  //   axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/document-validation/document-tracker/document/${transID}/Official Receipt`,
  //     // headers: {
  //     //   'Authorization': `Bearer ${globalData?.sessionToken}`
  //     // }
  //   }).then((res: any) => {

  //     setIsMissing(res.data?.status === "Missing" ? true : false);
  //   }).catch((err: any) => {
  //     console.error(err?.response?.data)
  //     let errorMessage = ""
  //     if (err?.response?.status === 401) errorMessage = err?.response?.data
  //     if (err?.response?.status === 400) errorMessage = err?.response?.data
  //   })
  // }

  // const isAnyFieldEmpty = () => {

  //   for (const key in mainState?.data) {
  //     const fieldValue = mainState?.data[key];
  //     if (key !== 'remarks' && key !== 'id' && key !== 'status' && (typeof fieldValue !== 'string' || !fieldValue.trim())) {

  //       return true;
  //     } else if (key === 'status' && (typeof fieldValue !== 'number' || fieldValue === 0)) {

  //       return true;
  //     }
  //   }
  //   return false;
  // }

  React.useEffect(() => {
    //apiGetDocumentStatus();
    fetchData();
  }, []);

  const handleInputChange = (e: any) => {
    const { value, id } = e.target;
    setMainState({
      ...mainState,
      data: { ...mainState.data, [id]: value },
    });
  };

  const handleSelectChange = (selection: any) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection.value },
      selections: { status: selection },
    });
  };

  return (
    <div>
      {isMissing === true ? (
        <MissingDocument />
      ) : (
        <>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Name of Insured<span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="name"
                  size="small"
                  type="text"
                  placeholder={`Enter Name Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.orNoConf + "%"} size="small" className={data.orNoConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.name || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('or_no')}
                  inputProps={{
                    "aria-label": "name",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.name === ""}
                />
                {(mainState?.data?.name ?? "") === "" && (
                  <FormHelperText error id="name">
                    {/* {mainState?.formError?.orNo} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth>
                <Typography variant="body1" className="tp-text-bold">
                  Register Owner Vehicle{" "}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="ownerVehicle"
                  size="small"
                  type="text"
                  placeholder={`Enter Owner Vehicle Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.owenerVehicleConf + "%"} size="small" className={data.owenerVehicleConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.ownerVehicle || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('or_date')}
                  inputProps={{
                    "aria-label": "ownerVehicle",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.ownerVehicle === ""}
                />
                {(mainState?.data?.ownerVehicle ?? "") === "" && (
                  <FormHelperText error id="ownerVehicle">
                    {/* {!!mainState?.formError?.owenerVehicle} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Make Model
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="makeModel"
                  size="small"
                  type="text"
                  placeholder={`Enter Make Model Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.makeModel || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "name",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.makeModel === ""}
                />
                {(mainState?.data?.makeModel ?? "") === "" && (
                  <FormHelperText error id="name">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Plate No.
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="plateNo"
                  size="small"
                  type="text"
                  placeholder={`Enter Plate No. Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.plateNo || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "name",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.plateNo === ""}
                />
                {mainState?.data?.plateNo === "" && (
                  <FormHelperText error id="plateNo">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h6" className="tp-text-header-primary">
            Incident Details
          </Typography>
          <hr />
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Incident Date
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="incidentDate"
                  size="small"
                  type="text"
                  placeholder={`Enter Incident Date Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.incidentDate || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "incidentDate",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.incidentDate === ""}
                />
                {(mainState?.data?.incidentDate ?? "") === "" && (
                  <FormHelperText error id="incidentDate">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Incident Place
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="incidentPlace"
                  size="small"
                  type="text"
                  placeholder={`Enter Incident Place Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.incidentPlace || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "incidentPlace",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.incidentPlace === ""}
                />
                {(mainState?.data?.incidentPlace ?? "") === "" && (
                  <FormHelperText error id="incidentPlace">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={4}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Incident Time
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="incidentTime"
                  size="small"
                  type="text"
                  placeholder={`Enter Incident Time Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.incidentTime || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "incidentTime",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.incidentTime === ""}
                />
                {mainState?.data?.plateNo === "" && (
                  <FormHelperText error id="incidentTime">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Incident Facts
              {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
              <span className="tp-text-required">*</span>
            </Typography>
            <TextareaAutosize
              id="incidentFacts"
              aria-label="incidentFacts"
              minRows={3}
              placeholder="Enter Incident Facts Here..."
              value={mainState?.data?.incidentFacts || ""}
              onChange={handleInputChange}
              style={{ width: "100%" }}
              disabled={disabled}
              className={
                mainState?.data?.incidentFacts === "" ? "error-class" : ""
              }
            />
            {(mainState?.data?.incidentFacts ?? "") === "" && (
              <FormHelperText error id="incidentFacts">
                {/* {!!mainState?.formError?.name} */}
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <Typography variant="h6" className="tp-text-header-primary">
            Property Details
          </Typography>
          <hr />
          <Grid container spacing={2} className="tp-mb-20px">
            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Property Owner
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="propertyOwner"
                  size="small"
                  type="text"
                  placeholder={`Enter Property Owner Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.propertyOwner || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "propertyOwner",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.propertyOwner === ""}
                />
                {(mainState?.data?.propertyOwner ?? "") === "" && (
                  <FormHelperText error id="propertyOwner">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl variant="outlined" fullWidth className="tp-mb-20px">
                <Typography variant="body1" className="tp-text-bold">
                  Property Address
                  {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
                  <span className="tp-text-required">*</span>
                </Typography>
                <OutlinedInput
                  id="propertyAddress"
                  size="small"
                  type="text"
                  placeholder={`Enter Property Address Here...`}
                  // endAdornment={<InputAdornment position="end">
                  //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
                  // </InputAdornment>}
                  value={mainState?.data?.propertyAddress || ""}
                  onChange={handleInputChange}
                  // onBlur={() => handleBlur('name')}
                  inputProps={{
                    "aria-label": "propertyAddress",
                  }}
                  disabled={disabled}
                  error={mainState?.data?.propertyAddress === ""}
                />
                {(mainState?.data?.propertyAddress ?? "") === "" && (
                  <FormHelperText error id="propertyAddress">
                    {/* {!!mainState?.formError?.name} */}
                    Empty required field. Please input data
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Property Damage Description
              {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}
              <span className="tp-text-required">*</span>
            </Typography>
            <OutlinedInput
              id="propertyDamagedesc"
              size="small"
              type="text"
              placeholder={`Enter Property Damage Description Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.propertyDamagedesc || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('name')}
              inputProps={{
                "aria-label": "propertyDamagedesc",
              }}
              disabled={disabled}
              error={mainState?.data?.propertyDamagedesc === ""}
            />
            {mainState?.data?.propertyDamagedesc === "" && (
              <FormHelperText error id="propertyDamagedesc">
                {/* {!!mainState?.formError?.name} */}
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>

          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Claim Participant (Comma Separated)
              {/* <span className="tp-text-subtitle">(Last Name, First Name, MI)</span>  */}

            </Typography>
            <OutlinedInput
              id="thirdParty"
              size="small"
              type="text"
              placeholder={`Enter Claim Participant Here...`}
              // endAdornment={<InputAdornment position="end">
              //   <Chip label={data.nameConf + "%"} size="small" className={data.nameConf === 100 ? "tp-chip-success" : "tp-chip-primary"} />
              // </InputAdornment>}
              value={mainState?.data?.thirdParty || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('name')}
              inputProps={{
                "aria-label": "thirdParty",
              }}
              disabled={disabled}
              error={mainState?.data?.thirdParty === ""}
            />

          </FormControl>

          <hr />

          <Typography variant="h6" className="tp-text-header-primary">
            For Processor
          </Typography>
          <hr />
          <FormControl variant="outlined" fullWidth className="tp-mb-20px">
            <Typography variant="body1" className="tp-text-bold">
              Status <span className="tp-text-required">*</span>
            </Typography>
            <Select
              id="status"
              placeholder="Please select status here..."
              value={mainState?.selections?.status || null}
              isClearable
              onChange={handleSelectChange}
              isDisabled={disabled}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Approved" },
                { value: 3, label: "Reject" },
              ]}
            />
            {mainState?.data?.status === null && (
              <FormHelperText error id="status">
                {/* {mainState?.formError?.status} */}
                Empty required field. Please input data
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" fullWidth className="tp-mb-30px">
            <Typography variant="body1" className="tp-text-bold">
              Remarks
            </Typography>
            <OutlinedInput
              id="remarks"
              size="small"
              type="text"
              multiline
              placeholder={`Enter Remarks Here...`}
              value={mainState?.data?.remarks || ""}
              onChange={handleInputChange}
              // onBlur={() => handleBlur('remarks')}
              inputProps={{
                "aria-label": "remarks",
              }}
              disabled={disabled}
            // error={!!formError?.remarks}
            />
          </FormControl>
          <hr />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {/* {option !== 3 && <Button onClick={() => handleBack()} variant="outlined" size='medium' >Back</Button>}&emsp; */}
              {disabled === true ? (
                <Button
                  onClick={() => {
                    handleNext();
                  }}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  {" "}
                  Next{" "}
                </Button>
              ) : (
                <>
                  <TransferButton
                    docId={docId}
                    transId={transID}
                    endpoint={"/api/acap/affidavit"}
                    documentType={"Affidavit"}
                    close={close}
                  />
                  &emsp;
                  <Button
                    // disabled={isAnyFieldEmpty()}
                    onClick={() => {
                      updateData();
                      // handleNext();
                    }}
                    color="error"
                    variant="contained"
                    size="medium"
                    disabled={["Clec", "Jr Clec", "Head Motor Claims", "Head Claims", "Head Clec"].includes(globalData?.role)}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
}
