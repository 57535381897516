import { Button, FormControl, MenuItem, Select, SelectChangeEvent, } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import fontkit from '@pdf-lib/fontkit';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import boldFontUrl from '../assets/arial-bold.ttf';
import regularFontUrl from '../assets/arial.ttf';
import insurerPdf from '../assets/nccdraft.pdf';
import { GlobalDataContext } from "../contexts/GlobalDataContext";

interface ResponseData {
  // Define the structure of the response data
  success: boolean;
  data?: any; // Adjust this based on the API response
}

interface DataRow {
  id: number;
  transId: number;
  saveDate: string;
  policyNo: string;
  sender: string;
  status: number;
  emailBody: string | null;
  type: string;
  submittedDate: string | null;
}

function getCurrentFormattedDate(): string {
  const currentDate = new Date();
  return currentDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
}

const GenerateNCC = ({
  policyNo,
  longName,
  sender,
  inceptionDate,
  expiryDate,
  model,
  motorMaker,
  interestDescription,
  motorNo,
  vehicleChassis,
  vehicleRegistrationNumber }: any) => {

  const [pdfBytes, setPdfBytes] = useState<Uint8Array | null>(null);
  const [isRendering, setIsRendering] = useState(false);
  const { transId } = useParams<{ transId: string }>();
  const { globalData } = React.useContext(GlobalDataContext);


  const [data, setData] = useState<DataRow[]>([]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasPositive, setCanvasPositive] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleStatusChange = (event: SelectChangeEvent<number>, params: any) => {
    const newStatus = event.target.value as number;

    // Update the row locally in the state
    setData((prevData: any) =>
      prevData.map((item: any) =>
        item.id === params.id
          ? { ...item, status: newStatus }
          : item
      )
    );

    // Send updated data to API
    const updatedRow = {
      ...params.row,
      status: newStatus,
    };
    updateData(updatedRow);
  };


  const columns: GridColDef<(typeof data)[number]>[] = [
    {
      field: 'transId',
      headerName: 'Trans ID',
      width: 100,
      editable: false,
    },
    {
      field: 'saveDate',
      headerName: 'Save Date',
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params?.value as string);
        const saveDate = !params?.value
          ? "" // Handle empty or undefined value
          : isNaN(date.getTime())
            ? "" // Handle invalid date
            : date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
        return saveDate
      },
    },
    {
      field: 'policyNo',
      headerName: 'Policy No',
      width: 150,
      editable: false,
    },
    {
      field: 'sender',
      headerName: 'Sender',
      sortable: false,
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      width: 150,
      renderCell: (params: any) => {
        return (
          <FormControl variant="outlined" size="small" sx={{ width: 400 }}>
            <Select
              value={params.value}
              onChange={(event) => handleStatusChange(event, params)}
              size="small"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ minWidth: 120 }}
              disabled={params.value === 4}
            >
              <MenuItem value={3}>Submitted</MenuItem>
              <MenuItem value={4}>Cancelled</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: 'emailBody',
      headerName: 'Email Body',
      sortable: false,
      width: 200,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      width: 100,
    },
    {
      field: 'submittedDate',
      headerName: 'Submitted Date',
      sortable: false,
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params?.value as string);
        const subDate = !params?.value
          ? "" // Handle empty or undefined value
          : isNaN(date.getTime())
            ? "" // Handle invalid date
            : date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
        return subDate
      },
    },
  ];

  React.useEffect(() => {
    if (transId) { // Only fetch data if transId is provided
      fetchData(`/api/acap/cnn-ncc-history/by-policy-no?policyNo=${policyNo}`,

      );

    }
  }, [transId]); // Include transId as a dependency

  const fetchData = (endpoint: string) => {
    setData([]); // Initialize as an empty array for DataGrid
    apiHandler(endpoint);
  };

  const apiHandler = async (endpoint: string) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      const responseData = res.data.data; // Assuming 'data' is the array of rows


      // Update state with data mapped for DataGrid
      setData(responseData.map((item: any, index: number) => ({
        id: item.id || index, // Ensure each row has a unique id
        transId: item.transId,
        saveDate: item.saveDate,
        policyNo: item.policyNo,
        sender: item.sender,
        status: item.status,
        emailBody: item.emailBody,
        type: item.type,
        submittedDate: item.submittedDate
      })));
    } catch (err: any) {
      console.error('Error fetching data:', err);
    }
  };

  const generatePdfBytes = async () => {
    try {
      const existingPdfBytes = await fetch(insurerPdf).then((res) => res.arrayBuffer());
      const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer());
      const regularFontBytes = await fetch(regularFontUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit)

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
      const regularFont = await pdfDoc.embedFont(regularFontBytes);
      const { width, height } = firstPage.getSize();

      const textDate = getCurrentFormattedDate();
      const text1 = 'This is to certify that the vehicle of ';
      const text2 = longName;
      const text3 = ' was insured with our company under ';

      const textWidth1 = regularFont.widthOfTextAtSize(text1, 10);
      const textWidth2 = boldFont.widthOfTextAtSize(text2, 10);


      firstPage.drawText(textDate, {
        x: 487,
        y: height - 121,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text1, {
        x: 66,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text2, {
        x: 66 + textWidth1,
        y: height - 231,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text3, {
        x: 66 + textWidth1 + textWidth2,
        y: height - 231,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text4 = 'Comprehensive Motor Car Policy ';
      const text5 = policyNo;
      const text6 = ' (Own Damage/ Theft and Voluntary Third Party Liability ';

      const textWidth4 = regularFont.widthOfTextAtSize(text4, 10);
      const textWidth5 = boldFont.widthOfTextAtSize(text5, 10);



      firstPage.drawText(text4, {
        x: 66,
        y: height - 242,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text5, {
        x: 66 + textWidth4,
        y: height - 242,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text6, {
        x: 66 + textWidth4 + textWidth5,
        y: height - 242,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      const text7 = 'coverage) from ';
      const text8 = inceptionDate + " to ";
      const text9 = expiryDate;
      const text10 = '. The vehicle insured is described as follows:';

      const textWidth7 = regularFont.widthOfTextAtSize(text7, 10);
      const textWidth8 = boldFont.widthOfTextAtSize(text8, 10);
      const textWidth9 = boldFont.widthOfTextAtSize(text9, 10);

      firstPage.drawText(text7, {
        x: 66,
        y: height - 253,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text8, {
        x: 66 + textWidth7,
        y: height - 253,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text9, {
        x: 66 + textWidth7 + textWidth8,
        y: height - 253,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(text10, {
        x: 66 + textWidth7 + textWidth8 + textWidth9,
        y: height - 253,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
      });


      // bullet points

      firstPage.drawText(`${model} ${motorMaker} ${interestDescription}`, {
        x: 250,
        y: height - 327,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(motorNo, {
        x: 250,
        y: height - 349,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleChassis, {
        x: 250,
        y: height - 374,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(vehicleRegistrationNumber, {
        x: 250,
        y: height - 399,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
      });

      const pdfBytes = await pdfDoc.save();
      setPdfBytes(pdfBytes);

      // Directly preview the PDF after generating it
      previewPdf(new Uint8Array(pdfBytes), 1);
    } catch (error) {
      console.error("Error generating PDF bytes:", error);
    }
  };

  const previewPdf = async (bytes: Uint8Array, pageNum: number) => {
    if (!bytes) return;

    if (isRendering) return; // Prevent overlapping render operations
    setIsRendering(true);

    const loadingTask = pdfjsLib.getDocument({ data: bytes });
    const pdf = await loadingTask.promise;
    const page = await pdf.getPage(pageNum);

    const viewport = page.getViewport({ scale: 1 });
    const canvas = canvasRef.current;
    setCanvasPositive(canvas)
    const context = canvas?.getContext('2d');

    if (canvas && context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    setIsRendering(false); // Mark rendering as completed
  };

  useEffect(() => {
    if (pdfBytes) {
      previewPdf(new Uint8Array(pdfBytes), currentPage); // Clone the pdfBytes for preview and specify the page number
    }
  }, [pdfBytes, currentPage]);

  const savePdf = () => {
    if (pdfBytes) {
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().replace(/[-:.TZ]/g, '');
      const fileName = `NCC Report_${formattedDate}.pdf`;
      saveAs(blob, fileName);
    } else {
      console.error("No PDF bytes to save.");
    }
  };

  const updateData = async (updatedData: any) => {
    try {

      const currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 8);
      const formattedDateTime = currentDateTime
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const payload = {
        transId: updatedData.transId,
        type: updatedData.type,
        saveDate: updatedData.saveDate,
        sender: updatedData.sender,
        status: updatedData.status,
        emailBody: updatedData.emailBody,
        policyNo: updatedData.policyNo,
        submittedDate: formattedDateTime,
      };

      const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history?Id=${updatedData.id}`, payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );

    } catch (err: any) {
      console.error(`Error updating data for Trans ID ${updatedData.transId}:`, err);
    }
  };

  const submitData = async (updatedData: any) => {
    try {

      const currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 8);
      const formattedDateTime = currentDateTime
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const payload = {
        transId: transId,
        type: "NCC",
        saveDate: formattedDateTime,
        sender: sender,
        status: 3,
        policyNo: policyNo,
        submittedDate: formattedDateTime,
      };

      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/acap/cnn-ncc-history`, payload,
        {
          headers: {
            'Authorization': `Bearer ${globalData?.accessToken}`
          }
        }
      );
      savePdf()
    } catch (err: any) {
      console.error(`Error updating data for Trans ID ${updatedData.transId}:`, err);
    }
  };

  return (
    <div>
      <form>

        <Button variant="contained" onClick={generatePdfBytes} sx={{ width: 300 }}>
          Preview PDF
        </Button>
      </form>
      <canvas ref={canvasRef} style={{ border: '1px solid black', marginTop: '20px', marginBottom: '20px' }}></canvas>

      <Box sx={{ height: 320, width: '100%' }}>
        <DataGrid
          rows={data} // Pass the updated data state here
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          disableColumnMenu={true}
        />
      </Box>
      <p style={{ color: "red" }}>{!canvasPositive ? "Please preview the pdf at the top..." : ""}</p>
      <Button disabled={!canvasPositive} variant="contained" onClick={submitData} sx={{ float: "right" }}>
        Submit and Save PDF
      </Button>
    </div>
  );
};

export default GenerateNCC;
