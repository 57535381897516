import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Dispatch } from "react";
import { GlobalDataContext } from "../../contexts/GlobalDataContext";
import ListOfLaborsLink from "./ListOfLaborsLink";
import ListOfPartsLink from "./ListOfPartsLink";

type CartItem = {
  id?: number; // Make id optional because it will be assigned by the backend
  deId?: string;
  transId?: string;
  name: string;
  amount: number;
  qty: number;
  price: number;
  saveDate?: string;
};

interface LaborRow {
  id?: number;
  scopeOfWorks: string;
  repairCondition: string;
  repair: number;
  replace: number;
  painting: number;
  saveDate?: string;
  transId?: string;
}

export interface ItemCoverage {
  itemCoverage: string;
  sumInsuredperItemCoverage: number;
}

export interface ApiData {
  deductible: number | null;
  itemCoverages: string; // This is a JSON string
  // ... include other fields if needed
}

export interface ApiResponse {
  message: string;
  data: ApiData;
  status: boolean;
  errors: any;
  success: boolean;
}

interface ClientTransactionProps {
  updateGrossTotal?: (totalAmount: number, laborPlusVat: number) => void;
  updateTotalDeductible?: (
    deductible: number,
    deductibleAmount: number
  ) => void;
  transID?: any;
  data?: any;
  totalRepairersAmount?: any;
  initialLaborAmount?: any;
  initialPartsAmount?: any;
  totalLaborAmount?: any;
  onTotalDepreciationChange: (totalDepreciation: number) => void;
  onTotalOfferAmountChange: (totalOfferAmount: number) => void;
  updateCart: (newCart: CartItem[]) => void;
  setTotalLaborAmount?: (amount: number) => void;
  lossReserveParts: number;
  onLaborDataChange: (laborData: any) => void;
  setLossReserveParts: (amount: number) => void;
  selectedBrand: string | null;
  selectedModel: string | null;
  lossReserveLabor: number;
  onIsTotalLossChange: (percentLoss: number) => void;
  setLossReserveLabor: (amount: number) => void;
  mainState: any;
  setMainState: Dispatch<any>;
  fmv: number;
  setFmv: React.Dispatch<React.SetStateAction<number>>;
  salvage: number;
  setSalvage: (value: number) => void;
}

const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
//

const ClientTransactionLink: React.FC<ClientTransactionProps> = ({
  updateGrossTotal,
  updateTotalDeductible,
  transID,
  data,
  onLaborDataChange,
  totalRepairersAmount,
  initialLaborAmount,
  initialPartsAmount,
  totalLaborAmount,
  updateCart,
  onTotalDepreciationChange,
  onTotalOfferAmountChange,
  setTotalLaborAmount,
  lossReserveParts,
  setLossReserveParts,
  lossReserveLabor,
  selectedBrand,
  onIsTotalLossChange,
  selectedModel,
  setLossReserveLabor,
  mainState,
  setMainState,
  salvage,
  setSalvage,
  fmv,
  setFmv
}) => {
  // const handleLaborRowsChange = (newRows: LaborRow[]) => {
  //   onLaborRowsChange(newRows);
  // };
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [deductible, setDeductible] = React.useState<number>(1);
  const [deductibleAmount, setDeductibleAmount] = React.useState<number>(0);
  const [totalSumInsured, setTotalSumInsured] = React.useState<number>(0);
  const [totalLoss, setTotalLoss] = React.useState<string>("Actual Loss");
  const [totalDepreciation, setTotalDepreciation] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  // const [fmv, setFmv] = React.useState<number>(0); // Default value set to 0

  const handleChangeFmv = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = Number(value.replace(/,/g, '')); // Remove commas if formatted
    if (!isNaN(numberValue) && numberValue >= 0) {
      setFmv(numberValue);
    }
  };

  // Default value set to 0

  const handleChangeSalvage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalvage(Number(event.target.value));
  };

  const [totalLossReserve, setTotalLossReserve] = React.useState<string>("100000.00");  // Default value set to 0.0

  const handleChangeTotalLossReserve = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Regular expression to allow only numbers and one decimal point
    const validNumberRegex = /^[0-9]*\.?[0-9]*$/;

    // Allow only valid input (numbers and a single decimal point)
    if (validNumberRegex.test(inputValue)) {
      setTotalLossReserve(inputValue);
    }
  };

  const handleBlur = () => {
    // On blur, format the number
    const numberValue = parseFloat(totalLossReserve) || 0.0;
    setTotalLossReserve(formatNumber(numberValue));
  };

  const [manualOverrideParts, setManualOverrideParts] =
    React.useState<boolean>(false);
  const [manualOverrideLabor, setManualOverrideLabor] =
    React.useState<boolean>(false);

  const formatNumber = (value: number | null | undefined) => {
    if (value == null) {
      return '';
    }
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  React.useEffect(() => {
    if (isTotalLoss) {
      const fetchSalvageValue = async () => {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transID}`);
          // Assuming the API returns { data: { salvageValue: number } }
          const fetchedSalvage = response.data.salvageValue;
          setSalvage(fetchedSalvage);
        } catch (err) {
          console.error('Error fetching salvage value:', err);
          setError('Failed to fetch salvage value.');
        } finally {
          setLoading(false);
        }
      };

      fetchSalvageValue();
    }
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/acap/policy-asia?transId=${transID}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: ApiResponse = await response.json();

        if (data.status && data.data) {
          const { deductible, itemCoverages } = data.data; // Changed here

          // Set deductibleAmount if available
          if (deductible !== null && deductible !== undefined) { // Changed here
            setDeductibleAmount(deductible); // Changed here
          }
          // Parse itemCoverages and set totalSumInsured for itemCoverage "202"
          if (itemCoverages) {
            const itemCoveragesArray: ItemCoverage[] = JSON.parse(itemCoverages);
            const coverage202 = itemCoveragesArray.find(
              (item) => item.itemCoverage === "202"
            );
            if (coverage202) {
              setTotalSumInsured(coverage202.sumInsuredperItemCoverage);
              if (fmv === 0) {
                // alert('FMV is 0');
                // alert(coverage202.sumInsuredperItemCoverage);
                setFmv(coverage202.sumInsuredperItemCoverage);
              }
            } else {
              console.warn('ItemCoverage "202" not found.');
            }
          }
        } else {
          setError(data.message || 'Failed to retrieve data.');
        }
      } catch (err: any) {
        console.error('Error fetching data:', err);
        setError(err.message || 'An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  React.useEffect(() => {
    if (updateGrossTotal) updateGrossTotal(totalAmount, laborPlusVat);
    if (updateTotalDeductible)
      updateTotalDeductible(deductible, deductibleAmount);
  }, [
    totalAmount,
    laborPlusVat,
    updateGrossTotal,
    deductible,
    deductibleAmount,
    updateTotalDeductible,
  ]);



  const handleDepreciationChange = (value: string) => {
    const newDepreciation = parseFloat(value);
    setTotalDepreciation(newDepreciation);
  };

  const handleTotalAmountPartsChange = (value: string) => {
    const newTotalAmount = parseFloat(value);
    setTotalAmount(newTotalAmount);
    // Only update lossReserveParts if it hasn't been manually changed
    if (!manualOverrideParts && newTotalAmount > 40000) {
      setLossReserveParts(newTotalAmount);
    } else if (!manualOverrideParts && newTotalAmount < 40000) {
      setLossReserveParts(40000);
    }
  };

  const handleLossReservePartsChange = (value: string) => {
    const newLossReserveParts = parseFloat(value);
    setLossReserveParts(newLossReserveParts);
    setManualOverrideParts(true); // Mark that the user has manually changed the value
  };

  const handleTotalAmountLaborChange = (value: any) => {
    const newTotalAmount = parseFloat(value);
    setLaborPlusVat(newTotalAmount);
    // Only update lossReserveLabor if it hasn't been manually changed
    if (!manualOverrideLabor && newTotalAmount > 20000.0) {
      setLossReserveLabor(newTotalAmount);
    } else if (!manualOverrideLabor && newTotalAmount < 20000.0) {
      setLossReserveLabor(20000.0);
    }
  };

  const handleLossReserveLaborChange = (value: string) => {
    const newLossReserveLabor = parseFloat(value);
    setLossReserveLabor(newLossReserveLabor);
    setManualOverrideLabor(true); // Mark that the user has manually changed the value
  };



  const handleDeductibleAmountChange = (value: string) => {
    setDeductibleAmount(Number(value));
  };

  const handleTotalSumInsuredChange = (newSum: string) => {
    setTotalSumInsured(Number(newSum));
  };

  const handleTotalLossChange = (event: SelectChangeEvent<string>) => {
    setTotalLoss(event.target.value as string);
  };

  const totalPartsAndLabors = totalAmount + laborPlusVat;
  const difference = totalRepairersAmount! - totalAmount;

  const differenceLabor = totalLaborAmount! - laborPlusVat;

  // Computations for Gross Totals
  const initialGrossTotal = initialLaborAmount! + initialPartsAmount!;
  const repairersGrossTotal = totalRepairersAmount! + totalLaborAmount!;
  const ourOfferGrossTotal = totalAmount + laborPlusVat;
  const differenceGrossTotal = repairersGrossTotal - ourOfferGrossTotal;

  // Computations for VAT
  const initialLaborVat = initialLaborAmount! * 0.12;
  const repairersVat = totalLaborAmount! * 0.12;
  const ourOfferVat = laborPlusVat * 0.12;
  const differenceVat = repairersVat - ourOfferVat;

  // Computations for Gross Amounts
  const initialGrossAmount = initialGrossTotal + initialLaborVat;
  const repairersGrossAmount = repairersGrossTotal + repairersVat;
  const ourOfferGrossAmount = ourOfferGrossTotal + ourOfferVat;
  const differenceGrossAmount = repairersGrossAmount - ourOfferGrossAmount;

  // Computation for Diff%
  const calculateDiffPercentage = (difference: number, base: number) =>
    base !== 0 ? (difference / base) * 100 : 0;
  const diffPercentageParts = calculateDiffPercentage(
    difference,
    totalRepairersAmount!
  );
  const diffPercentageLabor = calculateDiffPercentage(
    differenceLabor,
    totalLaborAmount!
  );
  const diffPercentageGrossTotal = calculateDiffPercentage(
    differenceGrossTotal,
    repairersGrossTotal
  );
  const diffPercentageVat = calculateDiffPercentage(
    differenceVat,
    repairersVat
  );
  const diffPercentageGrossAmount = calculateDiffPercentage(
    differenceGrossAmount,
    repairersGrossAmount
  );

  // #region Computation for % Loss
  const percentLoss =
    totalSumInsured !== 0 ? (ourOfferGrossAmount / totalSumInsured) * 100 : 0;

  const isTotalLoss = percentLoss >= 75;

  React.useEffect(() => {
    onIsTotalLossChange(percentLoss);
  }, [percentLoss, onIsTotalLossChange]);


  const totalDeductible = 1 * deductibleAmount;

  const grossOffer = ourOfferGrossAmount - totalDeductible;

  // #region Tracking of totalPartsAndLabors and percentLoss
  React.useEffect(() => {
    const updateStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const deDetailsData = await response.json();

        const offerAmount = totalPartsAndLabors;
        const lossPercent = percentLoss;




        if (!isNaN(offerAmount) && !isNaN(lossPercent)) {
          // if total offer amount > 300,000 or loss percent >= 75

          if ((offerAmount > 300000 || lossPercent >= 75) && (deDetailsData.headclecApprove === null || deDetailsData.headclecApprove === 0)) {
            const selection =
              globalData?.role === "Head Clec"
                ? { value: 9, label: "Pending for Offer" }
                : { value: 4, label: "Forward To Head CLEC" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec"
                  ? [
                    { value: 3, label: "Approve" },
                    { value: 9, label: "Pending for Offer" },
                    { value: 3, label: "Reject" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : [{ value: 4, label: "Forward To Head CLEC" }
                    // { value: 9, label: "Pending for Offer" }
                  ],
            });

            // for total loss
            setTotalLoss("Actual Loss");
          } else if ((offerAmount > 300000 || lossPercent >= 75) && deDetailsData.headclecApprove === 1) {
            const selection =
              globalData?.role === "Head Clec"
                ? { value: 3, label: "Approve" }
                : { value: 4, label: "Forward To Head CLEC" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec"
                  ? [
                    { value: 3, label: "Approve" },
                    { value: 5, label: "For Review By Clec" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : [{ value: 4, label: "Forward To Head CLEC" },
                  { value: 9, label: "Pending for Offer" }
                  ],
            });

            // for total loss
            setTotalLoss("Actual Loss");
          } else {
            // for status
            const selection =
              globalData?.role === "Head Clec"
                ? { value: 3, label: "Approve" }
                : { value: 3, label: "Open" };
            setMainState({
              ...mainState,
              data: { ...mainState.data, status: selection?.value },
              selections: { status: selection },
              statusOptions:
                globalData?.role === "Head Clec"
                  ? [
                    { value: 3, label: "For Review by CLEC" },
                    { value: 9, label: "Pending for Offer" },
                    { value: 3, label: "Reject" },
                    { value: 18, label: "Pending Adjuster's Report" },
                  ]
                  : isTotalLoss
                    ? [
                      { value: 18, label: "Pending Adjuster's Report" },
                      { value: 4, label: "Forward To Head CLEC" },
                    ]
                    : [
                      { value: 3, label: "Open" },
                      { value: 9, label: "Pending for Offer" },
                      { value: 18, label: "Pending Adjuster's Report" },

                    ],
            });
            // for total loss
            setTotalLoss("Not Total Loss");
          }
        }
      } catch (error) {
        console.error("Error fetching deDetailsData:", error);
        // Optionally, set an error state here
      }

    };
    updateStatus();
  }, [initialPartsAmount, percentLoss]);

  return (
    <div>
      <Typography variant="h6" className="tp-text-header-primary">
        Our Offer Estimate
        <Typography
          variant="caption"
          display="block"
          className="tp-text-subtitle"
        >
          Note: These are the the parts and labors that we will offer to the
          client.
        </Typography>
      </Typography>
      <hr className="tp-border-hr_primary" />
      <br />
      {/* 
        // #region ListOfParts
      */}
      <Typography variant="h6" className="tp-text-header-primary">
        List of Parts
      </Typography>
      <br />
      <ListOfPartsLink
        onTotalAmountChange={handleTotalAmountPartsChange}
        onTotalDepreciationChange={onTotalDepreciationChange}
        onTotalOfferAmountChange={onTotalOfferAmountChange}
        updateCart={updateCart}
        initialBrand={selectedBrand}
        initialModel={selectedModel}
      // onTotalDepreciationChange={setTotalDepreciation}
      />
      <br /><hr className="tp-border-hr_dark" /><br /><br />
      {/* 
        // #region ListOfLabors
      */}
      <Typography variant="h6" className="tp-text-header-primary">
        List of Labors
      </Typography>
      <ListOfLaborsLink
        onLaborPlusVatChange={handleTotalAmountLaborChange}
        transId={transID}
        onLaborDataChange={onLaborDataChange}
      />
      <hr className="tp-border-hr_primary" />
      <br /> <br />
      <Card style={{ width: "100%", marginTop: "15px", padding: "20px" }}>
        <Typography
          variant="h5"
          textAlign="center"
          className="tp-text-header-primary"
        >
          Cost of Claims
        </Typography>
        <br /><br />
        <Grid container spacing={2}>
          <Grid item md={3}>
            {/* <TextField
            label="Deductible"
            variant="outlined"
            type="number"
            value={deductible}
            onChange={(e) => handleDeductibleChange(e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          </Grid>
          <Grid item md={3}>
            <TextField
              label="Deductible Amount"
              variant="outlined"
              type="text"
              value={formatNumber(deductibleAmount)}
              onChange={(e) => handleDeductibleAmountChange(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <br /><br />
        <Grid container spacing={2}>
          <Grid item md={3} />
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Initial Estimate
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Repairer's
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Our Offer
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-header-primary">
              Difference
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" className="tp-text-header-primary">
              Diff%
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">Parts:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialPartsAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalRepairersAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(totalAmount)}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(difference)}</Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageParts.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">Total Labor:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialLaborAmount!)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalLaborAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(laborPlusVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(differenceLabor)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageLabor.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              Gross Total:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(initialGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(repairersGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(ourOfferGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" color="error" className="tp-text-bold">
              ₱ {formatNumber(differenceGrossTotal)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" className="tp-text-bold">
              {diffPercentageGrossTotal.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1">
              + VAT (Total Labour only) 12.00/12.00%:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(initialLaborVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(repairersVat)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">₱ {formatNumber(ourOfferVat)}</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(differenceVat)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1">
              {diffPercentageVat.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Typography variant="body1" className="tp-text-bold">
              Gross Amount:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-bold">
              ₱ {formatNumber(initialGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-bold">
              ₱ {formatNumber(repairersGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-bold">
              ₱ {formatNumber(ourOfferGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-bold">
              ₱ {formatNumber(differenceGrossAmount)}
            </Typography>
          </Grid>
          <Grid item md={1}>
            <Typography variant="body1" className="tp-text-bold">
              {diffPercentageGrossAmount.toFixed(2)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography variant="body1">- Deductible:</Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1">
              ₱ {formatNumber(totalDeductible)}
            </Typography>
          </Grid>
        </Grid>

        <hr className="tp-border-hr_dark" />
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Typography variant="body1" className="tp-text-bold">
              Approve Gross Offer:
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body1" className="tp-text-bold">
              ₱ {formatNumber(grossOffer)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <br /><br />
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Typography variant="body1" className="tp-text-bold">
            Total Sum Insured:
          </Typography>
        </Grid>
        <Grid item md={4}>
          <TextField
            label="Total Sum Insured"
            variant="outlined"
            type="text"
            value={formatNumber(totalSumInsured)}
            onChange={(e) => handleTotalSumInsuredChange(e.target.value)}
            fullWidth
            margin="normal"
          />
          {/* <TextField
                                label="Total Parts Amount"
                                variant="outlined"
                                type="text"
                                value={formatNumber(totalRepairersAmount)}
                                onChange={(e) => handleNumberChange(e, setTotalRepairersAmount)}
                                fullWidth
                                margin="normal"
                            /> */}
        </Grid>
        <Grid item md={4}>
          <TextField
            label="% Loss"
            variant="outlined"
            type="number"
            value={percentLoss.toFixed(2)} // Display value with two decimal places
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={3}>
          <Typography variant="body1" className="tp-text-bold">
            Total Sum Insured:
          </Typography>
        </Grid>
        <Grid item md={8}>

          <FormControl fullWidth margin="normal">
            <InputLabel>Total Loss</InputLabel>
            <Select
              value={totalLoss}
              onChange={handleTotalLossChange}
              label="Total Loss"
            >
              <MenuItem value="Not Total Loss">Not Total Loss</MenuItem>
              <MenuItem value="Actual Loss">Actual Loss</MenuItem>
              <MenuItem value="Constructive Loss">Constructive Loss</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <br />
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Typography variant="body1" className="tp-text-bold">
            Loss Reserve:
          </Typography>
        </Grid>
        <Grid item md={isTotalLoss ? 3 : 4}>
          <TextField
            label="Loss Reserve - Parts"
            variant="outlined"
            type="text"
            value={formatNumber(lossReserveParts)}
            onChange={(e) => handleLossReservePartsChange(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item md={isTotalLoss ? 3 : 4}>
          <TextField
            label="Loss Reserve - Labor"
            variant="outlined"
            type="text"
            value={formatNumber(lossReserveLabor)}
            onChange={(e) => handleLossReserveLaborChange(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Grid>

        {isTotalLoss && (
          <Grid item md={3}>
            <TextField
              label="Loss Reserve - Total Loss"
              variant="outlined"
              type="text" // Keep it as text to show formatted numbers
              value={formatNumber(parseFloat(totalLossReserve)) || '0'} // Show the value directly from the state
              onChange={handleChangeTotalLossReserve}
              //  onBlur={handleBlur} // Format the number when the user moves out of the input
              fullWidth
              margin="normal"
              inputProps={{ step: 0.01, min: 0 }}
            />
          </Grid>
        )}
      </Grid>
      {
        // #region Total Loss
      }
      <Typography variant="body1" color="primary" className="tp-text-bold">
        Total Loss:
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={isTotalLoss ? 3 : 12}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Total Loss</InputLabel>
            <Select
              size="small"
              value={totalLoss}
              onChange={handleTotalLossChange}
              label="Total Loss"
            >
              <MenuItem value="Not Total Loss">Not Total Loss</MenuItem>
              <MenuItem value="Actual Loss">Actual Loss</MenuItem>
              <MenuItem value="Constructive Loss">Constructive Loss</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {isTotalLoss && (
          <Grid item md={3}>
            <TextField
              label="Fair Market Value"
              variant="outlined"
              type="text"
              value={formatNumber(fmv)}
              onChange={handleChangeFmv}
              fullWidth
              margin="normal"
            />
          </Grid>
        )}

        {isTotalLoss && (
          <Grid item md={3}>
            <TextField
              label="Salvage Value"
              variant="outlined"
              type="text"
              value={formatNumber(salvage)}
              onChange={handleChangeSalvage}
              fullWidth
              margin="normal"
            />
          </Grid>
        )}
      </Grid>
      {
        // #endregion Total Loss
      }
      <br />
      <hr className="tp-border-hr_primary" />
      <br />
    </div>
  );
};

export default ClientTransactionLink;
