import * as React from "react";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Box,
  Button,
  TextField,
  FormControl,
  CircularProgress
} from '@mui/material';
import Select, { ActionMeta, SingleValue } from 'react-select';
import EstimationTable from './damage_evaluation/EstimationTable';
import ClientTransactionLink from './damage_evaluation/ClientTransactionLink';
import ForProcessor from './damage_evaluation/ForProcessor';
import NoImageFound from '../assets/noFoundImage.json';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { formatDate } from '../api/FormatDate';
import axios from 'axios';
import { GlobalDataContext } from '../contexts/GlobalDataContext';
import { v4 as uuidv4 } from "uuid";
import GenerateInsurer from './GenerateInsurer';
import ComputationSheet from "./ComputationSheet";
import GenerateInsurerTotalLoss from "./GenerateInsurerTotalLoss";
import ComputationSheetTotalLoss from "./ComputationSheetTotalLoss";
import DocumentTable from "./DocumentTable";
import TaskRemarks from "./activity_logs/TaskRemarks";
import { DEDetails as DEDetailsType } from "./DEDetails";


// Utility function
function toTitleCase(str: string | undefined | null): string | undefined {
  if (!str) {
    return str as undefined;
  }

  return str.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export default function DamageEvaluationLink({
  // totalRepairersAmount,
  // setTotalRepairersAmount,
  // totalLaborAmount,
  // setTotalLaborAmount,
  selectedBrand,
  selectedModel,
  onBrandModelUpdate,
}: {
  // totalRepairersAmount: number;
  // setTotalRepairersAmount: React.Dispatch<React.SetStateAction<number>>;
  // totalLaborAmount: number;
  // setTotalLaborAmount: React.Dispatch<React.SetStateAction<number>>;
  selectedBrand: string | null;
  selectedModel: string | null;
  onBrandModelUpdate: (brand: string | null, model: string | null) => void;
 
}) {
  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    if (!isNaN(parsedValue)) {
      setter(parsedValue);
    }
  };
  type OptionType = { value: number; label: string };
  type CartItem = {
    id?: number; // Make id optional because it will be assigned by the backend
    deId?: string;
    transId?: string;
    name: string;
    amount: number;
    qty: number;
    price: number;
    saveDate?: string;
  };

  interface LaborRow {
    id?: number;
    scopeOfWorks: string;
    repairCondition: string;
    repair: number;
    replace: number;
    painting: number;
    saveDate?: string;
    transId?: string;
  }

  interface IShopItemTable {
    shopEstimate: {
      totalParts: string | null;
      totalLabors: string | null;
    } | null;
    headers: string[] | null;
    rows: Object[] | null;
  }

  const [ourOfferGrossTotal, setOurOfferGrossTotal] = React.useState<number>(0);
  const [totalDeductibleAmount, setTotalDeductibleAmount] =
    React.useState<number>(0);
  const [totalAmount, setTotalAmount] = React.useState<number>(0);
  const [laborPlusVat, setLaborPlusVat] = React.useState<number>(0);
  const [cart, setCart] = React.useState<CartItem[]>([]);
  const laborAndMaterials = laborPlusVat;
  const regularParts = totalAmount;
  const [lossReserveParts, setLossReserveParts] = React.useState<number>(0);
  const [lossReserveLabor, setLossReserveLabor] = React.useState<number>(0);
  const location = useLocation();
  const { claimNo, docId } = location.state || {};
  const [deFromDb, setDeFromDb] = React.useState<any>({
    data: {},
  });
  const [shopItemTable, setShopItemTable] = React.useState<IShopItemTable>();
  const { globalData, setGlobalData } = React.useContext(GlobalDataContext);
  const [laborData, setLaborData] = React.useState({
    rows: [],
    laborRate: 410,
    totalTimeHours: 0,
    calculatedLaborCost: 0,
    paintWorkMaterials: 0,
    totalLaborCost: 0,
  });
  const [deDetails, setDeDetails] = React.useState<DEDetailsType | null>(null);
  const { deId } = useParams();

  const [totalRepairersAmount, setTotalRepairersAmount] = React.useState(3900.0);
  const [totalLaborAmount, setTotalLaborAmount] = React.useState(5000.0);

 
  const insurerRef = React.useRef<any>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleExternalCall = () => {

    if (insurerRef.current) {
      insurerRef.current.generateAndUploadPdf(); // Call the exposed method
    }
  };

  const handleLaborDataChange = (newLaborData: any) => {
    setLaborData(newLaborData);
  };

  const formatNumber = (value: number) => {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const parseNumber = (value: string) => {
    const parsedValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const [initialTotalParts, setTotalParts] = React.useState<number>(0);
  const [initialTotalLabor, setTotalLabor] = React.useState<number>(0);

  const handleUpdate = (parts: number, labor: number) => {
    setTotalParts(parts);
    setTotalLabor(labor / 1.12);
  };

  const [isTotalLoss, setIsTotalLoss] = React.useState<boolean>(false);

  const handleIsTotalLossChange = (percentLoss: number) => {
    const newIsTotalLoss = percentLoss >= 75;
    setIsTotalLoss(newIsTotalLoss);
  };

  const updateTotalDeductible = (amount: number, totalNumber: number) => {
    const totalDeductible = totalNumber * 1;
    setTotalDeductibleAmount(totalDeductible);
  };

  const handleUpdateCart = (newCart: CartItem[]) => {
    setCart(newCart);
  };
  const updateGrossTotal = (amount: number, laborVat: number) => {
    setTotalAmount(amount);
    setLaborPlusVat(laborVat);
    const grossTotal = amount + laborVat;
    setOurOfferGrossTotal(grossTotal);
  };



  const optionsLoss: OptionType[] = [
    { value: 1, label: "Pending for Offer" },
    { value: 2, label: "Third Party Damage" },
    { value: 3, label: "Theft" },
  ];

  const [mainState, setMainState] = React.useState<any>({
    data: {},
    statusOptions:
      globalData?.role === "Head Clec"
        ? [
          { value: 24, label: "Approve" },
          { value: 5, label: "For Review By Clec" },
          { value: 18, label: "Pending Adjuster's Report" },
        ]
        : isTotalLoss
        ? [
          { value: 4, label: "Forward To Head CLEC" },
          { value: 18, label: "Pending Adjuster's Report" }
       
        ]
        : [
          { value: 3, label: "Open" },
          { value: 9, label: "Pending for Offer" },
          // { value: 4, label: "Forward To Head CLEC" },
          { value: 18, label: "Pending Adjuster's Report" },
         
        ],
  });

  const [totalDepreciation, setTotalDepreciation] = React.useState(0);
  const [totalPartsAmount, setTotalOfferAmount] = React.useState(0);

  const handleTotalDepreciationChange = (newTotalDepreciation: number) => {
    setTotalDepreciation(newTotalDepreciation);
  };

  const handleTotalOfferAmountChange = (newTotalOfferAmount: number) => {
    setTotalOfferAmount(newTotalOfferAmount);
  };


  const [userData, setUserData] = React.useState<any>({});
  const [isDisable, setIsDisable] = React.useState<boolean>(false);
  const { transId } = useParams();
  const navigate = useNavigate();
  const [fmv, setFmv] = React.useState<number>(0);
  const [salvage, setSalvage] = React.useState<number>(100000); 

  const handleSelectChange = (
    selection: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {

    setMainState({
      ...mainState,
      data: { ...mainState.data, status: selection?.value },
      selections: { status: selection },
    });
  };

  const fetchDeTaskData = async () => {
    try {
      setMainState({ ...mainState, isLoading: true });
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`
      );
      const data = res.data.data;
      const statusOptions =
        globalData?.role === "Head Clec"
          ? [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 5, label: "Reject" },
          ]
          : [
            { value: 9, label: "Pending for Offer" },
            { value: 18, label: "Pending Adjuster's Report" },
            { value: 4, label: "Forward To Head CLEC" },
            { value: 5, label: "Reject" },
          ];
      setDeFromDb({
        ...deFromDb,
        data: data,
      });
      setIsDisable(globalData?.role === "Clec" && data?.status === 4);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const apiSubmitEmail = async (endpoint: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`);
    } catch (err: any) {
      console.error(err?.response?.data);
    }
  };

  // #region fetchShopItems
  const fetchShopItems = async () => {
    try {
      (async () => {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/api/acap/shop-estimate/single?transId=${transId}`,
          // headers: {
          //   'Authorization': `Bearer ${globalData?.sessionToken}`
          // }
        })
          .then((res: any) => {

            const data = res?.data?.data;
            const tblShopItemsJSON = JSON.parse(data?.tableItems);


            // Convert rows into a list of JSON objects
            const tblShopItemRows = tblShopItemsJSON?.rows.map((row: any) => {
              // Generate a unique ID for each row
              const id = uuidv4();

              // Handle the case where row is empty
              if (row.length === 0) {
                return tblShopItemsJSON?.header.reduce(
                  (acc: any, key: any) => {
                    acc[key] = "";
                    return acc;
                  },
                  { id }
                ); // Include unique id in the object
              }

              return row.reduce(
                (acc: any, value: any, index: any) => {
                  acc[tblShopItemsJSON?.header[index]] = value;
                  return acc;
                },
                { id }
              ); // Include unique id in the object
            });



            setShopItemTable({
              shopEstimate: data,
              headers: tblShopItemsJSON?.header,
              rows: tblShopItemRows,
            });

            // setTotalRepairersAmount(data?.totalAmount);
            // setTotalLaborAmount(data?.totalLabor);


          })
          .catch((err: any) => {
            setShopItemTable({
              shopEstimate: null,
              headers: null,
              rows: null,
            });

            console.error(err);
            let errorMessage = "";
            if (err?.response?.status === 401)
              errorMessage = err?.response?.data;
            if (err?.response?.status === 400)
              errorMessage = err?.response?.data;
          });
      })();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const patchStatusAndUser = async (docIdFromDe:Number) => {
    try {
      // 1. Fetch Head CLEC Users
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/accounts/all`,
        {
          params: { rolename: 'Head Clec' },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const headClecUsers = response.data.data;
      if (!headClecUsers || headClecUsers.length === 0) {
        throw new Error('No Head Clec users found.');
      }
  
      // 2. Determine the Head CLEC User ID
      const headClecUser =
        globalData?.role === 'Head Clec' ? 89 : headClecUsers[0].id;
  
      // 3. Determine Document Status
      const docStatus = mainState?.selections?.status?.value;
      if (typeof docStatus !== 'number') {
        throw new Error('Invalid document status.');
      }
  
      // 4. First PATCH Request: Update Dedetails
      const headclecApprove = globalData?.role === 'Head Clec' ? 1 : 0;

      await Promise.all([
        axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transId}`,
          {
        status: 25,
        headclecApprove: headclecApprove,
          },
          {
        headers: {
          'Content-Type': 'application/json',
        },
          }
        ),
        axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
        status: docStatus,
        userId: headClecUser,
          },
          {
        params: {
          transId: transId,
          id: docIdFromDe,
        },
        headers: {
          'Content-Type': 'application/json',
        },
          }
        ),
      ]);
  
      // 6. Navigate After Successful Updates
      navigate('/damage-evaluation');
    } catch (error: any) {
      console.error('Error in patchStatusAndUser:', error);
  
      // Optionally, display an error message to the user
      // For example, using a state variable to show a notification
      // setErrorMessage(error.message || 'An unexpected error occurred.');
    }
  };

  const handleSubmit = async () => {
    const deDetailsResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/dedetails/${transId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      
      }
    );
 
    
    const deDetailsData = await deDetailsResponse.json();
    const status = deDetailsData?.status;

    const deTaskResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task/${transId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      
      }
    );
    const deTaskData = await deTaskResponse.json();
    const docId = deTaskData?.data?.id;
    


    setSubmitting(true);
    if (
      mainState?.selections?.status?.value === 4 &&
      globalData?.role === "Clec" && status === 3
    ) {
      // alert('pasok kay senior clec')
      setSubmitting(false);
      patchStatusAndUser(docId);

    } else if (
      mainState?.selections?.status?.value === 9 &&
      globalData?.role === "Clec" && status === 25
    ) {
      
      setSubmitting(false);
      try {
        // 1. Fetch Head CLEC Users
         await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/acap/damage-evaluation/task`,
          {
            status: 9,
            userId: globalData?.id,
          },
          {
            params: {
              transId: transId,
              id: docId,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

    
      }catch (error: any) {
        console.error('Error in patchStatusAndUser:', error);
      }

      
      handleExternalCall();
      const endpoint = `/api/acap/email/ca`
      const emailRequest = { email: "abperez@3rd-pillar.com", claimNo: deDetailsData?.claimNo };
   
      axios.post(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
        email: emailRequest.email,
        claimNo: emailRequest.claimNo,
      })
      navigate("/damage-evaluation");
    } else if (
      mainState?.selections?.status?.value === 3 &&
      globalData?.role === "Head Clec" && status === 25
    ) {
      setSubmitting(false);
      patchStatusAndUser(docId);
    } else {
      // Proceed with the existing logic
     
      handleExternalCall();

      // await apiSubmitEmail(
      //   `/api/acap/damage-evaluation/task/approve?transId=${transId}`
      // );
      setSubmitting(false);
      navigate("/damage-evaluation");
    }
   
  };

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    setUserData(userData);
    fetchDeTaskData();
    fetchShopItems();
  }, []);

  return (
    <Container maxWidth={"xl"} sx={{ my: 12 }}>
      {/* <IconButton onClick={() => navigate(-1)} disableRipple={true} aria-label="open" sx={{ my: 2, padding: 0 }}>
                <ArrowBackIcon sx={{ height: 28, width: 28 }} />
                <Typography sx={{ ml: 1 }}>Back</Typography>
            </IconButton> */}
      <Card sx={{ my: 4 }}>
        <CardContent className="tp-mlr-50px tp-mtb-20px">
          <Grid container spacing={2}>
            <Grid item xs={9} sm={9} md={9}>
              <Typography variant="h4" className="tp-text-header-primary">
                Damage Evaluation
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className="tp-text-input-sub"
              >
                Note: Please check all Required Fields with (
                <span className="tp-text-required">*</span>) before you submit.
              </Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Peril Type:{" "}
                <span className="tp-text-default">Own Damage</span>
              </Typography>
            </Grid>
            <Grid item>
              <br />
              <Typography variant="h5" className="tp-text-header-primary">
                Claim No: <span className="tp-text-default">CNTEST</span>
              </Typography>
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />

          <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h5" className="tp-text-header-primary">
                <span className="tp-text-default">Transaction No.: &ensp;</span>
                ACAP - {transId}
              </Typography>
              <Typography variant="body1" className="tp-text-header-primary">
                <span className="tp-text-default">Date Received: &ensp;</span>
                2024-09-16
                {/* {formatDate(mainState?.data?.saveDate)? formatDate(mainState?.data?.saveDate): "2021-09-01"} */}
              </Typography>
              <br />
              <br />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Insured:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    Jimmy John Janancial
                  </Typography>
                </Grid>
              </Grid>
              <hr />
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="body1" className="tp-text-bold">
                    Policy No.:
                  </Typography>
                </Grid>
                <Grid item md={6} textAlign="right">
                  <Typography variant="body1" className="tp-text-primary">
                    {mainState?.data?.policyNo
                      ? mainState?.data?.policyNo
                      : "PC834899"}
                  </Typography>
                </Grid>
              </Grid>
              <hr />

              <br />
              <Typography variant="h6" className="tp-text-header-primary">
                List of Items
                <Typography
                  variant="caption"
                  display="block"
                  className="tp-text-subtitle"
                >
                  Note: These are the available data in OCR Process.
                </Typography>
              </Typography>

              {/* Estimation Table */}
              <EstimationTable transID={transId} />
              <TextField
                label="Total Parts Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalRepairersAmount)}
                onChange={(e) => handleNumberChange(e, setTotalRepairersAmount)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Total Labor Amount"
                variant="outlined"
                type="text"
                value={formatNumber(totalLaborAmount)}
                onChange={(e) => handleNumberChange(e, setTotalLaborAmount)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <br />
            {
              // #endregion Estimation Table
            }
            {
              // #region Document Summary
            }
            <Grid item xs={4} sm={4} md={4}>
              {shopItemTable?.shopEstimate !== null && (
                <React.Fragment>
                  {mainState?.data?.esfGdriveField ? (
                    <>
                      <Typography
                        variant="h6"
                        className="tp-text-header-primary"
                      >
                        Document Summary
                      </Typography>
                      <Typography variant="body1" className="tp-mt-15px">
                        Filename: &ensp;
                        <span className="tp-text-primary">Estimation Form</span>
                      </Typography>
                      <Typography variant="body1" className="tp-mb-15px">
                        Date Received: &ensp;
                        <span className="tp-text-primary">
                          {formatDate(mainState?.data?.saveDate)}
                        </span>
                      </Typography>
                      <hr />
                      {mainState?.data?.esfGdriveFileType === "pdf" ? (
                        <iframe
                          width={"100%"}
                          height={"600"}
                          src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/view?usp=sharing`}

                          title="pdf"
                        ></iframe>
                      ) : (
                        <iframe
                          width={"100%"}
                          height={"600"}
                          src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/view?usp=sharing`}
                          title="jpg"
                        ></iframe>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        className="tp-text-header-primary"
                      >
                        Document Summary
                      </Typography>
                      <Typography variant="body1" className="tp-mt-15px">
                        Filename: &ensp;
                        <span className="tp-text-primary">Estimation Form</span>
                      </Typography>
                      <Typography variant="body1" className="tp-mb-15px">
                        Date Received: &ensp;
                        <span className="tp-text-primary">2024-08-16</span>
                        {/* https://drive.google.com/file/d/1D8MvvIZeUlSuQnFBmffXOqpDKpBivo2I/view?usp=sharing */}
                      </Typography>
                      <hr />
                      <iframe
                        width={"100%"}
                        height={"600"}
                        src={`https://drive.google.com/file/d/1PXZ6EljdiB1A2H7P39ePvOli8VN8DH_e/preview`}

                        title="pdf"
                      ></iframe>
                    </>
                  )}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <hr className="tp-border-hr_primary" />
          <br />

          {/* Client Transaction */}
          <ClientTransactionLink
            transID={transId}
            data={mainState?.data}
            lossReserveParts={lossReserveParts}
            setLossReserveParts={setLossReserveParts}
            lossReserveLabor={lossReserveLabor}
            setLossReserveLabor={setLossReserveLabor}
            initialLaborAmount={initialTotalLabor}
            initialPartsAmount={initialTotalParts}
            updateGrossTotal={updateGrossTotal}
            onLaborDataChange={handleLaborDataChange}
            updateTotalDeductible={updateTotalDeductible}
            updateCart={handleUpdateCart}
            onTotalDepreciationChange={handleTotalDepreciationChange}
            onTotalOfferAmountChange={handleTotalOfferAmountChange}
            totalRepairersAmount={totalRepairersAmount}
            totalLaborAmount={totalLaborAmount}
            setTotalLaborAmount={setTotalLaborAmount}
            selectedBrand={selectedBrand}
            selectedModel={selectedModel}
            mainState={mainState}
            setMainState={setMainState}
            onIsTotalLossChange={handleIsTotalLossChange}
            fmv={fmv}
            setFmv={setFmv}
            salvage={salvage}
            setSalvage={setSalvage}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                className="tp-text-header-primary"
                textAlign="center"
              >
                CLEC Reports
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5" className="tp-text-header-primary">
                Insurer Report
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {isTotalLoss ? (
                <GenerateInsurerTotalLoss
                  ref={insurerRef}
                  repairersParts={totalRepairersAmount}
                  repairersLabor={totalLaborAmount}
                  laborData={laborData}
                  laborPlusVat={laborPlusVat}
                  totalAmount={totalAmount}
                  totalDepreciation={totalDepreciation}
                  totalPartsAmount={totalPartsAmount}
                  totalDeductible={totalDeductibleAmount}
                  ourOfferGrossTotal={ourOfferGrossTotal}
                  partsCart={cart}
                  transId={transId}
                />
              ) : (
                <GenerateInsurer
                  ref={insurerRef}
                  repairersParts={totalRepairersAmount}
                  repairersLabor={totalLaborAmount}
                  laborData={laborData}
                  laborPlusVat={laborPlusVat}
                  totalAmount={totalAmount}
                  totalDepreciation={totalDepreciation}
                  totalPartsAmount={totalPartsAmount}
                  totalDeductible={totalDeductibleAmount}
                  ourOfferGrossTotal={ourOfferGrossTotal}
                  partsCart={cart}
                  transId={transId}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2}>
              <Typography variant="h5" className="tp-text-header-primary">
                Computation Sheet
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              {isTotalLoss ? (
                <ComputationSheetTotalLoss fmv={fmv} salvage={salvage} deductible={totalDeductibleAmount}/>
              ) : (

                <ComputationSheet
                  laborAndMaterials={laborAndMaterials + laborAndMaterials * 0.12}
                  totalLabor={laborAndMaterials}
                  regularParts={regularParts}
                  depreciation={totalDepreciation}
                  deductible={totalDeductibleAmount}
                  totalLossReserve={lossReserveParts + lossReserveLabor}
                  deDetails={deDetails}
                  deId={deId}
                />
              )}
            </Grid>
          </Grid>
          <br />

          <DocumentTable />
          <br />
          <br />
          {/* <Grid container spacing={2}>
                        <Grid item xs={2} sm={2} md={2}>
                            <Typography variant="h5" className="tp-text-header-primary">
                                Loa Generation
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3}>
                            <Button color="info"
                                variant="contained">Generate LOA</Button>
                        </Grid>
                    </Grid> */}

          {/* For Processor */}
          <ForProcessor
        mainState={mainState}
        setMainState={setMainState}
        isDisable={isDisable}
        isTotalLoss={isTotalLoss}
        handleSelectChange={handleSelectChange}
      />
            <TaskRemarks />
      {
        // #endregion For Processor
      }

          <hr className="tp-border-hr_primary" />
          <br />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className="tp-text-header-primary">
              Login As User: &ensp;
              <span style={{ color: "#525252" }}>
                {toTitleCase(globalData?.firstName)}{" "}
                {toTitleCase(globalData?.lastName)}
              </span>
            </Typography>
            <Button
          disabled={isDisable || submitting}
          className="tp-custom-button-primary"
          variant="contained"
          onClick={handleSubmit}
          aria-label="submit-button"
        >
          {submitting ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
          </Box>
          <br />
        </CardContent>
      </Card>
    </Container>
  );
}
